import { createContext, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Translate from 'i18n-react-json';
import locales from '../locales';
import {
  Img10_1,
  Img10_2,
  Img11_1,
  Img12_1,
  Img12_2,
  Img13_1,
  Img14_1,
  Img14_2,
  Img15_1,
  Img15_2,
  Img16_1,
  Img16_2,
  Img17_1,
  Img17_2,
  Img18_1,
  Img18_2,
  Img19_1,
  Img19_2,
  Img1_1,
  Img1_2,
  Img1_3,
  Img20_1,
  Img20_2,
  Img2_1,
  Img2_2,
  Img3_1,
  Img3_2,
  Img3_3,
  Img3_4,
  Img3_5,
  Img4_1,
  Img4_2,
  Img4_3,
  Img4_4,
  Img4_5,
  Img4_6,
  Img5_1,
  Img5_2,
  Img5_3,
  Img6_1,
  Img6_2,
  Img6_3,
  Img7_1,
  Img7_2,
  Img7_3,
  Img8_1,
  Img8_2,
  Img9_1,
  ImgW1_1,
  ImgW1_2,
  ImgW1_3,
  ImgW2_1,
  ImgW2_2,
  ImgW3_1,
  ImgW3_2,
  ImgW3_3,
  ImgW3_4,
  ImgW3_5,
  ImgW4_1,
  ImgW4_2,
  ImgW4_3,
  ImgW4_4,
  ImgW4_5,
  ImgW4_6,
  ImgW5_1,
  ImgW5_2,
  ImgW5_3,
  ImgW6_1,
  ImgW6_2,
  ImgW6_3,
  ImgW7_1,
  ImgW7_2,
  ImgW7_3,
  ImgW8_1,
  ImgW8_2,
  ImgW9_1,
  ImgW10_1,
  ImgW10_2,
  ImgW11_1,
  ImgW12_1,
  ImgW12_2,
  ImgW13_1,
  ImgW14_1,
  ImgW14_2,
  ImgW15_1,
  ImgW15_2,
  ImgW16_1,
  ImgW16_2,
  ImgW17_1,
  ImgW17_2,
  ImgW18_1,
  ImgW18_2,
  ImgW19_1,
  ImgW19_2,
  ImgW20_1,
  ImgW20_2,
  Img22_2,
  Img22_1,
  ImgW22_2,
  ImgW22_1,
  ImgW21_2,
  ImgW21_1,
  Img21_2,
  Img21_1,
  ImgW23_1,
  ImgW24_1,
  Img24_1,
  ImgW24_2,
  Img24_2,
  ImgW24_3,
  Img24_3,
  ImgW25_1,
  Img25_1,
  ImgW25_2,
  Img25_2,
  ImgW25_3,
  Img25_3,
  ImgW30_2,
  Img30_2,
  Img30_1,
  ImgW30_1,
  ImgW30_3,
  Img30_3,
  ImgW30_4,
  Img30_4,
  ImgW30_5,
  Img30_5,
  Img29_1,
  ImgW29_1,
  ImgW29_2,
  Img29_2,
  ImgW29_3,
  Img29_3,
  ImgW29_4,
  Img29_4,
  Img28_2,
  ImgW28_2,
  Img28_1,
  ImgW28_1,
  ImgW28_3,
  Img28_3,
  ImgW28_4,
  Img28_4,
  ImgW28_5,
  Img28_5,
  ImgW28_6,
  Img28_6,
  Img27_1,
  ImgW27_1,
  ImgW27_2,
  Img27_2,
  ImgW27_3,
  Img27_3,
  ImgW27_4,
  Img27_4,
  ImgW27_5,
  Img27_5,
  Img26_1,
  ImgW26_1,
  ImgW26_2,
  Img26_2,
  ImgW26_3,
  Img26_3,
  ImgW26_4,
  Img26_4,
  ImgW26_5,
  Img26_5,
  Img31_1,
  ImgW31_1,
  ImgW32_1,
  Img32_1,
  Img32_2,
  Img32_3,
  Img32_4,
  ImgW32_2,
  ImgW32_3,
  ImgW32_4,
  Img33_1,
  Img33_2,
  Img33_3,
  Img33_4,
  Img33_5,
  ImgW33_1,
  ImgW33_2,
  ImgW33_3,
  ImgW33_4,
  ImgW33_5,
  Img34_1,
  Img34_2,
  ImgW34_1,
  ImgW34_2,
  Img35_1,
  Img35_2,
  Img35_3,
  ImgW35_1,
  ImgW35_2,
  ImgW35_3,
  Img36_1,
  ImgW36_1,
  ImgW36_2,
  Img36_2,
  Img36_3,
  ImgW36_3,
  Img37_1,
  ImgW37_1,
  Img37_2,
  ImgW37_2,
  Img37_3,
  ImgW37_3,
  Img37_4,
  ImgW37_4,
  Img37_5,
  ImgW37_5,
  Img37_6,
  ImgW37_6,
  Img38_1,
  ImgW38_1,
  Img38_2,
  ImgW38_2,
  Img38_3,
  ImgW38_3,
  Img39_1,
  ImgW39_1,
  Img39_2,
  ImgW39_2,
  Img39_3,
  ImgW39_3,
  Img40_1,
  ImgW40_1,
  Img40_2,
  ImgW40_2,
  Img40_3,
  ImgW40_3,
  Img40_4,
  ImgW40_4,
  Img41_1,
  ImgW41_1,
  Img41_2,
  ImgW41_2,
  Img41_3,
  ImgW41_3,
  Img42_1,
  ImgW42_1,
  Img42_2,
  ImgW42_2,
  Img42_3,
  ImgW42_3,
  Img42_4,
  ImgW42_4,
  Img42_5,
  ImgW42_5,
  Img43_1,
  ImgW43_1,
  Img43_2,
  ImgW43_2,
  Img43_3,
  ImgW43_3,
  Img43_4,
  ImgW43_4,
  Img43_5,
  ImgW43_5,
  Img43_6,
  ImgW43_6,
  Img44_1,
  ImgW44_1,
  Img44_2,
  ImgW44_2,
  Img44_3, 
  ImgW44_3,
  Img45_1,
  ImgW45_1,
  Img45_2,
  ImgW45_2,
  Img46_1,
  ImgW46_1,
  Img46_2,
  ImgW46_2,
  Img47_1,
  ImgW47_1,
  Img47_2,
  ImgW47_2,
  Img48_1,
  ImgW48_1,
  Img48_2,
  ImgW48_2,
  Img48_3,
  ImgW48_3,
  Img49_1,
  ImgW49_1,
  Img49_2,
  ImgW49_2,
  Img49_3,
  ImgW49_3,
  Img50_1,
  ImgW50_1,
  Img50_2,
  ImgW50_2,
  Img50_3,
  ImgW50_3,
  Img50_4,
  ImgW50_4,
  Img50_5,
  ImgW50_5,
  Img51_1,
  ImgW51_1,
  Img51_2,
  ImgW51_2,
  Img51_3,
  ImgW51_3,
  Img51_4,
  ImgW51_4,
  Img52_1,
  ImgW52_1,
  Img52_2,
  ImgW52_2,
  Img52_3,
  ImgW52_3,
  Img53_1,
  ImgW53_1,
  Img53_2,
  ImgW53_2,
  Img53_3,
  ImgW53_3,
  Img53_4,
  ImgW53_4,
  Img53_5,
  ImgW53_5,
  Img54_1,
  ImgW54_1,
  Img54_2,
  ImgW54_2,
  Img54_3,
  ImgW54_3,
  Img54_4,
  ImgW54_4,
  Img55_1,
  ImgW55_1,
  Img55_2,
  ImgW55_2,
  Img55_3,
  ImgW55_3,
  Img55_4,
  ImgW55_4,
  Img55_5,
  ImgW55_5,
  Img55_6,
  ImgW55_6,
  Img56_1,
  ImgW56_1,
  Img56_2,
  ImgW56_2,
  Img56_3,
  ImgW56_3,
  Img56_4,
  ImgW56_4,
  Img56_5,
  ImgW56_5,
  Img57_1,
  ImgW57_1,
  Img57_2,
  ImgW57_2,
} from '../assets/img/articles';
import mp4 from '../assets/video/article.mp4';

import { MainPage, NotFoundPage, ArticlePage, BlogPage } from '../pages';
import Layout from './Layout/Layout';
import Picture from '../components/Picture';

const lang = window.location.hostname.split('.')[0] === 'en' ? 'en' : 'ru';
export const Context = createContext<any>({});

const Application: React.FC = () => {
  const i18n = new Translate(locales);
  i18n.setLocale(lang);
  const [isModalCTX, setModalCTX] = useState<boolean>(false);

  const configArticles = [
    {
      id: 'recepty-rollov-s-mikrozelenju-polezno-i-vkusno',
      name: 'Роллы с микрозеленью: вкусные и полезные рецепты',
      title: 'Рецепты роллов с микрозеленью - полезно и вкусно',
      descr: 'Используем микрозелень в приготовлении роллов: из киноа; с огурцом; Филадельфия; с лимонной карамелью; с креветкой; с тунцом; с лососем.',
      bg: [Img57_1, ImgW57_1],
      date: '23.09.2024',
      content: (
        <>
        <p><b>Роллы — очень популярное блюдо</b>, которое достаточно простое в приготовлении. Рецептов и форматов его существует много, так что каждый найдет вариант для себя. А чтобы оно стало еще более полезным, стоит добавить к основным ингредиентам <Link to="/blog/obzor-pljusov-i-minusov-domašnego-vyraŝivanija-mikrozeleni">микрозелень</Link>. Она добавит приятных вкусовых нот, а также поможет улучшить состояние организма</p>
        <Picture
            png={Img57_2}
            webp={ImgW57_2}
            alt="Какая микрозелень улучшает работу мозга"
        />
        <h2>Мини-роллы из киноа с красной рыбой</h2>
        <p>Эта интерпретация роллов выглядит очень интересно. Вкусовые сочетания гарантированно запомнятся вам надолго. Микрозелень вы можете брать любую, какая вам больше нравится. Количество тоже варьируйте по своему усмотрению.</p>
        <p><b>Ингредиенты:</b></p>
        <ul>
          <li>нори — 1 шт.;</li>
          <li>сливочный сыр — 50 г;</li>
          <li>авокадо — ½ шт.;</li>
          <li>слабосоленая форель — 100 г</li>
        </ul>
        <p><b>Для подачи:</b></p>
        <ul>
          <li>подсолнечные семечки;</li>
          <li>кешью;</li>
          <li>лук-шалот;</li>
          <li>рисовый уксус;</li>
          <li>маринованный имбирь;</li>
          <li>васаби;</li>
          <li>соевый соус;</li>
          <li>микрозелень;</li>
          <li>тамариндовая паста;</li>
          <li>киноа</li>
        </ul>
        <p><b>Приготовление:</b></p>
        <ul>
          <li>Помойте авокадо, удалите кожицу и косточку. Также подготовьте лук шалот.</li>
          <li>Возьмите блендер, ковш и циновку для готовки роллов.</li>
          <li>Киноа отварите на небольшом огне — понадобится 15 минут.</li>
          <li>Лук нарежьте кусками произвольных размеров.</li>
          <li>В чаше блендера перемешайте лук, кешью, киноа и семечки. Сдобрите соусом тамаринд и уксусом. Перебейте блендером все до состояния однородной и вязкой массы.</li>
          <li>Переместите смесь в чистую посуду. Прикройте чистой тканью и оставьте на 10 минут.</li>
          <li>Форель и авокадо измельчите — лучше брусками.</li>
          <li>На лист нори поместите подготовленную основу под начинку. Распределите по всему периметру.</li>
          <li>В середину положите авокадо и форель. Смажьте сливочным сыром и скрутите роллы.</li>
        </ul>
        <p>Готовые роллы посыпьте любимой микрозеленью. Подавайте с соевым соусом, васаби и имбирем по вкусу.</p>
        <h2>Роллы с огурцом и микрозеленью</h2>
        <p>Роллы с огурцом можно разнообразить добавлением микрозелени. В приготовлении нет ничего сложного. Можно добавлять дополнительные ингредиенты по своему желанию.</p>
        <p><b>Ингредиенты:</b></p>
        <ul>
          <li>рис для суши — 200 г;</li>
          <li>вода — 200 мл;</li>
          <li>рисовый уксус — 2,5 ст. л.;</li>
          <li>сахар — 1 ч. л.;</li>
          <li>соль — ¼ ч. л.;</li>
          <li>нори — 3 шт.;</li>
          <li>огурец — 1 шт.;</li>
          <li>жареный кунжут — 5 ч. л.;</li>
          <li>микрозелень</li>
        </ul>
        <p><b>Для подачи:</b></p>
        <ul>
          <li>соевый соус;</li>
          <li>васаби;</li>
          <li>маринованный имбирь</li>
        </ul>
        <p><b>Приготовление:</b></p>
        <ul>
          <li>Промойте рис холодной водой, пока она не станет чистой.</li>
          <li>Раскалите сковородку и поджарьте кунжут до золотистого оттенка.</li>
          <li>Очистите огурец и нарубите соломкой средней толщины. Промойте микрозелень.</li>
          <li>Разложите продукты и возьмите циновку, а также пищевую пленку.</li>
          <li>Рис отправьте в кастрюлю и залейте водой. Дайте содержимому посуды вскипеть, затем сбавьте огонь и готовьте еще 15 минут.</li>
          <li>Уберите кастрюлю с плиты. Дайте рису постоять еще около 10 минут.</li>
          <li>Для приготовления заправки перемешайте рисовый уксус, сахар и соль в кастрюле. Варите на небольшом огне до растворения сыпучих ингредиентов.</li>
          <li>Как только рис настоится, смешайте его с заправкой.</li>
          <li>Положите на циновку пищевую пленку, сверху — лист нори. Распределите рис, в центр выложите огурец и микрозелень, посыпьте семенами кунжута.</li>
          <li>Скрутите плотный ролл. Нарежьте порционными кусочками.</li>
        </ul>
        <p>Подавайте роллы с имбирем и васаби, а также соевым соусом.</p>
        <h2>Филадельфия с микрозеленью</h2>
        <p>Филадельфию можно назвать классикой среди роллов. <Link to="/blog/mikrogrin-dlja-borby-s-ustalostju-i-povyšenija-ènergii">Полезная микрозелень</Link> добавит в привычный вкус новых нот. Так что, если вам хотелось попробовать что-то необычное, обязательно возьмите на заметку этот рецепт.</p>
        <p><b>Ингредиенты:</b></p>
        <ul>
          <li>рис для суши — большая горсть;</li>
          <li>лосось слабосоленый — 100 г;</li>
          <li>нори — ½ шт.;</li>
          <li>сливочный сыр — 100 г.</li>
        </ul>
        <p><b>Приготовление:</b></p>
        <ul>
          <li>Перед началом работы отварите рис до готовности.</li>
          <li>Рыбу перед началом приготовления для простоты можно немного подморозить.</li>
          <li>Половину листа нори уложите глянцевой стороной на циновку, обернутую пленкой.</li>
          <li>Возьмите руками горсть риса и выровняйте по нори. Оставьте примерно сантиметр без крупы с одной стороны, а со второй стороны — наоборот, выступите ею за край на сантиметр.</li>
          <li>Аккуратно накройте нори циновкой, чтобы рис был внизу, и переверните.</li>
          <li>Раскройте циновку. Рис должен быть внизу, нори — вверху.</li>
          <li>Смешайте сливочный сыр с микрозеленью. Положите по центру нори, распределяя столовой ложкой.</li>
          <li>Скрутите ролл таким образом, чтобы рис с выступающего края соединился с рисом на нори.</li>
          <li>Зафиксируйте круглое сечение ролла и раскройте.</li>
          <li>Рыбу порежьте очень тонкими слайсами. Выложите получившиеся кусочки плотно друг к другу на пленке перед роллом.</li>
          <li>Оберните ролл кусочками рыбы с помощью циновки.</li>
          <li>12.	Оберните полученную конструкцию пищевой пленкой. Нарежьте на несколько кусков.</li>
        </ul>
        <p>Как и всегда, подавайте роллы с васаби и имбирем.</p>
        <h2>Оригинальный ролл с микрозеленью и лимонной карамелью</h2>
        <p>Наверняка раньше вы не пробовали такие необычные роллы. Лимонная карамель и микрозелень необычно сочетаются друг с другом. Кстати, хотя в данном рецепте используется филе тунца, вы можете взять другую рыбу, если хочется разнообразия.</p>
        <p><b>Ингредиенты:</b></p>
        <ul>
          <li>рис для суши — 60 г;</li>
          <li>нори;</li>
          <li>сливочный сыр;</li>
          <li>свежий огурец;</li>
          <li>филе тунца;</li>
          <li>заправка для суши;</li>
          <li>лимонная карамель;</li>
          <li>микрозелень.</li>
        </ul>
        <p><b>Приготовление:</b></p>
        <ul>
          <li>Очень важно для роллов правильно приготовить рис. Для этого несколько раз промойте его под водой — она должна в итоге стать прозрачной.</li>
          <li>Залейте крупу водой в кастрюле и поставьте на умеренный огонь. Доведите до кипения.</li>
          <li>Не открывая крышку, сбавьте пламя до минимального. Готовьте 15 минут.</li>
          <li>Далее оставьте рис на 10 минут, он должен как следует настояться.</li>
          <li>Затем заправьте рис заправкой. Ее можно приготовить из рисового уксуса, подсластителя и соли. Заправкой заливают рис, пока он еще горячий.</li>
          <li>Бамбуковый коврик затяните пищевой пленкой.</li>
          <li>Лист нори разрежьте на две части. Смочите руки заправкой и поместите на водоросль слой риса. 2 сантиметра снизу оставьте без крупы. Сверху же рис должен выступать на те же 2 сантиметра.</li>
          <li>Переверните нори с рисом так, чтобы водоросли оказались наверху. Чайной ложкой положите сливочный сыр и огурец, нашинкованный брусками. Скатайте ролл полукруглой формы. Самое главное — не давите на него сильно.</li>
          <li>Ножом нарежьте рыбу на тонкие слайсы. Выложите ровным слоем так, чтобы на коврике не было просветов.</li>
          <li>Сверху тунца положите готовый рисовый ролл. Осторожно оберните, прижимая рыбу.</li>
          <li>Острый нож смочите водой и отрежьте неровные края ролла.</li>
          <li>Нарежьте изделие небольшими кусочками. Смажьте лимонной карамелью и посыпьте микрозеленью.</li>
        </ul>
        <p>Лимонная карамель добавит блюду пикантную кислинку. Лучше всего готовить ее самостоятельно, а не покупать в магазине.</p>
        <h2>Роллы с креветкой и микрозеленью</h2>
        <p>Роллы с креветкой пользуются большой популярностью. Людям нравится продуманное до мелочей сочетание ингредиентов. А микрозелень позволяет разнообразить привычную гамму.</p>
        <p><b>Ингредиенты:</b></p>
        <ul>
          <li>креветки очищенные небольшие;</li>
          <li>рис — 250 г;</li>
          <li>вода — 280 мл;</li>
          <li>рисовый уксус — 30 мл;</li>
          <li>соль;</li>
          <li>сахар — 1,5 ст. л.;</li>
          <li>мука — 20 г;</li>
          <li>вода — 10 мл;</li>
          <li>нори — 2 шт.;</li>
          <li>панировочные сухари;</li>
          <li>свежий огурец — 1 шт.;</li>
          <li>крем-сыр;</li>
          <li>микрозелень;</li>
          <li>растительное масло.</li>
        </ul>
        <p><b>Приготовление:</b></p>
        <ul>
          <li>Сварите креветки до готовности. Обычно на это требуется около двух минут. Остудите и при необходимости нарежьте.</li>
          <li>Несколько раз промойте рис и сложите в кастрюлю. Залейте водой, прикройте крышкой и доведите до кипения на сильном огне.</li>
          <li>Уменьшите огонь до минимального и готовьте рис 15 минут.</li>
          <li>Снимите посуду с плиты и уберите крышку. Сверху на кастрюлю положите полотенце. Плотно закройте крышкой и дайте постоять 15 минут.</li>
          <li>Перемешайте уксус с 2/3 чайной ложки соли и сахаром. Дайте сыпучим компонентам полностью раствориться. Отправьте эту заготовку к рису и доведите до однородности.</li>
          <li>Просейте муку и перемешайте ее с водой и солью. Добейтесь того, чтобы не было комков.</li>
          <li>Высыпьте в миску панировочные сухари.</li>
          <li>Огурец нарубите соломкой.</li>
          <li>Замотайте циновку для роллов пищевой пленкой. Положите лист нори и равномерно распределите рис, отступив немного с одного края.</li>
          <li>Выложите на рис креветки, огурцы и смешанный с микрозеленью сыр. Осторожно скатайте ролл при помощи циновки.</li>
          <li>Свободный край смочите водой и еще раз прокатайте рулет в коврике.</li>
          <li>Из оставшихся ингредиентов можно сделать еще один ролл.</li>
          <li>Раскалите в сковороде масло. Поддерживайте огонь на уровне выше среднего.</li>
          <li>Каждый ролл опустите в кляр, а после обваляйте в панировочных сухарях. Поджарьте на растительном масле, доводя до небольшой румяности.</li>
        </ul>
        <p>Как только роллы дойдут до готовности, поделите их на несколько кусочков и подайте к столу.</p>
        <h2>Спринг роллы с тунцом</h2>
        <p>Очень простая и легкая закуска, которая понравится ценителям тунца. Микрозелень дополнит основную вкусовую композицию. При желании тунец вы можете заменить на другую рыбу.</p>
        <p><b>Ингредиенты:</b></p>
        <ul>
          <li>филе тунца желтоперого — 200 г;</li>
          <li>рисовая бумага — 3 шт.;</li>
          <li>нори — 1 шт.;</li>
          <li>огурец — 1 шт.;</li>
          <li>авокадо — ½ шт.;</li>
          <li>микрозелень;</li>
          <li>оливковое масло;</li>
          <li>белый и черный кунжут;</li>
          <li>соль;</li>
          <li>соевый соус.</li>
        </ul>
        <p><b>Приготовление:</b></p>
        <ul>
          <li>Помойте авокадо и нарежьте на кусочки. Аналогичным образом поступите с огурцом.</li>
          <li>Подготовьте тунца: при необходимости разморозьте. Обжарьте его со всех сторон, добавив по вкусу кунжут.</li>
          <li>Смочите рисовую бумагу водой с обеих сторон. Положите ее на доску, с краю расположите кусочек нори и рыбу.</li>
          <li>Сверху разместите авокадо и огурец. Добавьте микрозелень и сверните изделие.</li>
          <li>Поджарьте до легкого колера.</li>
        </ul>
        <p>Готовые роллы подавайте с соевым соусом.</p>
        <h2>Спринг роллы с лососем и огурцом</h2>
        <p>Роллы с лососем — настоящая классика. Они сытные и надолго утоляют чувство голода. Также их можно приготовить на праздничный стол, чтобы порадовать гостей аппетитным угощением.</p>
        <p><b>Ингредиенты:</b></p>
        <ul>
          <li>рисовая бумага — 1 упаковка;</li>
          <li>лосось слабосоленый — 150 г;</li>
          <li>морковь — 1 шт.;</li>
          <li>огурцы — 1 шт.;</li>
          <li>микрозелень.</li>
        </ul>
        <p><b>Для соуса:</b></p>
        <ul>
          <li>соевый соус — 100 мл;</li>
          <li>лимонный сок — 3 ст. л.;</li>
          <li>кунжутное масло — 3 ст. л.;</li>
          <li>мед — 1 ч. л.;</li>
          <li>чеснок — 1 зубчик;</li>
          <li>корень имбиря;</li>
          <li>перец чили.</li>
        </ul>
        <p><b>Приготовление:</b></p>
        <ul>
          <li>Сначала сделайте соус. Мелко нарежьте чеснок и небольшой кусок имбиря. Смешайте их в одной посуде, добавив при желании острый перец. Дайте продукту настояться около получаса.</li>
          <li>Морковь и огурец нарубите соломкой. Рыбу — тонкими кусочками.</li>
          <li>В миску с теплой водой погрузите по очереди листы рисовой бумаги на 10 секунд — непосредственно перед началом работы с ними.</li>
          <li>Положите лист на тонкую поверхность. В середине расположите овощи и рыбу, а также микрозелень.</li>
          <li>Заверните рисовую бумагу в ролл.</li>
        </ul>
        <p>Подавайте приготовленные роллы с соусом. Они отлично сочетаются друг с другом.</p>
        <p>Как видите, есть много рецептов роллов с микрозеленью. Это действительно полезный продукт. При желании вы можете сочетать и любые другие ингредиенты — необычные вкусовые сочетания точно вас порадуют.</p>
        </>
      ),    
    },
    {
      id: 'obzor-mikrozeleni-poleznoj-dlja-raboty-mozga',
      name: 'Какая микрозелень улучшает работу мозга',
      title: 'Обзор микрозелени, полезной для работы мозга',
      descr: 'Виды микрозелени полезной для работы мозга. Набор микрозелени «Быстрый мозг» от Vitamincus. Химический состав микрозелени.',
      bg: [Img56_1, ImgW56_1],
      date: '23.09.2024',
      content: (
        <>
        <p><b>Мы не откроем тайну</b>, если скажем, что головной мозг — важнейший орган человека. Он, подобно компьютеру, способен хранить и обрабатывать информацию. Правильная работа мозга позволяет мыслить и добиваться успеха в различных сферах деятельности. </p>
        <p>В условиях стресса и неполноценного питания этому органу нужна особая забота. Предлагаем узнать: что такое микрозелень, какие витамины для мозга содержит и правда ли, что она так полезна, как о ней говорят.</p>
        <Picture
            png={Img56_2}
            webp={ImgW56_2}
            alt="Какая микрозелень улучшает работу мозга"
        />
        <h2>Микрозелень: что это такое</h2>
        <p><b>Микрозелень</b> — это съедобная часть молодых растений. В высоту всходы достигают 2,5-5 см. Стебельки молоденькие, упругие и сочные. Каждый из них венчается двумя нежными семядольными листочками. Они могут быть окрашены в желтоватый или светло-зеленый цвет, в зависимости от вида. У каждой микрозелени уникальный вкус — от сладкого до пряно-жгучего, что открывает поле для кулинарных экспериментов.</p>
        <p><b>Таким образом, микрогрин </b>— это молодые стебли и листья привычных нам овощей и трав. В настоящее время модно добавлять зеленые всходы в салаты, первое и второе. Но не только ради удовольствия люди едят крошечные молодые ростки. Многим известно о том, как микрозелень влияет на работу мозга и улучшает память.</p>
        <h2>Химический состав микрозелени</h2>
        <p>В 100 граммах микрозелени содержится 31 кКал (усреднённые значения)</p>
        <table>
        <tr>
            <th>Витамины и микроэлементы</th>
            <th>Содержание в 100 граммах</th>
        </tr>
        <tr>
            <td>Витамин С (аскорбиновая кислота)</td>
            <td>93.2 мг</td>
        </tr>
        <tr>
            <td>Витамин РР</td>
            <td>0.638 мг</td>
        </tr>
        <tr>
            <td>Витамин А</td>
            <td>150 мкг</td>
        </tr>
        <tr>
            <td>Витамин В1 (тиамин)</td>
            <td>0.065 мг</td>
        </tr>
        <tr>
            <td>Цинк</td>
            <td>0.4 мг</td>
        </tr>
        <tr>
            <td>Железо</td>
            <td>0.88 мг</td>
        </tr>
        <tr>
            <td>Селен</td>
            <td>3 мкг</td>
        </tr>
        <tr>
            <td>Марганец</td>
            <td>0.229 мг</td>
        </tr>
        <tr>
            <td>Медь</td>
            <td>45 мкг</td>
        </tr>
        </table>
        <Picture
            png={Img56_3}
            webp={ImgW56_3}
            alt="Химический состав микрозелени"
        />
        <h2>Применение в кулинарии</h2>
        <p><b>В наши дни каждый может</b> вырастить микрозелень в домашних условиях или купить её в магазине. Благодаря этому она пользуется популярностью у приверженцев здорового питания. Более того, новые научные исследования доказали, что микрозелень омолаживает мозг и улучшает интеллектуальные способности.</p>
        <p>Всходы редиса, петрушки или базилика едят сразу после среза и только непосредственно перед подачей добавляют в горячие бульоны и соусы. Овощным салатам, закускам и смузи — любому блюду маленькие листья зелени придают свежий и нарядный вид.</p>
        <p><b>Внимание!</b> Не рекомендуется микрозелень подвергать термический обработке, так как при этом теряются все полезные свойства.</p>
        <h2>Виды микрозелени</h2>
        <Picture
            png={Img56_4}
            webp={ImgW56_4}
            alt="Виды микрозелени"
        />
        <p><b>Предлагаем топ-7</b> самых полезных, на наш взгляд, культур микрозелени для выращивания. Ознакомьтесь с ними подробнее и узнайте, действительно ли микрогрин улучшает работу мозга. </p>
        <h3>Руккола</h3>
        <p>В составе рукколы витамины, эфирные масла и минеральные вещества. Стебли и листья рукколы обеспечивают организм витамином С. Микрозелень данной культуры нормализует обмен веществ, помогает выводить холестерин из организма и служит профилактикой железодефицитной анемии. Несравненный орехово-горчичный вкус рукколы придает любому блюду пикантную нотку.</p>
        <h3>Свекла</h3>
        <p>Молодые всходы свеклы отлично оттеняют вкус мясных и рыбных блюд. Они стимулируют мозговую деятельность за счет содержания в составе фолиевой кислоты, цинка и йода. Более того, регулярное употребление листьев свеклы помогает укрепить иммунитет, повысить аппетит и улучшить моторику желудочно-кишечного тракта. </p>
        <h3>Редис</h3>
        <p>Эта мини-зелень характеризуется жгучим вкусом и используется в овощных салатах, мясных блюдах, закусках по-корейски. Листья редиса богаты витаминами и эфирными маслами, улучшающими работу мозга. <Link to="/#delivery">купить микрозелень</Link> редиса с доставкой можно в нашем интернет-магазине.</p>
        <h3>Базилик</h3>
        <p>Базилик славится изысканным ароматом. Он подчеркивает вкус салата из красных томатов, идеален к мясу и рыбе. Молодые листья базилика содержат много аскорбиновой кислоты, витамины группы В и РР, а также эфирные масла, которые повышают концентрацию и стимулируют мозговую деятельность.</p>
        <h3>Горчица</h3>
        <p>Молодые побеги горчицы лидируют по содержанию витамина С и рутина. Врачи-диетологи рекомендуют употреблять их в свежих овощных салатах для укрепления кровеносных сосудов. Листья горчицы обладают острым вкусом, что стимулирует кровообращение и пробуждает мозг.</p>
        <h3>Дайкон</h3>
        <p>Темно-зеленые молоденькие листочки дайкона — кладезь витаминов и минералов. Они гармонично дополняют салаты, бутерброды и напитки-смузи. Нередко повара добавляют микрозелень дайкон в мясные бульоны для придания насыщенного острого вкуса. </p>
        <h3>Кресс-салат</h3>
        <p>Кресс-салат полезен сразу после срезки. В свежем виде он служит источником витаминов группы В, С, РР и микроэлементов. При регулярном употреблении микрогрина кресс-салата укрепляются сосуды головного мозга, улучшается память и мышление. Листья зелени отличаются нежным вкусом. Они используются в нарезках, дополняя приятную хрусткость и сочность свежих овощей.</p>
        <h2>Преимущества употребления микрозелени круглый год</h2>
        <p>Микрозелень — это источник антиоксидантов, микроэлементов и витаминов для мозга. Вот явные преимущества зеленых всходов:</p>
        <ul>
          <li>экологичный продукт, который не содержит в составе пестицидов и нитратов;</li>
          <li>листочки и стебли содержат витаминно-минеральный комплекс, обеспечивающий организм энергией на весь день;</li>
          <li>микрогрин имеет низкую калорийность;</li>
          <li>мини-травы — источник биологически активных веществ, которые помогают улучшить работу мозга;</li>
        </ul>
        <h2>Микрозелень: как правильно хранить?</h2>
        <Picture
            png={Img56_5}
            webp={ImgW56_5}
            alt="Микрозелень: как правильно хранить"
        />
        <p>Наверняка вы хотели бы узнать, как правильно хранить микрозелень, чтобы она оставалась свежей долгое время. Да, после срезки люцерна, горошек и амарант быстро теряют свои полезные и вкусовые свойства.</p>
        <p><b>Наверняка вы хотели бы узнать, как правильно хранить микрозелень, чтобы она оставалась свежей долгое время. Да, после срезки люцерна, горошек и амарант быстро теряют свои полезные и вкусовые свойства.</b></p>
        <ul>
          <li>Срезанные молодые всходы храните в холодильной камере в течение 7 дней. Для этого поместите урожай в контейнер, дно которого застелите влажной тканью. Так растения будут подпитываться влагой.</li>
          <li>Укроп, кинзу, эстрагон, базилик и шпинат можно заморозить на срок 5 месяцев. Перед этим советуем ошпарить растения кипятком, а затем поместить в пластиковый пакет.</li>
          <li>Переберите срезанную зелень, отбросьте все подгнившие экземпляры и разложите тонким слоем на бумажном полотенце. Подождите, когда она высохнет и в последующем используйте как приправу.</li>
        </ul>
        <p>Микрогрин помогает разнообразить привычный рацион своим ярким вкусом. Сама по себе мини-трава привносит в блюда свежесть и является полезной для нашего мозга. Семена легко прорастают в любом субстрате, независимо от времени года и погоды за окном. Создайте огород мечты у себя дома на подоконнике! При несложном уходе вы сможете наслаждаться ярким вкусом свежей и полезной микрозелени круглый год.</p>
        <h2>Набор микрозелени «Быстрый мозг»: что это</h2>
        <p><b>Если вы не завзятый огородник</b>, но цените растения за их пользу для организма, то обратите внимание на наш набор микрозелени «Быстрый мозг». В составе проростки семян маш, льна, подсолнуха и чечевицы. Вы можете купить микс «Быстрый мозг» Vitamincus в торговых сетях наших партнеров: О’кей, Spar, Globus, GREEN, Лотос, Перекресток или заказать с доставкой на дом.</p>
        <table>
        <tr>
            <th>Витамины и микроэлементы</th>
            <th>Польза для организма</th>
        </tr>
        <tr>
            <td>Фосфор</td>
            <td>
            • Отвечает за нормальную работу мозга;<br /><br />
            • Поддерживает здоровье печени, костей, зубов, сердца, почек.
            </td>
        </tr>
        <tr>
            <td>Магний</td>
            <td>
                • Снижает артериальное давление;<br /><br />
                • Нормализует уровень сахара в крови;<br /><br />
                • Устраняет спазмы сосудов головного мозга;<br /><br />
                • Участвует в передаче нервных импульсов.
            </td>
        </tr>
        <tr>
            <td>Медь</td>
            <td>
                • Поддерживает эластичность капилляров;<br /><br />
                • Стимулирует мозговую деятельность.
            </td>
        </tr>
        <tr>
            <td>Витамин В6</td>
            <td>
                • Поддерживает здоровье кожи, волос и ногтей;<br /><br />
                • Служит профилактикой авитаминоза;<br /><br />
                • Помогает при заболеваниях нервной системы.
            </td>
        </tr>
        <tr>
            <td>Витамин В9 (фолиевая кислота)</td>
            <td>
                • Нормализует аппетит и настроение;<br /><br />
                • Участвует в работе мозга и костной системы;<br /><br />
                • Помогает избавиться от лишнего веса;<br /><br />
                • Нормализует работу ЖКТ.
            </td>
        </tr>
        <tr>
            <td>Витамин К</td>
            <td>
                • Служит профилактикой остеопороза;<br /> <br />
                • Обеспечивает нормальную свертываемость крови.
            </td>
        </tr>
        </table>
        </>
      ),    
    },
    {
      id: 'obzor-pljusov-i-minusov-domašnego-vyraŝivanija-mikrozeleni',
      name: 'Микрозелень: ее польза для организма, плюсы и минусы выращивания дома',
      title: 'Обзор плюсов и минусов домашнего выращивания микрозелени',
      descr: 'О пользе микрозелени и её полезных свойствах для организма. Рассказываем о преимуществах и недостатках её выращивания в домашних условиях.',
      bg: [Img55_1, ImgW55_1],
      date: '14.08.2024',
      content: (
        <>
        <p><b>Люди в течение своей жизни выбирают разные способы </b>сохранения здоровья и повышения защитных свойств иммунитета. Одни могут принимать БАДы, другие – ходить на витаминные капельницы. Однако <b>естественные способы</b> восполнения недостатка питательных веществ всегда будут в почете. Одним из таких как раз является употребление в пищу микрозелени.</p>
        <Picture
            png={Img55_2}
            webp={ImgW55_2}
            alt="Микрозелень ее польза для организма, плюсы и минусы выращивания дома"
        />
        <p><b>Микрозелень</b> – это сеянцы различных растений – овощей, трав на ранней стадии их развития. Они употребляются в пищу еще до того, как на них появляются первые листочки. Считается, что именно на этой стадии развития растения накапливают достаточно много питательных веществ, витаминов, микроэлементов.</p>
        <p>Сегодня <Link to="/#catalog">купить микрозелень</Link> можно во многих магазинах, но некоторые люди по-прежнему предпочитают выращивать ее самостоятельно.</p>
        <p><b>В этой статье мы разберемся</b>, почему же все-таки многие люди предпочитают покупать микрозелень для улучшения своего здоровья, а также рассмотрим все преимущества и недостатки ее выращивания в домашних условиях.</p>
        <h2>По каким причинам микрозелень полезнее обычной зелени</h2>
        <Picture
            png={Img55_3}
            webp={ImgW55_3}
            alt="По каким причинам микрозелень полезнее обычной зелени"
        />
        <p><b>Микрозелень гораздо полезнее обычной, поскольку она содержит:</b></p>
        <ul>
          <li>В несколько раз больше полезных веществ, чем более зрелые растения. Конечно, не стоит забывать об обычных овощах и постоянно употреблять в пищу микрозелень. Все же в них содержатся другие, не менее нужные вещества.</li>
          <li>Большую концентрацию витамина C по сравнению, например, с той же рукколой. Если в микрозелени его концентрация достигает 100%, то в салате – всего 17%.</li>
          <li>Большую концентрацию витамина K. В укропе, например, он вообще отсутствует.</li>
          <li>В два раза больше витаминов группы В – В1, В2, В4. Однако по содержанию В6 и В9 все-таки лидируют петрушка и укроп.</li>
        </ul>
        <p><b>Стоит также отметить</b>, что консистенция у микрозелени довольно нежная, за счет чего она легко и быстро усваивается организмом.</p>
        <p><b>Микрозелень является популярной</b> не только за счет своих полезных свойств. Она также является доступным вариантом для любого человека и отличается неплохим вкусом. Если грамотно сочетать ее с другими продуктами, то можно получить питательные и вкусные блюда.</p>
        <p><b>Добавлять ее рекомендуется:</b></p>
        <ul>
          <li>в супы (нужно посыпать их мелко нарезанной микрозеленью чтобы добавить аромата и вкуса);</li>
          <li>в салаты (в сочетании с обычной зеленью она будет еще полезнее);</li>
          <li>в гарниры (дополнить рыбу зеленью всегда было хорошей идеей);</li>
          <li>в сэндвичи (для этого достаточно заменить обычную зелень на микрозелень);</li>
          <li>в соки, смузи (свежевыжатые напитки будут гораздо полезнее, если в них добавить ростки).</li>
        </ul>
        <p><b>Не стоит путать микрозелень с проростками</b>. Она, в отличие от них, представляет собой зеленый стебель с маленькими листочками, а не просто пророщенные семена без стебелька. </p>
        <h2>Полезные свойства для организма</h2>
        <p><b>Микрозелень приносит пользу:</b></p>
        <ul>
          <li>желудочно-кишечного тракта (ЖКТ);</li>
          <li>сердечно-сосудистой системе (ССС);</li>
          <li>иммунитету.</li>
        </ul>
        <p>Подробнее о ее пользе для конкретной системы поговорим ниже.</p>
        <Picture
            png={Img55_4}
            webp={ImgW55_4}
            alt="Полезные свойства для организма"
        />
        <h3>Польза для ЖКТ</h3>
        <p><b>В большинстве сортов микрозелени</b> содержатся пребиотики – вещества, которые способствуют увеличению числа полезных бактерий в кишечнике. Благодаря этому пищеварение значительно улучшается – кишечник начинает работать нормально, исчезает изжога, пропадают неприятные ощущения в желудке после еды.</p>
        <p><b>Клетчатка</b>, которая входит в состав микрозелени, поможет улучшить перистальтику кишечника. Благодаря ей человек сможет избавиться от запоров.</p>
        <h3>Польза для ССС</h3>
        <p><b>За счет того</b>, что микрозелень богата витаминами группы В, а также калием, магнием, минералами, она положительно сказывается на здоровье сердца и сосудов. Из-за этого ее, например, точно стоит употреблять тем, кому постоянно приходится контролировать артериальное давление.</p>
        <p><b>Фолиевая кислота в составе микрозелени</b> способствует появлению новых кровяных клеток. В результате риск разных заболеваний снижается.</p>
        <h3>Польза для иммунной системы</h3>
        <p><b>Из-за этого свойства</b> микрозелень и приобретают чаще всего. Дело в том, что она является источником антиоксидантов – витамина С, витамина Е, каротиноидов. За счет них клетки гораздо реже повреждаются свободными радикалами, что замедляет воспалительные процессы в организме и, конечно, укрепляет иммунную систему.</p>
        <p><b>В микрозелени</b> также можно встретить флавоноиды и полифенолы (биологические соединения). Они также отвечают за поддержание иммунной системы в норме и обладают противовспалительными, иммуномодулирующими свойствами.</p>
        <p><b>Микрозелень также</b> повышает уровень гемоглобина, что положительно сказывается на общем самочувствии человека, способствует правильной работе эндокринной системы, восстанавливает кислотно-щелочной баланс и является неплохой профилактикой раковых заболеваний. </p>
        <h2>Преимущества и недостатки выращивания микрозелени в домашних условиях</h2>
        <Picture
            png={Img55_5}
            webp={ImgW55_5}
            alt="Преимущества и недостатки выращивания микрозелени в домашних условиях"
        />
        <p><b>Выращивать микрозелень дома довольно удобно, поскольку она:</b></p>
        <ul>
          <li>Отличается большим разнообразием культур, за счет чего выбрать подходящую не составит труда.</li>
          <li>Будет доступна для употребления в пищу в любой сезон. Людям не нужно будет ждать, пока из семечка вырастет целый овощ – достаточно будет того, чтобы оно проросло.</li>
          <li>Требует немного места, особенно если хочется выращивать микрозелень только для собственного удовольствия. Благодаря этому ее можно выращивать в квартире, а не только на отдельном огороде или дачном участке.</li>
          <li>Является биологически чистым продуктом. Человек точно будет знать, что для ее выращивания не использовали дополнительные добавки.</li>
          <li>Считается продуктом с низкой калорийностью. При маленьком объеме способна снабдить организм всеми необходимыми питательными веществами.</li>
          <li>Позволяет делать вкусные блюда. Особенно вкусными получаются полезные <Link to="/#catalog">салаты из микрозелени</Link>.</li>
          <li>Имеет короткий период вегетации, который составляет всего 14 дней.</li>
          <li>Выращивается круглый год. Употреблять ее во внесезонные периоды, когда витаминов и разнообразия в питании просто не хватает, довольно полезно.</li>
          <li>Не требует применения питательных субстратов.</li>
          <li>Может выращиваться на любом субстрате.</li>
          <li>Не требует больших вложений, затрат, имеет простую технологию выращивания, которую способен освоить любой желающий.</li>
        </ul>
        <p><b>Если человек на постоянной основе</b> хочет выращивать микрозелень не только для себя. но еще и для продажи, то он должен знать, что его продукция точно будет пользоваться спросом. В последнее время на нее «подсело» много вегетарианцев, приверженцев здорового питания, спортсменов.</p>
        <p><b>К минусам выращивания микрозелени можно отнести:</b></p>
        <ul>
          <li>Ее уникальность. Не все семена нужно выращивать в одних и тех же условиях. Из-за этого не все могут позволить себе сразу несколько культур.</li>
          <li>Небольшой срок хранения при срезке. Это следует учитывать, если планируется куда-то перевозить микрозелень или хранить ее в холодильнике.</li>
          <li>Требования к качеству семян. Поскольку микрозелень съедается сразу же после проращивания, семена должны быть качественными.</li>
          <li>Необходимость постоянного полива. Ростки будут быстро погибать, если забыть полить их.</li>
          <li>Большой расход семян. После проращивания старых необходимо будет практически тут же проращивать новые, если хочется увидеть заметный эффект после их употребления. За счет используемого количества траты на семена для микрозелени будут гораздо выше, чем на обычные растения.</li>
          <li>Потребность в дополнительном освещении. Росткам может не хватать обычного солнечного света (особенно осенью и зимой). Тогда их владельцу придется покупать специальные ультрафиолетовые лампы. Зимой, например, нужно будет потратить на досвечивание около 10-12 часов.</li>
        </ul>
        <p><b>Для выращивания микрозелени</b> все-таки необходимы специальные знания, поэтому потратить время на изучение теории все же придется. Она также требует много места – для микрозелени придется покупать невысокие, но при этом широкие горшки.</p>
        <h2>Подробнее о семенах для микрозелени</h2>
        <Picture
            png={Img55_6}
            webp={ImgW55_6}
            alt="Подробнее о семенах для микрозелени"
        />
        <p><b>Для выращивания микрозелени</b> обычные семена не подойдут, поскольку их часто обрабатывают химическими веществами. Перед покупкой у продавца следует уточнить, для какой цели они предназначены. Если для лучшей всхожести, то они с большей вероятностью были обработаны для предотвращения появления болезни или инфекции.</p>
        <p><b>Посадочные семена</b> можно приобрести в специальных магазинах или заказать в интернете. Чтобы они проросли как можно быстрее, их, как и любые другие, не стоит слишком часто поливать, сажать слишком близко друг к другу, ставить горшки с ними в темные места.</p>
        <p><b>Семена бобов, фасоли и пасленовых культур</b> (картофель, баклажаны, помидоры, перец) в пищу употреблять нельзя, поскольку они содержат много токсических веществ. Ростки, которые получаются из тыквенных семян, тоже не подойдут – они будут слишком горькими и невкусными.</p>
        <p><b>Многие люди</b>, которые занимаются выращиванием микрозелени уже много времени, считают, что преимущества полностью перекрывают собой недостатки.</p>
        <p><b>Микрозелень</b> – это отличный способ получить недостающее количество витаминов и минералов. Ее можно выращивать как в жаркое, так и в холодное время года, за счет чего она пользуется популярностью у многих людей. Конечно, как и за любыми другими растениями, за ней придется ухаживать. Однако кропотливый труд точно окупится, когда на подоконнике в квартире или в теплице появятся первые ростки зелени.</p>
        </>
      ),    
    },
    {
      id: 'kak-pravilno-sbrosit-ves-bez-vreda-dlja-zdorovja',
      name: 'Как правильно сбросить вес без вреда для здоровья',
      title: 'Советы по снижению веса без вреда для здоровья',
      descr: 'Если вы решили сбросить вес, то помните о важном: медицинское обследование, правила безопасного похудения, составление рациона, мифы о похудении.',
      bg: [Img54_1, ImgW54_1],
      date: '14.08.2024',
      content: (
        <>
        <p><b>Многие люди,</b> которые все же принимают решение избавиться от лишнего веса, хотят сделать это как можно быстрее. Из-за этого они прибегают к различным диетам, о которых читают в интернете или узнают от своих знакомых, блогеров, знаменитостей. К сожалению, <b>не все из них оказываются полезными для организма</b> из-за несбалансированного рациона.</p>
        <Picture
            png={Img54_2}
            webp={ImgW54_2}
            alt="Как правильно сбросить вес без вреда для здоровья"
        />
        <p><b>Отсутствие достаточного количества полезных веществ</b> в пище приводит к тому, что снижается умственная активность, ухудшается работа сердца, печени, почек, опорно-двигательного аппарата. На психике человека неудачная диета также может сказаться. Многие женщины и мужчины сегодня страдают, например, от <b>расстройства пищевого поведения (РПП)</b>. </p>
        <p><b>Что же делать,</b> если губить свой организм не хочется, но при этом желание похудеть никуда не пропадает? Придерживаться правил безопасного похудения, о которых мы расскажем ниже! С их помощью можно не только грамотно составить меню на каждый день, но еще и пересмотреть свой подход к процессу получения идеальной фигуры.</p>
        <h2>Медицинское обследование</h2>
        <Picture
            png={Img54_3}
            webp={ImgW54_3}
            alt="Медицинское обследование"
        />
        <p><b>Если вы твердо решили</b> попрощаться с лишними килограммами, то сначала вам нужно будет пройти медицинское обследование. Уже долгое время эндокринологи советуют не изнурять себя жесткими диетами, которые могут и не принести результата, а сразу обратиться за квалифицированной помощью.</p>
        <p><b>Во-первых,</b> диагностика поможет выявить истинную причину появления лишнего веса. Во-вторых, еще никому не мешало лишний раз оценить общее состояние здоровья, убедиться в нормальном функционировании органов и систем.</p>
        <p><b>При прохождении медицинского обследования рекомендуется придерживаться следующих шагов:</b></p>
        <ul>
          <li>Обратитесь в государственную клинику к терапевту, чтобы получить направления на анализы, или сразу в платную лабораторию. Важно сдать общий клинический, биохимический, липидный, витаминный, специфический (на панкреатическую и альфа-амилазу, а также на липазу) анализ. Еще нужно будет провериться на гормоны, поскольку гормональные нарушения часто являются причиной набора веса.</li>
          <li>Также стоит пройти УЗИ щитовидной железы, органов малого таза, мочевыводительной и пищеварительной системы.</li>
        </ul>
        <p><b>После получения результатов анализов</b> нужно будет обратиться к диетологу. На их основании он предложит схему или план лечения, поможет составить рацион, а также может отправить на биоимпедансометрию, чтобы выявить состав тела (соотношение жира, жидкости, костной и мышечной ткани). Некоторые диетологи так же выписывают лекарства.</p>
        <p><b>Некоторым людям</b> также стоит обратиться к психотерапевту, поскольку похудение – это не всегда только про здоровую пищу. Некоторые установки сидят в голове, и пока человек их не «сломает» – он не сможет пересмотреть свой подход к похудению. К тому же, психологическая поддержка важна и на тех этапах, когда успех кажется недостижимым.</p>
        <h2>Главные правила безопасного похудения</h2>
        <Picture
            png={Img54_3}
            webp={ImgW54_3}
            alt="Главные правила безопасного похудения"
        />
        <p>Если вы посетили всех врачей и ступили на путь похудения, то важно помнить о правилах, которые помогут <b>сделать процесс получения идеальной фигуры более разумным:</b></p>
        <ul>
          <li><b>Нельзя начинать худеть во время простуды, обострения хронического заболевания, активных физических, психических нагрузок</b>. Многим переход на новое меню кажется ерундой, но на деле для нашего организма – это большой стресс, особенно когда раньше в него постоянно поступала только вредная пища. Неблагоприятные факторы усиливают его, из-за чего у многих диета заканчивается так и не начавшись.</li>
          <li><b>Нельзя резко начинать заниматься физическими упражнениями</b>. Особенно это касается людей с большим весом. Их дыхательная и сердечно-сосудистая система будут просто в шоке от таких резких нагрузок, что уж говорить и об опорно-двигательном аппарате. О значении спорта во время похудения написано в <Link to="/blog/o-znachimosti-sporta-v-zhizni-sovremennogo-cheloveka/">этой</Link> статье. </li>
          <li><b>Нужно постепенно корректировать</b> собственный рацион, поскольку резкий переход с одних продуктов на другие нередко вызывает сонливость, расстройство пищеварения, нервозность, апатию.</li>
          <li><b>Следует соблюдать питьевой режим,</b> поскольку недостаточное употребление жидкости тоже негативно сказывается на организме. Особенно это актуально для тех, кто не только меняет свой рацион, но еще и начинает заниматься физическими упражнениями.</li>
          <li><b>Нельзя без рекомендаций врача</b> приобретать лекарства для похудения и употреблять их, например, регуляторы аппетита. Человек может просто спровоцировать появление побочных эффектов на данный препарат, а также значительно ухудшить состояние своей пищеварительной системы.</li>
          <li><b>Следует обеспечить себе витаминно-минеральную поддержку</b>. За счет нее организм будет получать дополнительную подпитку питательными веществами, что значительно снизит риск возникновения проблем с ним. Подбирать средства необходимо с учетом результатов анализов, а также собственных индивидуальных реакций на них.</li>
          <li><b>Последний прием пищи</b> должен быть не позднее 3 часов до сна.</li>
          <li><b>Необходимо также</b> следить за работой кишечника и за общим состоянием здоровья. В процессе похудения человек не должен испытывать сильный упадок сил, значительное ухудшение морального и физического самочувствия.</li>
        </ul>
        <h2>Составление рациона</h2>
        <p><b>Здоровое питание</b> всегда предусматривает постепенный подход к изменению рациона. Резкая смена пищи с одной на другую или жесткая голодовка может привести к неприятным последствиям.</p>
        <h3>Какие продукты можно употреблять во время диеты</h3>
        <p><b>Если брать в учет</b> допустимое количество калорий и соблюдать баланс между белками, жирами и углеводами, то в пищу можно употреблять практически любые продукты. </p>
        <p><b>Однако лучше всего, чтобы в основе рациона была пища с высокой питательной ценностью:</b></p>
        <ul>
          <li>овощи, фрукты;</li>
          <li>мясо;</li>
          <li>молочные продукты;</li>
          <li>злаки;</li>
          <li>зелень, в том числе и ростковые салаты (<a href="link_here">прочесть подробнее о пользе микрозелени можно здесь</a>).</li>
          </ul>
        <p><b>Белки</b> играют важную роль в формировании иммунной системы. Они являются основным источником полезных веществ для иммунных клеток и антител.</p>
        <p><b>Углеводы </b>способствуют правильной работе мозга, дают организму энергию для выполнения различных действий.</p>
        <p><b>Жиры</b> также стимулируют мозговую деятельность, служат строительным материалом для различных клеток и тканей, помогает усваивать витамины.</p>
        <h3>От каких продуктов следует полностью отказаться</h3>
        <p><b>Если человек решил</b> придерживаться здорового питания, то он должен полностью исключить вредную еду вроде фастфуда, полуфабрикатов, десертов и т.д.</p>
        <p><b>Овощи, которые богаты крахмалом</b>, а также крупы в период активного снижения веса тоже лучше убрать.</p>
        <p><b>Правильное питание</b> также предусматривает готовку, по большей части, в воде, на пару или в духовке. Узнать больше о продуктах для правильного питания можно <Link to="/blog/top-10-produktov-dlja-pravilnogo-pitanija/">здесь</Link>.</p>
        <h2>Часто встречающиеся мифы о похудении</h2>
        <Picture
            png={Img54_4}
            webp={ImgW54_4}
            alt="Часто встречающиеся мифы о похудении"
        />
        <p><b>В компании людей, которые борются с лишним весом, часто можно услышать следующие мифы о похудении:</b></p>
        <ul>
          <li>Зеленый чай помогает сбросить вес. Не стоит недооценивать этот напиток, поскольку он хорошо утоляет жажду и помогает предотвратить атеросклероз сосудов. Однако этот напиток ни за что не поможет, если человек будет продолжать питаться неправильно.</li>
          <li>Фрукты помогают сжечь жир. Некоторые из них ускоряют обменные процессы, происходящие в организме, но точно не сжигают жир.</li>
          <li>Не все витамины полезны при похудении. Совершенно неверно – каждый микроэлемент выполняет в организме свою роль. Особенно критично во время похудения на здоровье человека может сказаться нехватка витамина Е, который отвечает за молодость кожи, нормальное функционирование нервной системы и т.д.</li>
          <li>Крупы позволяют быстрее похудеть. Да, только если употреблять их в умеренном количестве. Если же человек будет перебарщивать, то он начнет набирать массу.</li>
          <li>Сухариками можно заменить обычный хлеб. На самом деле они более калорийные.</li>
          <li>Зеленые салаты способствуют снижению веса. Да, но только в том случае, если не употреблять их с разными соусами, особенно с майонезом.</li>
          <li>В еде не должно быть ни грамма жира. Это абсолютно неверно, поскольку без жиров наш организм также начинает чувствовать себя плохо, ведь они являются неотъемлемой частью нашего рациона.</li>
        </ul>
        <p><b>Давно был развеян и самый главный миф о похудении</b> – голодовка никак не способствует сбрасыванию веса! Да, на какое-то время она может помочь, но потом из-за нее вскоре начинаются серьезные проблемы с ЖКТ, опорно-двигательным аппаратом и психикой. А усталость, головокружение и слабость, которые сопровождают голодовку, могут привести к обморокам, несчастным случаям, когда концентрация внимания человека снижается.</p>
        <p><b>Голодовка</b> также негативно сказывается и на внешнем виде человека. Он начинает выглядеть чересчур болезненно.</p>
        <h2>Ошибки, которые часто совершают люди, желающие похудеть</h2>
        <p><b>Многие люди</b> чересчур фокусируются на самой диете. Они постоянно думают о том, как бы не съесть несколько лишних кусочков, не набрать чуть больше положенных калорий.</p>
        <p><b>На фоне переживаний</b> о диете и самом процессе похудения у человека появляется стресс. Из-за него он часто срывается, поскольку пытается решить свои проблемы привычным путем – заеданием.</p>
        <p><b>Многие худеющие люди</b> также в этот период отстраняются от своих друзей и близких, забывают про любимое хобби. Не стоит зацикливаться только на похудении, ведь в мире есть еще так много интересных вещей, которым стоит уделить внимание.</p>
        <p><b>Физическая активность и диета</b> – это главные помощники во время избавления от лишнего веса. Правда, перед тем как приступить к похудению, нужно сначала обратиться к специалистам, тщательно обследовать свой организм, оценить состояние своего здоровья.</p>
        </>
      ),    
    },
    {
      id: 'obzor-sovremennyh-issledovanij-o-mikrozeleni:-polza,-pitatelnye-svojstva-i-primenenie',
      name: 'Обзор современных исследований и научных данных о микрозелени',
      title: 'Обзор современных исследований о микрозелени: польза, питательные свойства и применение',
      descr: 'Рассматриваем последние исследования, подтверждающие её пользу микрогрина для здоровья, влияние на иммунную систему и профилактику хронических заболеваний, а также советы по её применению в рационе и кулинарии.',
      bg: [Img53_1, ImgW53_1],
      date: '25.07.2024',
      content: (
        <>
        <p><b>Микрозелень, представляющая собой молодые растения</b>, которые собираются на ранней стадии роста, становится все более популярной в мире. Благодаря своей высокой питательности и разнообразию вкусов, микрозелень активно используется в кулинарии, а также рассматривается как мощный источник полезных веществ.</p>
        <p><b>Данная статья предоставляет обзор текущих исследований и научных данных о микрозелени</b>, акцентируя внимание на её питательных свойствах, потенциальных полезных эффектах для здоровья и области применения.</p>
        <Picture
            png={Img53_2}
            webp={ImgW53_2}
            alt="Обзор современных исследований и научных данных о микрозелени"
        />
        <h2>Питательные свойства микрозелени</h2>
        <p><b>Микрозелень — это стадии роста растений</b>, которые находятся между семенами и уже сформировавшимися растениями. Обычно её собирают через 7-21 день после прорастания. Популярные виды микрозелени включают аргу, шпинат, брокколи, редис, горчицу и многие другие. Каждое растение имеет свои уникальные питательные свойства, которые идеально подходят для добавления в блюда, салаты и напитки.</p>
        <h3>Витамины и минералы</h3>
        <p><b>Микрозелень является богатым источником</b> ряда витаминов и минералов. Некоторые исследования показывают, что микрозелень содержит значительно более высокую концентрацию витаминов и минералов по сравнению со взрослыми растениями.</p>
        <h3>Антиоксиданты</h3>
        <p><b>Микрозелень особенно ценна</b> за счет высокого содержания антиоксидантов, таких как каротиноиды и полифенолы.</p>
        <p>В исследовании, опубликованном в журнале <b>Journal of Agricultural and Food Chemistry</b>, было установлено, что микрозелень редиса содержит значительное количество антиоксидантов, что делает её хорошим выбором для поддержания здоровья (Zhang, Y., et al. "Antioxidant activity of microgreens: A comparison study." Journal of Agricultural and Food Chemistry, 2015).</p>
        <h2>Полезные эффекты для здоровья</h2>
        <Picture
            png={Img53_3}
            webp={ImgW53_3}
            alt="Полезные эффекты для здоровья"
        />
        <p><b>Микрозелень может быть полезна для здоровья</b> благодаря своему высокому содержанию витаминов, минералов и антиоксидантов. Она поддерживает иммунную систему, помогает в профилактике хронических заболеваний, таких как рак и диабет, а также способствует улучшению обмена веществ и пищеварения. Употребление микрозелени может повысить общий уровень питательных веществ в рационе и обеспечить защиту от окислительного стресса.</p>
        <h3>Поддержка иммунной системы</h3>
        <p><b>Микрозелень может играть важную роль</b> в поддержке иммунной системы благодаря своему высокому содержанию витаминов и антиоксидантов. Витамин С, присутствующий в таких растениях, как микрозелень петрушки и шпината, обладает противовоспалительными свойствами и может усилить защитные функции организма.</p>
        <p><b>Например, исследование, опубликованное в Nutrients</b>, подчеркивает важность витаминов и минералов в поддержании иммунного ответа (Maggini, S., et al. "Immune function and nutrition." Nutrients, 2018).</p>
        <h3>Профилактика хронических заболеваний</h3>
        <p><b>Некоторые исследования показывают</b>, что регулярное употребление микрозелени может снизить риск хронических заболеваний. Например, сульфорафан, содержащийся в микрозелени брокколи, был изучен на предмет своих свойств в профилактике рака.</p>
        <p><b>В одном из исследований, опубликованном в журнале Cancer Research</b>, было обнаружено, что сульфорафан препятствует росту опухолевых клеток (Dashwood, R. H. "Broccoli and cancer prevention: mechanisms of action and bioavailability." Cancer Research, 2006).</p>
        <h3>Улучшение обмена веществ</h3>
        <p><b>Добавление микрозелени в рацион может способствовать</b> улучшению обмена веществ и снижению веса.</p>
        <p><b>В исследовании, опубликованном в Obesity Research & Clinical Practice</b>, обсудили, как некоторые виды микрозелени, такие как горчица и редис, помогают контролировать уровень сахара в крови, что является важным аспектом в профилактике диабета 2 типа (Davis, B. C., et al. "Effects of dietary mustard microgreens on glucose metabolism in overweight and obese subjects." Obesity Research & Clinical Practice, 2016).</p>
        <h2>Применение микрозелени в рационе</h2>
        <Picture
            png={Img53_4}
            webp={ImgW53_4}
            alt="Применение микрозелени в рационе"
        />
        <p><b>Внедрение микрозелени в свой рацион</b> — отличный способ повысить питательную ценность пищи и улучшить общее здоровье. Эти молодые растения богаты витаминами, минералами и антиоксидантами, которые укрепляют иммунную систему и защищают от хронических заболеваний.</p>
        <p><b>Микрозелень не только добавляет яркие вкусы и текстуры в блюда</b>, но и способствует улучшению пищеварения и обмена веществ. Простое добавление микрозелени в салаты, смузи или сэндвичи поможет обогатить рацион и сделать его более разнообразным и полезным.</p>
        <h3>Кулинарная сфера</h3>
        <p><b>Микрозелень широко используется в кулинарии</b> благодаря своему насыщенному вкусу и привлекательному виду. Она идеально подходит для добавления в салаты, смузи, сэндвичи и как топпинг к основным блюдам. Микрозелень также может стать основой для создания оригинальных соусов и приправ.</p>
        <h3>Пищевая промышленность</h3>
        <p><b>Сегодня микрозелень активно используется</b> в пищевой промышленности. Многие рестораны и кафе предлагают блюда с использованием микрозелени, а производители здорового питания включают её в свои продукты. Это стало особенно актуальным среди потребителей, стремящихся к здоровому образу жизни и полноценному питанию.</p>
        <h2>Проблемы и рекомендации</h2>
        <Picture
            png={Img53_5}
            webp={ImgW53_5}
            alt="Проблемы и рекомендации"
        />
        <p><b>Несмотря на все преимущества микрозелени</b>, важно помнить несколько нюансов. Некоторые исследования указывают на возможность наличия патогенов в неправильно обработанной микрозелени, что может представлять риск для здоровья.</p>
        <p><b>Например, исследование, проведенное Центрами по контролю и профилактике заболеваний (CDC)</b>, показало, что неправильно обрабатываемая микрозелень может вызвать вспышки пищевых заболеваний (CDC. "Foodborne outbreaks linked to sprouts." 2019). Рекомендуется тщательно промывать микрозелень перед употреблением и покупать её у проверенных поставщиков.</p>
        <p><b>Также стоит обратить внимание на условия хранения</b>. Микрозелень следует хранить в холодильнике и употреблять до истечения срока годности, чтобы сохранить её питательные свойства.</p>
        <h2>Заключение</h2>
        <p><b>Микрозелень — это не только модный тренд</b>, но и мощный источник питательных веществ, который может значительно обогатить наш рацион. Современные исследования подтверждают её пользу для здоровья, включая поддержку иммунной системы, профилактику хронических заболеваний и улучшение обмена веществ. </p>
        <p><b>Включение микрозелени в повседневное питание</b> — это простой и вкусный способ улучшить здоровье и внести разнообразие в рацион. Хотя существует еще много вопросов о микрозелени, её потенциальные преимущества делают её интересным объектом для дальнейших исследований и экспериментов в кулинарии.</p>
        </>
      ),    
    },
    {
      id: 'mikrogrin-dlja-borby-s-ustalostju-i-povyšenija-ènergii',
      name: 'Как микрозелень помогает в борьбе с усталостью: лучшие виды микрогрина для энергии',
      title: 'Микрогрин для борьбы с усталостью и повышения энергии',
      descr: 'Обзор сортов микрозелени, которые способствуют повышению энергии и снижению усталости.',
      bg: [Img52_1, ImgW52_1],
      date: '25.07.2024',
      content: (
        <>
        <p>В современном мире многие люди страдают от <b>хронической усталости</b>. Стресс, активный образ жизни, недостаток витаминов и минералов в рационе могут существенно снизить уровень энергии и общее самочувствие.</p>
        <p><b>Однако, существует простой и эффективный способ улучшить свою энергию</b> — это включение в рацион микрозелени. Давайте обсудим, как микрозелень может помочь в борьбе с усталостью и какие виды этого суперфуда стоит добавить в своё питание.</p>
        <p><b>Микрозелень</b> — это молодые растения, которые достигают стадии роста от 7 до 21 дня, прежде чем их срезают. Они богаты витаминами, минералами и антиоксидантами, что делает их настоящим суперфудом. Микрозелень обладает ярким вкусом и может дополнить множество блюд, а также стать отличным источником энергии.</p>
        <Picture
            png={Img52_2}
            webp={ImgW52_2}
            alt="Как микрозелень помогает в борьбе с усталостью лучшие виды микрогрина для энергии"
        />
        <h2>Почему микрозелень можh2ет повысить вашу энергии и продуктивность</h2>
        <ul>
          <li><b>Высокое содержание питательных веществ:</b> микрозелень содержит высокие концентрации витаминов (таких как A, C, E, K), минералов (кальций, магний, калий, железо) и антиоксидантов. Эти вещества играют неоценимую роль в поддержке общего здоровья и уровня энергии.</li>
          <li><b>Усиление иммунной системы:</b> витамины и антиоксиданты помогают укрепить иммунитет, что может повысить вашу выносливость и общее состояние.</li>
          <li><b>Поддержка обмена веществ:</b> микрозелень, благодаря своим полезным компонентам, способствует более эффективному обмену веществ, что тоже влияет на уровень энергии.</li>
          <li><b>Снижение усталости:</b> микрозелень помогает бороться с усталостью благодаря антиоксидантам, которые нейтрализуют свободные радикалы, вызывающие окислительный стресс и усталость.</li>
        </ul>
        <h2>Лучшие виды микрозелени для энергии</h2>
        <Picture
            png={Img52_3}
            webp={ImgW52_3}
            alt="Лучшие виды микрозелени для энергии"
        />
        <p>Вот несколько видов микрозелени, которые особенно полезны для борьбы с усталостью:</p>
        <h3>Руккола</h3>
        <p>Руккола известна своим острым, чуть горьким вкусом и является отличным источником витаминов A и C. Эти витамины положительно влияют на уровень энергии и общее состояние организма. Кроме того, руккола содержит фолиевую кислоту и железо, которые способствуют улучшению кровообращения и обеспечивают кислородом клетки.</p>
        <h3>Брокколи</h3>
        <p>Микрозелень брокколи — это настоящий энергетический заряд. Она богата сульфорафаном, который обладает антиоксидантными свойствами и помогает в детоксикации организма. Брокколи также содержит витамины C и K, что способствует улучшению обмена веществ.</p>
        <h3>Шпинат</h3>
        <p>Микрозелень шпината является отличным источником железа, а также витаминов A, C и K. Железо необходимо для транспортировки кислорода по организму, что уменьшает чувство утомляемости и повышает уровень энергии.</p>
        <h3>Горчица</h3>
        <p>Микрозелень горчицы обладает неожиданно острым вкусом и содержит множество полезных веществ, включая витамины А, С и E. Эти антиоксиданты помогают организму бороться со стрессом и улучшают общее самочувствие.</p>
        <h3>Чечевица</h3>
        <p>Микрозелень чечевицы обладает высоким содержанием белка и клетчатки, что помогает поддерживать стабильный уровень сахара в крови. Это, в свою очередь, предотвращает резкие пики и падения энергии в течение дня.</p>
        <h3>Свекла</h3>
        <p>Микрозелень свеклы не только вкусна, но и богата витаминами, минералами и антиоксидантами. Она помогает улучшить циркуляцию крови и способствует повышению уровня энергии.</p>
        <h2>Как употреблять микрозелень?</h2>
        <p><b>Способы включения микрозелени в рацион разнообразны:</b></p>
        <ul>
          <li>Добавляйте микрозелень в салаты для дополнительного вкуса и питательных веществ.</li>
          <li>Используйте её как топпинг для сэндвичей и бургеров.</li>
          <li>Включайте в смузи и зеленые коктейли для усиливающего энергии перекуса.</li>
          <li>Используйте как гарнир к основным блюдам.</li>
        </ul>
        <h2>Выращивание микрозелени и покупка готовой продукции</h2>
        <p><b>Если вы хотите иметь свежую микрозелень под рукой, попробуйте вырастить её дома. Для этого вам понадобятся:</b></p>
        <ul>
          <li>Сеянцы и грунт (можно использовать специальные наборы для микрозелени)</li>
          <li>Подходящее место с достаточным количеством света.</li>
          <li>Около 7-21 дня терпения, чтобы получить свежую и полезную зелень.</li>
        </ul>
        <p><b>Микрозелень</b> — это не только вкусное, но и полезное добавление в ваш рацион. Она помогает повысить уровень энергии, улучшить общее состояние и укрепить иммунитет. И хотя выращивание микрозелени в домашних условиях может быть увлекательным занятием, не у всех хватает времени и условий для этого.</p>
        <p><b>Вместо самостоятельного выращивания микрозелени</b> вы можете с легкостью покупать готовую продукцию от Vitamincus. Мы предлагаем широкий ассортимент свежей и вкусной микрозелени и ростковых салатов, которые могут стать отличным дополнением к вашему рациону. Наша продукция проходит строгий контроль качества и хранится так, чтобы сохранить все полезные свойства.</p>
        <p>Выбирайте Vitamincus для удобного и надежного решения в вопросах питания. С нами вам не придется беспокоиться о процессе выращивания — достаточно лишь добавить микрозелень в свой рацион и наслаждаться её полезными свойствами каждый день.</p>
        </>
      ),    
    },
    {
      id: 'kak-vybrat-kontejner-ili-lotok-dlja-vyraŝivanija-mikrozeleni',
      name: 'Выбор контейнеров для выращивания микрозелени',
      title: 'Как выбрать контейнер или лоток для выращивания микрозелени',
      descr: 'Выбираем контейнер под выращивание микрозелени с учётом места, освещения. Подготовка семян и плотность высаживания микрогрина.',
      bg: [Img51_1, ImgW51_1],
      date: '19.06.2024',
      content: (
        <>
        <p><b>Микрозелень</b> — невероятно полезный продукт, который содержит огромное количество витаминов. Даже сложно представить, насколько большая польза будет для организма от этих пророщенных семян. Огромный плюс в том, что зелень можно выращивать везде, даже на обычном подоконнике, прилагая минимум усилий. Это одна из основных причин, почему микрозелень так популярна. </p>
        <p><b>Выращивать микрозелень</b> совсем несложно. Для этого вы не должны быть садоводом или обеспечивать растениям какие-то очень сложные условия. Поставить семена для проращивания можно в любом удобном для вас месте, и чаще всего выбирают подоконник. Однако это не лучший вариант летом, если окна выходят на южную сторону, ведь растениям может быть жарко.</p>
        <Picture
            png={Img51_2}
            webp={ImgW51_2}
            alt="Микрозелень в спортивном питании"
        />
        <p><b>На первом этапе</b> вам нужно определиться с тем, что будете выращивать. Здесь все просто: выбирайте из того, что вы любите. Далее нужно найти место для посадки. На него не должны падать прямые солнечные лучи слишком долго. Также не стоит ставить будущий урожай там, где есть сквозняк. </p>
        <p><b>После того как семена выбраны</b>, а место найдено, стоит решить, как именно вы будете выращивать зелень. <b>Существует несколько способов:</b> </p>
        <ul>
          <li>Торф;</li>
          <li>Субстрат;</li>
          <li>Грунт;</li>
          <li>Ватные диски;</li>
          <li>Джутовая мешковина;</li>
          <li>Поливные маты;</li>
          <li>Марля;</li>
          <li>Проращиватель.</li>
        </ul>
        <p><b>Если это ваш первый опыт</b>, стоит выбирать что-то попроще. Например, ватные диски, торф или грунт. Подробно о правилах выращивания микрозелени мы писали <Link to="/blog/pravila-po-vyrashchivaniyu-mikrozeleni-v-domashnikh-usloviyakh/">здесь</Link>. Там же расскажем об одном из самых простых способов, а также некоторых нюансах.</p>
        <h2>Контейнер под микрозелень</h2>
        <p><b>Насколько глубоким</b> должен быть лоток для выращивания микрозелени — еще один популярный вопрос у садоводов-новичков. Ответа на этот вопрос однозначного нет. Кто-то советует брать лоток с низкими бортами, чтобы семена получали достаточное количество света. Однако затемнить такую тару будет сложно, и тут лучше покупать что-то с более высокими стенками. </p>
        <p><b>Выбирайте лоток</b>, исходя из условий места, где вы планируете выращивать зелень, а именно от особенностей освещения. Важно, чтобы и по площади он тоже подходил. <b>Согласитесь</b>, ставить широкую рассадницу на узкий подоконник — не лучшая идея. Не покупайте лоток для рассады с разделением на ячейки — такие хороши только для выращивания помидоров и цветов. Для микрозелени берите рассадник с плоским ровным или рифленым дном. </p>
        <p><b>Большой плюс в лотках с низкими бортами</b> — туда сложно налить слишком много воды. А вот в рассадник с высокими стенками так и хочется плеснуть побольше. Если вы опасаетесь, что можете залить семена, можно купить лоток с дренажными отверстиями, которые препятствуют чрезмерному увлажнению грунта. </p>
        <Picture
            png={Img51_3}
            webp={ImgW51_3}
            alt="Контейнер под микрозелень"
        />
        <h3>Выращивание на подоконнике</h3>
        <p><b>Возьмите пластиковый контейнер</b> небольшого размера или какой-нибудь другой лоток. Важно, чтобы емкость не протекала. На дно выложите тонкий слой грунта. Другой вариант — положите чистую марлю или другую подобную ткань. Хорошенечко увлажните ее из пульверизатора и разместите по поверхности подготовленные семена. Затяните контейнер пленкой и отправьте туда, где он будет храниться. Важно выбрать место без сквозняков.</p>
        <p><b>Можно приобрести готовый набор</b> для проращивания микрозелени. Это самый простой вариант, так как там все уже есть — вам просто нужно действовать по инструкции. Главное, купить семена растений, которые вам нравятся. </p>
        <h3>Об освещении</h3>
        <p><b>Микрозелени</b>, как и любой другой растительности, нужен свет. И если вы решили высадить растения там, где его практически нет, или просто за окном зима, нужно использовать лампы. Однако света для этих растений требуется меньше, чем для полноценных овощей и культур, ведь микрозелень растет гораздо быстрее. </p>
        <p><b>Важно понимать</b>, что нагрев растениям не нужен — им важен только свет. Поэтому для домашнего огорода подойдут обычный LED-лампы. Но лучшим выбором будет LED-лампа со спектром, который подходит для фотосинтеза. </p>
        <h3>Плотность высаживания</h3>
        <p><b>При покупке емкости</b> для выращивания микрозелени учитывайте тот момент, что высаживать семена нужно плотно друг к другу. Только в этом случае вы сможете получить хороший и качественный урожай. В идеале семена должны лежать плотным ковром в один слой, касаясь друг друга.</p>
        <h3>Нужно ли замачивать семена</h3>
        <p><b>Еще один популярный вопрос</b>, о котором многие спорят — нужно ли предварительно замачивать семена. Это не обязательно. Замачивание семян в воде практически не влияет на сроки прорастания зелени, а вот распределять их по субстрату будет гораздо сложнее. Жидкости, которая будет в рассаднике, вполне достаточно, чтобы хорошие семена дали отличный урожай микрозелени. </p>
        <h3>Как долго растят микрозелень</h3>
        <p><b>Один из самых часто задаваемых вопросов новичков</b> — когда же можно собирать первый урожай. Микрозелень растет быстро, и очень важно вовремя ее срезать. Только в этом случае концентрация полезных веществ будет максимально. </p>
        <p><b>В среднем срезать микрозелень можно через 7-12 дней</b>, в некоторых случаях через две недели. Вообще срок прорастания зависит от сорта растения, а также микроклимата в помещении. Например, считается, что зимой зелень растет чуть дольше, поэтому важно использовать специальные осветительные приборы. Также они могут пригодиться, если зелень растет там, где нет света. </p>
        <p><b>Если вы не можете определиться</b>, можно ли собирать урожай, просто присмотритесь получше к пророщенным семенам. Вы увидите, что появились первые настоящие листочки, которые появляются после семядолями. Если появились два листочка, то зелень можно срезать. Вырывать с корнем не нужно, просто срежьте растения поперек стеблей, оставив корни на месте. И вскоре они дадут вам второй урожай. </p>
        <p><b>Профессионалы рекомендуют</b> подсаживать немного новых семян каждые 10 дней, чтобы получать непрерывный урожай. После того как вы срежете микрозелень, есть ее можно сразу. Именно в это время она будет максимально ароматной. Если растения все-таки придется хранить, то делать это можно в холодильнике в зип-пакете, контейнере с крышкой или в полиэтиленовом пакете. </p>
        <p>Если же вам не хочется тратить на это время, всегда можно купить микрозелень в магазине или на рынке. О том, что лучше — выращивать или покупать, мы подробно писали <Link to="/blog/pokupat-mikrozelen-ili-vyrashhivat-samostojatelno-razbiraemsja-v-voprose/">здесь</Link>.</p>
        <h2>Как едят микрозелень и нужно ли ее мыть</h2>
        <Picture
            png={Img51_4}
            webp={ImgW51_4}
            alt="Как едят микрозелень и нужно ли ее мыть"
        />
        <p><b>Подросшую микрозелень можно есть просто так</b> — сразу после среза. Также ее подают к различным блюдам из рыбы, мяса или овощей, добавляют в салаты или супы. Отлично сочетаются растения со сметаной, сладким творогом или йогуртом. </p>
        <p>Перед подачей нужно просто промыть растения прохладной водой. Несмотря на то, что зелень при росте никак не обрабатывают, не стоит пренебрегать ее мытьем перед подачей. Как минимум вы смоете пыль.</p>
        <p><b>Микрозелень едят в свежем виде</b> — варить или жарить ее не нужно. Конечно, если того требует рецепт, вы вправе добавить растения на любом этапе. Однако важно помнить, что выращивают зелень, в первую очередь, ради витаминов, а при термической обработке большинство из них теряются. </p>
        <p><b>Нарезать микрозелень не обязательно</b>, на то она и микро. Обычно ее добавляют в блюда прямо в срезанном виде — размер позволяет. Однако если рецепт того требует, то, конечно, травку можно и измельчить.</p>
        <h2>А если купить готовую микрозелень</h2>
        <p><b>Если у вас нет ни времени, ни желания</b> выращивать микрозелень на подоконнике, а получать ее витамины хочется, всегда можно купить уже выращенные растения. Также часто среди клиентов встречаются те, кто еще ни разу не пробовал микрозелень и пока не решил, готов ли он ее выращивать. </p>
        <p><b>Готовую микрозелень</b> вы можете приобрести у нас. В ассортименте широкий выбор полезной и качественной продукции по выгодным ценам. Мы растим микрозелень с любовью, чтобы вы могли украсить ей свои блюда, добавив витаминов в ежедневный рацион.</p>
        </>
      ),    
    },
    {
      id: 'podborka-receptov-poleznyh-zavtrakov-s-mikrozelenju',
      name: 'Микрозелень на завтрак: лучшие рецепты',
      title: 'Подборка рецептов полезных завтраков с микрозеленью',
      descr: 'Интересные рецепты завтраков с микрозеленью: бутерброды, овсяная каша, омлеты и блинчики. Добавьте в свой завтрак витаминов с помощью микрозелени.',
      bg: [Img50_1, ImgW50_1],
      date: '19.06.2024',
      content: (
        <>
        <p><b>Завтрак считается одним из самых важных приемов пищи</b>, поскольку дает заряд энергии на весь день, дает силы для работы или выполнения других важных дел. Кто-то любит утром есть каши, кому-то больше нравятся бутерброды на скорую руку, а кто-то предпочитает, например, сырники. Вариантов сотни. Главное, чтобы завтрак был полезным. Добавить в него витаминов можно с помощью микрозелени. Рассмотрим подробнее несколько интересных рецептов.</p>
        <h2>Бутерброды с микрозеленью</h2>
        <Picture
            png={Img50_2}
            webp={ImgW50_2}
            alt="Микрозелень на завтрак лучшие рецепты"
        />
        <p>Для приготовления вы потратите совсем немного времени. Но сделаете не только вкусный, но и полезный завтрак или перекус на работу.</p>
        <h3>С творожным сыром</h3>
        <p><b>Попробуйте приготовить сразу три вида вкуснейших бутербродов. Вам понадобятся такие продукты:</b></p>
        <ul>
          <li>220 г любимого творожного сыра;</li>
          <li>200 малосольной семги или любой другой красной рыбы;</li>
          <li>Небольшая баночка вяленых томатов;</li>
          <li>Любимый вид хлеба или сразу несколько разных кусочков;</li>
          <li>Один помидор;</li>
          <li>Одно авокадо;</li>
          <li>Один лимон;</li>
          <li>Одно вареное куриное яйцо;</li>
          <li>Пару зубчиков чеснока;</li>
          <li>Одну ч.л. 9% уксуса;</li>
          <li>Микрозелень редиса, капусты;</li>
          <li>Немного прованских трав, оливкового масла, соли и молотого черного перца.</li>
        </ul>
        <p>Процесс приготовления предельно прост. для начала кусочки хлеба обжарьте на сковороде без масла. Очистите лимон, выдавите из него сок. Измельчите чеснок с помощью пресса.</p>
        <p><b>Дальше действуйте так:</b></p>
        <ul>
          <li>Добавьте чеснок, лимонный сок, небольшое количество измельченной цедры и чеснока в сыр. Хорошенько перемешайте.</li>
          <li>Разделите авокадо на две части, выньте косточку и мякоть. Мякоть разомните вилкой до состояния кашицы. Добавьте по вкусу соль и специи, а также немного оливкового масла и лимонного сока.</li>
          <li>Нарежьте небольшими кубиками помидор. Добавьте к нему немного цедры, чеснок, оливковое масло и специи. Тоже хорошо перемешайте.</li>
          <li>Приготовьте яйцо-пашот. Для этого закипятите в кастрюле один литр воды, добавьте уксус. Используя ложку, сформируйте в воде воронку и влейте туда яйцо. Варите примерно три минуты. Аккуратно выньте на тарелку.</li>
        </ul>
        <p>Теперь сделайте бутерброды. На первый кусочек хлеба выложите творожный сыр, вяленые томаты, микрозелень капусты. На второй — авокадо, рыбу, яйцо. Посыпьте сверху микрозеленью редиса. На третий кусочек — помидоры, смешанные с заправкой и специями, смесь микрозелени. О том, почему микрозелень сейчас столь популярна, <Link to="/blog/mikrozelen-v-racione-pitanija-polza-dlja-zdorovja-fizicheskih-kondicij-i-lichnostnoj-produktivnosti/">смотрите здесь</Link>.</p>
        <h3>С нутом</h3>
        <p>В этом рецепте нут заменит творожный сыр.</p>
        <p><b>Итак, для начала подготовьте такие продукты:</b></p>
        <ul>
          <li>Пару кусочков батона или любимого хлеба;</li>
          <li>Одно авокадо;</li>
          <li>Одну горсть нута;</li>
          <li>Два куриных яйца;</li>
          <li>Одну горсть микрозелени рейса (можно заменить любой другой);</li>
          <li>Соль, перец;</li>
          <li>Пару столовых ложек оливкового масла;</li>
          <li>Половину чайной ложки красной икры;</li>
          <li>Одну чайную ложку лимонного сока.</li>
        </ul>
        <p>Заранее сварите яйца и нут. Нут тщательно очистите от пленок и сложите в чашу блендера. Сделайте из него пюре, включив блендер на максимальную мощность. Добавьте в полученную массу лимонный сок, соль и перец. Хорошенько перемешайте. </p>
        <p>Разрежьте авокадо на две части. Выньте косточку, измельчите мякоть вилкой. Ее также нужно посолить и полить лимонным соком. Почистите яйца и нарежьте колечками. </p>
        <p>Выкладывайте ингредиенты на поджаренный батон в такой последовательности: авокадо, нут, яйца. Сверху сбрызнуть оливковым маслом. Микрозелень и красную икру использовать для украшения.</p>
        <h3>Намазка для бутербродов</h3>
        <p>Кстати, из микрозелени можно приготовить оригинальную намазку на хлеб для бутербродов.</p>
        <p><b>Возьмите такие ингредиенты:</b></p>
        <ul>
          <li>100-150 г мягкой малосольной брынзы;</li>
          <li>100-150 г любого по жирности творога;</li>
          <li>Три столовых ложки льняного масла;</li>
          <li>Микрозелень (выберите любимую);</li>
          <li>Немного перца чили;</li>
          <li>Пару щепоток прованских трав.</li>
        </ul>
        <p>Для начала в какой-нибудь емкости соедините творог и брынзу. Тщательно перемешайте. Должна получиться однородная масса. Влейте в нее льняное масло и перемешайте еще раз.</p>
        <p>Всыпьте в творожную массу микрозелень. Также можно добавить укроп, петрушку, зеленый лук. Приправьте намазку прованскими травами и перцем чили, нарезанным тонкими слайсами. </p>
        <p>Переложите массу в банку с крышкой. Используйте для приготовления бутербродов. Она особенно вкусная в сочетании с цельнозерновым хлебом.</p>
        <h2>Овсянка, сэр</h2>
        <Picture
            png={Img50_3}
            webp={ImgW50_3}
            alt="Овсянка, сэр"
        />
        <p>Куда же без каши на завтрак. Попробуйте приготовить традиционную овсянку по необычному рецепту.</p>
        <p><b>Список ингредиентов таков:</b></p>
        <ul>
          <li>300 г овсяной крупы;</li>
          <li>150 мл кокосового молока;</li>
          <li>200 мл кокосовых сливок;</li>
          <li>Немного соли и сахара;</li>
          <li>150 г любых свежих ягод;</li>
          <li>Немного микрозелени.</li>
        </ul>
        <p>Процесс приготовления предельно прост. Овсяную крупу нужно хорошо промыть, залить водой и дать настояться. Идеальный вариант — в течение суток. Но можно и 6-8 часов или, например, ночь. По истечении этого времени воду слить, налить полтора литра новой и поставить на огонь. Доведите овсянку до кипения и оставьте на медленном огне томиться до полной готовности.</p>
        <p>Как только каша будет готова, влейте сливки и молоко, добавьте сахар, соль и тщательно перемешайте. Подавайте на стол, посыпав микрозеленью и ягодами.</p>
        <p>По-другому рецепту можно приготовить кашу с фруктами и сухофруктами. Подготовьте три столовых ложки овсяных хлопьев (без варки), одно яблоко, три финика, немного орешков (миндаль, грецкие, кедровые и т.д.), половину стакана любой микрозелени. </p>
        <p><b>Процесс приготовления выглядит так:</b></p>
        <ul>
          <li>Залейте хлопья небольшим количеством горячей воды. По желанию можно заменить ее молоком.</li>
          <li>Мелко порежьте или натрите на терке яблоко, измельчите финики. При желании замените их любыми другими сухофруктами.</li>
        </ul>
        <p>Осталось смешать микрозелень, яблоки и финики с овсянкой. Для усиления вкуса добавьте небольшое количество меда.</p>
        <h2>А может омлет?</h2>
        <Picture
            png={Img50_4}
            webp={ImgW50_4}
            alt="А может омлет?"
        />
        <p>В приготовлении этого омлета можно не ограничивать фантазию и использовать самые разные ингредиенты. Вот несколько вариантов.</p>
        <h3>Омлет-суфле</h3>
        <p>Сразу подготовьте все компоненты. <b>Кроме 100 г микрозелени, вам нужны:</b></p>
        <ul>
          <li>4 куриных яйца;</li>
          <li>На кончике ножа тертого мускатного ореха;</li>
          <li>60 мл 22% сливок;</li>
          <li>40 г сулугуни;</li>
          <li>30 г сливочного масла;</li>
          <li>Немного соли.</li>
        </ul>
        <p>В первую очередь разделите белки и желтки. В белковую массу добавьте соль и взбивайте миксером до тех пор, пока не сформируется устойчивая пена. В желтки всыпьте орехи и влейте сливки. Хорошенько взбейте и смешайте с тертым на терке сыром сулугуни.</p>
        <p>Теперь нужно осторожно соединить белки и желтки. Используйте для этого лопатку. Важно, чтобы структура взбитой взбитого белка не нарушилась. Добавьте в него микрозелень. Сливочным маслом смажьте подготовленные формы. Заполните их яичной массой на половину. Сверху положите небольшой кусочек масла. На 10 минут отправьте в духовку, предварительно разогретую до температуры 200°С. После достаньте и подавайте на стол прямо в формах.</p>
        <p>Кстати, вырастить микрозелень можно даже дома. Это отличная возможность всегда иметь ее под рукой. О правилах и особенностях выращивания читайте <Link to="/blog/pravila-po-vyrashchivaniyu-mikrozeleni-v-domashnikh-usloviyakh/">здесь</Link>.</p>
        <h3>С цветной капустой и кольраби</h3>
        <p>Для начала уточним, что расход указан для очищенных продуктов. <b>Итак, на одну порцию возьмите:</b></p>
        <ul>
          <li>60 мл молока любой жирности;</li>
          <li>68 г цветной капусты;</li>
          <li>8 г проростков кольраби или любой другой микрозелени;</li>
          <li>60 г сырого куриного яйца;</li>
          <li>4 г подсолнечного масла (почти чайная ложка);</li>
          <li>Немного соли.</li>
        </ul>
        <p>Включите духовку, выставив температуру на 160°С. Пока она греется, разберите цветную капусту на небольшие соцветия и хорошенько вымойте под проточной водой. нарежьте на кусочки, выложите на противень и запекайте на протяжении 10 минут.</p>
        <p>Разбейте яйца в глубокую емкость. Влейте молоко, посолите и перемешивайте до появления пены. Добавьте микрозелень, а после запеченную цветную капусту. </p>
        <p>Смажьте маслом противень с высокими бортиками. Влейте яичную смесь слоем 2-3 см. Запекайте на протяжении 8-10 минут при температуре 180-200°С. Другой вариант приготовления — на пару (полчаса).</p>
        <p>Подавать омлет на стол горячим, нарезанным на порционные кусочки. Если все сделано правильно, он будет пористым, нежным, со слегка зарумяненной поверхностью. Дополнительно можно посыпать микрозеленью перед подачей.</p>
        <h3>С помидорами и сыром</h3>
        <p>Это, пожалуй, самый простой вариант. Возьмите два куриных яйца, 20 г сливочного масла, микрозелень в любом количестве, две столовые ложки творожного сыра, один помидор, соль и перец по вкусу.</p>
        <p><b>Процесс приготовления состоит из нескольких этапов:</b></p>
        <ul>
          <li>Разрежьте помидор на две части. Используя ложку, удалите часть с семенами. Оставшуюся мякоть нарежьте кубиками по 5 мм.</li>
          <li>Соедините помидор с творожным сыром, добавьте соль и перец.</li>
          <li>Тщательно взбейте яйца. Посолите, поперчите, всыпьте микрозелень.</li>
        </ul>
        <p>Жарить омлет на растопленном сливочном масле примерно минуту. Потом выложите на одну половину начинку и накройте ее второй. Пусть постоит на плите еще несколько секунд. Подавайте на стол, украсив оставшейся зеленью.</p>
        <h2>Легкие блинчики</h2>
        <Picture
            png={Img50_5}
            webp={ImgW50_5}
            alt="Легкие блинчики"
        />
        <p>Если позволяет время, приготовьте на завтрак вкуснейшие, легкие и полезные блинчики. <b>Для теста нужны такие компоненты:</b></p>
        <ul>
          <li>Один стакан пшеничной муки;</li>
          <li>Четверть чайной ложки соды;</li>
          <li>Треть чайной ложки разрыхлителя;</li>
          <li>Половина столовой ложки сахарного песка;</li>
          <li>Немного соли;</li>
          <li>Семена тмина;</li>
          <li>Одна чайная ложка тимьяна;</li>
          <li>Две столовые ложки масла (лучше оливковое);</li>
          <li>200-250 г воды.</li>
        </ul>
        <p>Несмотря на такое количество ингредиентов, готовить блины несложно. Просто соедините все компоненты и тщательно перемешайте. Доведите до консистенции легкого йогурта. Если вы предпочитаете использовать более жидкое тесто, добавьте чуть больше воды. Выпекайте на блинной сковороде. </p>
        <p>Теперь нужно приготовить начинку. Соедините несколько видов микрозелени по четверти стакана каждого. Добавьте немного гранатовых зерен. Хорошо перемешайте и заверните начинку в блины. </p>
        <p>Подавайте блюдо на стол с заправкой. Для ее приготовления с можно смешать четверть стакана тахини, немного лимонного сока и зубчик чеснока (выдавить через пресс). Также можно полить кленовым сиропом.</p>
        <p>Итак, существует множество рецептов полезных и вкусных завтраков с микрозеленью. И для тех, кто спешит по утрам, и для тех, кто может выделить время на приготовление, например, блинчиков. Поэтому ее можно смело назвать универсальным продуктом.</p>
        </>
      ),    
    },
    {
      id: 'primenenie-mikrozeleni-v-èstetičeskoj-dietologii',
      name: 'Микрозелень в эстетической диетологии',
      title: 'Применение микрозелени в эстетической диетологии',
      descr: 'Эстетическая диетология и микрозелень: механизмы похудения, какие виды микрозелени включать в диету и как включить микрозелень в свою диету.',
      bg: [Img49_1, ImgW49_1],
      date: '24.05.2024',
      content: (
        <>
        <p><b>Эстетическая диетология</b> — это современная концепция питания, которая уделяет внимание не только здоровью и питательным веществам, но и внешнему влиянию пищи на красоту и здоровье кожи, волос и ногтей. Основной принцип эстетической диетологии заключается в том, что правильное питание в сочетании с употреблением определенных продуктов может улучшить внешний вид человека, обеспечивая коже, волосам и ногтям необходимые питательные вещества. Этот подход также учитывает индивидуальные особенности организма и рекомендует определенные продукты и витамины для достижения желаемых результатов.</p>
        <Picture
            png={Img49_2}
            webp={ImgW49_2}
            alt="Микрозелень в эстетической диетологии"
        />
        <p><b>Основная цель эстетической диетологии</b> — стремление к внешней красоте и привлекательности. Чтобы худеть и улучшать внешний вид, многие диетологи рекомендуют включать в свой рацион микрозелень.</p>
        <p><b>Впервые микрогрин стали использовать</b> в начале 80-х годов прошлого века. В последние годы он приобрел большую известность во всем мире.</p>
        <p><b>Микрозелень</b> — молодые побеги, которые срезают в фазе активного роста. В этот момент растение содержит максимальное количество полезных веществ, витаминов и антиоксидантов.</p>
        <p><b>Например, в состав микрогрина входят витамина С, каротиноиды, полифенолы. Эти полезные вещества помогают:</b></p>
        <ul>
          <li>Замедлять процесс старения;</li>
          <li>Защищать организм от негативного влияния окружающей среды;</li>
          <li>Активнее вырабатывать гиалуроновую кислоту и коллаген, что делает кожу более гладкой и упругой.</li>
        </ul>
        <p><b>На основе микрогрина</b> делают салаты и добавляют практически во все блюда. По вкусу молодые ростки нежнее и деликатнее, чем взрослые растения. Благодаря приятному вкусу и небольшим размерам, молодые ростки нравятся детям.</p>
        <p><b>Существует много сортов микрозелени</b>, которые отличаются по аромату, вкусовым качествам и содержанию полезных веществ. Вкус микрогрина может быть островатым, мягким, с легкой горчинкой, сладким.</p>
        <h2>Механизмы похудения</h2>
        <p><b>Чтобы похудеть</b>, необходимо снизить количество калорий в день и увеличить их расход. При резком сокращении рациона, человек ощущает постоянное чувство голода. Это негативно влияет на работу ЖКТ, настроение и общее самочувствие. Поэтому необходимо включать в рацион низкокалорийные продукты, которые надолго насыщают организм.</p>
        <p><b>Такими качествами обладает микрозелень</b>. Она малокалорийна и содержит много пищевых волокон, которые помогают долго оставаться сытыми, улучшают работу кишечника и желудка.</p>
        <p><b>Кроме того, клетчатка способствует:</b></p>
        <ul>
          <li>Нормализации стула;</li>
          <li>Выведению лишней воды из организма;</li>
          <li>Росту полезной микрофлоры в кишечнике.</li>
        </ul>
        <p><b>В состав микрозелени</b> входит большое количество ферментов, макро- и микроэлементов, которые оказывают оздоровительное действие на организм.</p>
        <p><b>В результате</b> у человека снижается вес, улучшается самочувствие и внешний вид. Благодаря регулярному потреблению микрозелени, состояние ногтей, волос и кожи улучшается. Появляется энергия и сила.</p>
        <h2>Какие виды микрозелени включать в диету</h2>
        <Picture
            png={Img49_3}
            webp={ImgW49_3}
            alt="Какие виды микрозелени включать в диету"
        />
        <p>Все виды микрозелени полезно употреблять во время диеты, так как они содержат много питательных веществ. Но существуют особенно полезные сорта микрогрина при похудении.</p>
        <p><b>Например:</b></p>
        <ul>
          <li>Лен;</li>
          <li>Зеленая гречка;</li>
          <li>Пажитник;</li>
          <li>Руккола;</li>
          <li>Горох;</li>
          <li>Брокколи;</li>
          <li>Редис.</li>
        </ul>
        <p><b>Микрозелень льна богата Омега-3</b>. Она положительно влияет на работу ЖКТ, помогает омолаживать организм. Содержит фитоэстрогены, которые особенно полезны для женщин. Вкус у микрозелени льна нежный, маслянистый, нейтральный. Хорошо сочетается с салатами, супами, напитками, гарнирами и кашами. Подают молодые ростки льна с бутербродами и закусками.</p>
        <p><b>Молодые ростки зеленой гречки</b> улучшают работу ЖКТ, укрепляет сосуды и капилляры. При регулярном употреблении помогает снижать уровень плохого холестерина и уменьшает чувство голода. Обладает нежным, нейтральным вкусом. Хорошо сочетается с овощными коктейлями, салатами.</p>
        <p><b>Микрозелень пажитника</b> способствует выводу из организма вредных веществ, нормализации работы ЖКТ. Во время диеты молодые ростки помогают улучшать состояние кожи и волос. Микрогрин пажитника обладает немного горьковатым с ореховыми нотками привкусом. В тоже время он сладковатый, жирный и маслянистый. В альтернативной медицине растение используют для лечения простудных и эндокринных болезней. В кулинарии подают в качестве дополнения к мясным блюдам, морской рыбе. Добавляют в соусы и маринады. Вкус молодых ростков хорошо сочетается с супами, смузи, йогуртами, сырами.</p>
        <p><b>Микрогрин брокколи листовой</b> благоприятно воздействует на работу ЖКТ и нервной системы. Помогает предотвращать развитие язвы желудка и двенадцатиперстной кишки. Употребляя микрозелень брокколи, человек лучше справляется с душевными волнениями, что важно при соблюдении диеты. По вкусу ростки менее горькие и острые по сравнению с взрослым растением. Чем-то напоминает листовую горчицу, но с приятным ореховым ароматом. Подают с острыми, сладкими и солеными блюдами.</p>
        <p><b>Микрогрин редиса</b> содержит компоненты, которые способствуют сжиганию жира. Кроме того, молодые ростки помогают выводить из организма токсины. Также микрогрин редиса — источник витаминов и минералов, которые отвечают за здоровье волос и кожи. При регулярном употреблении молодых ростков редиса улучшается общее состояние организма, повышается иммунитет. Их рекомендуют употреблять при похудении, так как после употребления редиса быстро наступает чувство сытости. Вкус у ростков пикантный. Он служит хорошим дополнением овощных салатов, рыбных и мясных блюд, смузи.</p>
        <p><b>Микрозелень рукколы</b>, благодаря входящим в состав компонентам, защищает от негативного влияния твердой пищи стенки желудка и улучшает пищеварение. Обладает мочегонным эффектом, что способствует уменьшению веса.</p>
        <p><b>Микрогрин гороха</b> нежный и сочный. Он аппетитно хрустит. Поэтому чаще всего его добавляют в салаты и посыпают гарниры.</p>
        <h2>Как включить микрозелень в свою диету</h2>
        <p><b>Чтобы самостоятельно вырастить микрогрин</b>, много затрат не надо (если сравнивать с классическим выращиванием аналогичных овощей). Микрозелень быстро растет. Максимальное время от посева до срезки — 14 суток. Кроме того, для выращивания не требуется много пространства. Стеллажи разрешается размещать друг над другом, что значительно экономит площадь. Для многих этот процесс становится хобби.</p>
        <p><b>Но существует немало минусов самостоятельного выращивания. Например:</b></p>
        <ul>
          <li>Необходимо найти в квартире свободное место. Его много не надо, но подходящее пространство в квартире выделить не так-то просто. Это становится проблемой в небольшой квартире. Выращивать микрогрин на подоконнике нельзя. Зимой проростки будут испытывать недостаток солнечного освещения, а в летнюю жару — сгорать под воздействием прямых солнечных лучей. Кроме того, при выращивании на подоконнике молодые ростки постоянно будут подвергаться колебанию температурных режимов из-за радиаторов отопления и направления ветра на улице (когда дует в сторону окна, на подоконнике становится холодно).</li>
          <li>Сложно выращивать несколько видов микрогрина, так как, в зависимости от сорта, требуется различная интенсивность освещения, объем влаги и температурный режим. Прежде чем получить первый урожай, может быть несколько неудачных попыток.</li>
          <li>Необходимо дополнительно освещать растения. Для получения хорошего урожая (особенно зимой), необходимо досвечивать микрозелень не менее 10-12 часов. Для этого надо потратиться на дополнительное освещение и платить за электричество.</li>
          <li>Чтобы получить хороший урожай необходимо приобретать специальные семена для проращивания, которые отличаются от обычных. Связано это с тем, что посевной материал, который продают во многих магазинах, обрабатывается химическими препаратами, которые защищают от возбудителей болезней, улучшают всхожесть. Поэтому эти семена не подходят для микрогрина из-за того, что вредные вещества попадут в организм с молодыми побегами. Специальный посевной материал не проходит специальную обработку.</li>
        </ul>
        <p><b>Кроме того</b>, при самостоятельном выращивании необходимо ежедневно ухаживать за микрозеленью: правильно поливать, освещать. Не получится на несколько дней уехать из дома, так как молодые ростки быстро завянут. Необходимо следить за домашними животными, которые могут развернуть стеллажи или съесть весь урожай. Если ваша цель — ежедневное употребление микрогрина во время диеты для похудения, то надо найти большую площадь для выращивания и постоянно досевать семена, что требует времени.</p>
        <p><b>В магазинах или маркетплейсах</b> можно круглый год покупать свежую микрозелень, которую выращивают с соблюдением всех технологий. При этом не придется портить внешний вид квартиры стеллажами, тратить время на посев и уход. На производстве полностью автоматизирован процесс выращивания микрогрина, что помогает максимально сохранять витамины, микроэлементы и полезные вещества.</p>
        <p><b>На помощь приходят профессиональные компании, такие как бренд Vitamincus</b>, которые предлагают широкий ассортимент высококачественной микрозелени. Благодаря тщательному отбору семян, специализированным методам выращивания и контролю качества продукции, бренд Vitamincus обеспечивает своим клиентам микрозелень высочайшего качества. Большой выбор видов зелени, превосходный вкус и уникальные питательные свойства делают продукцию Vitamincus идеальным выбором для тех, кто ценит свое здоровье, красоту и натуральность.</p>
        <p><b>При этом надо помнить</b>, что, просто ежедневно включая в рацион молодые ростки, похудеть не получится. Необходимо сбалансировать питание, исключить хлебобулочные и кондитерские изделия, употреблять больше овощей и фруктов, заниматься физкультурой и не забывать о прогулках на свежем воздухе. <Link to="/blog/reczepty-kak-upotreblyat-mikrozelen-salaty-blyuda-i-smuzi/">Здесь</Link> вы найдете вкусные, полезные рецепты с добавлением микрогрина.</p>
        </>
      ),    
    },
    {
      id: 'primenenie-mikrozeleni-v-sportivnom-pitanii',
      name: 'Микрозелень в спортивном питании',
      title: 'Применение микрозелени в спортивном питании',
      descr: 'Спортивное питание и микрозелень: польза микрогрина в питании спортсменов и рекомендации по употреблению микрозелени при занятиях спортом.',
      bg: [Img48_1, ImgW48_1],
      date: '24.05.2024',
      content: (
        <>
        <p><b>Тренировки станут гораздо эффективнее</b>, если составить правильное меню, так как к основе здорового образа жизни относят правильное питание. Каждый день рацион спортсмена должен быть разнообразным и включать полезные продукты, к которым относят микрозелень.</p>
        <p><b>Рассказываем, в чем заключается польза микрогрина в питании спортсменов, и какие сорта считаются лучшими.</b></p>
        <Picture
            png={Img48_2}
            webp={ImgW48_2}
            alt="Микрозелень в спортивном питании"
        />
        <p><b>Микрогрин</b> — молодые побеги зелени и овощей. Их высота достигает 5-7 сантиметров. Срезают микрозелень в момент раскрытия первых листочков. В процессе прорастания продукт содержит максимальное количество полезных свойств растения.</p>
        <p><b>Микрогрин обладает большой жизненной силой. При прорастании, в семенах в несколько раз увеличивается объем:</b></p>
        <ul>
          <li>Витамины;</li>
          <li>Ферменты;</li>
          <li>Антиоксиданты;</li>
          <li>Микроэлементы;</li>
          <li>Другие полезные для организма вещества.</li>
        </ul>
        <p><b>Например, микрогрин гречки</b> по сравнению с взрослым растением содержит в 18 раз больше витаминов и в 2 раза — антиоксидантов.</p>
        <p><b>Количество витамина Е</b> в микрозелени сильно возрастает. Например, в микрогрине брокколи оно увеличивается более чем в сто раз. В состав молодых ростков входит токоферол. Это мощный антиоксидант, который при регулярном употреблении помогает продлевать молодость и повышать противоопухолевую защиту организма.</p>
        <h2>Польза микрогрина в питании спортсменов</h2>
        <p><b>Микрозелень содержит клетчатку, хлорофилл и каротиноиды, необходимые для здоровья спортсменов. Входящие в состав растительные волокна помогают:</b></p>
        <ul>
          <li>Стимулировать пищеварительный процесс;</li>
          <li>Контролировать чувство голода;</li>
          <li>Правильно функционировать кишечнику.</li>
        </ul>
        <p><b>Микрозелень связывает и выводит из</b> организма отходы жизнедеятельности. Благодаря содержанию клетчатки уходят лишние килограммы, нормализуется вес.</p>
        <p><b>Микрозелень срезается на стадии активного роста</b>, когда содержание витаминов и других полезных веществ находится на максимальном уровне. Кроме того, продукт относят к категории низкокалорийных. Поэтому он идеально подходит для спортсменов.</p>
        <p>У микрозелени приятные вкусовые качества. Молодые ростки не резкие, но отлично ощущаются и придают блюдам пикантность.</p>
        <p><b>Спортсменам рекомендуют включать в рацион микрогрин, так как он:</b></p>
        <ul>
          <li>Повышает иммунитет;</li>
          <li>Улучшает обмен веществ;</li>
          <li>Нормализует кислотно-щелочной баланс;</li>
          <li>Очищает организм от токсинов;</li>
          <li>Способствует эффективному пищеварению;</li>
          <li>Снижает вес.</li>
        </ul>
        <p><b>Кроме того, микрозелень</b> в совокупности с правильным питанием, улучшает самочувствие, заряжает энергией, улучшает состояние ногтей, волос и кожи.</p>
        <p><b>Микрогрин помогает улучшать</b> спортивные показатели и способствует более быстрому восстановлению после тренировок. Продукт нормализует уровень холестерина в крови, регулирует пищеварение и контролирует аппетит. Это важно для спортсменов, которые стремятся поддерживать оптимальный вес и следят за питанием.</p>
        <p><b>Продукт полезен не только для профессионального спорта</b>, но и для любителей фитнеса. Молодые ростки способствуют укреплению мышц и суставов. Благодаря повышенному содержанию витамина С, железо усваивается организмом более эффективно и способствует синтезу коллагена. В результате здоровье суставов и связок улучшается. Где и как купить готовую к употреблению микрозелень узнаете <Link to="/blog/rasskazyvaem-gde-i-kak-kupit-mikrozelen-gotovuju-k-upotrebleniju-v-pishhu/">здесь</Link>.</p>
        <h2>Рекомендации по употреблению микрозелени при занятиях спортом</h2>
        <Picture
            png={Img48_3}
            webp={ImgW48_3}
            alt="Рекомендации по употреблению микрозелени при занятиях спортом"
        />
        <p><b>Микрозелень рекомендуют</b> употреблять утром и в обед. В этом случае организм получает сильный заряд бодрости на целый день. Идеальнее всего употреблять молодые ростки отдельно, тщательно пережевывая. В этом случае польза для организма будет максимальной.</p>
        <p><b>Кроме того, можете добавлять микрозелень в:</b></p>
        <ul>
          <li>Салаты;</li>
          <li>Первые и вторые блюда;</li>
          <li>Коктейли, смузи;</li>
          <li>Омлет;</li>
          <li>Сладкие блюда;</li>
          <li>Бутерброды;</li>
          <li>Творог.</li>
        </ul>
        <p><b>Не подвергайте молодые ростки тепловой обработке</b>, так как высокие температуры разрушают большую часть полезных веществ. Нельзя замораживать микрогрин и консервировать. Хранить разрешается при температуре +2…+4 С°. Моют молодые ростки непосредственно перед употреблением.</p>
        <p><b>Спортсмен должен правильно питаться постоянно</b>. Один микрогрин не сможет улучшить состояние здоровья и укрепить организм. Запас энергии и питательных веществ формируется длительное время.</p>
        <p><b>Для каждого спортсмена составляют индивидуальный план питания, который зависит от:</b></p>
        <ul>
          <li>телосложения;</li>
          <li>типа обмена веществ.</li>
        </ul>
        <p><b>При составлении меню </b>учитывают период подготовки и конкретный вид спорта. Питаться необходимо вовремя не меньше 5 раз в сутки. Употреблять еду надо каждые 3-4 часа. <b>Питание состоит из:</b></p>
        <ul>
          <li>Основных приемов пищи;</li>
          <li>Перекуса перед тренировкой;</li>
          <li>Восстановительного питания после тренировки.</li>
        </ul>
        <p>Нельзя пропускать завтрак. Оптимальный ежедневный баланс — это основная еда (3/4 продуктов), перекус (1/4 продуктов).</p>
        <h2>Лучшие сорта микрозелени для спортсменов</h2>
        <p><b>Спортсменам рекомендуют включать в рацион микрогрин:</b></p>
        <ul>
          <li>Базилика;</li>
          <li>Рукколы;</li>
          <li>Шпината;</li>
          <li>Розмарина;</li>
          <li>Горчицы;</li>
          <li>Мангольда;</li>
          <li>Щавеля;</li>
          <li>Амаранта;</li>
          <li>Гороха;</li>
          <li>Душицы;</li>
          <li>Чабреца.</li>
        </ul>
        <p><b>У базилика</b> приятный аромат с пряным, маслянистым вкусом, который напоминает взрослое растение, но с особыми утонченными нотками. Сочетается с мясными, рыбными и овощными блюдами. Его неповторимый цвет ценят во всем мире. Используют микрозелень базилика для украшения всех блюд.</p>
        <p><b>Руккола</b> идеально подходит для мясных и овощных блюд, сэндвичей. Обладает сильным ароматом. Вкус орехово-горчичный, острый, пряный. Подходит для украшения блюд, использования в маринадах и соусах.</p>
        <p><b>У микрогрина шпината</b> свежий, нейтральный, слегка сладковатый вкус. Сочетается с овощными гарнирами и мясными блюдами. Кулинары часто добавляют молодые ростки шпината в смузи, коктейли. Подают с рыбой и фруктами. Шпинат помогает укреплять костную ткань. Служит профилактикой артрита и остеопороза, налаживает правильный обмен веществ в соединительных тканях.</p>
        <p><b>Микрозелень розмарина</b> обладает удивительными вкусовыми качествами с нотками лимона, камфары и эвкалипта. Особую популярность микрогрин получил во Франции. Его широко применяют в свежем виде, на его основе готовят масло.</p>
        <p><b>У микрозелени горчицы</b> островатый, сочный вкус. Она служит отличным дополнением салатов, других закусок, гарниров, супов, рыбы и мяса. Помогает укреплять иммунитет при простудных заболеваниях, нормализует вес, укрепляет стенки сосудов.</p>
        <p><b>Микрогрин мангольда (листовой свеклы)</b> обладает сладким привкусом, который напоминает свеклу. Она часто служит заменой шпината, так как вкусовые качества у них схожи. Подают со свежими овощами и в качестве гарнира. Салат с добавлением микрогрина мангольда сочетается с черносливом. В качестве заправки используют растительное масло со смесью лимонного сока и горчицы или бальзамического уксуса. Для украшения используют кедровые орехи. Мелко нарезанные молодые ростки улучшают вкус обжаренных на гриле креветок. Микрогрин мангольда — отличный вариант для завтрака. Порубленные ростки добавляют в омлет. Чтобы завтрак получился более интересным, подают блюдо с фетой или другим рассольным сыром.</p>
        <p><b>Микрогрин амаранта</b> служит оригинальным украшением для готовых блюд. Пользуются популярностью смузи с добавлением молодых ростков. Микрогрин амаранта очищает организм и обеспечивает организм веществами, необходимыми для нормального функционирования всех органов.</p>
        <p><b>Микрозелень душицы</b> пользуется популярностью в Европе и Средиземноморье. Растение входит в состав многих миксов от простуды. Рекомендуют применять его в качестве общеукрепляющего и противовоспалительного средства. Эфирные масла используют в косметических средствах. Микрозелень душицы напоминает по вкусу базилик. Только листочки у растения более узкие. По цвету бывают желтоватые или зеленые. Вкус по сравнению с базиликом гораздо интенсивнее и насыщеннее, более терпкий, пряный. Обладает легкой горчинкой. Микрогрин хорошо сочетается с супами, пиццей и другими горячими блюдами.</p>
        <p><b>Микрозелень чабреца (тимьяна)</b> обладает сильным пряным ароматом. Вкус характерный, ярко выраженный. Содержит большое количество эфирных масел, которые придают блюдам особый вкус и аромат. Молодые ростки чабреца хорошо сочетаются с:</p>
        <ul>
          <li>Мясом, рыбой;</li>
          <li>Птицей;</li>
          <li>Бобами, грибами;</li>
          <li>Яйцами, овощами.</li>
        </ul>
        <p>Для сохранения полезных качеств и аромата, микрогрин добавляют перед подачей блюда. Небольшое количество молодых ростков чабреца помогает улучшить вкусовые качества кондитерских изделий и выпечки.</p>
        <p><b>Микрозелень гороха</b> похожа на тонкие усики. Это наиболее популярный вид микрогрина во всем мире. Молодые ростки сочные, слегка сладковатые. Обладают приятным, еле заметным ореховым привкусом. Чаще всего используют их в качестве гарнира. Также молодые ростки добавляют в овощные смузи, супы, салаты и используют в качестве украшения различных блюд. Калорийность у микрогрина гороха минимальная, поэтому он идеально подходит для спортсменов. Кстати, более подробную информацию о видах микрозелени можете узнать <Link to="/blog/luchshie-sorta-mikrozeleni/">здесь</Link>.</p>
        <p><b>Необязательно употреблять только один определенный микрогрин</b>, можно включать в рацион микс сортов. Это поможет разнообразить вкус блюд. Приобрести молодые ростки можно круглый год в супермаркетах. Хранить их можно в течение 3-4 дней в холодильнике. Если приобрели микрозелень в лотках, то продукт сохранит свежеть в течение 10-11 дней. Микрозелень содержит полезные вещества, которые необходимы для спортивного питания, в форме, хорошо усваиваемой организмом.</p>
        </>
      ),    
    },
    {
      id: 'obzor-populyarnykh-sortov-mikrozeleni-iz-raznykh-stran-mira',
      name: 'Популярные сорта микрозелени в странах мира',
      title: 'Обзор популярных сортов микрозелени из разных стран мира',
      descr: 'Какие сорта микрозелени пользуются популярностью в разных странах: Россия, Беларусь, Германия, Италия, Африка, Франция, Азия, Индия, США.',
      bg: [Img47_1, ImgW47_1],
      date: '24.04.2024',
      content: (
        <>
        <p><b>Микрогрин</b> — молодые побеги растений. Используют их в пищу и для украшения блюд. Продукт быстро стал популярным во всем мире. В статье узнаете, какие сорта микрозелени пользуются популярностью в разных странах и об их вкусовых качествах.</p>
        <Picture
            png={Img47_2}
            webp={ImgW47_2}
            alt="Популярные сорта микрозелени в странах мира"
        />
        <h2>Италия</h2>
        <p>В Италии наибольшей популярностью пользуется микрозелень базилика. Ее подают с помидорами и другими блюдами из томатов. Часто употребляют с фруктовыми джемами (например, малиновым, земляничным), свежими фруктами. Даже любимую пиццу итальянцы украшают микрозеленью базилика.</p>
        <p><b>На втором месте — микрогрин рукколы. Его часто добавляют в соус Песто. Молодые ростки обладают острым вкусом, который сочетается с:</b></p>
        <ul>
          <li>супами;</li>
          <li>салатами;</li>
          <li>сэндвичами.</li>
        </ul>
        <p>Микрозелень рукколы добавляют в зеленые смузи и используют в качестве украшения блюд.</p>
        <h2>Африка</h2>
        <p>В Северной Африке (Египет, Алжир, Тунис) чаще всего употребляют микрозелень майорана. Отличительная особенность этих стран — применение большого количества трав, пряностей и специй. Поэтому блюда всегда получаются ароматными и пикантными.</p>
        <p>Микрозелень майорана добавляют в салат с помидорами и огурцами, сочетают с микрогрином базилика, горчицы, рукколы. Это придает дополнительные вкусовые оттенки.</p>
        <h2>Франция</h2>
        <p>Тимьян — главная французская приправа. Она входит в состав «Прованских трав» и «Букета Гарни». В Южной Франции тимьян применяют в большом количестве блюд: рыбных, овощных, мясных. Обязательно добавляют специю в рататуй, тушеные кабачки с помидорами.</p>
        <p>Не меньшей популярностью пользуется во Франции микрозелень тимьяна. Нежные молодые росточки служат дополнением к блюдам и их украшением.</p>
        <p>На втором месте — микрогрин базилика. Французы покорены сладким, приятным привкусом. Его добавляют во многие национальные блюда. Также популярностью пользуется микрозелень шпината, которая содержит много витаминов и минералов. Принято употреблять это растение для профилактики анемии, так как в состав входит много железа.</p>
        <h2>Азия</h2>
        <p>В Азии (Япония, Китай, Индонезия) популярностью пользуется микрозелень горчицы. Ароматные ростки с островатым привкусом, становятся отличным дополнением овощных салатов с кукурузой и бобами.</p>
        <p>Также в азиатской кухне востребован микрогрин Джусай (лук Шнитт). У него приятный, пряный аромат, напоминающий чеснок. Подают с соленьями и маринованными блюдами. Ростки помогают укреплять иммунную систему, усиливать аппетит и устранять воспаления.</p>
        <p>Не обходят вниманием в Азии микрозелень сельдерея. У ростков приятный пряный вкус, но значительно нежнее по сравнению с взрослым растением.</p>
        <p><b>Используют для приготовления:</b></p>
        <ul>
          <li>салатов;</li>
          <li>мясных, рыбных блюд;</li>
          <li>зеленых коктейлей.</li>
        </ul>
        <p>Микрозелень сельдерея помогает повышать общий тонус организма, очищает его от токсинов, помогает укреплять защитные функции, замедляет процессы старения. В состав входят минералы, витамины, антиоксиданты, пищевые волокна.</p>
        <h2>Индия</h2>
        <p>В Индии особое место занимают пряности, специи и зелень. В последнее время большой популярностью стал пользоваться микрогрин. На столах местных жителей с пучком свежей зелени подают ко всем блюдам молодые ростки, которые насыщают организм полезными веществами.</p>
        <p><b>Наибольшей популярностью пользуется микрозелень кинзы. Ее добавляют в:</b></p>
        <ul>
          <li>закуски;</li>
          <li>местные лепешки;</li>
          <li>фаршированные овощи;</li>
          <li>маринады;</li>
          <li>соусы.</li>
        </ul>
        <p>У микрогрина кинзы насыщенный резко-пряный, сладковатый и слегка горьковатый привкус. Он отлично сочетается практически со всеми блюдами. Обогащает их оригинальным пикантным вкусом.</p>
        <p>Микрозелень кинзы помогает нормализовать давление, выводит токсины, укрепляет костную ткань, сердечно-сосудистую систему, стимулирует пищеварение.</p>
        <h2>США</h2>
        <p>Американцы предпочитают молодые ростки горчицы. На уикенды и все праздники в Америке жарят стейки и готовят барбекю. Именно к этим блюдам чаще всего подают микрогрин горчицы. Американцы утверждают, что весь вкусовой спектр микрозелени может раскрыть жареное мясо.</p>
        <p><b>У молодых ростков горчицы уникальный острый вкус, сочетающийся с:</b></p>
        <ul>
          <li>салатами;</li>
          <li>холодными закусками;</li>
          <li>рыбой;</li>
          <li>мясом;</li>
          <li>гарнирами;</li>
          <li>супами;</li>
          <li>бутербродами.</li>
        </ul>
        <p>Микрогрин горчицы придает блюдам оригинальный и уникальный вкус. При употреблении ростков в свежем виде, организм получает максимальный объем полезных веществ.</p>
        <p>Микрозелень горчицы помогает укреплять организм, повышать иммунитет, быстрее выздоравливать при ОРВИ. Молодые ростки улучшают пищеварение, ускоряют обмен веществ.</p>
        <p>Другая популярная микрозелень в США — кервель. У ростков мягкий пряный вкус, сладкий анисовый аромат. Вкусовые качества ярко-выражены, но деликатны.</p>
        <p><b>Микрогрин отлично дополняет:</b></p>
        <ul>
          <li>супы;</li>
          <li>соусы;</li>
          <li>блюда из яиц;</li>
          <li>картофель;</li>
          <li>рыбные и куриные блюда.</li>
        </ul>
        <p>Микрозелень кервеля — нежные ростки с оригинальным внешним видом. У них тонкие ярко-зеленые семядоли и ажурные первые два настоящих листочка. Поэтому микрогрин эффектно выглядит в различных блюдах.</p>
        <p><b>Приятный анисовый аромат нравится многим. Поэтому микрозелень добавляют в:</b></p>
        <ul>
          <li>овощной салат;</li>
          <li>яичницу;</li>
          <li>сметанный соус;</li>
          <li>масло;</li>
          <li>подливки (овощные, мясные);</li>
          <li>творожную запеканку.</li>
        </ul>
        <p>Гурманы добавляют микрозелень кервеля в оливковое масло, различные соусы и заправки. Она помогает придавать блюдам непревзойденные вкусовые качества и аромат.</p>
        <p>Душистые ростки сочетаются с мясом и рыбой. Микрозелень кервеля измельчают в блендере и подают в отдельной пиале. Кроме того, ее соединяют с мягким сыром, творогом. В результате получают полезный продукт с приятным пряным привкусом.</p>
        <h2>Беларусь, Россия</h2>
        <p>Самая популярная зелень в России и Беларуси — укроп и петрушка. Во все вторые и первые блюда добавляют эту зелень.</p>
        <p><b>У микрогрина укропа изысканный аромат и невероятно свежий привкус. Микрозелень становится отличным дополнением к разным блюдам:</b></p>
        <ul>
          <li>салатам;</li>
          <li>супам;</li>
          <li>омлетам;</li>
          <li>сэндвичам.</li>
        </ul>
        <p>Микрогрин укропа используют в качестве гарнира к основным блюдам. Маленькие ростки добавляют свежесть всем продуктам и придают им уникальный вкус. Также микрозелень укропа подают с морепродуктами, овощами и сырами.</p>
        <p>Молодые ростки насыщают организм клетчаткой, нормализуют обмен веществ, улучшают пищеварение. В состав входят антиоксиданты, которые ведут борьбу со свободными радикалами. Кроме того, у микрогрина укропа низкая калорийность. Поэтому он подходит для питания людей, стремящихся к здоровому образу жизни.</p>
        <p><b>У микрозелени петрушки пряный вкус взрослого растения, но значительно нежнее. Микрогрин применяют как обычную зелень. Подают к:</b></p>
        <ul>
          <li>рыбе;</li>
          <li>мясу;</li>
          <li>овощам;</li>
          <li>гарнирам.</li>
        </ul>
        <p>Микрогрин петрушки служит украшением блюд. Молодые ростки укрепляют иммунную систему, улучшают зрение, благоприятно влияют на сердечно-сосудистую систему. В состав входит большое количество эфирных масел.</p>
        <p>Микрогрин петрушки улучшает пищеварение, оказывает антисептическое и антибактериальное действие. Помогает подавлять патогенную микрофлору кишечника.</p>
        <p>Начинает набирать популярность микрозелень лука-порея. Все чаще ее добавляют в различные диетические блюда. Микрогрин лука-порея добавляет особый акцент и придает блюдам приятную свежесть. По сравнению с взрослым растением, молодые ростки обладают более концентрированным вкусом. Поэтому используют их умеренно, чтобы не нарушить сочетание вкусов. Кроме того, ростки хорошо сочетаются с другими видами микрогрина. При сочетании разных сортов, создаются интересные вкусовые композиции.</p>
        <h2>Германия</h2>
        <p>В спаржевый сезон в Германии пользуется популярностью микрогрин чесночного кресса и красного редиса. Этими сортами микрозелени по традиции украшают спаржу. Повышенным спросом микрогрин пользуется в зимний и весенний период. Накануне Пасхи все стремятся скинуть лишний вес. Поэтому спрос на молодые ростки значительно возрастает.</p>
        <p>У микрозелени красного редиса освежающий вкус. Ростки хрустящие, сочные с легкой горчинкой. Стебли у них малиново-розового цвета, а листочки - зеленого. </p>
        <p><b>Их используют в качестве дополнительного ингредиента к:</b></p>
        <ul>
          <li>сэндвичам;</li>
          <li>бутербродам;</li>
          <li>холодным закускам;</li>
          <li>напиткам;</li>
          <li>сушам и роллам.</li>
        </ul>
        <p>Молодые ростки красного редиса содержат много питательных веществ и приносят пользу здоровью.</p>
        </>
      ),    
    },
    {
      id: 'kak ispolzovali mikrozelen v kosmonavtike',
      name: 'Микрозелень для космонавтов',
      title: 'Как использовали микрозелень в космонавтике',
      descr: 'В данной статье мы расскажем о применении микрозелени для питания космонавтов и для выращивания микрогрина в космосе.',
      bg: [Img46_1, ImgW46_1],
      date: '24.04.2024',
      content: (
        <>
        <p><b>Существует мнение</b>, что в современном мире микрозелень появилась гораздо раньше, чем в ресторанах Сан-Франциско. Впервые ее применили для питания космонавтов в 1970-х годах. Космонавты на орбите Земли оставались все дольше, и остро вставал вопрос о правильном, полезном питании.</p>
        <Picture
            png={Img46_2}
            webp={ImgW46_2}
            alt="Микрозелень для космонавтов"
        />
        <p><b>Микрогрин ученые не рассматривали в качестве основного продукта. Это было дополнительное питание, которое содержит максимальное количество:</b></p>
        <ul>
          <li>микроэлементов;</li>
          <li>витаминов;</li>
          <li>макроэлементов.</li>
        </ul>
        <p><b>Кроме того</b>, масса и объем микрогрина минимальны. Поэтому выращивание молодых ростков в космосе — отличный вариант.</p>
        <p><b>Также к исследованиям микрогрина</b> приступило NASA. Специалисты изучают, можно ли производство микрозелени наладить в космосе, чтобы космонавты могли ежедневно употреблять в пищу свежую зелень, которая содержит много питательных веществ, необходимых для организма в долгом космическом путешествии.</p>
        <p><b>В NASA существуют подразделения</b>, в которых выращивают овощи. Там же тестируют выращивание микрозелени для космического полета при помощи коврика для выращивания. Кроме того, там проводят агрономическую биофортификацию. Это значит, что преднамеренно используют минеральные удобрения, чтобы увеличить концентрацию целевого минерала в съедобной части растения.</p>
        <p><b>Обычные культуры</b> выращивают в течение нескольких месяцев, а микрогрин можно употреблять в пищу через 1-2 недели после всходов. Кроме того, выращивать его получится даже в небольшом объеме. Все это делает продукт практичным для любого космического полета, ведь у экипажа время ограничено. Также существуют ограничения в корабле по массе, мощности и объему. Выращивать зелень, овощи до зрелости нельзя. Поэтому микрозелень становится отличной альтернативой.</p>
        </>
      ),    
    },
    {
      id: 'primenenie-mikrogrina-v-kosmeticheskikh-celyakh',
      name: 'Микрозелень в косметологии',
      title: 'Применение микрогрина в косметических целях',
      descr: 'Микрозелень в косметологии и рецепты использования микрозелени в косметических целях: маски, тоники, вода для умывания, скрабы и т.д.',
      bg: [Img45_1, ImgW45_1],
      date: '24.04.2024',
      content: (
        <>
        <p>Микрозелень используют и <b>в косметических целях</b> при приготовлении домашних масок для лица и различных отваров.</p>
        <p><b>Микрозелень</b> — отличное средство для ухода за кожей тела и лица. Уникальный состав молодых ростков улучшает структуру кожных покровов, увлажняет и делает их более упругими и гладкими. Витамины С и А, входящие в состав микрогрина, снижают воспалительные процессы и раздражения. Молодые ростки способствуют заживлению кожи и поддерживают естественный процесс выработки коллагена.</p>
        <Picture
            png={Img45_2}
            webp={ImgW45_2}
            alt="Микрозелень в косметологии"
        />
        <p><b>Кроме того,</b> микрогрин протирают в блендере, перекладывают в форму для льда и замораживают. Ледяными кубиками протирают кожу утром и вечером. Такая процедура помогает тонизировать кожу и насыщать ее полезными элементами.</p>
        <p><b>Рецепты использования микрозелени в косметических целях:</b></p>
        <ul>
          <li><b>Косметическая маска.</b> Микрозелень перемолоть, соединить с йогуртом, медом или овсяными хлопьями. Нанести на кожу лица и оставить на несколько минут. Маска поможет увлажнить кожу, сделать ее сияющей и здоровой на вид.</li>
          <li><b>Тоник для лица.</b> Молодые ростки заливают кипятком и оставляют до полного остывания. Смесь процеживают. Наносят после очищающих процедур. Раздраженную кожу тоник помогает увлажнить, успокоить и освежить.</li>
          <li><b>Вода для умывания.</b> Микрозелень измельчить. Воду подогреть (горячую использовать нельзя), соединить жидкость и молодые ростки. Перемешать и оставить на 15-20 минут. За это время полезные вещества высвободятся. Используют для умывания. Вода с микрозеленью очищает и освежает кожные покровы.</li>
          <li><b>Скраб.</b> Микрогрин измельчают и соединяют с коричневым сахаром. Дважды в неделю втирают в кожу. Процедура удаляет отмершие клетки и освежает лицо.</li>
        </ul>
        <p>Прежде чем наносить домашнее косметическое средство с микрогрином на кожу, необходимо <b>проверить наличие аллергии</b>. Это особенно касается людей с чувствительной кожей. Надо небольшой объем средства нанести на внутреннюю часть запястья и оставить на пару минут. При появлении покраснения, зуда, раздражения использовать продукт нельзя.</p>
        <p>Всем известно, что здоровье кожи и ее красота начинаются изнутри. Поэтому надо регулярно добавлять молодые ростки в состав любых блюд. Сочетая косметические средства и еду с микрозеленью, вы получите максимум пользы не только для кожи, но и в целом для организма. </p>
        </>
      ),    
    },
    {
      id: 'istoriya-poyavleniya-i-razvitiya-mikrogrina-v-mire',
      name: 'История появления микрозелени в разных культурах мира',
      title: 'История появления и развития микрогрина в мире',
      descr: 'Как человек решил использовать микрозелень. От осознания пищевой ценности, до декоративного использования в блюдах.',
      bg: [Img44_1, ImgW44_1],
      date: '24.04.2024',
      content: (
        <>
        <p><b>Микрозелень</b> — полезный продукт, заряжающий организм бодростью. Маленькие ростки приобрели популярность у людей, который ведут здоровый образ жизни и придерживаются здорового питания.</p>
        <p><b>Микрозелень</b> — молодые ростки растений. В пищу употребляют микрогрин корнеплодов, листовых культур и овощей. Ростки выращивают до формирования первых листочков, затем их срезают.</p>
        <p>Проростки значительно отличаются от обычной зелени. Дело в том, что на ранней стадии в молодом побеге сконцентрировано максимальное количество полезных микроэлементов и витаминов (примерно в 5 раз больше по сравнению с взрослой культурой).</p>
        <p><b>Существует много сортов микрозелени. У каждого вида своя пищевая ценность. В среднем в 100 г содержится:</b></p>
        <ul>
          <li>31 ккал;</li>
          <li>белок — 3 г;</li>
          <li>пищевые волокна — 3 г;</li>
          <li>углеводы — 5 г;</li>
          <li>остальное — вода.</li>
        </ul>
        <p>Поэтому микрозелень полезна для спортивного и диетического питания.</p>
        <p>Наиболее востребованные сорта микрогрина — подсолнечник, руккола и горох. Они просты в выращивании, обладают приятным вкусом и пользуются популярностью при украшении блюд.</p>
        <Picture
            png={Img44_2}
            webp={ImgW44_2}
            alt="Микрозелень: как все начиналось"
        />
        <h2>Микрозелень: как все начиналось</h2>
        <p>История насчитывает сотни лет. <b>Еще в XVI веке в Японии</b> монахи приступили к выращиванию микрогрина. Они оценили его пищевую ценность и декоративное использование в блюдах.</p>
        <p><b>В Древнем Риме и Греции</b> выращивали молодые ростки как пищевой продукт. Микрогрин применяли в качестве лекарственного средства для лечения многих болезней и для укрепления здоровья.</p>
        <p><b>В 1920 году ученый из США Эдмон Зекели</b> предложил концепцию биогенетического питания. Молодые ростки растений классифицировались как один из полезных продуктов. В часть рациона он предлагал включать пророщенные семена. Он считал эти ростки биогенетическим продуктом, который несет жизнь. Причина такого мнения заключается в том, что во время проращивания минералы семян преобразуются в хелатную формулу. Это такое соединение, при котором минералы прочно связаны с аминокислотами. Именно хелатную формулу организм усваивает идеально.</p>
        <p><b>В 1940 году агрохимик из США Ч. Шнабель</b> доказал, что животные, употребляющие молодые ростки в качестве пищевой добавки, становились более энергичными и здоровыми, по сравнению с теми, которые питались обычным кормом.</p>
        <p><b>В 1950 году Э. Вигмор</b>, которая основала в США Институт Гиппократа, провела эксперименты с производными ростков пшеницы для лечения разных болезней. В результате появился пользующийся популярностью в настоящее время сок из проростков пшеницы — витграсс.</p>
        <p><b>Микробиолог, член комиссии при NASA Р. Янг</b>, который написал книгу об ощелачивании организма, утверждал, что молодые ростки:</p>
        <ul>
          <li>обладают сильными регенерирующими свойствами;</li>
          <li>питательны;</li>
          <li>оказывают отрицательное действие на организм;</li>
          <li>содержат много витаминов и минералов.</li>
        </ul>
        <p>Также он утверждал, что сразу после проращивания в ростках образуются фитохимические вещества, которые ведут борьбу с раковыми клетками.</p>
        <p><b>После стремительного развития сельского хозяйства и промышленных производств</b> пищевых продуктов интерес к микрогрину постепенно пропал. Его заменили другими более удобными и долговременными продуктами.</p>
        <p><b>Последние 30-40 лет</b> начал расти интерес к ЗОЖ и правильному питанию. Люди вспомнили о питательной ценности молодых ростков.</p>
        <p>В современном <b>мире микрозелень «возродилась» в 1980-1990 годах</b>. Некоторые фермерские хозяйства и садоводы решили поэкспериментировать с выращиванием микрогрина и использовать его как пищевой продукт. В ходе лабораторных исследований они обнаружили, что ростки обладают высокой питательной ценностью. Кроме того, у них приятный вкус и красивый внешний вид. Эти качества делали продукт привлекательным для покупателей.</p>
        <p><b>Само же понятие «микрозелень»</b> появилось в Сан-Франциско. Именно там шеф-повара в дорогих ресторанах стали использовать ее при приготовлении блюд. Примерно за 10 лет, <b>к середине 1990 годов</b>, продукт распространился по Южной Калифорнии. Микрозелень использовали не только повара в ресторанах, но и обычные граждане на своих кухнях.</p>
        <p>Массовое выращивание микрогрина в Европе началось в 2000-х годах. Первоначально использовали в пищу ограниченное количество сортов. В ассортименте были только:</p>
        <ul>
          <li>базилик;</li>
          <li>руккола;</li>
          <li>кале (кудрявая капуста);</li>
          <li>свекла;</li>
          <li>кориандр.</li>
        </ul>
        <p>Сейчас выращивают несколько десятков сортов микрогрина.</p>
        <h2>Заключение</h2>
        <Picture
            png={Img44_3}
            webp={ImgW44_3}
            alt="Заключение"
        />
        <p>Микрогрин стал популярным и широкодоступным. Сейчас на полках магазинов покупатели могут найти несколько десятков сортов разных культур. В ресторанах подают блюда, украшенные полезными ростками. Многие фермы переквалифицировались и приступили к выращиванию микрогрина.</p>
        <p><b>Микрозелень постепенно превратилась</b> в неотъемлемую часть современной диетологии и кулинарии. Ценность молодых ростков заключается в питательной ценности, уникальных возможностях применения в разных блюдах. Молодые ростки стали популярными ингредиентами в супах, салатах, смузи, сэндвичах.</p>
        <p><b>Микрогрин</b> — яркий пример того, как старинная традиция может быть переосмыслена и адаптирована к современным потребностям. История молодых ростков стала напоминанием о ценности природных продуктов. Это доказывает, что правильное питание — ключ к энергичной и здоровой жизни.</p>
        </>
      ),    
    },
    {
      id: 'pravila-po-vyrashchivaniyu-mikrozeleni-v-domashnikh-usloviyakh',
      name: 'Микрозелень: правила по выращиванию',
      title: 'Правила по выращиванию микрозелени в домашних условиях',
      descr: 'Выращивание микрозелени в домашних условиях: выбор семян, подбор сортов, сохранение качества микрозелени, подготовка места и варианты выращивания.',
      bg: [Img43_1, ImgW43_1],
      date: '26.03.2024',
      content: (
        <>
        <p><b>Микрозелень стала мегапопулярной</b> в последнее время, и неудивительно, что практически любой фуд-блогер или сторонник правильного питания рекомендует включить ее в ежедневный рацион. Самый простой способ включить микрозелень в свой рацион – покупка готовой продукции, например наших ростковых салатов Vitamincus. Но можно попробовать выращивать микрозелень самостоятельно.</p>
        <p>Как выбрать семена, каким образом сажать, и где определить место для домашней грядки — будем разбираться вместе.</p>
        <Picture
            png={Img43_2}
            webp={ImgW43_2}
            alt="Микрозелень: правила по выращиванию"
        />
        <h2>Что такое микрозелень</h2>
        <p><b>Микрозелень появляется</b> путем проращивания семян ряда овощей и трав. Ее срезают на фазе зародышевого листочка плюс 1-2 полноценных листка. В высоту микрозелень не превышает 15 сантиметров, но чаще ее размер находится в пределах 5-10 сантиметров. Растут семена в среднем от одной до двух недель.</p>
        <p><b>Таким способом можно прорастить самые разные травы и злаки:</b> кинзу, подсолнечник, редьку, редис, горчицу, горох, чечевицу и многое другое. А вот картофель, баклажан, перец и фасоль для этого не подходят из-за содержания в них алкалоидов. Микрозелень обладает более тонким и мягким вкусом того растения, из которого она выращена. То есть микрозелень редиса похожа на редис, а микрозелень горчицы — на горчицу.</p>
        <p><b>Считается,</b> что выращивать ее совсем не сложно. Она не занимает много места, не требует особого ухода, да и в целом ее можно держать на подоконнике. Это отчасти так, и все же есть некоторые нюансы, которые следует учитывать, если вы хотите получить по-настоящему хороший урожай.</p>
        <h2>Польза микрозелени</h2>
        <Picture
            png={Img43_3}
            webp={ImgW43_3}
            alt="Микрозелень: правила по выращиванию"
        />
        <p>Польза микрозелени неоспорима. Ученые доказали, что в пророщенных ростках полезных элементов в несколько десятков раз больше, чем в полностью сформированных растениях. Молодые побеги не тратят всю свою силу на активный рост, не подвергаются термическому воздействию или высушиванию.</p>
        <p><b>В пророщенных семенах</b> в большом количестве содержится аскорбиновая кислота, каротиноиды, витамины К и Е. А в водном экстракте растений, выращиваемых под светодиодными или фитолампами, повышенное содержание полифенолов. Именно они отвечают за профилактику онкологических заболеваний.</p>
        <p>В микрозелени полезные вещества представлены в максимальной концентрации. Большое количество микро и макроэлементов, минералов, витаминов и нутриентов благотворно влияют на организм. А сама микрозелень добавляет новые краски уже привычным блюдам. Также она улучшает пищеварение и помогает контролировать вес. А самые вкусные рецепты с добавлением пророщенных семян вы можете почитать <Link to="/blog/reczepty-kak-upotreblyat-mikrozelen-salaty-blyuda-i-smuzi/">здесь</Link>.</p>
        <p><b>Для здорового и полноценного питания</b> необходимо употреблять овощи на ежедневной основе. Мужчинам рекомендуется съедать в пределах 1-4 чашек овощей каждый день, женщинам — 1-3 (точное количество зависит от возраста). Микрозелень может быть прекрасной альтернативой, так как ее легко добавлять в самые разные блюда. При этом для достижения того же эффекта ее можно есть в гораздо меньшем количестве.</p>
        <h2>Выбор семян</h2>
        <Picture
            png={Img43_4}
            webp={ImgW43_4}
            alt="Полезные вещества"
        />
        <p>Выращивать или покупать готовую? Такой вопрос встает перед многими, кто решил добавить эту полезную привычку — есть микрозелень — в свою жизнь. Процесс проращивания семян относительно простой, не занимает много времени и сил. При этом вы можете самостоятельно контролировать качество и количество растений, наблюдать за процессом проращивания и пробовать новые вкусы.</p>
        <p><b>Если вы только панируете начать заниматься проращиванием семян, у вас наверняка есть миллион вопросов:</b> какую зелень выбрать, на что обратить внимание, какое выбрать место для домашней грядки, каким образом выращивать зелень и многое другое. Начать необходимо с выбора семян. На что же обратить внимание?</p>
        <h2>Полезные вещества</h2>
        <p><b>Здесь подойдут любые семена,</b> так как все они содержат огромное количество полезных веществ. Когда при выборе вас пытаются убедить, что самые лучшие семена вы можете приобрести только у них, это маркетинговая уловка. Если семена обрабатываются какими-либо веществами, это всегда указывается на упаковке.</p>
        <p><b>Покупать семена для проращивания</b> можно в обычных садоводческих магазинах. А если у вас есть свой огород, смело берите семена с него — это лучший вариант. Для таких целей соберите созревшие семена, просушите их на солнце и храните в бумаге в темном и сухом месте.</p>
        <h2>Сорт</h2>
        <p><b>Выбор семян,</b> пригодных для проращивания, велик. Здесь в первую очередь ориентируйтесь на свой вкус. Для первых ростков подойдут самые разные салаты, капуста, пряные ароматные травы, горчица, редис, горох, руккола, горчица. Новичкам стоит выбрать люцерну, редис или горчицу — они считаются самыми неприхотливыми.</p>
        <p>Перед покупкой убедитесь, что ростки семян можно есть. Далеко не вся рассада пригодна к употреблению в пищу. Например, нельзя проращивать перец, баклажан и томат, а тыквенные ростки имеют ощутимо горький вкус. Более подробно о сортах микрозелени мы писали <Link to="/blog/luchshie-sorta-mikrozeleni">здесь</Link>.</p>
        <h2>Качество</h2>
        <p><b>Не стоит</b> покупать семена с рук, так как велик риск приобрести что-то совершено иное. Если у вас нет собственного огорода, то лучше покупать семена для выращивания микрозелени в сетевых, проверенных интернет-магазинах. Продукция в них обычно проходит все этапы проверки, а владельцы дорожат своей репутацией.</p>
        <p>При выборе конкретной марки обратите внимание на информацию, которая указана на упаковке. В идеале там должны быть следующие данные:</p>
        <ul>
          <li>информация о продавце (с реквизитами);</li>
          <li>информация о семенах;</li>
          <li>номер партии;</li>
          <li>ГОСТ;</li>
          <li>срок годности;</li>
          <li>всхожесть в процентах.</li>
        </ul>
        <p><b>Важно понимать,</b> что не существует 100% всхожести. Хорошим показателем считается, если всхожесть держится на уровне 70-85%. По возможности сохраняйте пакетики от семян, чтобы потом можно было покупать их, если понравится. И, наоборот, больше не брать, если что-то не утроит.</p>
        <h2>Как выбрать место</h2>
        <Picture
            png={Img43_5}
            webp={ImgW43_5}
            alt="Полезные вещества"
        />
        <p><b>Самым популярным местом</b> для выращивания микрозелени можно назвать подоконник. Именно там чаще всего разводят мини-огород. Однако это не лучшее решение летом, если окна «выходят» на южную сторону, и весь день семена будут под лучами палящего солнца. Микрозелень будет плохо расти при постоянном солнечном воздействии, а может и вовсе не взойти. Также она не любит сквозняков.</p>
        <p>В целом вы можете разместить микрозелень в любом удобном месте. В идеале оно должно быть хорошо освещено. Однако если таких поверхностей у вас нет, можно проращивать семена даже в кладовке. В этом случае понадобится купить лампы, чтобы дать росткам достаточно света. Включайте лампу на 12 часов подряд. При дневном освещении естественным путем росткам достаточно шести часов света в сутки.</p>
        <p><b>Старайтесь поддерживать</b> постоянную температуру в помещении. Оптимальная — от +18 до +24°. На холоде процесс появления ростков значительно замедляется, а при жаре некоторые семена могут не прорасти. Ни в коем случае не размещайте микрозелень возле плиты, духовки или обогревателей.</p>
        <h2>Варианты выращивания микрозелени</h2>
        <p><b>Способов проращивания семян так много, что их разнообразие может поставит в тупик начинающих садоводов:</b></p>
        <ul>
          <li><b>Субстрат.</b> Это могут быть грунт или торф, который продают россыпью (его нужно будет пересыпать в емкость для выращивания) или рулонами (вырезается под площадь контейнера). Перед тем, как выбрать субстрат, стоит ознакомиться с рекомендациями по выращиванию конкретного сорта микрозелени. Субстраты бывают многоразовыми, могут иметь естественное или искусственное происхождение, а также способствовать ускоренному росту растений.</li>
          <li><b>Торф.</b> Выбирайте тот, где нет химических добавок.</li>
          <li><b>Грунт.</b> Купить можно в садоводческом магазине. Следите, чтобы в нем не было ненужных добавок.</li>
          <li><b>Поливные маты.</b> Представляют собой ткань из полиэтилена с добавлением шерстяных или иных видов волокон. Его нужно вырезать ножницами нужной формы под тару, в которой будете проращивать микрозелень. Маты не только обладают высокими впитывающими характеристиками, но и отлично распределяют влагу по всей поверхности, чтобы она была равномерной. А специальный состав, которым их обрабатывают, препятствует появлению плесени.</li>
          <li><b>Джутовая мешковина.</b> Это натуральное экологически чистое волокно, которое легко использовать. Отличается низкой теплопроводностью, влагу удерживает хуже, чем маты. Использовать мешковину из джута можно лишь на один цикл проращивания. Так как может появиться плесень.</li>
          <li><b>Ватные диски.</b> Простейший способ, где даже не требуется особая подготовка. Ватные диски (можно заменить на вату) нужно выложить в контейнер, хорошенечко увлажнить, а сверху положить семена. Обязательно закройте контейнер пищевой пленкой. А после появления первых росточков удалите ее. Опрыскивайте водой молодые растения хотя бы пару раз в день.</li>
          <li><b>Марля.</b> Работает по тому же принципу, что и ватные диски. Важно поддерживать постоянную влажность. Полученную микрозелень можно есть прямо с семенами.</li>
          <li><b>Проращиватель.</b> Самые обычные проращиватели состоят из тары, корзинки с решетчатым дном и крышки Усовершенствованные модели могут иметь функцию автоматического опрыскивания и поддержания оптимальной температуры.</li>
        </ul>
        <p>Как правило, первую микрозелень выращивают самым простым способом. И уже потом многие покупают специальное оборудование.</p>
        <h2>Как ухаживать за микрозеленью</h2>
        <Picture
            png={Img43_6}
            webp={ImgW43_6}
            alt="Полезные вещества"
        />
        <p><b>Примерно через 7-10 дней</b> после посадки зелень можно срезать. Каких-то особых правил по уходу нет. Ваша главная задача не дать ей высохнуть и не залить ее водой. Регулярно опрыскивайте растения из пульверизатора и контролируйте влажность субстрата. В идеале для полива использовать качественную воду. Если она из-под крана, то обязательно дайте воде настояться. Температура воды для орошения должна быть в пределах от +15 до +21°.</p>
        <p><b>Зимой обязательно</b> используйте дополнительные источники света. Также лампы нужно в регионах с малым количеством солнечных дней. Лучше всего для растений подходят фитолампы. Готовый урожай срезайте ножницами ближе к корням уже на 12-14 день. Как видите, особых хлопот разведение микрозелени не доставит, а польза для организма колоссальная.</p>
        <h2>Если на выращивание нет ни времени, ни сил, ни желания</h2>
        <p>Если у вас нет желания или возможности тратить время и усилия на самостоятельное выращивание микрозелени, не отчаивайтесь! Вам всегда доступна возможность приобрести готовую продукцию от Vitamincus.</p>
        <p>Наша компания предлагает широкий выбор свежей и качественной микрозелени, выращенной с заботой и вниманием. Мы гарантируем, что наши продукты сохранят все свои полезные свойства и вкус, чтобы вы могли насладиться всеми их преимуществами без лишних хлопот.</p>
        <p>Так что, если вам нужна здоровая и вкусная добавка к вашему рациону, обратитесь к нам - Vitamincus всегда рад помочь вам получить все преимущества микрозелени без лишних усилий.</p>
        </>
      ),
    },
    {
      id: 'prazdnichnoe-ukrashenie-blyud-s-pomoshch-yu-mikrozeleni',
      name: 'Микрозелень на праздничном столе',
      title: 'Праздничное украшение блюд с помощью микрозелени',
      descr: 'Используем микрозелень на праздничном столе для оформления и оригинальной подаче блюд. Рассказываем как микрозеленью можно украшать блюда',
      bg: [Img42_1, ImgW42_1],
      date: '26.03.2024',
      content: (
        <>
        <p><b>Любое блюдо становится праздничным</b> при правильном оформлении и оригинальной подаче. Самый простой способ — украшение микрозеленью.</p>
        <p>Даже привычный для всех салат оливье или горячий бутерброд, который посыпали маленькими ростками, станет выглядеть аппетитно и празднично. Рассказываем, как использовать микрогрин для украшения, и какие секреты в этом деле существуют.</p>
        <h2>Для чего нужен микрогрин</h2>
        <Picture
            png={Img42_2}
            webp={ImgW42_2}
            alt="Микрозелень на праздничном столе"
          />
        <p><b>Микрогрин содержит</b> много полезных для здоровья веществ и витаминов. Он ведет борьбу со свободными радикалами, укрепляет и оздоравливает организм, но это не все достоинства молодых ростков.</p>
        <p><b>Знаменитые шеф-повара, известные блогеры и обычные кулинары</b> используют микрозелень при оформлении блюд. Ценят ее за вкусовые и эстетические качества.</p>
        <p>У каждого вида микрозелени свой привкус. Молодые ростки могут быть пряными, сладкими, жгучими. Благодаря этому, они позволяют в полной мере проявлять кулинарную фантазию. Кроме того, микрозелень красочна и привлекательна. У нее богатая цветовая палитра. В зависимости от сорта, ростки бывают зелеными, алыми, фиолетовыми, желтыми и отличаются по насыщенности оттенков. Благодаря молодым росткам, блюда выглядят фотогенично и привлекательно. При одном взгляде, вызывают аппетит.</p>
        <h2>Как хранить микрогрин</h2>
        <Picture
            png={Img42_3}
            webp={ImgW42_3}
            alt="Как хранить микрогрин"
          />
        <p><b>Выбирая микрогрин для оформления блюд,</b> помните, что нежные ростки не выносят тепловую обработку. Доставайте их из холодильника только перед подачей на стол. В этом случае они не успеют завять и не потеряют сочность.</p>
        <p><b>Для сохранения привлекательного внешнего вида и вкуса, воспользуйтесь простыми правилами:</b></p>
        <ul>
          <li>Приобретайте микрозелени столько, сколько необходимо для оформления блюд. Впрок покупать не стоит, так как у микрозелени ограниченный срок годности.</li>
          <li>Храните молодые ростки в холодильнике. Оптимальный температурный режим от +2 °С до +6°С.</li>
          <li>Не оставляйте микрозелень под прямыми лучами солнечного света. Она быстро завянет, потеряет красивый внешний вид, а ее вкусовые качества значительно ухудшатся.</li>
          <li>Храните микрогрин в открытом контейнере. Прослаивайте его влажными салфетками или полотенством.</li>
          <li>Мойте ростки перед подачей на стол. Иначе от переизбытка влаги они быстро испортятся.</li>
        </ul>
        <p>Соблюдая рекомендации, микрозелень можно использовать в течение недели. О том, как правильно выбирать молодые ростки, рассказывали <Link to="/blog/lajfhaki-i-sovety-po-vyboru-mikrozeleni/">здесь</Link>.</p>
        <h2>Как микрозеленью можно украшать блюда</h2>
        <Picture
            png={Img42_4}
            webp={ImgW42_4}
            alt="Как микрозеленью можно украшать блюда"
          />
        <p><b>Красивая подача блюд</b> — не уловка шеф-поваров. Еда создана не только для того, чтобы насытиться и утолить чувство голода. Она доставляет гастрономическое и эстетическое удовольствие, но только при правильном оформлении и подаче блюда. Микрозелень помогает расставлять яркие вкусовые и визуальные акценты.</p>
        <p><b>Чтобы микрозелень стала украшением праздничного стола, следуйте простым правилам:</b></p>
        <ul>
          <li>Не переусердствуйте с микрозеленью. Используйте ее в качестве акцента или дополнения к основным ингредиентам блюда. Цель заключается в том, чтобы завершить образ, а не перегрузить его.</li>
          <li>Играйте с цветами микрозелени. У одних сортов ярко-зеленый оттенок, у других — красный или фиолетовый. Выбирайте цвета, контрастирующие и дополняющие другие продукты.</li>
          <li>Используйте микрозелень с разной текстурой, чтобы придать блюду интересный визуальный эффект. Например, шпинат нежный и гладкий, а у базилика грубые и мясистые листья.</li>
          <li>Равномерно распределяйте микрозелень по поверхности блюд или сфокусируйтесь на центре тарелки. Создавайте гармоничный образ и не раскидывайте небрежно ростки по всей поверхности.</li>
          <li>Чтобы блюдо выглядело аппетитно, для украшения используйте только свежее растение. На его поверхности не должно быть повреждений.</li>
          <li>Если выбрали сорт микрозелени с большими листьями, то разорвите их руками на более мелкие и аккуратные части. Не скидывайте грубой кучей ростков на поверхность блюда.</li>
          <li>Выбирая сорт, учитывайте его вкус. Он должен сочетаться с другими продуктами.</li>
          <li>Используйте умеренное количество молодых ростков. Помните, что ими вы украшаете блюдо. Не затмевайте его вкус и аромат большим количеством микрозелени.</li>
          <li>Обращайте внимание на текстуру микрогрина. Она поможет придать блюдам оригинальный визуальный эффект. У некоторых сортов листья мягкие и нежные, у других — грубые, хрупкие.</li>
        </ul>
        <p>Правильно сочетайте микрозелень с продуктами. Например, у кресс-салата пикантный вкус, который идеально подходит для овощных салатов. Зато он испортит привкус фруктовых смузи. К ним идеально подойдут подсолнечник с ореховым вкусом и сладкий горошек.</p>
        <h2>В каких блюдах и как применять</h2>
        <p>Микрозелень — универсальный продукт, который сочетается со всеми блюдами. Она становится украшением любого праздничного стола и семейного праздника.</p>
        <Picture
            png={Img42_5}
            webp={ImgW42_5}
            alt="Микрозелень в салатах"
          />
        <h3>Микрозелень в салатах</h3>
        <p>На любом праздничном столе всегда есть салаты. Чтобы вызвать восторг и удивление гостей, не обязательно придавать блюду замысловатые формы в виде зверюшек. Отдавайте предпочтение элегантному и модному оформлению. Микрозелень может быть в блюдах не только ингредиентом, но и эффектным украшением.</p>
        <p>Для примера, поместите любимый салата в бокалы от вина, а сверху украсьте хрустящими, молодыми ростками. Пряностью и свежестью наполнят блюда микрозелени кресс-салата, амаранта, шпината, кориандра.</p>
        <h3>Микрозелень в горячих блюдах</h3>
        <p>Запеченный картофель и овощи-гриль подавайте с микрогрином лука-шнитта. Говядина и стейки отлично сочетаются с проростками брокколи и красной капусты.</p>
        <p>Чтобы подача была максимально эффектной, приготовьте подушку из ростков савойской капусты, а сверху разместите рыбные стейки или фаршированные шампиньоны. Такая подача придаст любимым блюдам более аппетитный внешний вид.</p>
        <h3>Микрозелень и холодные закуски</h3>
        <p>Вкус фаршированных яиц усилит микрогрин горчицы и настурции. Тарталетки, бутерброды, рулетики, нарезки (овощные, мясные), канапе идеально сочетаются с:</p>
        <ul>
          <li>руколой;</li>
          <li>редисом;</li>
          <li>дайконом;</li>
          <li>луком-пореем;</li>
          <li>мангольдом.</li>
        </ul>
        <p>Красивую сырную нарезку подавайте с кудрявым микрогрином гороха.</p>
        <h3>Напитки и коктейли с добавлением микрозелени</h3>
        <p>К аперитивам, пуншам и лимонадам шеф-повара добавляют микрогрин базилика, свеклы, бораго и амаранта. Розовый редис идеально сочетается с коктейлями на основе свежевыжатых соков.</p>
        <p>Микрозелень идеально подходит для украшения напитков. Она хорошо держится на поверхности коктейля и приятно пахнет. При оформлении обращайте внимание на количество микрозелени. Используйте ее рационально. В бокал с напитком добавляйте небольшое количество ростков, чтобы коктейль или смузи было приятно пить.</p>
        <p>Большую роль играет цветовая палитра напитка. Она не должна быть похожа на светофор. Каждый компонент обязан гармонично сочетаться между собой. Микрозелень может быть цветовым акцентом, но знайте меру. Помните, что напиток — не новогодняя елка.</p>
        <p>Также не забывайте о составе. Микрозелень должна по вкусу сочетаться с компонентами напитка. Не добавляйте в молочный коктейль горькие сорта. Украшение должно быть лаконичным, но максимально раскрывать ароматические и вкусовые нюансы готового напитка. Микрозелень — своеобразная изюминка, которая помогает сделать коктейль незабываемым.</p>
        <h3>Кондитерские изделия и десерты с микрозеленью</h3>
        <p>У микрозелени пикантный, ароматный и приятный привкус, который помогает вносить разнообразие в десерты. Молодые ростки идеально подходят для украшения:</p>
        <ul>
          <li>тортов;</li>
          <li>пирожных;</li>
          <li>фруктово-ягодных желе;</li>
          <li>шоколадных муссов.</li>
        </ul>
        <p>Со сладкими блюдами отлично сочетается микрогрин подсолнечника, чиа, щавеля.</p>
        <p>Микрозелень гороха, люцерны, базилика становится прекрасным дополнением к свежим капкейкам, покрытым кремовой шапочкой.</p>
        <p>Микрогрин придает десертам красивый внешний вид, добавляет свежесть и неповторимый вкус. Кроме того, он содержит много полезных витаминов и минералов. Поэтому ростки служат не только украшением блюда, но и повышают его полезные свойства.</p>
        <p>При декорировании кондитерских изделий микрозеленью следите, чтобы она сочеталась с вкусовыми характеристиками десерта. Например, для фруктовых тарталеток выбирайте базилик или мяту, а для шоколадных тортов — петрушку или кресс-салат.</p>
        <p>Приобретайте зелень у надежных поставщиков. При выборе, обращайте внимание на ее форму, цвет и состояние листиков. Только здоровая, качественная, красивая микрозелень украсит ваш десерт. Увядшие ростки испортят не только внешний вид, но и вкус выпечки.</p>
        <p>Небольшие веточки микрозелени можете аккуратно располагать поверх десерта или по бокам. Не менее красиво смотрятся интересные композиции из нескольких видов микрогрина на поверхности тортов.</p>
        <p>Молодые ростки предоставляют неограниченные возможности для творчества. Их используют для создания целых картинок или узоров на тортах, пирожных и печенье. Такое оформление пользуется популярностью на свадьбах и других торжественных мероприятиях.</p>
        <p>Микрозелень — уникальный элемент декора, который придает кондитерским изделиям неповторимый вид. Она подчеркивает вкусовые и эстетические качества десертов, делает их более здоровыми и питательными.</p>
        <h3>Мясо и рыба в сочетании с микрозеленью</h3>
        <p>Украшение рыбы и мяса микрозеленью — популярный тренд в кулинарии. Она добавляет контрастность и яркость блюду. Красиво оформленное мясо или рыба с молодыми ростками выглядит аппетитно и привлекательно, вызывая интерес у гостей.</p>
        <p>Для украшения стейка подходит микрозелень базилика или рукколы, которые придают мясу свежий аромат и пряность. Для рыбных блюд используют ростки укропа или кинзы, подчеркивающие естественный вкус рыбы.</p>
        <p>Для украшения, микрозелень равномерно располагают поверх блюда, создавая интересные композиции. Разрешается комбинировать несколько видов ростков. Разнообразные цветовые оттенки сделают рыбные и мясные блюда более яркими и аппетитными.</p>
        <p>Микрозелень можете использовать не только в качестве украшения. Это отличный гарнир, который поможет улучшить пищеварение. Кстати, о том, как часто можно включать молодые ростки в рацион, рассказывали <Link to="//blog/мikrozelen-v-dieticheskom-pitanii-malenkij-shag-k-bolshomu-zdorovju">здесь</Link>.</p>
        </>
      ),
    },
    {
      id: 'podborka-vegetarianskih-receptov-s-mikrozelenju',
      name: 'Микрозелень: лучшая вегетарианская подборка',
      title: 'Подборка вегетарианских рецептов с микрозеленью',
      descr: 'Вегетарианские рецепты блюд с микрозеленью: овощное рагу, постный борщ, рататуй, пицца, овощной торт, паста с микрозеленью и т.д.',
      bg: [Img41_1, ImgW41_1],
      date: '14.02.2024',
      content: (
        <>
        <p><b>Микрозелень</b> — не мода, а современный суперфуд. Молодые побеги содержат необходимые для организма витамины, минералы, макро- и микроэлементы в большей концентрации, чем взрослые растения. Мы расскажем, с чем можно употреблять микрогрин и в какие вегетарианские блюда получаются вкуснее всего с молодыми ростками.</p>
        <Picture
            png={Img41_2}
            webp={ImgW41_2}
            alt="Микрозелень: лучшая вегетарианская подборка"
          />
        <h2>С чем едят микрозелень</h2>
        <p><b>Микрозелень</b> нельзя обрабатывать никаким способом. Молодые ростки употребляют только в свежем виде. Хранить в холодильник их нельзя дольше недели.</p>
        <p><b>Из-за варки и жарки</b> полезные вещества распадаются, а микрогрин превращается в бесполезную зеленую массу. Такой же эффект от консервирования и заморозки.</p>
        <p><b>В свежем виде микрозелень — отличный ингредиент для:</b></p>
        <ul>
          <li>первых блюд;</li>
          <li>салатов;</li>
          <li>бутербродов;</li>
          <li>смузи;</li>
          <li>сладких блюд;</li>
          <li>коктейлей.</li>
        </ul>
        <p>Микрозеленью украшают мясные, рыбные блюда и из морепродуктов.</p>
        <p><b>Разные сорта культур</b> значительно отличаются друг от друга. По вкусу напоминают плоды взрослых растений, но гораздо нежнее и ароматнее. Если любите пикантный привкус, то выбирайте микрогрин кинзы, горчицы, редиса и лука. Для сладкоежек идеально подойдут ростки подсолнечника, горошка, амаранта, кукурузы. Кстати, об интересных фактах о микрогрине можете прочитать <Link to="/blog/10-interesnyh-faktov-o-mikrozeleni/">здесь</Link>.</p>
        <h2>Вегетарианские рецепты с микрозеленью</h2>
        <Picture
            png={Img41_3}
            webp={ImgW41_3}
            alt="Вегетарианские рецепты с микрозеленью"
          />
        <p><b>Вегетарианство</b> пользуется популярностью у всех слоев населения. Вегетарианское питание полезно для здоровья, помогает обретать душевный покой. Блюда, приготовленные из продуктов растительного происхождения, обладают отличными вкусовыми качествами и благоприятно влияют на состояние здоровья.</p>
        <p><b>Микрозелень</b> — хороший источник белка для вегетарианцев. Молодые ростки помогают обеспечивать организм необходимыми питательными веществами. Предлагаем несколько вкусных рецептов, которые помогут разнообразить ваш рацион.</p>
        <h3>Овощное рагу с микрозеленью</h3>
        <p>Если не хотите нагружать организм тяжелой жирной пищей, приготовьте овощное рагу. Если кабачки у вас зрелые, срезайте предварительно кожуру. У молодых плодов можете оставлять тонкую, мягкую кожицу.</p>
        <p><b>Потребуется:</b></p>
        <ul>
          <li>картофель — 550 г;</li>
          <li>кабачки — 550 г;</li>
          <li>перец;</li>
          <li>капуста — 350 г;</li>
          <li>морковь — 250 г;</li>
          <li>растительное масло — 40 мл;</li>
          <li>лук — 150 г;</li>
          <li>помидоры — 300 г;</li>
          <li>лавровый лист;</li>
          <li>микрозелень — 120 г;</li>
          <li>чеснок — 3 зубчика;</li>
          <li>вода — 500 мл;</li>
          <li>соль.</li>
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul>
          <li>В утятницу поместите картофель, нарезанный крупными частями.</li>
          <li>Добавьте лавровые листы. Налейте воду. Посолите.</li>
          <li>Поставьте на минимальный режим конфорки.</li>
          <li>Кабачки нарежьте небольшими кубиками.</li>
          <li>Через 10 минут после закипания воды, добавьте в утятницу кабачки. Посолите.</li>
          <li>Нашинкуйте капусту. Отправьте в утятницу.</li>
          <li>Измельчите луковицу. Натрите на средней терке морковь. Посолите. Поперчите.</li>
          <li>Пропустите через пресс чеснок. Соедините его в сковороде с маслом. Добавьте лук и морковь.</li>
          <li>Жарьте до мягкости.</li>
          <li>Добавьте помидоры, нарезанные кубиками. Потомите на минимальном режиме конфорки 5 минут.</li>
          <li>Зажарку переложите в утятницу. Равномерно распределите по всей поверхности.</li>
          <li>Закройте крышкой. Оставьте на полчаса на минимальном режиме конфорки.</li>
          <li>Уберите с плиты и слегка остудите.</li>
        </ul>
        <p>Рагу — слоеное блюдо. Накладывайте его, поддевая ложкой с самого низа, захватывая каждый слой. При подаче посыпайте каждую порцию микрозеленью.</p>
        <h3>Постный борщ с микрогрином</h3>
        <p>Постный борщ по достоинству оценят вегетарианцы и люди, которые придерживаются здорового питания.</p>
        <p><b>Потребуется:</b></p>
        <ul>
          <li>бурак — 5 шт.;</li>
          <li>картофель — 150 г;</li>
          <li>морковь — 100 г;</li>
          <li>лук — 100 г;</li>
          <li>болгарский перец — 150 г;</li>
          <li>растительное масло — 50 мл;</li>
          <li>лавровый лист — 2 шт.;</li>
          <li>чеснок — 5 зубчиков;</li>
          <li>томатная паста — 60 мл;</li>
          <li>соль;</li>
          <li>перец;</li>
          <li>микрозелень — 100 г;</li>
          <li>лимонный сок — 30 мл.</li>
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul>
          <li>Свеклу, морковь натрите на крупной терке. Лук нарежьте кубиками, картофель — половинками кружочков, болгарский перец — соломкой.</li>
          <li>Разогрейте растительное масло в кастрюле. Обжарьте лук до золотистого цвета.</li>
          <li>Переложите в кастрюлю морковь и свеклу. Жарьте 5 минут.</li>
          <li>Добавьте картофель и перец. Готовьте 3 минуты.</li>
          <li>Добавьте томатную пасту, перемешайте и готовьте еще 2-3 минуты.</li>
          <li>Налейте воду. Она должна покрыть овощи. Закиньте лавровые листы.</li>
          <li>Включите максимальный режим конфорки. Когда вода закипит, переключите огонь на минимум. Накройте крышкой и варите полчаса. Овощи должны стать мягкими.</li>
          <li>Пропустите чеснок через пресс и отправьте его в суп. Посолите и поперчите. Варите 5 минут.</li>
          <li>Добавьте лимонный сок (если не любите кисловатый привкус в супе, то можете не использовать этот ингредиент).</li>
        </ul>
        <p>Налейте порционно по тарелкам. Подавайте, посыпав микрозеленью.</p>
        <h3>Брокколи в сливочном соусе</h3>
        <p>В сливочном соусе брокколи получается нежной и тает во рту. Блюдо низкокалорийное и легкое. Идеально подходит для ужина. Брокколи можете использовать свежее и замороженное.</p>
        <p><b>Потребуется:</b></p>
        <ul>
          <li>брокколи — 500 г;</li>
          <li>лук — 130 г;</li>
          <li>перец;</li>
          <li>жирные сливки — 500 мл;</li>
          <li>твердый сыр — 200 г;</li>
          <li>соль;</li>
          <li>микрозелень — 120 г;</li>
          <li>растительное масло.</li>
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul>
          <li>Разберите капусту на соцветия.</li>
          <li>Воду посолите, и доведите до кипения. Закиньте соцветия. Варите 5 минут.</li>
          <li>Откиньте брокколи на дуршлаг. Подождите 5 минут. Жидкость должна стечь максимально.</li>
          <li>Нашинкуйте луковицу. Обжарьте в растительном масле до золотистости.</li>
          <li>Добавьте капусту. Тушите на среднем режиме конфорки 4 минуты.</li>
          <li>Натрите сыр на средней терке.</li>
          <li>Переложите овощи в форму для запекания. Залейте сливками.</li>
          <li>Запекайте 20 минут в духовой печи при 180°С.</li>
          <li>Посыпьте сыром. Готовьте еще 2 минуты. Стружка должна расплавиться.</li>
        </ul>
        <p>Достаньте готовое блюдо из духовой печи. Оставьте на 5 минут. Переложите в тарелки и посыпьте микрозеленью.</p>
        <h3>Рататуй с микрозеленью</h3>
        <p>Овощной рататуй — не только полезное, но и вкусное блюдо. В классическом варианте готовят его в духовом шкафу.</p>
        <p><b>Потребуется:</b></p>
        <ul>
          <li>чеснок — 3 зубчика;</li>
          <li>томаты — 350 г;</li>
          <li>тимьян;</li>
          <li>кабачок — 400 г;</li>
          <li>розмарин;</li>
          <li>баклажан — 400 г;</li>
          <li>микрозелень — 130 г;</li>
          <li>растительное масло — 20 мл;</li>
          <li>сливочное масло — 20 г;</li>
          <li>лук — 200 г;</li>
          <li>специи.</li>
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul>
          <li>Нарежьте овощи тонкими кружочками. Мелко порубите чеснок.</li>
          <li>Смажьте форму для запекания сливочным маслом.</li>
          <li>Слоем выложите чеснок, лук, тимьян и розмарин.</li>
          <li>Распределите поочередно остальные овощи.</li>
          <li>Полейте растительным маслом. Посыпьте специями.</li>
          <li>Накройте фольгой.</li>
          <li>Поставьте в духовую печь запекаться на 50 минут. Температурный режим 180°С.</li>
          <li>Снимите фольгу. Готовьте полчаса.</li>
        </ul>
        <p>Подавайте, посыпав микрогрином.</p>
        <h3>Пицца из кабачков с микрозеленью</h3>
        <p>Если вы не употребляете мясные блюда, не стоит отказываться от пиццы. Предлагаем приготовить не менее вкусный вариант с кабачками и микрозеленью.</p>
        <p><b>Потребуется:</b></p>
        <ul>
          <li>кабачок — 600 г;</li>
          <li>специи — по вкусу;</li>
          <li>мука — 80 г;</li>
          <li>микрозелень — 120 г;</li>
          <li>яйцо — 1 шт.;</li>
          <li>сухари для панировки — 20 г;</li>
          <li>сливочное масло — 10 г;</li>
          <li>нежирный сорт твердого сыра — 50 г;</li>
          <li>помидор — 150 г;</li>
          <li>разрыхлитель — щепотка.</li>
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul>
          <li>Натрите на крупной терке кабачки. Соедините с яйцом. Добавьте специи. Перемешайте.</li>
          <li>Насыпьте муку и разрыхлитель. Перемешайте.</li>
          <li>Промажьте маслом форму для запекания. Присыпьте панировочными сухарями.</li>
          <li>Перелейте кабачковую смесь. Разровняйте.</li>
          <li>Выложите помидоры, нарезанные тонкими кольцами. Посыпьте натертым на средней терке сыром.</li>
          <li>Уберите в прогретый до 180°С духовой шкаф. Запекайте 25 минут.</li>
          <li>Достаньте пиццу из духового шкафа. Нарежьте на части. Посыпьте микрозеленью.</li>
        </ul>
        <p>При желании можете добавить в пиццу мелко порубленный лук и сладкий перец.</p>
        <h3>Овощной торт</h3>
        <p>Овощной торт — не замена сладкому блюду, а оригинальная закуска, которая помогает сделать праздничный стол разнообразнее.</p>
        <p><b>Потребуется:</b></p>
        <ul>
          <li>мука — 320 г;</li>
          <li>разрыхлитель — 1 ч. л.;</li>
          <li>яйца — 4 шт.;</li>
          <li>молоко — 650 мл;</li>
          <li>соль — 0,5 ч. л.;</li>
          <li>сахар — 20 г;</li>
          <li>сладкий перец — 300 г;</li>
          <li>лук — 150 г;</li>
          <li>растительное масло — 30 мл;</li>
          <li>цуккини — 400 г;</li>
          <li>твердый сыр — 300 г;</li>
          <li>сыр Фета — 200 г;</li>
          <li>микрозелень — 70 г.</li>
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul>
          <li>В чаше миксера соедините муку с разрыхлителем, молоком, яйцом, сахаром, солью и растительным маслом. Взбейте.</li>
          <li>Приготовьте блины (при необходимости добавьте муку или воду, чтобы масса была достаточно густой для приготовления блинов).</li>
          <li>Овощи мелко порубите. Обжарьте в сковороде до золотистости.</li>
          <li>Дно разъемной формы промажьте маслом.</li>
          <li>Выкладывайте слоями: блин, начинка, сыр Фета.</li>
          <li>Посыпьте сверху натертым на крупной терке твердым сыром. Поставьте в прогретый духовой шкаф на 20 минут. Температурный режим 180°С.</li>
        </ul>
        <p>Достаньте торт из формы. Посыпьте микрогрином и сразу подавайте к столу.</p>
        <h3>Паста с микрозеленью</h3>
        <p>Попробуйте приготовить ароматную пасту, которую по достоинству оценит вся семья.</p>
        <p><b>Потребуется:</b></p>
        <ul>
          <li>тальятелле — 250 г;</li>
          <li>сливки 10% - 130 мл;</li>
          <li>зеленый горошек — 120 г;</li>
          <li>сливочный сыр — 80 г;</li>
          <li>кабачок — 300 г;</li>
          <li>чеснок — 1 зубчик;</li>
          <li>черный перец;</li>
          <li>лук — 100 г;</li>
          <li>петрушка — 30 г;</li>
          <li>микрогрин — 70 г;</li>
          <li>пармезан — 70 г;</li>
          <li>оливковое масло — 20 мл;</li>
          <li>соль.</li>
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul>
          <li>Нарежьте очищенные кабачки и лук на тонкие полукольца.</li>
          <li>Петрушку порубите.</li>
          <li>Вскипятите воду. Подсолите. Отварите пасту до состояния аль денте.</li>
          <li>Прогрейте в сковороде с толстым дном масло. Поместите крупно порубленный зубчик чеснока. Подрумяньте до золотистого цвета. Достаньте чеснок. Он больше не понадобится.</li>
          <li>Засыпьте лук и половину петрушки. Жарьте 1 минуту на среднем режиме конфорки. Пока лук не станет прозрачным.</li>
          <li>Добавьте кабачки, горошек и оставшуюся петрушку. Готовьте, постоянно помешивая, 3 минуты.</li>
          <li>Влейте сливки. Когда жидкость закипит, закиньте пасту. Посыпьте половиной натертого сыра. Перемешайте. Посолите и поперчите. Перемешайте.</li>
          <li>Готовьте на среднем режиме конфорки 5 минут, постоянно перемешивая. До кипения не доводите.</li>
        </ul>
        <p>Подавайте в теплом виде, посыпав тертым сыром и микрозеленью. О том, как готовить вторые блюда с микрогрином, мы рассказывали <Link to="/blog/ispolzovanie-mikrozeleni-dlja-prigotovlenija-vtoryh-bljud/">здесь</Link>.</p>
        </>
      )
    },
    {
      id: 'ispolzovanie-mikrozeleni-vprigotovlenii-salata-Cezar',
      name: 'Микрозелень и лучшие рецепты салата «Цезарь»',
      title: 'Использование микрозелени в приготовлении салата "Цезарь"',
      descr: 'Добавление микрозелени делает любимое многими блюдо более питательным, насыщенным по вкусу и красивым.',
      bg: [Img40_1, ImgW40_1],
      date: '14.02.2024',
      content: (
        <>
        <p>Для сохранения здоровья необходимо правильно питаться. Сбалансированная диета включает большое количество фруктов, овощей и зелени. Чтобы поддерживать здоровье, врачи рекомендуют употреблять функциональные продукты, которые насыщены фитонутриентами. Например, микрозелень. Это нежные молодые ростки растений разных видов бобовых, овощных, злаковых культур.</p>
        <Picture
            png={Img40_2}
            webp={ImgW40_2}
            alt="Микрозелень и лучшие рецепты салата «Цезарь»"
          />
        <h2>Рецепты приготовления салата «Цезарь» с микрозеленью</h2>
        <p><b>«Цезарь» — салат,</b> который пользуется популярностью во всем мире с прошлого века. Со временем классический вариант неоднократно видоизменялся и сейчас существует множество вариаций, которые не уступают оригиналу. <b>Добавление микрозелени</b> делает любимое многими блюдо более питательным, насыщенным по вкусу и красивым.</p>
        <Picture
            png={Img40_3}
            webp={ImgW40_3}
            alt="Классический рецепт с микрозеленью"
          />
        <h3>Классический рецепт с микрозеленью</h3>
        <p><b>В классическом варианте</b> в блюдо добавляют курицу. Идеальный вариант — куриная грудка. Микрозелень можете добавлять одного вида или микс сортов.</p>
        <p><b>Потребуется:</b></p>
        <ul> 
          <li>салат "Латук" - 20 листиков</li> 
          <li>куриное филе - 200 г</li> 
          <li>чеснок - 2 зубчика</li> 
          <li>батон - 270 г</li> 
          <li>микрозелень - 100 г</li> 
          <li>черри - 120 г</li> 
          <li>перец молотый</li> 
          <li>соль</li> 
          <li>оливковое масло - 70 мл</li> 
          <li>сыр "Пармезан" - 60 г</li> 
          <li>майонез - 30 мл</li> 
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul> 
          <li>Отварите в подсоленной воде филе. Нарежьте кубиками.</li> 
          <li>С батона срежьте корочку. Мякиш нарежьте кубиками.</li> 
          <li>Чесночные зубчики мелко покрошите.</li> 
          <li>Прогрейте в сковороде масло. Добавьте чеснок и кубики батона. Обжаривайте до золотистости.</li> 
          <li>Черри разрежьте на две части. Сыр натрите на средней терке.</li> 
          <li>Салат порвите руками.</li> 
          <li>Соедините все подготовленные продукты, кроме черри.</li> 
          <li>Посолите. Посыпьте перцем. Добавьте микрогрин и аккуратно перемешайте.</li> 
          <li>Украсьте салат сверху помидорами.</li> 
        </ul>
        <p>Майонез подавайте отдельно, добавляя его порционно перед подачей.</p>
        <h3>Салат «Цезарь» с микрозеленью и креветками</h3>
        <p><b>Этот вариант</b> по достоинству оценят любители морепродуктов. В рецепте указано количество отваренных креветок.</p>
        <p><b>Потребуется:</b></p>
        <ul> 
          <li>оливки - 1 банка</li> 
          <li>креветки - 300 г</li> 
          <li>листья салата - 1 пучок</li> 
          <li>томаты черри - 200 г</li> 
          <li>горчица «дижонская» - 20 г</li> 
          <li>чеснок - 1 зубчик</li> 
          <li>сыр «Пармезан» - 60 г</li> 
          <li>батон - 250 г</li> 
          <li>оливковое масло - 0 мл</li> 
          <li>натуральный йогурт - 200 мл</li> 
        </ul> 
        <p><b>Пошаговый процесс:</b></p>
        <ul> 
          <li>В небольшую тарелку налейте оливковое масло. Добавьте измельченный чеснок. Оставьте на 2 часа.</li> 
          <li>Часть масла перемешайте с вареными креветками. Отставьте на полчаса.</li> 
          <li>Мякоть батона нарежьте кубиками. Поджарьте в разогретом чесночном масле. Подсушите гренки в прогретом до 185°С духовом шкафу в течение 3 минут.</li> 
          <li>Натрите сыр на терке. Половину соедините с горчицей и йогуртом.</li> 
          <li>На дно блюда поместите листья салата. Полейте приготовленным соусом.</li> 
          <li>Распределите сверху креветки, оливки и сыр.</li> 
          <li>Украсьте половинками черри и микрозеленью.</li> 
        </ul>
        <p>Гренками посыпайте перед подачей.</p>
        <h3>Салат с микрогрином и семгой</h3>
        <p><b>Малосольная семга</b> сделает салат особенно праздничным и понравится всем гостям. При желании можете украсить готовое блюдо не только микрозеленью, но и красной икрой.</p>
        <p><b>Потребуется:</b></p>
        <ul> 
          <li>салат - 1 пучок</li> 
          <li>отварные креветки - 250 г</li> 
          <li>малосольная семга - 120 г</li> 
          <li>черри - 7 шт.</li> 
          <li>сыр "Пармезан" - 70 г</li> 
          <li>готовые сухарики - 200 г</li> 
          <li>микрозелень - 70 г</li> 
          <li>натуральный йогурт - 120 мл</li> 
          <li>чеснок - 2 зубчика</li> 
          <li>горчица - 10 г</li> 
          <li>маринованные корнишоны - 2 шт.</li> 
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul> 
          <li>Нарвите руками салатные листья. Поместите в салатник.</li> 
          <li>Нарежьте тонкими полосками семгу. Распределите по зелени.</li> 
          <li>Добавьте креветки и черри, разрезанные на две части.</li> 
          <li>Нарежьте на 4 части огурцы. Отправьте в чашу блендера. Добавьте натуральный йогурт, горчицу и чеснок. Взбейте соус.</li> 
          <li>Полейте салат. Сверху украсьте микрозеленью. Посыпьте сыром и добавьте сухарики.</li> 
        </ul>
        <p>Вместо йогурта можете использовать сметану.</p>
        <h3>Салат «Цезарь» с пекинской капустой и микрозеленью</h3>
        <p><b>С пекинской капустой</b> салат получается сочным и нежным. Заранее готовить блюдо не рекомендуется, иначе продукты пустят сок, а сухарики размякнут. Это негативно повлияет на вкус.</p>
        <p><b>Потребуется:</b></p>
        <ul> 
          <li>белый хлеб - 4 кусочка</li> 
          <li>оливковое масло - 30 мл</li> 
          <li>креветки - 270 г</li> 
          <li>твердый сыр - 170 г</li> 
          <li>пекинская капуста - небольшой вилок</li> 
          <li>маслины без косточек - 1 банка</li> 
          <li>чесночные зубчики - 2 шт.</li> 
          <li>нежирная сметана - 100 мл</li> 
          <li>лимонный сок - 20 мл</li> 
          <li>молотый перец</li> 
          <li>микрозелень - 120 г</li> 
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul> 
          <li>Отварите креветки. Белый хлеб нарежьте кубиками.</li> 
          <li>Прогрейте масло в сковороде. Добавьте кубики хлеба. Поджарьте до румяности.</li> 
          <li>Натрите сыр на крупной терке. Нашинкуйте капусту.</li> 
          <li>Маслины нарежьте пополам.</li> 
          <li>Перемешайте подготовленные продукты.</li> 
          <li>Соедините горчицу со сметаной, пропущенным через пресс чесноком, лимонным соком. Посыпьте перцем и перемешайте.</li> 
          <li>Полейте салат приготовленным соусом.</li> 
          <li>Сверху распределите микрозелень и сухарики.</li> 
        </ul>
        <p>Креветки можете не только отваривать, но и поджаривать.</p>
        <h3>Салат «Цезарь» с копченой рыбой и микрозеленью</h3>
        <p><b>Этот вариант подходит</b> для любителей копченых блюд. Лучше всего салат сочетается с миксом сортов микрозелени.</p>
        <p><b>Потребуется:</b></p>
        <ul> 
          <li>кресс-салат - 1 пучок</li> 
          <li>копченая семга - 320 г</li> 
          <li>репчатый лук - 200 г</li> 
          <li>куриное яйцо</li> 
          <li>Вустерский соус - 10 мл</li> 
          <li>оливковое масло - 60 мл</li> 
          <li>лимон - 0,5 шт.</li> 
          <li>соль</li> 
          <li>микрозелень - 120 г</li> 
          <li>перец</li> 
          <li>горчица - 10 г</li> 
        </ul> 
        <p><b>Пошаговый процесс:</b></p>
        <ul> 
          <li>Выжмите с половинки лимона сок. Соедините с яйцом. Добавьте горчицу, оливковое масло, Вустерский соус. Посолите, поперчите и перемешайте венчиком до однородности.</li> 
          <li>Кресс-салат нарвите руками. Отправьте в миску.</li> 
          <li>Добавьте мелко нарезанную копченую семгу и измельченный лук.</li> 
          <li>Полейте соусом.</li> 
          <li>Сверху распределите микрозелень.</li> 
        </ul>
        <p>При желании можете добавить сухарики.</p>
        <h3>«Цезарь» с говядиной и микрозеленью</h3>
        <p><b>Если хотите,</b> чтобы салат был не только вкусным, но и максимально сытным, то приготовьте его по этому рецепту. Говядину выбирайте свежую, незамороженную.</p>
        <p><b>Потребуется:</b></p>
        <ul> 
          <li>говядина - 200 г</li> 
          <li>молоко - 20 мл</li> 
          <li>белый хлеб - 80 г</li> 
          <li>салатные листья - 200 г</li> 
          <li>базилик - 2 г</li> 
          <li>растительное масло - 30 мл</li> 
          <li>сливочное масло - 20 г</li> 
          <li>черри - 120 г</li> 
          <li>консервированный тунец - 50 г</li> 
          <li>чеснок - 1 зубчик</li> 
          <li>зерновая горчица - 20 г</li> 
          <li>майонез - 200 мл</li> 
          <li>твердый сыр - 90 г</li> 
          <li>лимонный сок с половинки плода</li> 
          <li>микрозелень - 120 г</li> 
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul> 
          <li>Измельчите в чаше блендера тунец. Добавьте пропущенный через пресс чеснок, майонез, лимонный сок, натертый на мелкой терке сыр и горчицу. Перемешайте.</li> 
          <li>Хлеб нарежьте мелкими кубиками.</li> 
          <li>Пересыпьте в сухую сковороду (без масла).</li> 
          <li>Сбрызните молоком. Добавьте мелко измельченные листья базилика. Жарьте до золотистости.</li> 
          <li>Остудите гренки.</li> 
          <li>Мясо посыпьте солью и перцем. Поджарьте медальон в разогретом растительном масле с двух сторон. В конце приготовления добавьте сливочное масло. Оно сделает говядину нежнее по вкусу.</li> 
          <li>Говядину переложите в тарелку, остудите. Нарежьте тонкими слайсами.</li> 
          <li>Разрежьте пополам томаты.</li> 
          <li>На блюдо поместите салатные листья. Полейте соусом.</li> 
          <li>Распределите равномерно говядину. Посыпьте гренками и выложите томаты.</li> 
          <li>Присыпьте натертым на мелкой терке сыром. Укройте микрозеленью.</li> 
        </ul>
        <p>Если у вас мало время на приготовление, то используйте готовые сухарики с любым вкусом, которые продают в магазинах.</p>
        <h3>Салат «Цезарь» с копченой курицей</h3>
        <p><b>Это быстрый рецепт</b> приготовления, так как курицу заранее не надо варить или поджаривать. В рецепте рекомендуется использовать окорока, но вы можете добавлять в салат любую часть.</p>
        <p><b>Потребуется:</b></p>
        <ul> 
          <li>куриные окорока (подкопченные) - 400 г</li> 
          <li>сыр "Пармезан" - 70 г</li> 
          <li>помидоры - 220 г</li> 
          <li>листовой салат - 250 г</li> 
          <li>свежий багет - 450 г</li> 
          <li>сок половинки лимона</li> 
          <li>соль</li> 
          <li>отварное яйцо</li> 
          <li>горчица - 10 г</li> 
          <li>оливковое масло - 20 мл</li> 
          <li>чеснок - 1 зубчик</li> 
          <li>перец</li> 
          <li>микрозелень - 130 г</li> 
        </ul>
        <p><b>Пошаговый процесс:</b></p>
        <ul> 
          <li>Нарежьте багет на мелкие кубики. Обжарьте на сковороде, добавив немного масла.</li> 
          <li>Салат порвите руками. Сыр натрите на мелкой терке. Нарежьте на дольки помидоры, а курицу — соломкой.</li> 
          <li>Соедините подготовленные продукты.</li> 
          <li>Яйцо отправьте в чашу блендера. Влейте лимонный сок. Добавьте горчицу, оливковое масло и чеснок, пропущенный через пресс. Посолите и поперчите. Перемешайте.</li> 
          <li>Полейте приготовленным соусом салат. Подавайте, украсив микрозеленью.</li> 
        </ul>
        <p>О том, как готовить вторые блюда с микрогрином, мы рассказывали <Link to="/blog/ispolzovanie-mikrozeleni-dlja-prigotovlenija-vtoryh-bljud/">здесь</Link>.</p>
        <h2>Почему микрозелень включают в рацион питания</h2>
        <p><b>Сначала микрогрин появился</b> в ресторанах в качестве декоративного элемента блюд, благодаря разнообразию вкусов, форм и ароматов. Затем продукт стал более востребованным в качестве нутрицевтика. Постепенно молодые растения начали применять в кулинарии во всем мире. В ассортимент микрогрина входит несколько десятков разных культур.</p>
        <Picture
            png={Img40_4}
            webp={ImgW40_4}
            alt="Классический рецепт с микрозеленью"
          />
        <p><b>Микрозелень рекомендуют включать</b> в рацион питания из-за биохимической ценности. Ростки содержат фитонутриенты, витамины и минералы в большем количестве по сравнению с взрослыми растениями.</p>
        <p><b>Микрогрин</b> — биологически полезный и экологически чистый продукт. При выращивании ростков не применяют пестициды и минеральные удобрения. За небольшой период роста, продукт не успевает накапливать из атмосферы вредные вещества. По сравнению с взрослым растением, микрозелень легче и лучше усваивается организмом.</p>
        <p><b>Употребляют ее в сыром виде,</b> поэтому продукт сохраняет все свои полезные свойства. Кроме того, у микрогрина небольшая калорийность. Поэтому молодые ростки — идеальный вариант для людей, которые следят за своим здоровьем. Например, калорийность на 100 г микрогрина:</p>
        <ul>
          <li>капусты — 34 ккал;</li>
          <li>редиса — 14,9 ккал;</li>
          <li>рукколы — 25 ккал.</li>
        </ul>
        <p>Микрогрин можно добавлять в любые блюда и не беспокоиться о фигуре.</p>
        <p><b>Кроме того, у микрозелени</b> большое разнообразие вкусов. Она обогащает блюда не только красивым внешним видом, но и целым спектром вкусовых ощущений. У каждого вида свой привкус: от нежно-сладкого до горьковатого.</p>
        <p><b>Чаще всего в блюда добавляют микрозелень:</b></p>
        <ul>
          <li>базилика, чечевицы;</li>
          <li>редиса, дайкона;</li>
          <li>салата, руколы;</li>
          <li>подсолнечника, сои;</li>
          <li>маш и другие.</li>
        </ul>
        <p>Нельзя употреблять микрозелень растений, в состав которых входят алкалоиды (природные яды). К ним относят тыкву, баклажаны, кабачок, помидоры, картофель и перец. Кстати, об интересных фактах о микрогрине можете прочитать <Link to="/blog/10-interesnyh-faktov-o-mikrozeleni/">здесь</Link>.</p>
        </>
      )
    },
    {
      id: 'vkusnye-i-poleznye-recepty-ragu-s-dobavleniem-mikrozeleni',
      name: 'Микрозелень: лучшие рецепты рагу',
      title: 'Вкусные и полезные рецепты рагу с добавлением микрозелени',
      descr: 'Правила употребления микрогрина и вкусные рецепты рагу: классическое рагу, рагу в духовке, рагу «Рататулли», рагу из говядины  и т.д.',
      bg: [Img39_1, ImgW39_1],
      date: '13.02.2024',
      content: (
        <>
        <p><b>Микрогрин</b> — молодые всходы привычных для всех растений. Чаще всего употребляют зелень и корнеплоды. Злаки встречаются реже. Предлагаем приготовить с микрозеленью вкусное, сытное рагу, которое подходит для питания всей семьи.</p>
        <h2>Что такое микрозелень</h2>
        <p><b>В 1980-1990 годах</b> микрозелень появилась в ресторанах Калифорнии. Шеф-поварам понравилось использовать ее в своих блюдах. Микрозелень не только делала вкус разнообразней, но и улучшала внешний вид.</p>
        <Picture
            png={Img39_2}
            webp={ImgW39_2}
            alt="Что такое микрозелень"
          />
          <p><b>В первое время ассортимент растений был небольшим. В ресторанах подавали блюда с ростками:</b></p>
          <ul>
            <li>свеклы, кориандра;</li>
            <li>капусты, руколы;</li>
            <li>базилика;</li>
            <li>микс (несколько сортов).</li>
          </ul>
          <p><b>Со временем микрозелень получила мировое признание. В пищу стали использовать больше сортов. Люди оценили вкус:</b></p>
          <ul> 
            <li>бораго</li> 
            <li>китайская капуста</li> 
            <li>дайкон, кинза</li> 
            <li>фасоль, лук-порей</li> 
            <li>редис, горчица</li> 
            <li>подсолнечник</li> 
          </ul>
          <p><b>Не выращивают и не употребляют в пищу</b> микрозелень картофеля, томатов, перцев и баклажан. В их состав входят ядовитые алкалоиды (соланин, тропан), вызывающие серьезные отравления.</p>
          <h2>Правила употребления микрогрина и вкусные рецепты рагу</h2>
          <Picture
            png={Img39_3}
            webp={ImgW39_3}
            alt="Правила употребления микрогрина и вкусные рецепты рагу"
          />
          <p><b>Микрозелень употребляют</b> как обычную зелень и овощи. Главное, не подвергать ростки термической обработке. Употреблять их можно только свежими. Зелень должна быть недавно срезанной, иначе она потеряет свои свойства и вкусовые качества.</p>
          <p><b>Микрозелень сочетается</b> со всеми продуктами. Особенно вкусным с ростками получается рагу. Предлагаем воспользоваться несколькими проверенными рецептами.</p>
          <h3>Классическое овощное рагу с микрогрином</h3>
          <p>По вкусу микрогрин напоминает своего взрослого сородича, но привкус более приятный и мягкий. Для рагу выбирайте свой любимый сорт или используйте микс.</p>
          <p><b>Потребуется:</b></p>
          <ul> 
            <li>баклажаны - 450 г</li> 
            <li>кабачки - 400 г</li> 
            <li>красный сладкий перец - 300 г</li> 
            <li>чеснок - 4 зубка</li> 
            <li>помидоры - 300 г</li> 
            <li>укроп - 30 г</li> 
            <li>лук - 200 г</li> 
            <li>микрогрин - 70 г</li> 
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Баклажаны и кабачок нарежьте кубиками.</li> 
            <li>Перец измельчите. Брусочки должны быть средними по размеру.</li> 
            <li>Помидоры очистите от кожуры. Чтобы она легче и быстрее снялась, поместите овощ в кипяток на 15 секунд. Нарежьте мякоть любыми кусочками.</li> 
            <li>Луковицу нарежьте полукольцами. Обжарьте в растительном масле до прозрачности.</li> 
            <li>Засыпьте к помидорам перец. Перемешайте и потомите на минимальном огне 5 минут.</li> 
            <li>Добавьте кабачки и баклажаны. Жарьте на среднем режиме конфорки 5 минут.</li> 
            <li>Добавьте помидоры и пропущенный через пресс чеснок. Посыпьте солью и любимыми специями. Перемешайте.</li> 
            <li>Накройте крышкой, тушите 20 минут.</li> 
          </ul>
          <p>Готовое блюдо подавайте горячим с кусочками сливочного масла, посыпав измельченным укропом и украсив микрозеленью.</p>
          <h3>Рагу в духовке с микрозеленью</h3>
          <p>Для приготовления используйте одну большую форму или несколько маленьких горшочков. Курицу в рецепте можете заменить любым мясом.</p>
          <p><b>Потребуется:</b></p>
          <ul> 
            <li>баклажаны - 300 г</li> 
            <li>кабачки - 300 г</li> 
            <li>болгарский перец - 200 г</li> 
            <li>картофель - 300 г</li> 
            <li>чеснок - 3 зубчика</li> 
            <li>сыр твердого сорта - 230 г</li> 
            <li>соль</li> 
            <li>куриное филе - 300 г</li> 
            <li>орегано</li> 
            <li>консервированные томаты - 350 г</li> 
            <li>оливковое масло - 30 мл</li> 
            <li>микрозелень - 70 г</li> 
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Баклажаны, кабачки, томаты крупно порежьте.</li> 
            <li>Куриное филе нарежьте кубиками, картофель - тонкими слайсами, болгарский перец - брусочками, чесночные зубчики - мелкими кубиками.</li> 
            <li>Выложите все продукты слоями в большую форму или порционные горшочки. Сыр не добавляйте. Посыпьте орегано и солью.</li> 
            <li>Сверху полейте оливковым маслом.</li> 
            <li>Отправьте в заранее прогретый до 180°С духовой шкаф.</li> 
            <li>Запекайте 45 минут.</li> 
            <li>Посыпьте натертым сыром. Оставьте в духовом шкафу на 5 минут.</li> 
          </ul>
          <p>Подавайте рагу, посыпав микрозеленью.</p>
          <h3>Рагу «Рататулли» с микрогрином</h3>
          <p>По этому рецепту рагу подают как холодную закуску и в качестве гарнира к мясу. Когда блюдо настоится, становится еще вкуснее.</p>
          <p><b>Потребуется:</b></p>
          <ul> 
            <li>баклажаны - 600 г</li> 
            <li>кабачки - 800 г</li> 
            <li>лук - 300 г</li> 
            <li>томаты - 400 г</li> 
            <li>чеснок - 2 зубчика</li> 
            <li>микрозелень - 70 г</li> 
            <li>лимон - 0,5 плода</li> 
            <li>оливковое масло - 30 мл</li> 
            <li>соль</li> 
            <li>мускатный орех - 2 г</li> 
            <li>тимьян - 2 г</li> 
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Обдайте кипятком томаты. Снимите аккуратно кожицу. Нарежьте кубиками.</li> 
            <li>Если кабачки зрелые, срежьте кожуру. У молодых овощей кожица мягкая. Ее срезать не надо.</li> 
            <li>Кабачки и баклажаны нарежьте полукольцами. Измельчите луковицы.</li> 
            <li>Налейте в казан масло. Прогрейте.</li> 
            <li>Засыпьте мелко порубленный чеснок. Через 10 секунд добавьте подготовленные овощи и перемешайте.</li> 
            <li>Тушите 5 минут. Посолите. Посыпьте тимьяном и мускатным орехом. Перемешайте.</li> 
            <li>Накройте казан крышкой. Готовьте рагу 40 минут.</li> 
          </ul>
           <p>Подавайте порционно, полив лимонным соком и украсив микрогрином.</p>
           <h3>Рагу из говядины и микрозелени</h3>
           <p>Нежные овощи придают блюду насыщенным аромат, а говядина делает рагу сытным. Рецепт прост в приготовлении, поэтому с поставленной задачей справится даже неопытный кулинар.</p>
           <p><b>Потребуется:</b></p>
           <ul> 
            <li>говяжья вырезка - 550 г</li> 
            <li>подсолнечное масло</li> 
            <li>картофель - 450 г</li> 
            <li>морковь - 150 г</li> 
            <li>лук - 170 г</li> 
            <li>микрозелень - 50 г</li> 
            <li>томаты - 450 г</li> 
            <li>кабачок - 300 г</li> 
            <li>петрушка, укроп - 70 г</li> 
            <li>вода - 500 мл</li> 
            <li>специи и соль</li> 
          </ul> 
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Удалите пленку с говяжьей вырезки. Нарежьте мясо средними кусочками.</li> 
            <li>Прогрейте подсолнечное масло в сковороде. Поместите говядину. Жарьте на максимальном режиме конфорки до румяной корочки. Переложите в тарелку.</li> 
            <li>Морковь натрите на крупной терке. Лук нарежьте полукольцами. Отправьте в сковороду, в которой готовили мясо. Обжарьте овощи до мягкости.</li> 
            <li>Отдельно поджарьте до полуготовности картофель и кабачок, нарезанные брусочками.</li> 
            <li>Подготовленные продукты отправьте в кастрюлю. Добавьте помидоры, нарезанные ломтиками. Предварительно не забудьте снять с помидор кожицу.</li> 
            <li>Залейте водой. Накройте кастрюлю крышкой. Тушите 45 минут.</li> 
            <li>Измельчите петрушку и укроп. Отправьте в кастрюлю. Перемешайте.</li> 
          </ul>
          <p>Порционно разложите рагу по тарелкам и посыпьте микрозеленью.</p>
          <h3>Рагу с говядиной и микрозеленью в мультиварке</h3>
          <p>Рагу получается сытным и не занимает много времени. Микрозелень помогает разнообразить вкус, делая его более насыщенным.</p>
          <p><b>Потребуется:</b></p>
          <ul> 
            <li>говяжья вырезка - 600 г</li> 
            <li>морковь - 200 г</li> 
            <li>лук - 200 г</li> 
            <li>томаты - 300 г</li> 
            <li>сладкий перец - 400 г</li> 
            <li>сметана - 250 мл</li> 
            <li>микрозелень - 100 г</li> 
            <li>вода - 1,7 л</li> 
            <li>подсолнечное масло - 30 мл</li> 
            <li>соль, специи</li> 
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Очистите мясо от пленки и жилок. Нарежьте небольшими кусочками.</li> 
            <li>Отправьте в чашу мультиварки. Добавьте подсолнечное масло. Включите режим «Выпечка». Жарьте, постоянно помешивая до румяности.</li> 
            <li>Добавьте лук, нарезанный кубиками. Через 5 минут засыпьте натертую на крупной терке морковь.</li> 
            <li>Готовьте 7 минут, периодически перемешивая.</li> 
            <li>Добавьте помидоры, нарезанные кусочками. Перемешайте.</li> 
            <li>Готовьте до мягкости.</li> 
            <li>Сметану соедините с водой, солью и специями. Залейте в чашу мультиварки. Перемешайте.</li> 
            <li>Включите режим «Тушение». Таймер поставьте на 70 минут.</li> 
          </ul>
          <p>Разложите рагу по глубоким тарелкам. Обильно посыпьте микрозеленью.</p>
          <h3>Рагу с черносливом и микрогрином</h3>
          <p>Чернослив придает рагу насыщенность и делает блюдо пикантным. Для блюда выбирайте микрозелень с ярким вкусом. Подойдет горчица, лук, капуста, рукола.</p>
          <p><b>Потребуется:</b></p>
          <ul> 
            <li>говядина - 900 г</li> 
            <li>чернослив - 250 г</li> 
            <li>сметана - 270 мл</li> 
            <li>лук - 250 г</li> 
            <li>мука - 50 г</li> 
            <li>соль</li> 
            <li>вода - 100 мл</li> 
            <li>микрозелень - 120 г</li> 
            <li>корица - 3 г</li> 
            <li>молотый перец - 2 г</li> 
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Говяжью вырезку очистите от пленок. Нарежьте полосками и отбейте кухонным молотком, чтобы мясо стало мягким.</li> 
            <li>Прогрейте масло в сковороде. Добавьте говядину. Жарьте до румяной корочки, постоянно помешивая.</li> 
            <li>Добавьте измельченный лук. Готовьте 5 минут.</li> 
            <li>Отдельно поджарьте до желтого оттенка муку. Перемешайте с водой и сметаной, чтобы не было комочков.</li> 
            <li>Залейте соусом мясо с луком.</li> 
            <li>Посолите, посыпьте молотым перцем и корицей. Перемешайте.</li> 
            <li>Тушите полчаса. Добавьте мелко порезанный чернослив. Перемешайте. Тушите 20 минут.</li> 
          </ul> 
          <p>Подавайте рагу в горячем виде, сверху посыпав измельченным микрогрином.</p>
          <h3>Рагу с капустой и микрозеленью</h3>
          <p>В рецепте используют свежее свиное мясо. Если хотите изменить вкус блюда, то можете заменить его копченым.</p>
          <p><b>Потребуется:</b></p>
          <ul> 
            <li>сахар - 20 г</li> 
            <li>микрогрин - 100 г</li> 
            <li>свиная вырезка - 450 г</li> 
            <li>душистый перец - 7 горошин</li> 
            <li>капуста - 600 г</li> 
            <li>картофель - 450 г</li> 
            <li>лук - 300 г</li> 
            <li>чеснок - 3 зубчика</li> 
            <li>соль</li> 
            <li>вода - 330 мл</li> 
            <li>лавровый лист - 3 шт.</li> 
            <li>морковь - 300 г</li> 
            <li>свиное сало - 230 г</li> 
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Нарежьте сало мелкими кубиками. Отправьте в разогретую сковороду. Вытопите. Должны получиться шкварки.</li> 
            <li>Нарежьте свинину кубиками. Перемешайте со шкварками. Поджарьте.</li> 
            <li>Капусту нашинкуйте. Нарежьте лук полукольцами. Морковь натрите на мелкой терке. Отправьте к мясу. Перемешайте.</li> 
            <li>Сковороду накройте крышкой. Тушите полчаса.</li> 
            <li>Нарежьте картошку полукольцами. Отправьте к овощам. Посыпьте сахаром и посолите.</li> 
            <li>Добавьте пропущенный через пресс чеснок и горошины перца. Залейте кипятком. Тушите до готовности.</li> 
            <li>Готовое блюдо немного остудите, посыпьте молодыми ростками.</li> 
          </ul> 
          <p>Чтобы рагу не превратилось в кашу, картофель нарезайте крупнее.</p>
          <h3>Рагу с микрогрином и свиными ребрышками</h3>
          <p>Корейка для приготовления не подходит. Выбирайте мясистые свиные ребра.</p>
          <p><b>Потребуется:</b></p>
          <ul> 
            <li>свиные ребра - 900 г</li> 
            <li>чеснок - 2 зубчика</li> 
            <li>растительное масло</li> 
            <li>вода - 350 мл</li> 
            <li>цветная капуста - 450 г</li> 
            <li>картофель - 350 г</li> 
            <li>морковь - 150 г</li> 
            <li>черный перец</li> 
            <li>микрогрин - 100 г</li> 
            <li>консервированная фасоль - 210 г</li> 
            <li>петрушка - 20 г</li> 
            <li>соль</li> 
            <li>лук - 200 г</li> 
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul> 
            <li>Разрежьте мясо, чтобы каждое ребро было отдельно.</li> 
            <li>Картофель, морковь понадобятся соломкой, лук - полукольцами, чеснок - мелкими кубиками.</li> 
            <li>Капусту разберите на соцветия.</li> 
            <li>Налейте масло в сотейник. Обжарьте морковь с луком.</li> 
            <li>Добавьте ребра. Жарьте на среднем огне 10 минут.</li> 
            <li>Добавьте картофель и чеснок. Залейте кипятком.</li> 
            <li>Накройте крышкой. Готовьте 8 минут.</li> 
            <li>Добавьте консервированную фасоль, соцветия капусты и порубленную петрушку.</li> 
            <li>Посолите, поперчите. Перемешайте рагу и тушите на среднем огне 15 минут.</li> 
          </ul>
          <p>Рагу снимите с огня и оставьте настояться под закрытой крышкой на четверть часа. Переложите в тарелки и украсьте микрозеленью.</p>
        </>
      )
    },
    { 
      id: 'vkusnye-recepty-salatov-s-dobavleniem-mikrozeleni-i-kalmarov', 
      name: 'Микрозелень: вкус, правила употребления, рецепты салатов с кальмарами', 
      title: 'Вкусные рецепты салатов с добавлением микрозелени и кальмаров', 
      descr: 'Полезные рецепты салатов с микрозеленью и кальмарами. Различные сочетания продуктов для приготовления вкусных салатов.', 
      bg: [Img38_1, ImgW38_1], 
      date: '13.02.2024', 
      content: ( 
        <> 
          <p><b>Микрозелень</b> — молодые ростки растений, которые насыщены питательными веществами. Сочетаются они со всеми продуктами: мясом, овощами, морепродуктами. Благодаря разнообразию вкусов, делают привычные блюда особенными и более насыщенными.</p> 
        <h2>Вкус микрозелени</h2> 
        <p>Первыми микрозелень стали применять для оформления блюд калифорнийские повара еще в 80-х годах прошлого века. Ассортимент был небольшим. В пищу употребляли всего несколько видов. Постепенно микрогрин приобрел популярность во всем мире.</p> 
        <p><b>Вкус микрозелени варьируется</b> от пряного и сладкого до жгучего и острого. Это дает возможность каждому человеку подобрать сорт под собственные предпочтения. Чаще всего вкус молодых ростков напоминает взрослое растение, но ощущается нежнее.</p> 
        <b>В таблице указаны наиболее популярные сорта микрозелени и их вкус:</b> 
        <table> 
          <thead> 
            <tr> 
              <th>Сорт микрогрина</th> 
              <th>Вкус</th> 
            </tr> 
          </thead> 
          <tbody> 
            <tr> 
              <td>Горох </td> 
              <td>Сладковатый, нежный.</td> 
            </tr> 
            <tr> 
              <td>Чечевица</td> 
              <td>Сладкий, напоминает горошек.</td> 
            </tr> 
            <tr> 
              <td>Красная капуста </td> 
              <td>Слегка сладковатый.</td> 
            </tr> 
            <tr> 
              <td>Подсолнечник </td> 
              <td>Сладкий, ореховый, сочный.</td> 
            </tr> 
            <tr> 
              <td>Клевер  </td> 
              <td>Сочный, нейтральный, хрустящий.</td> 
            </tr> 
            <tr> 
              <td>Свекла </td> 
              <td>Немного слаще взрослого растения.</td> 
            </tr> 
            <tr> 
              <td>Кукуруза </td> 
              <td>В меру сладкий, сочный.</td> 
            </tr> 
            <tr> 
              <td>Щавель </td> 
              <td>Лимонный, слегка кислый.</td> 
            </tr> 
            <tr> 
              <td>Пак-чой </td> 
              <td>Немного сладкий, мягкий.</td> 
            </tr> 
            <tr> 
              <td>Салат-латук </td> 
              <td>Нежный, немного сладкий.</td> 
            </tr> 
            <tr> 
              <td>Редис </td> 
              <td>Остро-жгучий, хрустящий.</td> 
            </tr> 
            <tr> 
              <td>Люцерна </td> 
              <td>Травянистый привкус, хрустящий, мягкий.</td> 
            </tr> 
            <tr> 
              <td>Амарант </td> 
              <td>Свекловичный.</td> 
            </tr> 
            <tr> 
              <td>Маш </td> 
              <td>Нейтральный, хрустящий.</td> 
            </tr> 
            <tr> 
              <td>Брокколи </td> 
              <td>Немного горький, хрустящий.</td> 
            </tr> 
            <tr> 
              <td>Кориандр </td> 
              <td>Цитрусовый, специфический.</td> 
            </tr> 
            <tr> 
              <td>Рукола </td> 
              <td>Остро-перечный, ореховый.</td> 
            </tr> 
            <tr> 
              <td>Базилик </td> 
              <td>Интенсивный, немного сладкий.</td> 
            </tr> 
            <tr> 
              <td>Горчица </td> 
              <td>Пряный, жгучий.</td> 
            </tr> 
            <tr> 
              <td>Кресс-салат </td> 
              <td>Немного горький, пряный.</td> 
            </tr> 
            <tr> 
              <td>Сельдерей </td> 
              <td>Слегка соленый, пряный.</td> 
            </tr> 
            </tbody> 
        </table>
        <p><b>Выбирая сорт микрозелени,</b> ориентируйтесь на индивидуальные предпочтения. Для маленьких детей идеально подойдут сладкие и сочные ростки подсолнечника, красной капусты и гороха. Женщины по достоинству оценят ореховый аромат руколы и амаранта, 
        летнюю сочность настурции и бораго, пикантную горечь кресс-салата и цикория. Мужчинам понравится терпкий вкус лука, базилика и пажитника. Подробно о пользе микрозелени для мужского организма рассказывали <Link to="/blog/mikrozelen-dlja-muzcin-polza-dlja-organizma-i-recepty-bljud/">здесь</Link></p>
        <p>Если вам нравится люцерна, то и ее молодые ростки тоже придутся по вкусу. Если не можете определиться с выбором, то добавляйте в пищу микс сортов.</p>
        <p><b>Нельзя употреблять</b> микрогрин томатов, баклажан, картофеля, так как они содержат соланин. Ядовитое вещество вызывает проблемы с пищеварением.</p>
        <Picture
            png={Img38_2}
            webp={ImgW38_2}
            alt="Вкус микрозелени"
          />
          <h2>Правила употребления микрозелени</h2> 
          <p>Микрозелень добавляют практически во все блюда. При употреблении соблюдают несколько правил.</p>
          <p><b>Например, молодые ростки:</b></p>
          <ul>
            <li>долго не хранят в холодильнике;</li>
            <li>не подвергают термической обработке;</li>
            <li>не замораживают, не маринуют, не консервируют;</li>
            <li>добавляют в остывшие блюда.</li>
          </ul>
          <p>Микрогрин — универсальная приправа. Ее добавляют во все блюда и подают в качестве самостоятельного гарнира.</p>
          <h2>Лучшие рецепты салатов с микрозеленью и кальмарами</h2>
          <Picture
            png={Img38_3}
            webp={ImgW38_3}
            alt="Лучшие рецепты салатов с микрозеленью и кальмарами"
          />
          <p><b>Закуска с морепродуктами</b> — вкусное и ароматное блюдо. Готовят его на праздники и подают в обычный день на обед или ужин. Нейтральный вкус кальмара идеально дополняет микрозелень. Сорт молодых ростков подходит любой. При выборе ориентируйтесь на собственные предпочтения.</p>
          <h3>Классический салат с микрозеленью, кальмаром и сыром</h3>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>тушки кальмар — 270 г;</li>
            <li>лимонный сок — 20 мл;</li>
            <li>зеленый лук — 10 г;</li>
            <li>яйца — 2 шт.;</li>
            <li>яблоко — 200 г;</li>
            <li>сыр — 70 г;</li>
            <li>огурец — 300 г;</li>
            <li>сметана — 70 мл;</li>
            <li>микрозелень — 150 г.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Тушки кальмар отварите и очистите. Нарежьте тонкой соломкой.</li>
            <li>Если огурцы зрелые, срежьте кожуру. Овощ нарежьте соломкой.</li>
            <li>Яйца отварите. Нарежьте небольшими кубиками.</li>
            <li>У яблока удалите сердцевину. Нарежьте брусочками. Сбрызните лимонным соком, чтобы предотвратить потемнение.</li>
            <li>Нашинкуйте лук.</li>
            <li>Соедините подготовленные продукты. Добавьте микрозелень. Посолите и поперчите по вкусу.</li>
            <li>Заправьте сметаной.</li>
          </ul>
          <p>Выложите салат на блюдо. Посыпьте сыром, натертым на крупной терке.</p>
          <p><b>Салат с микрозеленью, кальмаром и крабовыми палочками</b></p>
          <p>Крабовые палочки предварительно разморозьте при комнатной температуре.</p>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>тушки кальмар — 350 г;</li>
            <li>майонез;</li>
            <li>крабовые палочки — 300 г;</li>
            <li>очищенные креветки — 300 г;</li>
            <li>консервированная кукуруза — 1 банка;</li>
            <li>яйца — 4 шт.;</li>
            <li>микрозелень — 150 г.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Ошпарьте и очистите кальмары. Отварите их в кипящей подсоленной воде в течение двух минут. Остудите и нарежьте полосками.</li>
            <li>Креветки очистите. Отварите в течение пяти минут. Отправьте в ледяную воду. Разделите на две части.</li>
            <li>Нарежьте крабовые палочки кубиками.</li>
            <li>Слейте маринад с кукурузы.</li>
            <li>Яйца отварите. Очистите и нарежьте некрупными кусочками. Форма может быть произвольной.</li>
            <li>Соедините все продукты. Посолите. Добавьте микрозелень. Заправьте майонезом.</li>
          </ul>
          <p>Если хотите сделать салат менее калорийным, то замените майонез греческим йогуртом.</p>
          <h3>Салат с микрогрином, кальмарами, креветками и красной икрой</h3>
          <p>Приготовление не займет много времени, а результат всех поразит. Это блюдо не только получается вкусным, но и становится украшением праздничного стола.</p>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>консервированные кальмары — 300 г;</li>
            <li>микрозелень — 100 г;</li>
            <li>крабовые палочки — 250 г;</li>
            <li>отварные креветки — 250 г;</li>
            <li>укроп — 30 г;</li>
            <li>красная икра — 105 г;</li>
            <li>яйца — 4 шт.;</li>
            <li>майонез.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Нарежьте соломкой кальмары и крабовые палочки.</li>
            <li>Креветки разрежьте на две части.</li>
            <li>Отварите яйца, очистите и нарежьте кубиками.</li>
            <li>Соедините все ингредиенты, добавьте нашинкованный укроп и микрогрин. Полейте майонезом, посолите и перемешайте.</li>
          </ul>
          <p>Украсьте красной икрой, которую разложите чайной ложкой в шахматном порядке.</p>
          <h3>Салат с микрозеленью, кальмарами и курицей</h3>
          <p>Необычное сочетание ингредиентов по достоинству оценит вся семья. Морепродукты в сочетании с микрозеленью порадуют вкусом, а курица сделает блюдо более сытным.</p>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>копченая курица — 170 г;</li>
            <li>креветки — 150 г;</li>
            <li>микрозелень — 150 г;</li>
            <li>кальмар — 250 г;</li>
            <li>сыр — 90 г;</li>
            <li>кислое яблоко — 250 г;</li>
            <li>лимонный сок — 20 мл;</li>
            <li>майонез.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Вскипятите воду. Посолите. Закиньте тушки кальмар. Варите 5 минут.</li>
            <li>Отдельно в течение пяти минут отварите креветки.</li>
            <li>Нарежьте кальмары и курицу соломкой.</li>
            <li>Семенную часть удалите у яблока. Срежьте кожуру. Нарежьте яблоко соломкой. Сбрызните лимонным соком.</li>
            <li>Разрежьте креветки на две части вдоль хвоста.</li>
            <li>Соедините подготовленные продукты. Добавьте микрозелень.</li>
            <li>Заправьте майонезом.</li>
          </ul>
          <p>Если не любите вкус копченой курицы, то замените ее отварной или жареной.</p>
          <h3>Салат с микрогрином, кальмарами и яйцом</h3>
          <p>Помидоры в салате используют в качестве украшения, поэтому лучше приобретайте черри. Готовьте блюдо непосредственно перед подачей, так как помидоры дают сок, из-за которого микрозелень может увянуть.</p>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>тушки кальмар — 370 г;</li>
            <li>майонез — 90 г;</li>
            <li>плавленый сыр — 100 г;</li>
            <li>крабовые палочки — 230 г;</li>
            <li>салатные листья — 4 шт.;</li>
            <li>чеснок — 1 зубчик;</li>
            <li>помидоры черри — 100 г;</li>
            <li>отваренные яйца — 2 шт.;</li>
            <li>микрозелень — 120 г.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Залейте на две минуты кипятком тушку кальмара. Снимите пленку. Отварите в кипящей воде в течение трех минут. Остудите сразу под холодной водой.</li>
            <li>Нарежьте соломкой кальмары, яйца, крабовые палочки.</li>
            <li>Сыр мелко натрите.</li>
            <li>Соедините подготовленные продукты с микрозеленью. Добавьте чеснок, пропущенный через пресс. Посолите и заправьте майонезом.</li>
            <li>Выложите на тарелку листья салата. Сверху разместите салат. Украсьте помидорами черри, разрезанными пополам.</li>
          </ul>
          <p>Чтобы плавленый сыр легко натирался, поместите его на полчаса в морозильную камеру.</p>
          <h3>Салат с микрозеленью, кальмарами и ветчиной</h3>
          <p>Чтобы сократить время приготовления, в рецепте используют консервированные кальмары. При желании можете добавлять отваренные.</p>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>кальмары — 250 г консервированных;</li>
            <li>сладкий перец — 150 г;</li>
            <li>консервированный горошек — 1 банка;</li>
            <li>сыр — 120 г;</li>
            <li>лук — 70 г;</li>
            <li>яйца — 2 шт. отваренных;</li>
            <li>ветчина — 250 г;</li>
            <li>огурец — 150 г;</li>
            <li>микрозелень — 100 г;</li>
            <li>майонез.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Нарежьте соломкой сладкий перец, огурец, кальмары.</li>
            <li>Лук нашинкуйте тонкими полукольцами.</li>
            <li>Ветчину и яйца нарежьте кубиками.</li>
            <li>Соедините подготовленные ингредиенты. Добавьте зеленый горошек, с которого предварительно слили маринад.</li>
            <li>Посыпьте солью. Заправьте майонезом.</li>
          </ul>
          <p>Салат переложите на тарелку. Обильно посыпьте микрозеленью и натертым сыром.</p>
          <h3>Рецепт салата с микрозеленью, грибами и кальмарами</h3>
          <p>В рецепте предлагают использовать шампиньоны, но можете заменить их отваренными боровиками или лисичками.</p>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>отварные кальмары — 250 г;</li>
            <li>микрозелень — 70 г;</li>
            <li>маринованные огурцы — 150 г;</li>
            <li>лук — 150 г;</li>
            <li>яйца — 2 отваренных;</li>
            <li>лимонный сок — 25 мл;</li>
            <li>майонез.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Нарежьте шампиньоны пластинами, а лук - полукольцами. Обжарьте в масле на максимальном режиме конфорки.</li>
            <li>Кальмарам придайте форму соломки.</li>
            <li>Нарежьте брусочками огурцы и яйца.</li>
            <li>Соедините в миске все ингредиенты. Полейте лимонным соком. Перемешайте с майонезом.</li>
          </ul>
          <p>Салат переложите на тарелку и посыпьте микрозеленью.</p>
          <h3>Салат с микрозеленью и помидорами</h3>
          <p>Этот рецепт идеально подходит для низкокалорийной диеты. На приготовление понадобится не более 15 минут.</p>
          <p><b>Потребуется:</b></p>
          <ul>
            <li>кальмары отваренные — 350 г;</li>
            <li>чеснок — 1 зубчик;</li>
            <li>помидоры — 200 г;</li>
            <li>сыр — 50 г;</li>
            <li>сметана — 70 мл;</li>
            <li>микрозелень — 70 г.</li>
          </ul>
          <p><b>Пошаговый процесс:</b></p>
          <ul>
            <li>Нарежьте соломкой кальмары. Чеснок пропустите через пресс.</li>
            <li>Сыр натрите на крупной терке.</li>
            <li>Помидоры понадобятся кубиками. Если мякоть слишком мягкая и сочная, то удалите ее. Салат не должен быть жидким.</li>
            <li>Соедините в миске подготовленные продукты.</li>
          </ul>
          <p>Заправляйте салат сметаной перед подачей на стол. Посыпьте обильно микрозеленью. Кстати, о том, как часто можно включать молодые ростки в рацион, рассказывали <Link to="/blog/vkljuchenie-mikrozeleni-v-racion-pitanija/">здесь</Link>.</p>
        </>
      ) 
    },
    {
      id: 'mikrozelen-dlja-pozilyh-ljudej-polza-i-recepty-bljud',
      name: 'Микрозелень: незаменимый помощник для здоровья пожилых людей',
      title: 'Микрозелень для пожилых людей: польза и рецепты блюд',
      descr:
        'Мы расскажем, в чем заключается польза микрозелени для организма пожилых людей, и поделимся рецептами блюд с микрозеленью.',
      bg: [Img37_1, ImgW37_1],
      date: '26.12.2023',
      content: (
        <>
          <p>
            <b>Правильное питание</b> — залог крепкого здоровья. Это особенно важно для людей старше
            60 лет. Для поддержания жизненной активности и укрепления иммунитета необходимо
            сбалансированное питание.
          </p>
          <p>
            <b>Микрозелень</b> — это полезные, молодые растения, которые содержат необходимый
            ежедневный для человека объем питательных веществ и витаминов. Поэтому их рекомендуют
            включать в ежедневный рацион людей старше 60 лет.
          </p>
          <Picture
            png={Img37_2}
            webp={ImgW37_2}
            alt="Микрозелень: незаменимый помощник для здоровья пожилых людей"
          />
          <h2>Что такое микрозелень</h2>
          <p>
            <b>Микрозелень</b> — это ростки, которые находятся на самой ранней стадии развития.
            Употребляют их в пищу через 7-14 дней после появления всходов. Поэтому микрогрин, по
            сравнению с взрослыми растениями, содержит в 30-50 раз больше питательных веществ.
          </p>
          <Picture png={Img37_3} webp={ImgW37_3} alt="Что такое микрозелень" />
          <p>
            <b>Молодые ростки содержат в большом количестве:</b>
          </p>
          <ul>
            <li>витамины: А, С, Е, К, группы В;</li>
            <li>фолиевую кислоту;</li>
            <li>минералы;</li>
            <li>антиоксиданты;</li>
            <li>каротиноиды.</li>
          </ul>
          <p>
            <b>Богатый состав микрозелени</b>, при ежедневном употреблении, помогает поддерживать
            здоровье пожилых людей.
          </p>
          <h2> Польза микрозелени для пожилых людей</h2>
          <Picture png={Img37_4} webp={ImgW37_4} alt="Польза микрозелени для пожилых людей" />
          <p>
            <b>Неправильное питание</b> приносит серьезный вред здоровью людей, особенно после
            шестидесятилетнего возраста. При ежедневном включении микрозелени в рацион, вы сможете
            поддержать здоровье сердца, оптимизировать работу пищеварения и укрепить иммунную
            систему.
          </p>
          <h3>Поддержка здоровья сердца</h3>
          <p>
            В состав микрозелени входит большое количество антиоксидантов: витамина С и
            бета-каротина.
          </p>
          <p>
            <b>Эти соединения помогают снижать:</b>
          </p>
          <ul>
            <li>
              уровень вредных свободных радикалов в организме, что способствует уменьшению риска
              развития сердечно-сосудистых заболеваний, которые часто встречаются у пожилых людей;
            </li>
            <li>
              риск развития сердечно-сосудистых заболеваний: атеросклероза, инфаркта и инсульта;
            </li>
            <li>
              уровень воспаления в сосудистой системе, что предотвращает образование тромбов и
              стабилизирует артериальное давление.
            </li>
          </ul>
          <p>
            Кроме того, микрозелень содержит фитохимикалии, которые полезны для здоровья глаз,
            снижают риск возникновения катаракты и других проблем со зрением.
          </p>
          <h3>Улучшение пищеварения</h3>
          <b>В состав микрозелени входят пищевые волокна, которые помогают:</b>
          <ul>
            <li>поддерживать здоровье пищеварительной системы;</li>
            <li>регулировать перистальтику кишечника;</li>
            <li>нормализовать стул;</li>
            <li>предотвращать запоры.</li>
          </ul>
          <p>
            Клетчатка служит пищей для полезных бактерий в кишечнике, что способствует оптимизации
            работы кишечной флоры. Также она защищает от развития различных заболеваний (например,
            от дивертикулеза и язвы желудка), улучшает иммунитет и помогает бороться с инфекциями.
          </p>
          <h3> Снижение риска развития хронических заболеваний</h3>
          <p>
            <b>У микрозелени</b> высокий уровень фитохимических соединений, оказывающих
            противовоспалительные и антикарциногенные свойства.
          </p>
          <p>
            <b>Эти вещества</b> предотвращают окислительные процессы в организме и защищают клетки
            от разрушительного воздействия свободных радикалов (агрессивных химических частиц,
            образующихся из-за метаболических процессов, воздействия ультрафиолетовых лучей,
            загрязнения воздуха и курения).
          </p>
          <p>
            <b>
              Антиоксиданты, входящие в состав микрогрина, особенно полезны для пожилых людей, так
              как помогают снижать риски развития:
            </b>
          </p>
          <ul>
            <li>рака;</li>
            <li>диабета;</li>
            <li>артрита;</li>
            <li>онкологических заболеваний;</li>
            <li>инфекций.</li>
          </ul>
          <p>
            Кроме того, антиоксиданты снижают уровень холестерина, уменьшают воспалительные процессы
            в теле и симптомы, связанные с ними.
          </p>
          <h3>Легкость усвоения</h3>
          <p>
            <b>Микрогрин</b> — молодые всходы растений, которые еще не развили полностью свои
            клетки. Они обладают нежной и мягкой текстурой, благодаря чему легко перевариваются
            организмом. Это особенно важно для пожилых людей с чувствительным желудком и проблемами
            пищеварения.
          </p>
          <p>
            <b>Благодаря своей легкой переваримости,</b> микрозелень мало нагружает
            желудочно-кишечный тракт и позволяет организму эффективно усваивать питательные
            вещества. Кроме того, в молодых ростках содержатся ферменты, которые помогают разлагать
            пищу и облегчают пищеварение.
          </p>
          <p>
            <b>Микрогрин содержит</b> все необходимые питательные вещества в конденсированной форме,
            что способствует их легкому усвоению организмом. Это важно для пожилых людей, у которых
            пищеварительная система менее эффективна.
          </p>
          <h2>Какие сорта микрогрина наиболее полезны для пожилых людей</h2>
          <Picture
            png={Img37_5}
            webp={ImgW37_5}
            alt="Какие сорта микрогрина наиболее полезны для пожилых людей"
          />
          <p>
            <b>Питание для людей старше 60 лет</b> должно быть сбалансированным, чтобы предотвратить
            развитие и свести к минимуму вероятность обострения хронических заболеваний. Вся
            микрозелень полезна для здоровья и помогает укреплять иммунитет, но у каждого вида
            существуют свои особенности и благоприятное действие на организм.
          </p>
          <p>
            <b>Вид микрозелени и его полезные свойства:</b>
          </p>
          <b>Петрушка:</b>
          <ul>
            <li>укрепляет иммунитет;</li>
            <li>замедляет процесс старения;</li>
            <li>улучшает остроту зрения и память;</li>
            <li>участвует в формировании мозговых клеток.</li>
          </ul>
          <b>Шпинат:</b>
          <ul>
            <li>ускоряет обменные процессы;</li>
            <li>выводит из организма шлаки и токсины;</li>
            <li>способствует формированию новых клеток;</li>
            <li>улучшает пищеварение;</li>
            <li>приводит в норму работу кишечника.</li>
          </ul>
          <b>Укроп:</b>
          <ul>
            <li>усиливает аппетит;</li>
            <li>стабилизирует артериальное давление;</li>
            <li>успокаивает нервную систему;</li>
            <li>улучшает состояние кожных покровов и зрения;</li>
            <li>укрепляет иммунитет.</li>
          </ul>
          <b>Сельдерей:</b>
          <ul>
            <li>повышает общий тонус организма;</li>
            <li>укрепляет нервную систему;</li>
            <li>благоприятно воздействует на мочеполовую систему;</li>
            <li>снимает воспалительные процессы;</li>
            <li>предотвращает накопление бляшек в сосудах;</li>
            <li>замедляет старение.</li>
          </ul>
          <b>Брокколи:</b>
          <ul>
            <li>укрепляет иммунную систему;</li>
            <li>улучшает зрение;</li>
            <li>снижает риск развития сердечно-сосудистых заболеваний;</li>
            <li>улучшает состояние сосудов.</li>
          </ul>
          <b>Горох:</b>
          <ul>
            <li>повышает уровень энергии;</li>
            <li>выводит токсины;</li>
            <li>улучшает пищеварение;</li>
            <li>нормализует давление;</li>
            <li>укрепляет сосуды;</li>
            <li>снижает уровень сахара в крови.</li>
          </ul>
          <b>Редис:</b>
          <ul>
            <li>снижает лишний вес;</li>
            <li>ускоряет переваривание пищи и метаболизм;</li>
            <li>повышает эластичность стенок сосудов;</li>
            <li>защищает от атеросклероза.</li>
          </ul>
          <b>Кресс-салат:</b>
          <ul>
            <li>улучшает аппетит и пищеварение;</li>
            <li>помогает пищеварению правильно работать;</li>
            <li>полезен при артрите, диабете, гипертонии.</li>
          </ul>
          <p>
            Чтобы получить максимум пользы для здоровья, употребляйте ежедневно микс сортов
            микрозелени.
          </p>
          <h2>Полезные рецепты</h2>
          <Picture png={Img37_6} webp={ImgW37_6} alt="Полезные рецепты" />
          <p>
            Питание пожилых людей должно быть не только сбалансированным, но и дробным. Врачи
            рекомендуют принимать пищу 4 раза в день. По возможности питайтесь ежедневно в одно и то
            же время.
          </p>
          <h3>Завтрак</h3>
          <p>
            У пожилых людей изменяется микрофлора кишечника, объем молочнокислых бактерий
            уменьшается, а гнилостных — увеличивается, что приводит к развитию разных хронических
            заболеваний. Поэтому диетологи рекомендуют начинать утро с полноценного завтрака.
            Хороший вариант — овсяная каша с микрозеленью. Она помогает расширять сосуды, убирать
            спазмы, стимулировать перистальтику кишечника, повышать желчевыделение и снижать уровень
            холестерина в крови.
          </p>
          <b>Для приготовления понадобится:</b>
          <ul>
            <li>овсяные хлопья — 3 ст. л.;</li>
            <li>яблоко — 150 г;</li>
            <li>курага — 50 г;</li>
            <li>орехи — 30 г (любые);</li>
            <li>микрозелень — 50 г;</li>
            <li>мед — 10 г.</li>
          </ul>
          <b>Процесс приготовления:</b>
          <ul>
            <li>Залейте овсяные хлопья горячей водой.</li>
            <li>Орехи, курагу и яблоко измельчите. Перемешайте с медом. Соедините с кашей.</li>
            <li>Посыпьте микрозеленью.</li>
          </ul>
          <p>Микрогрин добавляйте перед подачей на стол.</p>
          <h3>Обед</h3>
          <p>
            Обед обязательно должен включать суп. Врачи рекомендуют исключить жирные сорта птицы и
            мяса. Лучше заменять их рыбой. Отлично подойдет скумбрия, сардина, горбуша, семга. Если
            дважды в неделю будете включать рыбу в рацион питания, снизите риск развития
            сердечно-сосудистых заболеваний и укрепите иммунитет. Добавление в суп микрогрина не
            только сделает блюдо питательным, но и поможет полностью усвоить все полезные вещества.
          </p>
          <b>Для приготовления рыбного супа потребуется:</b>
          <ul>
            <li>консервированная горбуша — 250 г;</li>
            <li>картофель — 250 г;</li>
            <li>лук — 120 г;</li>
            <li>молоко — 230 мл;</li>
            <li>вода — 1,5 л;</li>
            <li>микрозелень — 30 г.</li>
          </ul>
          <b>Пошаговый процесс:</b>
          <ul>
            <li>Картофель нарежьте небольшими кубиками. Нашинкуйте луковицу.</li>
            <li>Доведите до кипения воду. Добавьте лук и картошку. Варите до готовности.</li>
            <li>
              Горбушу достаньте из банки. Вилкой или ножом разрежьте на мелкие части. Отправьте к
              овощам. При желании жидкость из консервной банки можете перелить в суп.
            </li>
            <li>Доведите до кипения и поварите 2 минуты.</li>
            <li>Добавьте молоко и специи по вкусу.</li>
            <li>Доведите до кипения. Варите пять минут. Отключите огонь.</li>
            <li>
              Кастрюлю накройте крышкой. Оставьте на 10 минут, чтобы вкус супа стал насыщеннее.
            </li>
            <li>Налейте порционно в тарелки. Посыпьте микрозеленью.</li>
          </ul>
          <p>
            Подавайте со сметаной и хлебцами. На второе можете приготовить запеченную в духовке рыбу
            с картошкой и овощной салат с микрозеленью. Больше полезных рецептов блюд найдете{' '}
            <Link to="/blog/ispolzovanie-mikrozeleni-dlja-prigotovlenija-vtoryh-bljud/">здесь</Link>
            .
          </p>
          <h3>Ужин</h3>
          <p>
            Наедаться на ночь врачи не советуют в любом возрасте. Людям, старше 60 лет рекомендуют
            готовить легкие блюда. Вкусный и питательный вариант — куриные шашлычки с брокколи и
            микрозеленью.
          </p>
          <b>Потребуется:</b>
          <ul>
            <li>куриная грудка — 450 г;</li>
            <li>лук — 180 г;</li>
            <li>чеснок — 2 зубчика;</li>
            <li>молотая паприка — 3 г;</li>
            <li>соль;</li>
            <li>микрогрин — 30 г;</li>
            <li>брокколи — 150 г.</li>
          </ul>
          <b>Пошаговый процесс:</b>
          <ul>
            <li>Мясо нарежьте порционными кусочками, лук — кольцами.</li>
            <li>Пропустите через пресс чеснок.</li>
            <li>Соедините подготовленные продукты. Посыпьте паприкой и солью.</li>
            <li>Оставьте на 2 часа мариноваться.</li>
            <li>За полчаса до начала приготовления замочите деревянные шпажки в холодной воде.</li>
            <li>Насадите грудку на шпажки, чередуя с кольцами лука.</li>
            <li>
              Заготовки положите на противень, который предварительно застелили пергаментной
              бумагой.
            </li>
            <li>Запекайте в разогретом духовом шкафу полчаса при температуре 200°С.</li>
            <li>В кастрюлю налейте воду. Вскипятите. Поместите брокколи. Поварите 5 минут.</li>
            <li>На тарелки порционно разложите шашлычки, брокколи и микрогрин.</li>
          </ul>
          <p>
            О том, как часто включать микрозелень в рацион, и кому противопоказаны молодые ростки,
            узнаете <Link to="/blog/vkljuchenie-mikrozeleni-v-racion-pitanija">здесь</Link>.
          </p>
        </>
      ),
    },
    {
      id: 'mikrozelen-dlja-muzcin-polza-dlja-organizma-i-recepty-bljud',
      name: 'Микрозелень: польза для мужского организма',
      title: 'Микрозелень для мужчин: польза для организма и рецепты блюд',
      descr:
        'Мы расскажем, в чем заключается польза микрозелени для организма мужчин, и в какие блюда ее можно добавлять.',
      bg: [Img36_1, ImgW36_1],
      date: '26.12.2023',
      content: (
        <>
          <p>
            <b>Микрозелень</b> — это молодые ростки различных растений, богатые минерами, витаминами
            и микроэлементами. Они помогают поддерживать здоровье мужчины при интенсивном образе
            жизни и регулярных физических нагрузках.
          </p>
          <p>
            Мы расскажем, в чем заключается польза микрозелени для организма, и в какие блюда ее
            можно добавлять.
          </p>
          <h2>В чем польза микрогрина для мужского организма</h2>
          <Picture
            png={Img36_2}
            webp={ImgW36_2}
            alt="В чем польза микрогрина для мужского организма"
          />
          <p>
            <b>Микрозелень</b> — это ростки в возрасте 7-14 дней. По сравнению со зрелыми растениями
            они содержат в несколько раз больше питательных веществ.
          </p>
          <p>
            <b>В состав входят:</b>
          </p>
          <ul>
            <li>
              Витамины (А, С, Е, К, группы В). Они укрепляют иммунную систему, улучшают состояние
              волос, кожи и глаз.
            </li>
            <li>Минералы (железо, цинк, магний). Поддерживают общее здоровье мужчин.</li>
            <li>
              Микроэлементы. Улучшают качество циркуляции крови в органах малого таза. Устраняют
              стресс и депрессивное настроение.
            </li>
            <li>
              Антиоксиданты. Защищают организм от свободных радикалов, предотвращая развитие
              различных заболеваний. Также антиоксиданты помогают снимать воспалительные процессы,
              сокращают риск возникновения сердечно-сосудистых болезней, предотвращают развитие
              рака.
            </li>
            <li>
              Клетчатка. Это необходимый компонент для здорового пищеварительного процесса, так как
              способствует правильному перевариванию пищи и поддержанию нормальной кишечной
              микрофлоры. Микрогрин снижает возможность возникновения запоров, помогает поддерживать
              оптимальный уровень холестерина.
            </li>
          </ul>
          <p>
            <b>Микрозелень помогает мужчинам </b> поддерживать необходимый уровень энергии и
            повышать спортивные показатели. Восстанавливает потраченные во время тренировки ресурсы,
            улучшает концентрацию и продлевает выносливость.
          </p>
          <p>
            <b>Молодые ростки помогают</b> увеличивать потенцию, нормализовать давление, улучшать
            репродуктивную функцию и качество семенной жидкости. Микрогрин повышает потенцию и
            либидо. Кроме того, он служит профилактикой развития простатита и повышает вероятность
            зачатия ребенка.
          </p>
          <p>
            <b>Регулярное употребление микрозелени:</b>
          </p>
          <ul>
            <li>приводит в норму работу мочеполовых органов;</li>
            <li>выводит токсины;</li>
            <li>улучшает сон;</li>
            <li>убирает изо рта неприятный запах;</li>
            <li>ускоряет метаболизм;</li>
            <li>помогает справляться с авитаминозом;</li>
            <li>понижает уровень сахара в крови;</li>
            <li>оказывает мочегонное действие;</li>
            <li>способствует снижению лишнего веса.</li>
          </ul>
          <p>
            <Link to="/blog/mikrozelen-dlja-ukreplenija-zdorovja/">Здесь</Link>
            мы более подробно рассказывали о том, как микрозелень помогает укреплять здоровье.
            Употреблять молодые ростки лучше всего в составе различных блюд.
          </p>
          <h2>Рецепты с микрозеленью для мужчин</h2>
          <Picture png={Img36_3} webp={ImgW36_3} alt="Рецепты с микрозеленью для мужчин" />
          <p>
            <b>Блюда для мужчин</b> должны быть питательными, полезными, сытными и содержать много
            белка, так как по сравнению с женщинами у них больше энергетические затраты. Предлагаем
            приготовить несколько вкусных блюд с микрозеленью, которые подойдут для ежедневного
            употребления.
          </p>
          <h3>Мясо тушеное с микрогрином</h3>
          <p>
            Во время тушения мясо пропитывается ароматом приправ и овощей. В качестве основного
            ингредиента можете использовать свинину, говядину или курицу.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>мясо — 500 г;</li>
            <li>лук — 230 г;</li>
            <li>чеснок — 3 зубчика;</li>
            <li>морковь — 350 г;</li>
            <li>специи для мяса — 5 г;</li>
            <li>растительное масло — 50 мл;</li>
            <li>соль;</li>
            <li>бульон — 200 мл (овощной или мясной);</li>
            <li>микрозелень — 70 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Нарежьте мясо на небольшие кусочки. Лук и чеснок измельчите, а морковь натрите на
              крупной терке.
            </li>
            <li>
              Прогрейте сотейник с растительным маслом на среднем огне. Поместите лук и чеснок,
              обжаривайте до мягкости и золотистости.
            </li>
            <li>
              Добавьте в сотейник нарезанное мясо. Обжарьте его до румяности со всех сторон.
              Посолите и поперчите по вкусу.
            </li>
            <li>
              Добавьте натертую морковь, перемешайте. Овощ должен равномерно соединиться с мясом.
              Посолите, посыпьте специями.
            </li>
            <li>
              Залейте бульоном и доведите до кипения. Уменьшите огонь. Накройте крышкой и тушите на
              минимальном режиме конфорки около 1,5-2 часов, пока мясо не станет мягким и сочным.
              Подавайте, посыпав микрозеленью.
            </li>
          </ul>
          <p>В качестве гарнира подавайте картофельное пюре или свежие овощи.</p>
          <h3>Мясо, запеченное с сыром и картофелем</h3>
          <p>
            Хрустящая сырная корочка идеально сочетается и дополняет вкус мяса. Блюдо получается
            сытным и полезным.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>свинина — 350 г;</li>
            <li>картофель — 700 г;</li>
            <li>соль;</li>
            <li>лук — 150 г;</li>
            <li>сыр — 120 г;</li>
            <li>микрозелень — 70 г;</li>
            <li>вода — 80 мл;</li>
            <li>молотый перец,</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Мясо нарежьте ломтиками и отбейте. Посолите, посыпьте перцем.</li>
            <li>Положите в блюдо для запекания.</li>
            <li>Нашинкуйте лук. Посыпьте мясо. Полейте водой.</li>
            <li>Картофель нарежьте тонкими ломтиками. Укройте мясо.</li>
            <li>Посыпьте солью и специями. Накройте фольгой.</li>
            <li>Отправьте в разогретый духовой шкаф. Запекайте при 200°С полчаса.</li>
            <li>Снимите фольгу. Посыпьте натертым сыром. Запекайте до румяной корочки.</li>
          </ul>
          <p>Нарежьте порционными кусочками. Разложите по тарелкам и посыпьте микрозеленью.</p>
          <h3>Куриные рулетики с сыром и микрозеленью</h3>
          <p>Вкусное блюдо, которое подойдет для обеда или ужина.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>куриного филе — 500 г;</li>
            <li>сыр — 100 г</li>
            <li>зелень (петрушка, базилик, укроп или смесь) — 50 г;</li>
            <li>микрозелень — 70 г;</li>
            <li>яйца — 2 шт.;</li>
            <li>сливки — 50 мл;</li>
            <li>соль и перец по вкусу.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Куриное филе нарежьте тонкими ломтиками.</li>
            <li>Сыр натрите на крупной терке. Зелень мелко нарежьте.</li>
            <li>
              Соедините яйца со сливками. Посолите, поперчите. Хорошо взбейте, чтобы смесь стала
              однородной.
            </li>
            <li>
              Разложите куриные пласты на рабочей поверхности. Присыпьте их натертым сыром и мелко
              нарезанной зеленью. Начните с одного конца и аккуратно сверните каждый пласт в
              рулетик.
            </li>
            <li>
              Разогрейте сковороду с растительным маслом на среднем огне. Обжарьте рулетики с двух
              сторон до золотистого цвета.
            </li>
            <li>
              Залейте в сковороду смесь из яиц и сливок. Поставьте на маленький огонь и готовьте еще
              около 5-7 минут до загустения сливочного соуса.
            </li>
            <li>Подавайте, украсив микрозеленью.</li>
          </ul>
          <p>В качестве гарнира подойдет отварной картофель, рис или свежий овощной салат.</p>
          <h3>Куриные крылышки с микрогрином</h3>
          <p>
            Быстрое блюдо, которое подходит для обеда и ужина. Крылышки можете заменить голенью или
            бедром.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>куриные крылышки — 750 г;</li>
            <li>майонез — 100 мл;</li>
            <li>вода;</li>
            <li>черный перец;</li>
            <li>чеснок — 3 зубка;</li>
            <li>микрогрин — 70 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Крылышки поместите в кастрюлю. Посыпьте солью и молотым черным перцем.</li>
            <li>Добавьте майонез и пропущенный через пресс чеснок. Перемешайте.</li>
            <li>Залейте водой. Жидкость должна покрыть крылышки наполовину. Перемешайте.</li>
            <li>Накройте кастрюлю крышкой.</li>
            <li>Тушите на минимальном режиме конфорки 1,5 часа.</li>
            <li>Подавайте с картофельным пюре, рисом или гречкой, посыпав микрогрином.</li>
          </ul>
          <p>
            Чтобы вкус блюда получился более насыщенным, можете добавить приправу карри и томатный
            соус.
          </p>
          <h3>Суп Харчо с микрозеленью</h3>
          <p>
            Суп Харчо — традиционное грузинское блюдо, известное своим ярким вкусом и ароматом.
            Микрозелень идеально сочетается со всеми используемыми в рецепте ингредиентами и делает
            обед более полезным. Предпочтение отдавайте мясной мякоти на кости. Идеально подойдет
            грудинка или ребра.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>говядина — 500 г;</li>
            <li>лук — 350 г;</li>
            <li>морковь — 250 г;</li>
            <li>перец болгарский — 250 г;</li>
            <li>чеснок — 3 зубчика;</li>
            <li>томатная паста — 60 мл;</li>
            <li>растительное масло — 50 мл;</li>
            <li>зира — 5 г;</li>
            <li>петрушка — 10 г;</li>
            <li>куркума — 2 г;</li>
            <li>молотый красный перец — 2 г;</li>
            <li>соль;</li>
            <li>кинза — 10 г;</li>
            <li>черный молотый перец;</li>
            <li>микрозелень — 50 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Говядину нарежьте небольшими кусочками. Лук и морковь очистите и мелко нарежьте.
              Удалите семена из перца. Мелко нарежьте болгарский перец и чили. Чеснок измельчите.
            </li>
            <li>
              В глубокой кастрюле или большой сковороде разогрейте растительное масло. Обжарьте лук
              до мягкости.
            </li>
            <li>
              Добавьте морковь, болгарский перец и чили, чеснок. Готовьте на среднем огне,
              периодически перемешивая, пока морковь не станет мягкой.
            </li>
            <li>Добавьте томатную пасту. Перемешайте. Готовьте 5 минут.</li>
            <li>Поджарьте говядину до румяной корочки. Отправьте к овощам.</li>
            <li>
              Посыпьте молотым красным перцем, куркумой и зирой. Посолите и поперчите по вкусу.
              Перемешайте.
            </li>
            <li>
              Добавьте в кастрюлю 1,5-2 литра воды. Жидкость должна полностью покрыть ингредиенты.
            </li>
            <li>
              Доведите до кипения. Включите режим конфорки на минимум. Потомите 1,5 часа. Мясо
              должно стать мягким.
            </li>
            <li>
              Подавайте суп Харчо, посыпав перед подачей микрозеленью, нашинкованной петрушкой и
              кинзой.
            </li>
          </ul>
          <p>Традиционно суп Харчо подают с грузинским хлебом.</p>
          <h3>Жульен с микрозеленью</h3>
          <p>
            Жульен — изысканное французское блюдо, которое завоевало популярность во многих странах.
            Микрозелень помогает сделать его вкус более приятным и насыщенным.
          </p>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>куриное филе — 500 г;</li>
            <li>шампиньоны — 200 г;</li>
            <li>лук — 170 г;</li>
            <li>сливочное масло — 2 ст. л.;</li>
            <li>мука — 2 ст. л.;</li>
            <li>молоко — 500 мл;</li>
            <li>перец;</li>
            <li>микрогрин — 50 г;</li>
            <li>соль;</li>
            <li>приправы (сушеный тимьян, петрушка);</li>
            <li>твердый сыр — 150 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Куриное филе нарежьте тонкими полосками, шампиньоны и луковицу — мелкими кубиками.
            </li>
            <li>
              Разогрейте сковороду на среднем огне. Добавьте сливочное масло. Обжарьте лук до
              прозрачности.
            </li>
            <li>
              Засыпьте шампиньоны. Посолите. Жарьте на среднем огне, периодически перемешивая до
              готовности. Переложите в отдельную емкость.
            </li>
            <li>Поместите в сковороду куриное филе. Посолите. Поперчите. Жарьте до готовности.</li>
            <li>
              В отдельной кастрюле приготовьте соус бешамель. Для этого растопите сливочное масло на
              среднем огне. Добавьте муку. Постоянно помешивая, готовьте, пока мука не приобретет
              золотистый оттенок. Добавьте молоко. Варите до загустения, постоянно перемешивая.
              Следите, чтобы не образовались комочки.
            </li>
            <li>Равномерно распределите шампиньоны по поверхности готового куриного филе.</li>
            <li>Посыпьте приправами. Полейте соусом бешамель.</li>
            <li>Сыр натрите на крупной терке. Посыпьте блюдо.</li>
            <li>
              Сковороду накройте крышкой. Готовьте на минимальном режиме конфорки 5-10 минут (пока
              сыр полностью не расплавится).
            </li>
            <li>Уберите со сковороды крышку. Посыпьте микрогрином и сразу подавайте на стол.</li>
          </ul>
          <p>
            Микрозелень — универсальный продукт. Он сочетается со всеми продуктами и улучшает их
            вкусовые качества. Какие закусочные торты с микрогрином можно приготовить для мужчин и
            всей семьи, узнаете{' '}
            <Link to="/blog/primenenie-mikrozeleni-v-prigotovlenii-zakusochnyh-tortov">здесь</Link>.
          </p>
        </>
      ),
    },
    {
      id: 'ispolzovanie-mikrozeleni-v-racione-pitanija-zhenshhin',
      name: 'Микрозелень в рационе питания женщин',
      title: 'Использование микрозелени в рационе питания женщин',
      descr:
        'Узнайте, как микрозелень может быть полезной для здоровья женщин, а также ознакомьтесь с различными рецептами, включающими этот ценный продукт для поддержания здорового рациона питания.',
      bg: [Img35_1, ImgW35_1],
      date: '03.12.2023',
      content: (
        <>
          <p>
            <b>Микрогрин</b> — молодое растение в фазе 1-2 листочков. Срезают его через неделю после
            появления всходов. Благодаря высокому содержанию витаминов, минералов и микроэлементов,
            микрозелень рекомендуют включать в ежедневный рацион питания женщин.
          </p>
          <h2>Что такое микрозелень и в чем ее польза</h2>
          <p>
            Микрозелень — молодые проростки растений, например, гороха, пшеницы, горчицы,
            кресс-салата. Некоторые люди употребляют их в качестве отдельного вида пищи, другие —
            дополняют микрогрином готовые блюда.
          </p>
          <p>
            Чтобы вырастить полезные, качественные ростки, используют семена высокого качества,
            которые не обработаны химией. <b>На фермах их:</b>
          </p>
          <ul>
            <li>высаживают в субстрат;</li>
            <li>автоматически в определенное время подают воду;</li>
            <li>подсвечивают фитолампами.</li>
          </ul>
          <p>
            <b>Микрогрин гораздо полезнее взрослых растений</b>, так как в нем сконцентрировано в
            10-20 раз больше минеральных элементов и витаминов. В пищу употребляют все части
            ростков: листья и стебли. Благодаря большому содержанию витамина С, зелень укрепляет
            иммунитет, ускоряет защитные реакции и участвует во всех процессах внутри организма.
          </p>
          <p>
            <b>Большинство видов микрогрина</b> обладает выраженным антиоксидантным действием,
            которое благоприятно воздействует на пищеварение. Кроме того, продукт содержит мало
            углеводов и калорий, поэтому идеально подходит для диетического питания.
          </p>
          <h2>Какие сорта микрогрина наиболее полезны для женщин</h2>
          <Picture
            png={Img35_2}
            webp={ImgW35_2}
            alt="Какие сорта микрогрина наиболее полезны для женщин"
          />
          <p>
            Питание должно быть разнообразным и сбалансированным. Микрозелень — продукт, который
            помогает поддерживать женское здоровье. Полезны все виды микрогрина, но особое внимание
            стоит обратить на ростки льна, брокколи, чиа и амаранта.
          </p>
          <h3>Амарант</h3>
          <p>
            <b>ООН признала амарант «культурой 21 века»</b>, так как ростки растения содержат
            большое количество:
          </p>
          <ul>
            <li>антиоксидантов;</li>
            <li>кальция, фолатов;</li>
            <li>каротиноидов;</li>
            <li>магния, витамина К.</li>
          </ul>
          <p>
            Поэтому молодые ростки растения укрепляют костную ткань и играют немаловажную роль в
            свертывании крови.
          </p>
          <p>
            Кроме того, микрогрин амаранта содержит фосполипиды, пектин и фитосеролы, которые
            помогают снижать в крови уровень холестерина. Входящий в состав антиоксидант сквален
            разрушает свободные радикалы и подавляет рост раковых клеток.
          </p>
          <h3>Чиа</h3>
          <p>
            <b>В состав микрогрина чиа</b> входят железо, Омега-3 и Омега-6 (полинасыщенные жирные
            кислоты), магний и кальций. Поэтому ростки растения поддерживают здоровье кожных
            покровов и способствуют укреплению сердечно-сосудистой системы.
          </p>
          <p>
            Триптофан (аминокислота) вырабатывает серотонин, который помогает успокаивать нервную
            систему.
          </p>
          <p>
            Если врач советует снизить вес, то микрогрин чиа поможет в этом. Большая часть
            клетчатки, которая содержится в растении — растворимая. Она помогает впитывать воду,
            расширяется в желудке. В результате пищеварение замедляется, а после еды вы ощущаете
            чувство сытости.
          </p>
          <h3>Брокколи</h3>
          <p>
            <b>Микрогрин брокколи</b> содержит уникальное соединение — сульфорафан. Оно оказывает
            противомикробное и противораковое действие. По сравнению с взрослым растением, ростки
            содержат сульфорафана в 20 раз больше.
          </p>
          <p>
            Зелень рекомендуют употреблять для профилактики роста стволовых клеток рака молочных
            желез. Это значит, что сульфорафан служит естественной защитой для женского организма от
            появления онкологии груди.
          </p>
          <p>
            <b>Также ростки содержат:</b>
          </p>
          <ul>
            <li>ферменты;</li>
            <li>витамины К, С;</li>
            <li>фолиевую кислоту.</li>
          </ul>
          <p>
            Микрогрин брокколи помогает выводить из женского организма холестерин, улучшать
            пищеварение, укреплять нервную и сердечно-сосудистую систему.
          </p>
          <h3>Маш</h3>
          <p>
            Входящие в состав белок и клетчатка помогают подавлять гормон голода. После употребления
            ростков, женщина чувствует насыщение и долго не ощущает голод, что помогает поддерживать
            вес и оставаться в тонусе.
          </p>
          <p>
            Ростки маша — натуральный антибиотик, который борется с зарождающимся и существующим
            воспалительным процессом, который вызван деятельностью патогенных бактерий. Регулярное
            употребление микрогрина во время эпидемии гриппа, поможет сохранить здоровье и хорошее
            самочувствие.
          </p>
          <h3>Лен</h3>
          <p>
            Полиненасыщенные кислоты, входящие в состав ростков, ведут борьбу с холестерином, что
            служит хорошей профилактикой атеросклероза. Пищевые волокна положительно влияют на
            работу кишечника, выводят токсины, оказывают легкое слабительное действие.
          </p>
          <p>
            Ежедневное употребление 30-50 г проростков льна, помогает восполнить суточную
            потребность женского организма в минералах и витаминах. Кстати, о других полезных сортах
            микрозелени мы рассказывали <Link to="/blog/luchshie-sorta-mikrozeleni/">здесь</Link>.
          </p>
          <h2>Полезные рецепты блюд с микрогрином</h2>
          <Picture png={Img35_3} webp={ImgW35_3} alt="Полезные рецепты блюд с микрогрином" />
          <p>
            <b>В кулинарии микрогрин</b> ценят за внешний вид и вкусовые качества. Микрозелень
            обладает специфическим концентрированным вкусом, добавляющим в привычные блюда
            неповторимые нотки. Предлагаем несколько рецептов, которые разнообразят питание женщин и
            сделают их рацион более полезным.
          </p>
          <h3>Салат из шпината</h3>
          <p>Блюдо поможет восполнить запасы железа, витаминов группы В и кальция.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>шпинат — 100 г;</li>
            <li>несоленая брынза — 50 г;</li>
            <li>грецкие орехи — 50 г;</li>
            <li>лимонный сок — 20 мл;</li>
            <li>соевый соус — 20 мл;</li>
            <li>чеснок — 1 зубчик;</li>
            <li>молотая паприка — 1 г;</li>
            <li>микрогрин — 30 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Листья шпината промойте и просушите. Порвите руками.</li>
            <li>Нарежьте кубиками брынзу.</li>
            <li>Измельчите в ступке или в кофемолке орехи.</li>
            <li>
              Соедините лимонный сок, пропущенный через пресс чеснок, соевый соус. Добавьте паприку
              и перемешайте.
            </li>
            <li>
              В салатник поместите орехи, шпинат, микрогрин и брынзу. Перемешайте. Полейте
              приготовленным соусом.
            </li>
          </ul>
          <p>
            Микрозелень можете использовать любого растения, которое нравится по вкусу. Салат хорошо
            сочетается с миксом сортов молодых ростков.
          </p>
          <h3>Томленая говядина с микрозеленью</h3>
          <p>Блюдо содержит необходимые витамины, цинк, фолиевую кислоту и железо.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>нежирная говядина — 500 г;</li>
            <li>соль;</li>
            <li>чернослив без косточек — 200 г;</li>
            <li>помидоры — 400 г;</li>
            <li>зелень (укроп, петрушка, кинза) — 70 г;</li>
            <li>болгарский перец — 250 г;</li>
            <li>лук — 170 г;</li>
            <li>растительное масло — 20 мл;</li>
            <li>морковь — 200 г;</li>
            <li>микрозелень — 70 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Говядину нарежьте небольшими кубиками. Переложите в кастрюлю.</li>
            <li>Залейте водой. Жидкость должна покрыть мясо.</li>
            <li>Включите минимальный режим конфорки и тушите 45 минут под закрытой крышкой.</li>
            <li>Нарежьте кубиками морковь и лук.</li>
            <li>Чернослив разрежьте пополам.</li>
            <li>
              В жаропрочную форму сложите слоями: измельченный лук, мясо, морковь, перец, помидоры,
              чернослив. Полейте растительным маслом. Каждый слой посолите по вкусу.
            </li>
            <li>
              Прогрейте духовой шкаф до 200°С. Поставьте подготовленную форму с продуктами. Готовьте
              четверть часа.
            </li>
            <li>Убавьте режим духовой печи до 160°С. Потомите до готовности.</li>
          </ul>
          <p>
            Подавайте блюдо в горячем виде. Перед подачей перемешайте, посыпьте измельченной зеленью
            и украсьте микрогрином.
          </p>
          <h3>Брокколи с микрозеленью и творожным соусом</h3>
          <p>
            Это отличный вариант для легкого ужина, который поможет насытить организм йодом,
            витаминами и кальцием.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>брокколи — 300 г;</li>
            <li>творог — 120 г;</li>
            <li>яблоко — 150 г;</li>
            <li>молоко — 30 мл;</li>
            <li>зеленый лук — 20 г;</li>
            <li>микрозелень — 50 г;</li>
            <li>лимонный сок — 20 мл;</li>
            <li>соль.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Налейте в кастрюлю воду. Доведите до кипения. Поместите брокколи. Варите 4 минуты.
            </li>
            <li>Очищенное от кожуры и семечек яблоко, натрите на мелкой терке.</li>
            <li>Порубите мелко лук.</li>
            <li>
              Творог разомните вилкой. Добавьте молоко. Перемешайте. Объем молока можете увеличивать
              или уменьшать. Творожный соус не должен быть слишком густым.
            </li>
            <li>Соедините приготовленный соус с яблоком и луком. Посолите.</li>
            <li>Брокколи перемешайте с микрозеленью. Полейте творожным соусом.</li>
          </ul>
          <p>Вместо молока в рецепте можете использовать сливки любой жирности.</p>
          <h3>Йогуртовый коктейль с микрозеленью</h3>
          <p>
            Коктейль восполнит недостаток витаминов и микроэлементов, укрепит здоровье. Это отличный
            вариант, чтобы утолить чувство голода между основными приемами пищи.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>натуральны йогурт — 250 мл;</li>
            <li>сушеная вишня — 20 г;</li>
            <li>миндаль — 20 г;</li>
            <li>мед — 20 г;</li>
            <li>микрогрин льна (можно заменить любым видом микрозелени) — 30 г;</li>
            <li>кунжут — 1 ч. л.;</li>
            <li>пшеничные отруби — 1 ч. л.;</li>
            <li>лимонный сок — 10 мл.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Мелко порубите ножом миндаль.</li>
            <li>В чашу блендера поместите йогурт, мед, орехи, микрозелень и вишню. Взбейте.</li>
            <li>Добавьте кунжут, лимонный сок и отруби. Перемешайте.</li>
          </ul>
          <p>Сушеную вишню в рецепте можете заменить любыми свежими ягодами или сухофруктами.</p>
        </>
      ),
    },
    {
      id: 'primenenie-mikrozeleni-v-prigotovlenii-zakusochnyh-tortov',
      name: 'Закусочные торты с микрозеленью',
      title: 'Применение микрозелени в приготовлении закусочных тортов',
      descr:
        'Готовим вкусные закусочные торты с добавлением микрозелени: торт из шпинатных блинов, печёночный торт, блинный торт, кабачковый торт и т.д.',
      bg: [Img34_1, ImgW34_1],
      date: '03.12.2023',
      content: (
        <>
          <p>
            Чтобы быть здоровыми, надо <b>получать от пищи максимум</b>, правильно и красиво
            питаться. <p>Для этого следует включать в рацион «суперфуды»</p> — продукты, содержащие
            большое количество витаминов, минералов и других питательных элементов.
          </p>
          <p>
            <b>К таким «суперфудам»</b> относится микрогрин.
          </p>
          <h2>Зачем включать микрозелень в рацион питания</h2>
          <p>
            <b>Микрогрин</b>— кладезь микроэлементов и витаминов. Чем меньше размер ростков, тем
            больше в них полезных веществ. Больше всего питательных элементов растение содержит на
            старте роста (первые 7-14 дней после появления всходов).
          </p>
          <p>
            <b>Кроме того</b> за это время микрозелень не успевает впитать вредные элементы из
            окружающей среды. Это экологически чистый продукт, который оказывает максимально
            возможное положительное действие на организм.
          </p>
          <p>
            <b>Основной эффект</b>, за который микрогрин ценят поклонники здорового питания —
            антиоксидантный. Это значит, что продукт помогает выводить из организма свободные
            радикалы, повреждающие оболочку клеток ДНК и составляющие хряща. Входящие в состав
            полифенолы, служат профилактикой развития сердечно-сосудистых заболеваний. Более
            подробно о том, какие полезные вещества содержит микрозелень, мы рассказывали{' '}
            <Link to="/blog/sostav-mikrozeleni-vitaminy-himicheskij-sostav-i-poleznye-veshhestva">
              здесь
            </Link>
            .
          </p>
          <h2>Рецепты закусочных тортов</h2>
          <p>
            <b>Предлагаем несколько</b> полезных, витаминных рецептов закусочных тортов с
            микрозеленью, которые помогут разнообразить праздничное меню, послужат хорошим перекусом
            или заменят ужин.
          </p>
          <Picture png={Img34_2} webp={ImgW34_2} alt="Рецепты закусочных тортов" />
          <p>
            Микрогрин используйте <b>любого сорта</b>, главное, чтобы он нравился вам по вкусу.
            Также подходит микс микрозелени. В этом случае вы насытите организм большим количеством
            питательных элементов, так как каждое растение содержит разные микроэлементы, минералы и
            витамины.
          </p>
          <h3>Закусочный торт Наполеон</h3>
          <p>
            Закусочный торт Наполеон настолько вкусный, что составит конкуренцию всем салатам. При
            добавлении микрозелени, блюдо станет полезным и эффектно оформленным.
          </p>
          <b>Потребуется:</b>
          <ul>
            <li>слоеное бездрожжевое тесто — 500 г;</li>
            <li>зеленый лук — 3 стебля;</li>
            <li>копченая паприка;</li>
            <li>яйца — 6 шт.;</li>
            <li>подкопченный лосось — 30 г;</li>
            <li>чеснок — 2 зубка;</li>
            <li>икра мойвы — 180 г;</li>
            <li>укроп — 30 г;</li>
            <li>сливочное масло — 10 г;</li>
            <li>микрозелень — 50 г;</li>
            <li>сливочный плавленый сыр — 400 г;</li>
            <li>майонез (по вкусу).</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Достаньте замороженное тесто из упаковки. Поместите на противень, застеленный
              пергаментной бумагой. Тесто размораживать не надо.
            </li>
            <li>
              Поставьте в холодный духовой шкаф. Установите температуру 200°С. Дождитесь, чтобы
              тесто поднялось в высоту и пропеклось. Готовые коржи должны легко разбираться на
              тонкие слои.
            </li>
            <li>
              Укроп мелко нашинкуйте. Пропустите через пресс чесночные зубчики. Соедините с 260
              граммами сливочного плавленого сыра.
            </li>
            <li>
              Измельчите подкопченный лосось. Соедините с икрой и 140 граммами сливочного плавленого
              сыра. Перемешайте.
            </li>
            <li>Две приготовленные начинки накройте пищевой пленкой и поставьте в холодильник.</li>
            <li>Отварите яйца. Очистите. Желтки оставьте для украшения. Белки разомните вилкой.</li>
            <li>
              Мелко порубите зеленый лук и обжарьте в течение двух минут в сливочном масле.
              Остудите.
            </li>
            <li>Соедините жареный лук с белком. Добавьте майонез и перемешайте.</li>
            <li>Слоеные коржи разделите на слои. Промажьте разными начинками.</li>
            <li>Соберите торт.</li>
            <li>Уберите в холодильное отделение на 4 часа.</li>
          </ul>
          <p>
            Перед подачей на стол посыпьте раскрошенными желтками, посыпьте паприкой и украсьте
            микрозеленью.
          </p>
          <h3> Торт из шпинатных блинов микрозеленью</h3>
          <p>
            Торт покорит вас своим красим цветом и приятным ароматом. Блюдо получается полезным,
            насыщает организм витаминами и помогает противостоять простудным заболеваниям.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>яйца — 3 шт.;</li>
            <li>специи;</li>
            <li>мука — 280 г;</li>
            <li>молоко — 380 мл;</li>
            <li>подсолнечное масло — 50 мл;</li>
            <li>шампиньоны свежие — 250 г;</li>
            <li>шпинат — 80 г;</li>
            <li>вода — 250 мл;</li>
            <li>лук — 180 г;</li>
            <li>соль — 5 г;</li>
            <li>сливки — 380 мл;</li>
            <li>оливковое масло — 20 мл;</li>
            <li>сахар — 0,5 ч. л.;</li>
            <li>микрогрин — 70 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Вскипятите воду. Опустите шпинат. Варите 7 минут. Взбейте блендером.</li>
            <li>Молоко соедините с яйцами, солью, сахаром, 250 г муки. Взбейте миксером.</li>
            <li>
              Соедините тесто с шпинатной смесью и оливковым маслом. Перемешайте. Тесто должно
              получиться однородным.
            </li>
            <li>Приготовьте блины на сковороде обычным способом.</li>
            <li>
              Для соуса поджарьте шампиньона на подсолнечном масле. Добавьте измельченную луковицу.
              Посолите. Соедините с 30 г муки. Обжарьте.
            </li>
            <li>
              Посыпьте специями. Добавьте сливки. Тщательно перемешайте, чтобы не успели
              образоваться комки. Соус должен быть в меру густым.
            </li>
            <li>
              Пропитайте шпинатные блины приготовленным соусом и соберите торт. Украсьте
              микрозеленью перед подачей на стол.
            </li>
          </ul>
          <p>
            В прослойку между коржами можете добавить натертый сыр или поджаренные кусочки мяса. Это
            сделает блюдо более сытным и насыщенным по вкусу.
          </p>
          <h3> Печеночный торт</h3>
          <p>
            Печеночный торт — не десерт, а полезное, сытное, закусочное блюдо. При добавлении
            микрозелени приобретает оригинальный вкус.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>куриная печень — 600 г;</li>
            <li>молотый черный перец;</li>
            <li>мука — 60 г;</li>
            <li>яйцо — 1 крупное;</li>
            <li>растительное масло;</li>
            <li>сметана — 100 мл;</li>
            <li>лук — 270 г;</li>
            <li>соль;</li>
            <li>морковь — 370 г;</li>
            <li>майонез — 150 мл;</li>
            <li>петрушка — 30 г;</li>
            <li>чеснок — 2 зубка;</li>
            <li>микрозелень — 50 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Лук нарежьте полукольцами. Морковь натрите на крупной терке.</li>
            <li>
              Поджарьте овощи в сковороде на растительном масле. Лук и морковь должны стать мягкими.
              Посолите, добавьте молотый перец. Перемешайте.
            </li>
            <li>
              Промойте куриную печень, удалите пленки и протоки. Пропустите через мясорубку.
              Соедините с яйцом.
            </li>
            <li>Добавьте в печень сметану. Посолите, поперчите. Перемешайте.</li>
            <li>
              Засыпьте в печеночную массу муку. Перемешивайте до однородности. Комков остаться не
              должно. По консистенции тесто надо как на оладьи.
            </li>
            <li>Поджарьте на скороде печеночные блины.</li>
            <li>Чесночные зубчики пропустите через пресс и соедините с майонезом.</li>
            <li>
              Соберите торт, промазывая каждый блин майонезом и выкладывая между печеночными коржами
              обжаренные овощи.
            </li>
            <li>
              Верхний слой также промажьте майонезом и распределите овощи. Посыпьте измельченной
              петрушкой. Поставьте в холодильник на 3 часа.
            </li>
          </ul>
          <p>Перед подачей на стол украсьте микрозеленью.</p>
          <h3>Блинный торт с семгой и микрогрином</h3>
          <p>
            Блины приготовьте по своему любимому рецепту на молоке или воде. Семгу используйте
            малосоленую готовую или как предложено в рецепте, возьмите сырую рыбу и самостоятельно
            засолите ее.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>блины — 9 шт.;</li>
            <li>микрогрин — 50 г;</li>
            <li>семга — 500 г;</li>
            <li>черный перец;</li>
            <li>майонез — 80 мл;</li>
            <li>российский сыр — 180 г;</li>
            <li>сахар — 0,5 ст. л. (для рыбы);</li>
            <li>сметана — 80 мл;</li>
            <li>вареные яйца — 3 шт.;</li>
            <li>соль — 1 ст. л. без горки (для рыбы);</li>
            <li>сливочное масло — 100 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Посыпьте рыбу сахаром, перцем и солью. Равномерно распределите по всей поверхности.
              Оставьте на сутки.
            </li>
            <li>Сметану соедините с майонезом.</li>
            <li>Соберите торт, промазывая каждый блин майонезно-сметанным соусом.</li>
            <li>Сверху первого слоя натрите на терке сливочное масло, укройте ломтиком семги.</li>
            <li>Второй слой посыпьте натертым яйцом.</li>
            <li>В третьем слое на соус натрите сыр и сливочное масло.</li>
            <li>Повторите процесс 2-3 раза.</li>
            <li>Сверху торт украсьте розочками из семги и микрозеленью.</li>
          </ul>
          <p>Подавать торт можете сразу после приготовления.</p>
          <h3>Закусочный кабачковый торт с микрогрином</h3>
          <p>
            Лучше всего для приготовления подходит молодой кабачок с тонкой, мягкой шкуркой. Если у
            вас зрелый овощ, то предварительно срежьте кожуру.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>кабачок — 900 г;</li>
            <li>микрогрин — 50 г;</li>
            <li>яйца — 3 шт.;</li>
            <li>мука — 160 г;</li>
            <li>майонез — 2050 мл;</li>
            <li>сода — 2 щепотки;</li>
            <li>укроп, петрушка — 30 г;</li>
            <li>свежие шампиньоны — 250 г;</li>
            <li>лук — 300 г;</li>
            <li>помидоры — 300 г;</li>
            <li>чеснок — 3 зубка;</li>
            <li>полоски бекона — 5 шт.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Натрите кабачок на крупной терке. Отожмите сок.</li>
            <li>Добавьте яйца. Посолите и поперчите. Перемешайте.</li>
            <li>Добавьте муку и соду. Тщательно перемешайте, чтобы не образовались комочки.</li>
            <li>
              Поджарьте на сковороде 4 толстых лепешки. Чтобы они хорошо пропеклись внутри, готовьте
              на минимальном режиме конфорки под крышкой.
            </li>
            <li>Лук нашинкуйте. Измельчите шампиньоны.</li>
            <li>
              Поджарьте лук до золотистого цвета. Добавьте шампиньоны. Потомите на среднем огне,
              пока жидкость полностью не испарится.
            </li>
            <li>Помидоры нарежьте кружочками. Обжарьте с двух сторон.</li>
            <li>
              Соедините майонез с пропущенным через пресс чесноком, измельченной зеленью.
              Перемешайте.
            </li>
            <li>Бекон поджарьте на сухой сковороде с двух сторон.</li>
            <li>Остудите кабачковые лепешки.</li>
            <li>Первый корж промажьте майонезным соусом. Укройте половиной грибов</li>
            <li>Накройте кабачковым коржом. Промажьте майонезом, распределите помидоры.</li>
            <li>
              Третью лепешку смажьте майонезным соусом и укройте грибами. Накройте оставшимся
              коржом.
            </li>
            <li>
              Равномерно распределите майонез по всей поверхности торта. Края укройте беконом.
              Оставьте в холодильнике на 2 часа.
            </li>
          </ul>
          <p>Украсьте торт микрогрином и подавайте на стол.</p>
          <h3>Рыбный торт с микрогрином</h3>
          <p>
            Такой вариант торта мало кто пробовал, поэтому вы сможете не только вкусно накормить
            гостей, но привести их в восторг незабываемым вкусом. В рецепте используют рыбу минтай,
            но можете заменить ее на любую другую, которую любят в вашей семье.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>филе минтая — 400 г;</li>
            <li>черный перец;</li>
            <li>яйца — 4 шт.;</li>
            <li>укроп — 30 г;</li>
            <li>сливочное масло — 110 г;</li>
            <li>твердый сыр — 250 г;</li>
            <li>мука — 100 г;</li>
            <li>сок лимона;</li>
            <li>творог — 220 г;</li>
            <li>микрогрин — 30 г;</li>
            <li>зеленый лук — 30 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Нарежьте филе небольшими кубиками. Посыпьте солью. Сбрызните соком лимона. Добавьте
              измельченный укроп. Оставьте на полчаса промариноваться.
            </li>
            <li>
              Желтки соедините с размягченным маслом, мукой, творогом, перцем и солью. Добавьте
              мелко порубленный зеленый лук.
            </li>
            <li>Взбейте белки до пышной пены.</li>
            <li>Половину сыра натрите на крупной терке. Соедините с рыбой и белками.</li>
            <li>Форму для выпечки смажьте маслом. Распределите тесто.</li>
            <li>Укройте рыбной массой.</li>
            <li>Нарежьте оставшийся сыр на тонкие пластины. Равномерно укройте торт.</li>
            <li>Разогрейте духовой шкаф до 220°С. Запекайте торт полчаса.</li>
            <li>Духовой шкаф выключите, дверку не открывайте. Оставьте торт на четверть часа.</li>
            <li>
              Достаньте закуску из духовки. Переложите на красивое блюдо. Обильно украсьте
              микрогрином.
            </li>
          </ul>
          <p>
            Микрозелень — универсальный продукт, который сочетается со всеми ингредиентами. О том,
            как приготовить блюда с микрогрином и тыквой мы рассказывали{' '}
            <Link to="/blog/Mikrozelen-luchshie-recepty-s-tykvoj/">здесь</Link>.
          </p>
        </>
      ),
    },
    {
      id: 'Mikrozelen-luchshie-recepty-s-tykvoj',
      name: 'Рецепты блюд с микрозеленью и тыквой',
      title: 'Микрозелень: лучшие рецепты с тыквой',
      descr: 'Применение микрозелени в кулинарии. Подборка рецептов блюд с тыквой и микрозеленью.',
      bg: [Img33_1, ImgW33_1],
      content: (
        <>
          <p>
            <b>Микрозелень</b> — съедобные растения, выращенные до стадии юных проростков. Они
            обладают высоким уровнем питательных веществ, добавляют интересный вкусовой и
            эстетический акцент в блюда.
          </p>
          <p>
            <b>В статье вы узнаете</b>, как приготовить полезные, овощные блюда из тыквы и
            микрогрина.
          </p>
          <Picture png={Img33_2} webp={ImgW33_2} alt="Рецепты блюд с микрозеленью и тыквой" />
          <h2>Что такое микрозелень?</h2>
          <Picture png={Img33_3} webp={ImgW33_3} alt="Что такое микрозелень?" />
          <p>
            <b>Микрозелень</b> — это молодые проростки съедобных растений, которые собирают до
            полного развития. <b>Чаще всего выращивают:</b>
          </p>
          <ul>
            <li>злаки;</li>
            <li>травы;</li>
            <li>овощи;</li>
            <li>листовые культуры.</li>
          </ul>

          <p>
            Их проращивают в специальных контейнерах, в которых соблюдают оптимальные условия для
            роста. Это дает возможность получать микрозелень с более высоким содержанием питательных
            веществ по сравнению с взрослыми растениями.
          </p>
          <h2>Процесс выращивания микрозелени</h2>
          <p>
            <b>Процесс выращивания микрозелени</b> занимает от 3-5 дней до нескольких недель и
            зависит от типа растения. Главный этап — проращивание семян. Их высеивают на
            специальную, предварительно подготовленную почву или подложку, а затем увлажняют.
          </p>
          <p>
            <b>Растения растут под оптимальными условиями, такими как правильная:</b>
          </p>
          <ul>
            <li>температура;</li>
            <li>освещение;</li>
            <li>влажность.</li>
          </ul>
          <p>
            <b>По мере роста</b> микрозелень регулярно поливают, чтобы поддержать оптимальный
            уровень влажности. Когда ростки достигают определенной стадии, их срезают и используют в
            кулинарии.
          </p>
          <h2>Применение микрозелени в кулинарии</h2>
          <p>
            <b>Микрозелень</b> идеально сочетается с различными блюдами. Добавляет им не только
            красивый внешний вид, но и новые вкусовые качества.
          </p>
          <Picture png={Img33_4} webp={ImgW33_4} alt="Применение микрозелени в кулинарии" />
          <p>
            <b>Микрогрин используют в:</b>
          </p>
          <ul>
            <li>
              Супах и соусах. Микрозелень придает им свежесть и аромат. Служит мягким гарниром для
              супов.
            </li>
            <li>
              Сэндвичи и булки. Ростки добавляют для усиления вкуса и дополнительного питательного
              баланса.
            </li>
            <li>
              Салаты. Микрозелень наполняет их большим количеством витаминов и полезных
              микроэлементов.
            </li>
            <li>Пицца. Ростки добавляют нежный вкус и яркий внешний вид.</li>
            <li>
              Коктейли и напитки. Микрогрин используют для ароматизации и питательной ценности.
            </li>
          </ul>
          <p>
            <b>Сочетание вкусовых и эстетических качеств,</b> делает микрозелень востребованной в
            кулинарии. Благодаря своей универсальности, микрогрин предоставляет бесчисленные
            возможности для творчества и вдохновляет поваров и гурманов на поиск новых способов
            использования полезных ростков в разных блюдах.
          </p>
          <h2>Рецепты блюд из тыквы и микрозелени</h2>
          <p>
            <b>Тыква</b> — яркий и полезный осенний овощ, из которого готовят большое количество
            вкусных блюд. При добавлении микрогрина, они становятся намного полезнее, укрепляют
            иммунную систему и помогают организму противостоять вирусным заболеваниям.
          </p>
          <Picture png={Img33_5} webp={ImgW33_5} alt="Рецепты блюд из тыквы и микрозелени" />
          <h3>Тыквенный суп с кокосовым молоком</h3>
          <p>
            Если у вас нет лайма, замените его лимоном. Вместо овощного бульона можете использовать
            обычную воду.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>тыква — 1 средняя;</li>
            <li>лук — 150 г;</li>
            <li>чеснок — 2 зубка;</li>
            <li>кокосовое молоко — 400 мл;</li>
            <li>овощной бульон — 500 мл;</li>
            <li>куркума — 3 г;</li>
            <li>оливковое масло — 20 мл;</li>
            <li>имбирь — 3 г;</li>
            <li>соль;</li>
            <li>черный перец;</li>
            <li>сок из половинки лайма;</li>
            <li>микрозелень — 30 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Тыкву очистите от кожуры и семян, мякоть нарежьте на кубики. Луковицу и чеснок мелко
              измельчите.
            </li>
            <li>
              В большой кастрюле разогрейте оливковое масло, добавьте лук и чеснок. Обжарьте на
              среднем огне до мягкости и золотистости.
            </li>
            <li>
              Добавьте нарезанную тыкву. Пассеруйте овощи на среднем огне в течение 5-7 минут,
              периодически помешивая.
            </li>
            <li>Добавьте куркуму и имбирь, тщательно перемешайте.</li>
            <li>
              Влейте овощной бульон и кокосовое молоко. Включите максимальный режим конфорки.
              Доведите до кипения.
            </li>
            <li>
              Уменьшите режим конфорки и варите на среднем огне примерно 15-20 минут, пока тыква
              полностью не размягчится.
            </li>
            <li>
              Снимите суп с огня и немного охладите. Измельчите при помощи погружного блендера.
              Масса должна стать однородной по консистенции.
            </li>
            <li>
              Добавьте соль, перец и сок половины лайма. Перемешайте и разогрейте на среднем огне.
            </li>
          </ul>
          <p>
            Подавайте тыквенный суп с кокосовым молоком в глубоких тарелках, украсив микрозеленью.
            Чтобы придать блюду дополнительную свежесть, можете в каждую порцию добавить дольку
            лайма.
          </p>
          <h3>Тыквенные оладьи</h3>
          <p>
            Тыквенные оладьи — идеальный завтрак для всей семьи. Благодаря добавлению специй,
            получаются ароматными и напоминают рождественскую выпечку.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>мука — 320 г;</li>
            <li>тыква — 1 стакан пюре;</li>
            <li>яйцо — 1 шт.;</li>
            <li>разрыхлитель — 5 г;</li>
            <li>сахар — 20 г;</li>
            <li>молотый кориандр — 5 г;</li>
            <li>молотый имбирь — 3 г;</li>
            <li>корица — 5 г;</li>
            <li>соль — 2 г;</li>
            <li>сметана — 100 мл;</li>
            <li>микрогрин — 20 г;</li>
            <li>растительное или оливковое масло (для жарки).</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>В большой миске смешайте муку, разрыхлитель, сахар, специи и соль.</li>
            <li>Отдельно соедините тыквенное пюре и яйцо. Перемешайте до однородности.</li>
            <li>
              Взбивая блендером, постепенно добавляйте сухие ингредиенты. Тесто должно стать
              однородным.
            </li>
            <li>Разогрейте сковороду на среднем огне и добавьте немного масла.</li>
            <li>
              Столовой ложкой выкладывайте порциями тесто на сковороду, формируя оладьи диаметром
              около 7 см.
            </li>
            <li>
              Поджарьте до золотисто-коричневой корочки, примерно по 2-3 минуты с каждой стороны.
            </li>
            <li>Чтобы удалить лишнее масло, переложите готовые оладьи на бумажное полотенце.</li>
            <li>Измельчите микрозелень и соедините со сметаной.</li>
          </ul>
          <p>Теплые оладьи разложите по тарелкам. Полейте сметанной, перемешанной с микрогрином.</p>
          <h3>Суп-пюре</h3>
          <p>Для приготовления супа-пюре используют свежую и замороженную тыкву.</p>
          <p>
            <b>Необходимые ингредиенты:</b>
          </p>
          <ul>
            <li>тыква — 1 кг;</li>
            <li>лук — 250 г;</li>
            <li>овощной бульон — 1 л;</li>
            <li>чеснок — 2 зубчика;</li>
            <li>сливочное масло — 40 г;</li>
            <li>жирные сливки — 170 мл;</li>
            <li>зира — 1 г;</li>
            <li>микрозелень — 25 г;</li>
            <li>соль.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Тыкву нарежьте кубиками.</li>
            <li>
              Лук нашинкуйте. Сливочное масло растопите в сотейнике. Добавьте лук. Жарьте до
              мягкости.
            </li>
            <li>
              Добавьте мелко порубленные зубчики чеснока. Потомите на минимальном огне 1 минуту.
            </li>
            <li>
              В кастрюлю поместите тыкву. Залейте овощным бульоном. Варите на среднем огне до
              готовности.
            </li>
            <li>Добавьте лук с чесноком, зиру. Посолите.</li>
            <li>Влейте сливки. На среднем огне прогрейте, но не кипятите.</li>
          </ul>
          <p>
            Налейте суп в тарелки. Перед подачей посыпьте микрозеленью. Больше рецептов
            приготовления супов-пюре с микрогрином найдете{' '}
            <Link to="/blog/kak-prigotovit-sup-pjure-s-mikrozelenju">здесь</Link>.
          </p>
          <h3>Салат из запеченной тыквы с ветчиной</h3>
          <p>Рецепт легкого салата, который идеально подойдет в качестве перекуса.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>тыква — 1 небольшая;</li>
            <li>ветчина — 200 г;</li>
            <li>огурец — 120 г;</li>
            <li>листья салата — 50 г;</li>
            <li>микрогрин — 50 г;</li>
            <li>грецкие орехи — 50 г;</li>
            <li>оливковое масло — 30 мл;</li>
            <li>горчица — 10 г</li>
            <li>бальзамический уксус — 30 мл;</li>
            <li>перец, соль.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Предварительно разогрейте духовку до 200°С.</li>
            <li>Тыкву очистите от кожуры, удалите семена и нарежьте кубиками.</li>
            <li>
              Противень застелите пергаментной бумагой. Выложите кубики тыквы, посыпьте солью и
              перцем.
            </li>
            <li>Запекайте в духовке примерно 30 минут, пока она не станет мягкой и золотистой.</li>
            <li>Нарежьте ветчину и огурцы кубиками. Порвите руками листья салата.</li>
            <li>Измельчите грецкие орехи.</li>
            <li>Для соуса смешайте оливковое масло, горчицу и бальзамический уксус.</li>
            <li>
              В большой миске соедините запеченную тыкву, ветчину, огурцы и салат. Полейте соусом и
              посыпьте грецкими орехами. Перемешайте.
            </li>
            <li>Посыпьте микрозеленью.</li>
          </ul>
          <p>Подавайте салат сразу после приготовления.</p>
          <h3>Рецепт курицы с тыквой, грецкими орехами и микрозеленью</h3>
          <p>Курицу в рецепте при желании можете заменить индейкой.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>куриные грудки — 450 г;</li>
            <li>тыква — 500 г;</li>
            <li>грецкие орехи — 130 г;</li>
            <li>мед — 40 г;</li>
            <li>соевый соус — 50 мл;</li>
            <li>микрогрин — 50 г;</li>
            <li>оливковое масло — 50 мл;</li>
            <li>рисовый уксус — 20 мл;</li>
            <li>соль, перец.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Нарежьте куриную грудку на кусочки среднего размера.</li>
            <li>Тыкву очистите от кожуры, удалите семена и нарежьте на кубики.</li>
            <li>Разрежьте грецкие орехи на половинки.</li>
            <li>
              В большой миске соедините мед, соевый соус, оливковое масло, рисовый уксус, соль и
              перец.
            </li>
            <li>
              Добавьте куриную грудку в миску и хорошо перемешайте, чтобы мясо равномерно
              пропиталось соусом. Оставьте на 10-15 минут.
            </li>
            <li>
              Разогрейте сковороду на среднем огне и обжарьте куриную грудку до золотистой корочки и
              полной готовности.
            </li>
            <li>
              Добавьте в сковороду нарезанную тыкву и грецкие орехи. Готовьте 5-7 минут, пока тыква
              не станет мягкой, а орехи не приобретут глазированный вид.
            </li>
          </ul>
          <p>
            Подавайте блюдо в горячем виде, посыпав микрогрином. В качестве гарнира можете
            использовать рис.
          </p>
          <h3>Тыква, фаршированная рисом и сухофруктами</h3>
          <p>
            Тыква, фаршированная рисом — эффектное блюдо, которое украсит любой праздничный стол и
            поднимет настроение в будний день своими яркими красками. В качестве сухофруктов можете
            использовать курагу, чернослив, изюм или их смесь.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>тыква — 1 небольшая;</li>
            <li>рис — 1 стакан;</li>
            <li>сухофрукты — 100 г;</li>
            <li>лук — 150 г;</li>
            <li>чеснок — 2 зубка;</li>
            <li>микрозелень — 40 г;</li>
            <li>оливковое масло — 50 мл;</li>
            <li>соль, перец.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Тыкву разрежьте пополам поперек. Удалите семена.</li>
            <li>Разогрейте духовку до 200°С.</li>
            <li>
              Противень застелите пергаментной бумагой. Выложите половинки тыквы. Посыпьте солью и
              перцем.
            </li>
            <li>
              Запекайте в духовой печи примерно 40-50 минут, пока она не станет мягкой и золотистой.
            </li>
            <li>Пока тыква запекается, приготовьте начинку. Рис отварите до готовности.</li>
            <li>
              Нашинкованный лук и измельченный чеснок поджарьте на сковороде на среднем огне в масле
              до золотистости.
            </li>
            <li>Добавьте сухофрукты. Перемешайте и потомите 2 минуты на минимальном огне.</li>
            <li>Готовый рис соедините с сухофруктами и луком.</li>
            <li>Достаньте готовую тыкву из духовой печи. Наполните начинкой.</li>
            <li>Верните тыкву в духовую печь еще на 10-15 минут, чтобы начинка прогрелась.</li>
          </ul>
          <p>Подавайте тыкву как самостоятельное блюдо, посыпав микрогрином.</p>
          <h3>Драники из тыквы с микрогрином</h3>
          <p>Вкусный и полезный вариант быстрого завтрака для всей семьи.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>тыква — 700 г;</li>
            <li>лук — 170 г;</li>
            <li>яйца — 2 шт.;</li>
            <li>микрогрин — 30 г;</li>
            <li>мука — 80 г;</li>
            <li>соль, перец;</li>
            <li>растительное масло (для жарки).</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Очистите тыкву от кожуры, удалите семена и натрите на крупной терке.</li>
            <li>Луковицу нарежьте мелкими кубиками.</li>
            <li>
              В большой миске соедините натертую тыкву, лук, яйца, муку, соль и перец. Хорошо
              перемешайте, чтобы все ингредиенты равномерно смешались.
            </li>
            <li>Прогрейте сковороду на среднем огне. Добавьте немного растительного масла.</li>
            <li>
              Столовой ложкой выкладывайте тыквенное тесто на сковороду, формируя круглые, плоские
              драники.
            </li>
            <li>Жарьте с каждой стороны до золотистой корочки.</li>
            <li>Подавайте, украсив микрогрином.</li>
          </ul>
          <p>
            Чтобы драники были менее жирными, со сковороды перекладывайте их на минуту на бумажное
            полотенце. Оно впитает излишки масла.
          </p>
        </>
      ),
      date: '10.11.2023',
    },
    {
      id: 'мikrozelen-v-dieticheskom-pitanii-malenkij-shag-k-bolshomu-zdorovju',
      name: 'Микрозелень в диетическом питании: маленький шаг к большому здоровью',
      title: 'Использование микрозелени в диетическом питании',
      descr:
        'Микрозелень с точки зрения диетологии. Преимущества микрозелени в диетическом питании. Диетические блюда с микрогрином.',
      bg: [Img32_1, ImgW32_1],
      content: (
        <>
          <p>
            <b>Здоровое питание и употребление полезных продуктов</b> стало популярным у многих
            людей. Приверженцы ЗОЖ обращают особое внимание на <b>микрозелень</b>, которую ценят за
            высокие питательные свойства и уникальный вкус. Рестораны включают ростки в свое меню, а
            диетологи рекомендуют употреблять их ежедневно.
          </p>
          <Picture
            webp={ImgW32_2}
            png={Img32_2}
            alt="Микрозелень в диетическом питании: маленький шаг к большому здоровью"
          />
          <h2>Что такое микрозелень с точки зрения диетологии</h2>
          <p>
            <b>Микрозелень</b> — это нежные зеленые всходы различных семян овощей, трав и злаковых.
            Их срезают, когда растение только начинает прорастать. Именно в этот момент они обладают
            самым большим содержанием питательных веществ.
          </p>
          <p>
            <b>
              Микрозелень — отличный вариант для диетического питания. Чаще всего в рацион включают
              ростки:
            </b>
          </p>
          <ul>
            <li>пшеницы;</li>
            <li>горчицы;</li>
            <li>гороха;</li>
            <li>моркови;</li>
            <li>капусты;</li>
            <li>редиса;</li>
            <li>рукколы и других овощей.</li>
          </ul>
          <p>
            <b>У каждого вида микрозелени</b> свои вкусовые качества и питательные свойства.
            Употреблять их надо только в свежем виде. Нельзя подвергать ростки заморозке и
            термической обработке. В этом случае они утрачивают все свои питательные свойства. О
            лучших сортах микрозелени мы рассказывали{' '}
            <Link to="/blog/luchshie-sorta-mikrozeleni">здесь</Link>.
          </p>
          <h2>Преимущества микрозелени в диетическом питании</h2>
          <Picture
            webp={ImgW32_3}
            png={Img32_3}
            alt="Преимущества микрозелени в диетическом питании"
          />
          <p>
            <b>Преимущества микрозелени в диетическом питании:</b>
          </p>
          <ul>
            <li>
              Молодые всходы легко усваиваются организмом, что облегчает процесс переваривания и
              усвоения питательных веществ.
            </li>
            <li>
              По сравнению с взрослыми растениями, микрозелень содержит больше витаминов и
              минералов. Поэтому молодые ростки — отличный способ улучшить питательную ценность
              диеты.
            </li>
            <li>
              У микрогрина нежный и свежий вкус, который придает новые вкусовые ощущения привычным
              блюдам. Ростки хорошо сочетаются с салатами, котлетами, бутербродами, супами, крупами,
              овощами.
            </li>
            <li>
              Большое количество антиоксидантов в молодых ростках помогает бороться со свободными
              радикалами и предотвращать развитие онкологии.
            </li>
            <li>
              При регулярном употреблении молодые ростки способствуют снижению веса, благодаря
              большому содержанию клетчатки, придающей чувство сытости.
            </li>
          </ul>
          <p>
            <b>Кроме приятных вкусовых качеств,</b> микрозелень делает блюда более красивыми и
            аппетитными. Какую пользу молодые ростки приносят организму, рассказывали{' '}
            <Link to="/blog/vkljuchenie-mikrozeleni-v-racion-pitanija">здесь</Link>.
          </p>
          <h2>Диетические блюда с микрогрином</h2>
          <p>
            <b>Микрозелень</b> — полезный продукт, который диетологи рекомендуют включать в
            ежедневный рацион. Чтобы насытить организм необходимым объемом витаминов и питательных
            веществ, достаточно употреблять 30-50 грамм микрогрина в сутки. При желании порцию
            можете увеличивать. Добавляйте ростки в любые диетические блюда.
          </p>
          <Picture webp={ImgW32_4} png={Img32_4} alt="Диетические блюда с микрогрином" />
          <h3>Н3 Суп из кабачка с микрогрином</h3>
          <p>
            Чтобы блюдо получилось максимально нежным, используйте молодой кабачок. Если у вас
            зрелый плод, то предварительно срежьте кожуру и удалите семена.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>кабачок — 2 небольших;</li>
            <li>лук — 170 г;</li>
            <li>морковь — 200 г;</li>
            <li>картофель — 300 г;</li>
            <li>куриный бульон — 1 л;</li>
            <li>оливковое масло — 30 мл;</li>
            <li>перец, соль;</li>
            <li>микрогрин — 50 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Луковицу, морковь, кабачок и картофель нарежьте небольшими кубиками.</li>
            <li>
              Разогрейте оливковое масло в большой кастрюле на среднем огне. Добавьте лук и обжарьте
              его до мягкости и золотистого цвета.
            </li>
            <li>
              Добавьте морковь и картофель в кастрюлю. Перемешайте с луком. Готовьте 5 минут,
              периодически помешивая.
            </li>
            <li>Добавьте кабачки и потомите еще 2-3 минуты.</li>
            <li>Залейте куриный бульон. Доведите до кипения на максимальном режиме конфорки.</li>
            <li>
              Уменьшите огонь и варите суп на медленном огне примерно 15-20 минут, пока овощи не
              станут мягкими.
            </li>
            <li>Посолите и поперчите по вкусу.</li>
          </ul>
          <p>Налейте суп в тарелки. Слегка остудите. Посыпьте микрозеленью.</p>
          <h3>Салат с кабачком, помидорами и микрогрином</h3>
          <p>Чтобы салат выглядел эффектнее, используйте томаты черри.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>кабачок — 2 средних;</li>
            <li>помидоры — 300 г;</li>
            <li>огурец — 150 г;</li>
            <li>красный лук — 70 г;</li>
            <li>лимон — сок половинки;</li>
            <li>оливковое масло — 50 мл;</li>
            <li>перец, соль;</li>
            <li>микрогрин — 50 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Кабачки, помидоры и огурец нарежьте тонкими полукружками, лук — полукольцами.
              Соедините в салатнике.
            </li>
            <li>В отдельной емкости смешайте сок лимона, оливковое масло, соль и перец.</li>
            <li>Полейте салат соусом и аккуратно перемешайте.</li>
          </ul>
          <p>Подавайте салат с кабачками и помидорами, украсив микрогрином.</p>
          <h3>Оладьи из кабачка</h3>
          <p>
            Если хотите разнообразить вкус оладушек, добавьте в тесто натертый на мелкой терке сыр.
            Вкусовые качества и аромат блюда значительно улучшатся.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>кабачок — 800 г;</li>
            <li>лук — 200 г;</li>
            <li>яйца — 2 шт.;</li>
            <li>мука — 100 г;</li>
            <li>соль, перец;</li>
            <li>микрогрин — 50 г;</li>
            <li>сметана — 150 мл;</li>
            <li>разрыхлитель — 5 г;</li>
            <li>растительное масло — 50 мл.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Очистите кабачки от кожуры, удалите семена и натрите на крупной терке.</li>
            <li>Луковицу нарежьте мелкими кубиками.</li>
            <li>
              Соедините кабачок, лук, яйца, муку, разрыхлитель, соль и перец. Тщательно перемешайте.
            </li>
            <li>Разогрейте сковороду на среднем огне. Добавьте немного растительного масла.</li>
            <li>
              Порциями выкладывайте при помощи столовой ложки кабачковое тесто на сковороду,
              формируя плоские круглые оладьи.
            </li>
            <li>Жарьте с каждой стороны до золотистой корочки.</li>
            <li>Сметану соедините с микрогрином.</li>
          </ul>
          <p>
            Переложите готовые оладьи на бумажные полотенца, чтобы удалить лишнее масло. Подавайте
            блюдо горячим, полив сметаной.
          </p>
          <h3>Индейка с кабачками, помидорами и микрогрином в духовке</h3>
          <p>Кабачок в рецепте можете заменить тыквой, картошкой или использовать их смесь.</p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>филе индейки — 500 г;</li>
            <li>кабачок — 2 средних;</li>
            <li>помидоры — 400 г;</li>
            <li>оливковое масло — 50 мл;</li>
            <li>итальянские травы — 5 г;</li>
            <li>микрозелень — 50 г;</li>
            <li>перец, соль.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Включите духовую печь на 200°С разогреваться.</li>
            <li>Нарежьте индейку, кабачки и помидоры кусочками. Перемешайте.</li>
            <li>Соедините с оливковым маслом, итальянскими травами, солью и перцем.</li>
            <li>Равномерно распределите смесь на противне.</li>
            <li>
              Поставьте в разогретую духовку и готовьте около 25-30 минут. Индейка должна стать
              нежной и сочной.
            </li>
            <li>Готовое блюдо выложите на тарелку. Посыпьте микрогрином.</li>
          </ul>
          <p>Мясо индейки можете заменить куриным филе.</p>
          <h3>Диетические тефтели из куриного фарша с микрогрином в духовке</h3>
          <p>
            Нежный вкус куриных тефтелей идеально сочетается с микрогрином яркого вкуса: луком,
            горчицей, капустой, редисом.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>куриный фарш — 500 г;</li>
            <li>яйцо — 1 шт.;</li>
            <li>овсяные хлопья — 0,5 чашки;</li>
            <li>морковь — 150 г;</li>
            <li>лук — 150 г;</li>
            <li>чеснок — 2 зубка;</li>
            <li>соевый соус — 30 мл;</li>
            <li>соль, перец;</li>
            <li>микрогрин — 150 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>Разогрейте духовку до 180°С.</li>
            <li>
              Пропустите через мясорубку морковь, лук и чеснок. Овсянку мелко измельчите. Для этой
              цели можете использовать кофемолку или погружной блендер.
            </li>
            <li>
              Соедините подготовленные продукты с фаршем, яйцом и соевым соусом. Посолите и
              поперчите по вкусу.
            </li>
            <li>
              Сформируйте небольшие тефтели и выложите их на противень. Отправьте в духовую печь.
            </li>
            <li>
              Готовьте около 20-25 минут. Тефтели должны стать золотистыми и полностью пропечься.
            </li>
          </ul>
          <p>Подавайте тефтели горячими. В качестве гарнира используйте микрогрин.</p>
          <h3>Рыбные котлеты с кабачками в духовке с микрогрином</h3>
          <p>
            Рыба должна присутствовать в рационе всех людей. Диетологи рекомендуют включать ее в
            меню минимум один раз в неделю. Для приготовления подходит филе лосося, трески, судака,
            щуки.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>рыбное филе — 500 г;</li>
            <li>кабачок — 300 г;</li>
            <li>яйцо — 1 шт.;</li>
            <li>панировочные сухари — 0,5 стакана;</li>
            <li>чеснок — 2 зубка;</li>
            <li>микрогрин — 70 г;</li>
            <li>перец, соль;</li>
            <li>зеленый лук — 2 пера.</li>
          </ul>
          <p>
            <b>Пошаговый процесс приготовления:</b>
          </p>
          <ul>
            <li>Разогрейте духовку до 180°С.</li>
            <li>Кабачок натрите на мелкой терке. Измельчите зеленый лук и чеснок.</li>
            <li>Через мясорубку пропустите рыбное филе.</li>
            <li>
              Соедините подготовленные продукты. добавьте яйцо, панировочные сухари, соль и перец.
              Перемешайте.
            </li>
            <li>
              Сформируйте небольшие котлеты и выложите их на противень, покрытый пергаментной
              бумагой.
            </li>
            <li>Поставьте в разогретую духовку. Запекайте полчаса.</li>
          </ul>
          <p>Подавайте рыбные котлеты с любимым соусом, посыпав микрогрином.</p>
          <h3>Диетическая овощная запеканка</h3>
          <p>
            Вкусное и полезное блюдо идеально подходит для завтрака всей семьи или легкого ужина.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>кабачок — 1,2 кг;</li>
            <li>баклажан — 400 г;</li>
            <li>помидоры — 300 г;</li>
            <li>лук — 150 г;</li>
            <li>чеснок — 2 зубка;</li>
            <li>оливковое масло — 50 мл;</li>
            <li>перец;</li>
            <li>соль;</li>
            <li>микрозелень — 70 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Кабачок, баклажаны и помидоры нарежьте тонкими кружочками, луковицу — полукольцами,
              чеснок — мелкими кубиками. Посолите и поперчите по вкусу. Перемешайте.
            </li>
            <li>Равномерно распределите овощи на противне. Полейте оливковым маслом.</li>
            <li>
              Поставьте в разогретую до 180°С духовку и готовьте около 30-35 минут. Овощи должны
              запечься и стать мягкими.
            </li>
          </ul>
          <p>Подавайте запеканку в горячем состоянии, посыпав микрозеленью.</p>
          <h3>Диетические сырники с микрогрином</h3>
          <p>
            Сырники хорошо сочетаются с гороховым микрогрином, который придает им не только
            аппетитный внешний вид, но и приятный ореховый привкус.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>обезжиренный творог — 500 г;</li>
            <li>яйца — 2 шт.;</li>
            <li>овсяные хлопья — 40 г;</li>
            <li>мед — 20 г;</li>
            <li>ванильный экстракт — 1 ч. л.;</li>
            <li>оливковое масло (для жарки);</li>
            <li>микрогрин — 50 г.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Соедините творог, яйца, овсянку, мед и ванильный экстракт. Масса должна стать
              однородной.
            </li>
            <li>Сформируйте небольшие сырники.</li>
            <li>Разогрейте сковороду на среднем огне, добавьте немного оливкового масла.</li>
            <li>Обжарьте сырники с двух сторон.</li>
          </ul>
          <p>Сырники подавайте горячими или холодными, украсив микрогрином.</p>
          <h3>Рыба в омлете в духовке</h3>
          <p>
            Для блюда рекомендуем выбирать микрозелень рукколы. Ее горьковатый вкус идеально
            сочетается с рыбой. Также можете использовать смесь сортов микрогрина.
          </p>
          <p>
            <b>Потребуется:</b>
          </p>
          <ul>
            <li>рыбное филе — 500 г;</li>
            <li>яйца — 5 шт.;</li>
            <li>молоко — 70 мл;</li>
            <li>чеснок — 1 зубчик;</li>
            <li>оливковое масло — 1 ч. л.;</li>
            <li>перец, соль;</li>
            <li>микрогрин — 50 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Взбейте яйца с молоком до получения однородной смеси. Посолите и поперчите по вкусу.
            </li>
            <li>Добавьте в яичную смесь мелко порубленный лук, чеснок и аккуратно перемешайте.</li>
            <li>
              Разогрейте сковороду на среднем огне, добавьте оливковое масло и обжарьте рыбу с двух
              сторон до золотистого цвета.
            </li>
            <li>Выложите обжаренную рыбу на противень. Полейте приготовленной яичной смесью.</li>
            <li>
              Отправьте в духовую печь, прогретую до 180°С. Запекайте 20 минут. Омлет должен
              подняться и стать золотистым.
            </li>
          </ul>
          <p>
            Подавайте блюдо горячим, посыпав микрозеленью. Можете добавить лимонные дольки, которые
            сделают вкус рыбы более насыщенным и приятным.
          </p>
        </>
      ),
      date: '10.11.2023',
    },
    {
      id: 'podderzhka-msp-v-losino-petrovskom-gorodskom-okruge-moskovskoj-oblasti',
      name: 'Поддержка МСП в Лосино-Петровском городском округе Московской области',
      title: 'Поддержка МСП в Лосино-Петровском городском округе Московской области',
      descr: 'Поддержка МСП в Лосино-Петровском городском округе Московской области',
      bg: [Img31_1, ImgW31_1],
      content: (
        <>
          <p>
            В соответствии с Национальным планом ("дорожной картой") развития конкуренции в
            Российской Федерации на 2021 - 2025 годы в Подмосковье проводятся мероприятия по
            созданию условий для развития малого и среднего бизнеса.
          </p>
          <p>
            В Подмосковье при необходимости оказывается всесторонняя поддержка компаниям для
            развития.
          </p>
          <p>
            Так, в 2023 году компании из Лосино-Петровского городского округа Московской области по
            автоматизированному производству салатов из пророщенных семян и микрозелени как субъекту
            МСП была предоставлена финансовая поддержка в виде региональной субсидии в категории
            «Модернизация». Компенсация затрат составила около 400 тысяч рублей.
          </p>
          <p>
            Также в июне 2023 года компания получила статус резидента Skolkovo за свою инновационную
            разработку барботажно-джакузийной ванны с собственным программным обеспечением, которая
            ускоряет прорастание семечки без использования химических компонентов и наполняет
            проросток кислородом. На данный момент инженеры компании совершенствуют технологию
            проращивания с сокращением времени на прорастание семян.
          </p>
          <p>
            Это позволит в дальнейшем внедрять разработанную технологию на другие пищевые
            предприятия, что кратно повысит эффективность и производительность компаний.
          </p>
          <p>
            В следующем году планируется покупка земель и постройка собственных производственных
            зданий, что расширит производственные площади и даст возможность кратного увеличения
            производимой продукции.
          </p>
          <p>
            <a
              href="https://invest.mosreg.ru/region/razvitie-konkurencii/news/345"
              target="_blank"
              rel="noreferrer">
              Ссылка на источник
            </a>
          </p>
        </>
      ),
      date: '04.10.2023',
    },
    {
      id: 'poleznaja-vypechka-s-mikrozelenju',
      name: 'Полезная выпечка с микрозеленью',
      title: 'Готовим выпечку с добавлением микрозелени',
      descr:
        'Рецепты выпечки с микрозеленью: тосты с авокадо и микрогрином, пирог с микрозеленью и клубникой, пицца с микрозеленью, тарталетки с микрогрином, капустный пирог с микрозеленью и т.д.',
      bg: [Img30_1, ImgW30_1],
      content: (
        <>
          <p>
            <b>Микрозелень</b> — молодые ростки, которые по сравнению с взрослыми растениями,
            содержат гораздо больше витаминов, минералов и микроэлементов. С микрогрином можно
            готовить различную вкусную и полезную выпечку для всей семьи. Мы собрали для вас лучшие,
            проверенные рецепты, которые подходят для ежедневного питания.
          </p>
          <Picture webp={ImgW30_2} png={Img30_2} alt="Полезная выпечка с микрозеленью" />
          <h2>В чем польза микрозелени</h2>
          <p>
            <b>Микрозелень</b> — полезный продукт, который идеально подходит для спортивного и
            диетического питания. Микрогрин содержит фолиевую кислоту, железо, фосфор, магний и
            витамины разных групп.
          </p>
          <p>
            <b>При регулярном употреблении</b> ростки помогают улучшить обмен веществ, так как в их
            состав входят ферменты (сложные белковые молекулы), которые способствуют лучшему
            усвоению пищи.
          </p>
          <p>
            <b>Также микрогрин</b> содержит хлорофилл и антиоксиданты, оказывающие благоприятное
            действие на организм человека. Например, антиоксиданты:
          </p>
          <ul>
            <li>замедляют процессы старения;</li>
            <li>помогают клеткам быстрее восстанавливаться;</li>
            <li>укрепляют иммунитет;</li>
            <li>оптимизируют обменные процессы.</li>
          </ul>
          <p>
            Хлорофил по структуре похож на гемоглобин, так как помогает насыщать кровь кислородом.
            Также он оказывает противоопухолевое действие, связывает и выводит токсины, тормозит
            рост патогенной микрофлоры, уменьшает болевые ощущения.
          </p>
          <p>
            <b>Многочисленные минеральные соединения благоприятно влияют на системы:</b>
          </p>
          <ul>
            <li>сердечно-сосудистую;</li>
            <li>кроветворную;</li>
            <li>иммунную;</li>
            <li>выделительную;</li>
            <li>репродуктивную;</li>
            <li>эндокринную.</li>
          </ul>
          <p>
            <b>Кроме того</b>, у каждого сорта микрозелени существуют собственные полезные свойства
            для организма. Например, кресс-салат благоприятно влияет на зрение, базилик — на
            пищеварение, пшеница выводит токсины, гречка укрепляет каппиляры, овес — иммунную и
            нервную систему, редис улучшает состояние кожных покровов и волос. Более подробно о
            пользе микрозелени можете прочитать{' '}
            <a href="https://vitamincus.me/blog/mikrozelen-dlja-ukreplenija-zdorovja/">здесь</a>.
          </p>
          <h2>Завтрак с микрозеленью</h2>
          <Picture webp={ImgW30_3} png={Img30_3} alt="Завтрак с микрозеленью" />
          <p>
            Быстрый и полезный завтрак можете приготовить за несколько минут. Батон в рецепте можете
            заменить ржаным или бездрожжевым хлебом. Количество ингредиентов указано на 4 порции.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>яйцо — 4 шт.;</li>
            <li>соль;</li>
            <li>батон — 4 ломтика;</li>
            <li>смесь микрозелени — 30 г;</li>
            <li>сыр — 50 г;</li>
            <li>перец.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Разогрейте духовой шкаф до 180°С.</li>
            <li>Натрите на средней терке сыр.</li>
            <li>
              В каждом ломтике батона вырежьте середину. Поместите заготовку на противень. В центр
              каждого ломтика залейте сырое яйцо и посолите. Сверху присыпьте сыром.
            </li>
            <li>Поставьте в духовой шкаф. Запекайте 7-10 минут.</li>
            <li>Переложите на тарелки и посыпьте микрозеленью.</li>
          </ul>
          <p>Подавайте блюдо в теплом виде.</p>
          <h2>Тосты с авокадо и микрогрином</h2>
          <p>
            Тосты станут полноценным завтраком или перекусом. Это отличный легкий вариант полезного
            блюда, который отлично подходит для людей, которые следят за фигурой.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>ржаной или бездрожжевой хлеб — 3 ломтика;</li>
            <li>перец;</li>
            <li>авокадо — 1 шт.;</li>
            <li>микрозелень — 30 г;</li>
            <li>лимон — 0,5 шт.;</li>
            <li>кунжут — 10 г;</li>
            <li>помидор — 1 шт.;</li>
            <li>соль;</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Разогрейте духовой шкаф до 220°С.</li>
            <li>
              На противне разложите ломтики хлеба. Поставьте в духовой шкаф. Поджаривайте хлеб 5
              минут.
            </li>
            <li>
              Авокадо разрежьте, удалите косточку и очистите от шкурки. Нарежьте каждую половинку на
              тонкие пластинки.
            </li>
            <li>
              На тосты выложите авокадо, сверху полейте соком лимона и равномерно распределите
              помидоры, нарезанные тонкими слайсами.
            </li>
            <li>Посыпьте кунжутом, солью и перцем. Сверху украсьте микрозеленью.</li>
          </ul>
          <p>
            Микрогрин можете использовать одного сорта или смесь, состоящую из разных видов ростков.
          </p>
          <h2>Пирог с микрозеленью и клубникой</h2>
          <Picture webp={ImgW30_4} png={Img30_4} alt="Пирог с микрозеленью и клубникой" />
          <p>
            Микрозелень хорошо сочетается с любыми продуктами. Предлагаем приготовить на десерт
            вкусный и полезный пирог с микрогрином, который поможет укрепить здоровье и улучшить
            состояние ногтей, волос и кожи.
          </p>
          <p>
            <b>Ингредиенты для теста:</b>
          </p>
          <ul>
            <li>мука — 320 г;</li>
            <li>соль — 1 ч. л.;</li>
            <li>какао — 40 г;</li>
            <li>кленовый сироп — 4 ст. л.;</li>
            <li>сливочное растопленное масло — 120 мл.</li>
          </ul>
          <p>
            <b>Ингредиенты для начинки:</b>
          </p>
          <ul>
            <li>козий сыр — 80 г (комнатной температуры);</li>
            <li>греческий йогурт — 80 г;</li>
            <li>кленовый сироп — 50 мл.</li>
          </ul>
          <p>
            <b>Для украшения:</b>
          </p>
          <ul>
            <li>клубника — 2 стакана;</li>
            <li>микрозелень — горсть.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Муку соедините с солью и какао в миске для взбивания.</li>
            <li>Добавьте растопленное сливочное масло и кленовый сироп.</li>
            <li>
              Взбивайте до тех пор, пока масса не станет рассыпчатой. Руками разомните тесто, затем
              скатайте его в шар, поместите в пакет и оставьте на полчаса в холодильнике.
            </li>
            <li>Переложите тесто в форму и равномерно распределите, сформировав бортики.</li>
            <li>При помощи вилки сделайте на всей поверхности теста дырочки.</li>
            <li>Соедините козий сыр с кленовым сиропом и греческим йогуртом. Взбейте миксером.</li>
            <li>Переложите начинку на тесто.</li>
            <li>Запекайте в духовой печи 20-30 минут при температуре 180°С.</li>
            <li>Достаньте пирог. Оставьте на столе до полного остывания. Достаньте из формы.</li>
            <li>
              Клубнику разрежьте пополам. Распределите по поверхности пирога. Посыпьте микрозеленью.
            </li>
          </ul>
          <p>Если любите сладкие десерты, то сверху клубнику можете посыпать сахарной пудрой.</p>
          <h2>Пицца с микрозеленью</h2>
          <p>
            Микрозелень помогает делать подачу пиццы нарядной, а вкус — более интересным. При помощи
            сорта микрогрина вы можете изменять вкусовые качества блюда. Например, микрозелень
            редиса островатая, руколы — пряная, кресс-салата — горьковатая, подсолнечника — мясистая
            и сочная. Зато ростки гороха по вкусу напоминают молодой, нежный горошек. Они такие же
            сладкие и хрустящие. Вы можете выбрать для украшения пиццы один сорт микрогрина или
            несколько.
          </p>
          <p>
            <b>Ингредиенты для теста:</b>
          </p>
          <ul>
            <li>проростки чечевицы — 1 стакан;</li>
            <li>черный молотый перец;</li>
            <li>нутовая мука — 0,5 стакана;</li>
            <li>перец чили — 0,5 шт.;</li>
            <li>соль — 0,5 ч. л.;</li>
            <li>чеснок — 4 зубка;</li>
            <li>шпинат — 0,5 пучка;</li>
            <li>кокосовое молоко — 60 мл;</li>
            <li>помидоры — 100 г;</li>
            <li>оливковое масло — 20 мл.</li>
          </ul>
          <p>
            <b>Ингредиенты для начинки:</b>
          </p>
          <ul>
            <li>репчатый лук — 100 г;</li>
            <li>петрушка — 30 г;</li>
            <li>помидоры — 200 г;</li>
            <li>сыр — 100 г;</li>
            <li>соль;</li>
            <li>болгарский перец — 100 г;</li>
            <li>микрозелень — 0,5 стакана.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Залейте теплой водой проростки чечевицы. Оставьте на 6 часов.</li>
            <li>Разрежьте перец чили пополам и удалите семена.</li>
            <li>
              Поместите в чашу блендера зубчики чеснока, шпинат, перец чили и помидоры. Взбейте.
            </li>
            <li>
              Добавьте молоко и муку, поперчите и посолите. Взбивайте смесь, пока масса не станет
              густой и однородной.
            </li>
            <li>Прогрейте духовой шкаф до 200°С.</li>
            <li>Застелите противень бумагой для выпечки. Распределите тесто.</li>
            <li>Поставьте противень в духовую печь. Выпекайте 7 минут.</li>
            <li>
              Достаньте из духовки основу для пиццы. При помощи кулинарной кисточки смажьте края
              водой, чтобы они не стали сухими.
            </li>
            <li>
              Нашинкуйте лук и петрушку. Помидоры и болгарский перец нарежьте кубиками. Распределите
              подготовленные продукты на основе для пиццы.
            </li>
            <li>Поставьте в духовую печь. Готовьте 5 минут.</li>
            <li>Сыр натрите на терке. Посыпьте пиццу и готовьте в духовке еще 3 минуты.</li>
            <li>Достаньте готовое блюдо. Украсьте микрозеленью.</li>
          </ul>
          <p>
            Подавать пиццу можете с полезным коктейлем из микрозелени. Рецепты приготовления найдете{' '}
            <a href="https://vitamincus.me/blog/recepty-i-sovety-po-prigotovleniju-koktejlej-i-smuzi-iz-mikrozeleni/">
              здесь
            </a>
            .
          </p>
          <h2>Тарталетки с микрогрином</h2>
          <p>
            Простой и полезный рецепт, который подходит для взрослых и детей старше 18 месяцев.
            Слоеное тесто можете приготовить самостоятельно или купить готовое в магазине. Рецепт
            рассчитан на 4 порции.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>слоеное тесто — 500 г;</li>
            <li>мягкий творог — 125 г;</li>
            <li>яйцо — 2 шт.;</li>
            <li>зеленый лук — 4 пера;</li>
            <li>соль;</li>
            <li>черный перец;</li>
            <li>микрозелень — 20 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Раскатайте пласт слоеного теста (250 г). Разрежьте на 4 части.</li>
            <li>Возьмите стакан и вырежьте круги.</li>
            <li>
              Раскатайте второй пласт теста и разделите на такие же по размеру части. Смочите их
              немного водой. Сверху поместите квадраты, в которых вырезали круги.
            </li>
            <li>
              Противень застелите бумагой для выпечки. Разложите заготовки. Вырезанные кружочки для
              тарталеток не нужны. Их вы тоже приготовьте в духовке, затем разрежьте пополам и
              смажьте любой начинкой.
            </li>
            <li>Поставьте в разогретую до 200°С духовую печь. Выпекайте 15 минут.</li>
            <li>
              Достаньте заготовки, в центре слегка примните ложкой, чтобы получилось углубление для
              начинки.
            </li>
            <li>
              Мягкий сыр перемешайте с яйцами и измельченным зеленым луком. Посолите и поперчите.
            </li>
            <li>В центр заготовки поместите начинку (через края она не должна вытекать).</li>
            <li>Поставьте в духовую печь запекаться на 10 минут.</li>
          </ul>
          <p>
            Готовые тарталетки украсьте микрозеленью. Подавать можете в горячем или в холодном виде.
          </p>
          <h2>Капустный пирог с микрозеленью</h2>
          <Picture webp={ImgW30_5} png={Img30_5} alt="Капустный пирог с микрозеленью" />
          <p>
            Открытый пирог получается красивым, вкусным и ароматным. Для приготовления используйте
            силиконовую форму, с антипригарным покрытием или разъемную.
          </p>
          <p>
            <b>Ингредиенты для теста:</b>
          </p>
          <ul>
            <li>мука — 160 г;</li>
            <li>соль — 1 г;</li>
            <li>сливочное масло — 100 г;</li>
            <li>майонез — 50 г;</li>
            <li>сметана — 70 г;</li>
            <li>сода — 0,5 ч. л.</li>
          </ul>
          <p>
            <b>Ингредиенты для начинки:</b>
          </p>
          <ul>
            <li>белокочанная капуста — 600 г;</li>
            <li>репчатый лук — 150 г;</li>
            <li>микрозелень — 50 г;</li>
            <li>растительное масло — 50 мл;</li>
            <li>морковь — 150 г.</li>
          </ul>
          <p>
            <b>Для заливки:</b>
          </p>
          <ul>
            <li>яйца — 2 шт.;</li>
            <li>твердый сыр — 150 г;</li>
            <li>сметана — 70 мл.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Репчатый лук нарежьте полукольцами. Морковь натрите на крупной терке.</li>
            <li>Соедините овощи в сковороде. Добавьте растительное масло. Обжарьте до мягкости.</li>
            <li>
              Добавьте капусту, нарезанную тонкой соломкой. Можете нашинковать при помощи ножа,
              натереть на V-образной терке или воспользоваться кухонным комбайном. Посолите.
            </li>
            <li>Жарьте на среднем огне до полуготовности. Капуста должна немного хрустеть.</li>
            <li>
              Сливочное масло в кухонном комбайне разбейте в крошку (сливочное масло можете заменить
              маргарином).
            </li>
            <li>
              Добавьте сметану и майонез. Посолите. Перемешайте в комбайне. Тесто должно быть мягким
              и податливым. Сформируйте из него колобок, затем заверните в пленку и оставьте на
              полчаса.
            </li>
            <li>Для заливки соедините сметану с яйцами и сыром, натертым на крупной терке.</li>
            <li>Тесто распределите руками в форме для выпечки, поднимая краешки.</li>
            <li>Распределите капустную начинку и добавьте заливку.</li>
            <li>Выпекайте 40 минут в духовой печи, разогретой до 180°С.</li>
            <li>Готовое блюдо посыпьте микрозеленью.</li>
          </ul>
          <p>
            В горячем виде пирог очень нежный и разваливается. Есть его можете вилкой. После
            остывания кусочки удобно брать руками.
          </p>
          <p>
            Микрозелень добавляйте только в готовое блюдо, так как при термической обработке
            полезные вещества распадаются. Свежий микрогрин идеально сочетается со всеми продуктами,
            поэтому не бойтесь экспериментировать и радовать семью новыми и полезными блюдами.
          </p>
        </>
      ),
      date: '30.09.2023',
    },
    {
      id: 'luchshie-sorta-mikrozeleni',
      name: 'Лучшие сорта микрозелени',
      title: 'Рассказываем про лучшие сорта микрозелени',
      descr:
        'Сорта микрозелени: микрозелень подсолнечника, микрозелень клевера, микрозелень редьки, микрозелень редиса, микрозелень руколы, проростки маш, микрозелень гороха, микрозелень чечевицы.',
      bg: [Img29_1, ImgW29_1],
      content: (
        <>
          <p>
            <b>Микрозелень</b> — молодые ростки трав и овощных растений. Используют их для украшения
            блюд и добавляют в пищу. Микрозелень содержит большое количество полезных микроэлементов
            и витаминов, которые легче и полностью усваиваются организмом. Предлагаем разобраться в
            полезных свойствах микрогрина и понять, какие сорта считаются лучшими.
          </p>
          <Picture webp={ImgW29_2} png={Img29_2} alt="Лучшие сорта микрозелени" />
          <h2>В чем польза микрозелени</h2>
          <p>
            <b>Микрозелень</b> — аналог полезных продуктов, которые не всем разрешается употреблять.
            Например, людям, контролирующим вес и с сахарным диабетом, необходимо существенно
            ограничивать употребление круп. В этом случае в рацион следует включать молодые побеги
            пшеницы и гречки. Микрозелень этих растений не содержит крахмал и обладает более
            полезными свойствами по сравнению с крупами.
          </p>
          <p>
            <b>К полезным свойствам всех сортов микрогрина относят:</b>
          </p>
          <ul>
            <li>поддержку организма при онкологии и других тяжелых хронических заболеваниях;</li>
            <li>предотвращение проблем, связанных с дефицитом витамина D;</li>
            <li>укрепление иммунитета;</li>
            <li>
              положительное воздействие на состояние кожных покровов, костной системы, ногтей и
              волос;
            </li>
            <li>способность снижать вес;</li>
            <li>предотвращение сезонного авитаминоза;</li>
            <li>улучшение работы сердечно-сосудистой системы;</li>
            <li>
              благоприятное воздействие на ЖКТ (особенно при болезнях, при которых запрещается
              включать в рацион грубую клетчатку);
            </li>
            <li>предотвращение рисков возникновения инсульта и инфаркта;</li>
            <li>снижение гипоксии и улучшение кислородного обмена.</li>
          </ul>
          <p>
            <b>Кроме того</b>, микрозелень помогает облегчить переход на здоровое питание. При
            регулярном употреблении снижает гипоксию и улучшает кислородный обмен. Микрогрин полезен
            для здоровья людей в любом возрасте.
          </p>
          <h2>Применение в кулинарии</h2>
          <p>
            Микрозелень полезно включать в ежедневный рацион. Употреблять ее можно в свежем виде, а
            также добавлять в:
          </p>
          <ul>
            <li>напитки;</li>
            <li>различные вторые блюда;</li>
            <li>супы;</li>
            <li>салаты;</li>
            <li>начинку для пиццы;</li>
            <li>смузи;</li>
            <li>бутерброды (в качестве украшения);</li>
            <li>коктейли.</li>
          </ul>
          <Picture webp={ImgW29_3} png={Img29_3} alt="Применение в кулинарии" />
          <p>
            <b>Микрогрин отлично</b> сочетается с растительным маслом, творогом, орехами, овощами и
            чесноком. Ростки — отличный гарнир к мясу и рыбе. Также микрозелень подают со всеми
            видами сыров и холодными закусками.
          </p>
          <p>
            <b>Микрогрин содержит</b> большое количество полезных веществ, поэтому популярность
            ростков у людей быстро возрастает.
          </p>
          <h2>Сорта микрозелени</h2>
          <p>
            <b>Универсального ответа</b>, какой сорт самый лучший, вкусный и полезный для человека,
            не существует. Но, чтобы получить от ростков максимум, надо соблюдать важное правило.
            Самой полезной считается микрозелень, которая не подвергалась термообработке.
          </p>
          <p>
            <b>По вкусу микрогрин</b> может быть кисловатым, острым, с горчинкой. У микрозелени
            более концентрированный аромат и нежнее вкус по сравнению с взрослыми растениями, к
            которым относятся ростки. Какой сорт выбрать, зависит от вкусовых предпочтений.
          </p>
          <h2>Микрозелень подсолнечника</h2>
          <p>
            <b>Микрозелень подсолнечника</b> — наиболее удачный вариант для бутербродов и салатов.
            Ростки обладают приятным привкусом ореха и текстурой шпината. Микрогрин следует включать
            в рацион людям, которые придерживаются вегетарианской диеты, так как он содержит большое
            количество белка и железа.
          </p>
          <p>
            Благодаря содержанию цинка, витамина D и С, а также селена, микрозелень нормализует
            деятельность сердечно-сосудистой, нервной системы и укрепляет иммунитет. Аминокислоты и
            антиоксиданты, входящие в состав, замедляют процесс старения и помогают
            восстанавливаться клеткам.
          </p>
          <h3>Микрозелень клевера</h3>
          <p>
            <b>Микрозелень клевера</b> обладает приятным привкусом свежего огурца и сочной
            текстурой. Благодаря универсальности подходит ко всем блюдам. Ростки содержат большое
            количество эфирных масел, витаминов и минералов, полезных для организма.
          </p>
          <p>
            Микрогрин клевера оказывает мочегонное и желчегонное действие, стимулирует иммунные силы
            организма, укрепляет стенки лимфатических и кровеносных сосудов, помогает расслаблять
            нервную систему и снимать психологическое напряжение, выводит из организма тяжелые
            металлы. Благодаря высокому содержанию изофлавоноидов поддерживает репродуктивную
            систему.
          </p>
          <h3>Микрозелень редьки</h3>
          <p>
            <b>Ростки редьки</b> оздоравливают микрофлору кишечника, повышают выносливость
            организма, выводят из организма шлаки и отложения солей. Оказывают противомикробное,
            антиканцерогенное и общеукрепляющее действие.
          </p>
          <p>
            <b>Микрогрин редьки обладает приятным жгучим привкусом и содержит:</b>
          </p>
          <ul>
            <li>витамины В, РР, С, Е, А;</li>
            <li>калий, кальций;</li>
            <li>железо, натрий;</li>
            <li>магний, йод;</li>
            <li>фосфор;</li>
            <li>клетчатку;</li>
            <li>эфирные масла;</li>
            <li>фитонциды;</li>
            <li>аминокислоты.</li>
          </ul>
          <p>
            Микрозелень способствует снижению веса, кровяного давления. Ростки в целом улучшают
            пищеварение и относятся к хорошему противовоспалительному средству. Поэтому микрогрин
            редьки полезно включать в рацион при простудных заболеваниях.
          </p>
          <h3>Микрозелень редиса</h3>
          <p>
            <b>Микрозелень редиса</b> обладает хорошими вкусовыми качествами и приятным ароматом.
            Ростки содержат:
          </p>
          <ul>
            <li>витамины А, В, К;</li>
            <li>аскорбиновую кислоту;</li>
            <li>калий;</li>
            <li>цинк;</li>
            <li>магний;</li>
            <li>марганец;</li>
            <li>кальций и железо.</li>
          </ul>
          <p>
            При регулярном употреблении ростки помогают подавлять воспалительные процессы, облегчать
            работу печени, предупреждать анемию и выводить из организма вредные вещества. Также
            микрозелень редиса повышает эластичность стенок сосудов, защищает организм от
            атеросклероза, снижает уровень холестерина.
          </p>
          <h3>Микрозелень руколы</h3>
          <p>
            Микрозелень руколы содержит большое количество витамина С, эфирных масел, каротина,
            йода, а также изотиоцианатов, оказывающих антиканцерогенное, противовоспалительное
            действие и замедляющих процессы старения организма.
          </p>
          <p>
            <b>Также в состав микрогрина входят:</b>
          </p>
          <ul>
            <li>витамины группы В;</li>
            <li>токоферол, медь;</li>
            <li>филлохинон, калий;</li>
            <li>магний, цинк;</li>
            <li>марганец, кальций;</li>
            <li>железо и другие полезные витамины и минералы.</li>
          </ul>
          <p>
            При постоянном употреблении микрозелени руколы, вы улучшите обмен веществ, повысите
            концентрацию гемоглобина в крови и предотвратите развитие анемии. Входящие в состав
            антиоксиданты предупреждают развитие сердечных заболеваний, онкологии, катаракты и
            других патологий органов зрения.
          </p>
          <p>
            Ростки руколы помогают укреплять костную ткань, замедляют прогрессирование остеопороза,
            способствуют похудению и улучшают состояние кожных покровов. Также микрозелень
            поддерживает необходимый для здоровья уровень глюкозы и препятствует возникновению
            гипергликемии.
          </p>
          <h3>Проростки маш</h3>
          <p>
            Микрозелень маш помогает укреплять иммунную систему, повышать сопротивляемость организма
            к простудным заболеваниям. Также ростки:
          </p>
          <ul>
            <li>восстанавливают зрение и снижают усталость глаз;</li>
            <li>нормализуют уровень кислотности;</li>
            <li>снижает количество камедонов и акне;</li>
            <li>регулируют показатели гемоглобина;</li>
            <li>повышают выносливость;</li>
            <li>снижают аппетит, подавляя желание съесть жирную и сладкую пищу;</li>
            <li>укрепляют стенки суставов и сосудов;</li>
            <li>ускоряют обменные процессы;</li>
            <li>снижают риски развития стресса;</li>
            <li>препятствуют развитию онкологических заболеваний.</li>
          </ul>
          <p>
            Благодаря антиоксидантному действию, микрозелень маш служит профилактикой и терапией
            воспалительных процессов.
          </p>
          <h3>Микрозелень гороха</h3>
          <p>
            <b>Микрозелень гороха</b> оказывает омолаживающее, иммуномодулирующее, антиоксидантное
            действие. Благодаря мочегонному эффекту, микрогрин помогает уменьшать и предотвращать
            появление отеков. Ростки гороха:
          </p>
          <ul>
            <li>нормализуют работу печени и почек;</li>
            <li>выводят из организма шлаки;</li>
            <li>повышают потенцию;</li>
            <li>
              снижают риски образования тромбов, так как укрепляют стенки кровеносных сосудов.
            </li>
          </ul>
          <p>
            Микрозелень содержит повышенное количество антиоксидантов. Обеспечивает организм
            необходимыми микроэлементами и минералами круглый год.
          </p>
          <h3>Микрозелень чечевицы</h3>
          <p>
            <b>Вкус микрозелени чечевицы</b> сладковатый, легкий с приятным ореховым послевкусием.
            Максимальную пользу микрогрин приносит в сочетании с продуктами, которые содержат
            большое количество витамина С (например, помидоры, рукола, перец).
          </p>
          <p>
            Ростки содержат клетчатку, которая помогает надолго сохранять чувство сытости. Микрогрин
            чечевицы способствует снижению лишнего веса и холестерина, нормализует уровень сахара в
            крови. Входящие в состав аминокислоты успокаивающе действуют на нервную систему,
            уменьшают чувство тревожности.
          </p>
          <h2>Выбор микрозелени</h2>
          <p>
            <b>Какую микрозелень выбрать</b>, зависит от ваших вкусовых предпочтений, но существуют
            общие рекомендации. Для детей наиболее полезны ростки:
          </p>
          <ul>
            <li>гороха;</li>
            <li>подсолнечника;</li>
            <li>шпината;</li>
            <li>редиса.</li>
          </ul>
          <p>
            У перечисленной микрозелени приятный сладковатый вкус и особая сочность. Ростки богаты
            витаминами и микроэлементами, необходимыми растущему организму для правильного развития.
            Более подробно о включении микрогрина в рацион ребенка можете прочитать{' '}
            <a href="https://vitamincus.me/blog/mikrozelen-v-racione-rebenka/">здесь</a>.
          </p>
          <Picture webp={ImgW29_4} png={Img29_4} alt="Выбор микрозелени" />
          <p>
            <b>Для мужчин</b> можете выбрать сорта с терпкими, ярко выраженными вкусами. Например,
            ростки лука, базилика, гороха, кинзы, пажитника содержит цинк, токоферол и другие
            полезные вещества, которые повышают мужскую силу и улучшают иммунитет.
          </p>
          <p>
            <b>Женщины</b> по достоинству оценят сорта с летней свежестью, нежным ореховым
            послевкусием и приятной горчинкой. Например, бораго (огуречная трава), кресс-салат,
            цикорий. Эта микрозелень богата антиоксидантами, которые помогают снижать лишний вес,
            улучшать метаболизм, заряжать энергией и поднимать настроение.
          </p>
          <p>
            Если вы любите свеклу, то смело можете выбирать ростки этого овоща. Но у некоторых
            сортов микрозелени вкус отличается от взрослого растения. Например, если вы не любите
            кольраби или щавель, то побеги вам могут понравиться, так как обладают совершенно
            другими вкусовыми оттенками.
          </p>
          <p>
            Независимо от сорта, микрозелень приносит неоценимую пользу организму детей и взрослых.
            Новый продукт вводите в рацион постепенно, начина с 30 грамм и постепенно увеличивайте
            порцию до 100 грамм. Так как микрогрин содержит большое количество минералов и
            витаминов, то для укрепления иммунитета, улучшения обмена веществ, замедления процессов
            старения и укрепления нервной системы употреблять ростки можете 2-3 раза в неделю. Более
            подробно о том, как при помощи микрозелени укрепить здоровье, читайте{' '}
            <a href="https://vitamincus.me/blog/mikrozelen-dlja-ukreplenija-zdorovja/">здесь</a>.
          </p>
        </>
      ),
      date: '30.09.2023',
    },
    {
      id: 'kak-prigotovit-sup-pjure-s-mikrozelenju',
      name: 'Как приготовить суп-пюре с микрозеленью',
      title: 'Рецепты полезных и вкусных супов с добавлением микрозелени',
      descr:
        'Готовим суп-пюре с микрозеленью: картофельный суп-пюре; овощной суп-пюре; томатный суп; гороховый крем-суп; морковно-лаймовый суп; суп-пюре из шампиньонов; куриный суп; суп-пюре с колбасой.',
      bg: [Img28_1, ImgW28_1],
      content: (
        <>
          <p>
            <b>Микрозелень</b> — ароматное, полезное дополнение к блюдам. Микрогрин идеально
            сочетается со всеми продуктами, насыщает организм полезными микроэлементами, минералами
            и витаминами. Предлагаем лучшие, проверенные рецепты вкусных супов-пюре с ростками,
            которые подойдут для питания детей и взрослых.
          </p>
          <Picture webp={ImgW28_2} png={Img28_2} alt="Как приготовить суп-пюре с микрозеленью" />
          <h2>В чем польза микрозелени</h2>
          <p>
            <b>Микрозелень</b> — молодые ростки, которые употребляют в пищу через 5-7 дней после
            появления всходов. Микрогрин, по сравнению с взрослыми растениями, содержит в 5 раз
            больше ферментов, витаминов и микроэлементов.
          </p>
          <p>
            <b>Кроме того, молодые ростки:</b>
          </p>
          <ul>
            <li>улучшают перистальтику кишечника и процессы вывода токсинов;</li>
            <li>оказывают антибактериальный эффект;</li>
            <li>уменьшают проницаемость капилляров;</li>
            <li>положительно влияют на работу почек, нервной и эндокринной системы;</li>
            <li>повышают работоспособность;</li>
            <li>нормализуют вес;</li>
            <li>укрепляют иммунную систему;</li>
            <li>поддерживают состояние кожных покровов;</li>
            <li>снижают риски развития сердечных заболеваний, диабета, некоторых видов рака.</li>
          </ul>
          <p>
            Для насыщения организма необходимыми витаминами и минералами достаточно употреблять в
            день 30-50 грамм ростков. Чтобы микрозелень не потеряла свои полезные свойства, не
            подвергайте ее термической обработке. Добавляйте ростки в слегка остывший суп
            непосредственно перед употреблением. Более подробно о пользе микрозелени для здоровья
            можете прочитать{' '}
            <a href="https://vitamincus.me/blog/mikrozelen-v-racione-pitanija-polza-dlja-zdorovja-fizicheskih-kondicij-i-lichnostnoj-produktivnosti/">
              здесь
            </a>
            .
          </p>
          <h2>Картофельный суп-пюре с микрозеленью</h2>
          <Picture webp={ImgW28_3} png={Img28_3} alt="Картофельный суп-пюре с микрозеленью" />
          <p>
            Нежный картофельный суп-пюре с микрогрином насытит организм витаминами, придаст энергии
            и сил. В прохладную погоду он согреет и сделает иммунитет крепче. Для приготовления
            блюда понадобится кастрюля с толстым дном.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>сыр твердого сорта — 50 г;</li>
            <li>картофель — 1 кг;</li>
            <li>перец черный молотый;</li>
            <li>лук — 150 г;</li>
            <li>соль;</li>
            <li>чеснок — 3 зубка;</li>
            <li>микрозелень — 150 г;</li>
            <li>овощной бульон — 1 л;</li>
            <li>оливковое масло — 25 мл;</li>
            <li>свежий имбирь (тертый) — 2 ст. л.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Мелко нашинкуйте лук и чесночные зубчики. Натрите на мелкой терке имбирь и сыр.</li>
            <li>В кастрюлю налейте оливковое масло. Прогрейте.</li>
            <li>Засыпьте лук. Жарьте минуту. Добавьте чеснок, имбирь и готовьте 2 минуты.</li>
            <li>
              Очищенную картошку нарежьте кубиками. Отправьте к овощам, затем залейте овощным
              бульоном. Посолите и поперчите по вкусу.
            </li>
            <li>После закипания варите на среднем огне под крышкой четверть часа.</li>
            <li>Измельчите овощи погружным блендером. Масса должна получиться однородной.</li>
            <li>Засыпьте в горячий суп сыр. Перемешайте. Подавайте, посыпав микрозеленью.</li>
          </ul>
          <p>Если у вас нет овощного бульона, можете заменить его обычной водой.</p>
          <h2>Овощной суп-пюре с микрозеленью</h2>
          <p>
            Желаемую густоту супа-пюре можете регулировать при помощи воды, добавляя больший или
            меньший объем жидкости.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>смесь сортов микрозелени — 400 г;</li>
            <li>тыква — 150 г;</li>
            <li>кабачок — 150 г;</li>
            <li>карри — 5 г;</li>
            <li>куриный бульон (можете заменить обычной водой или овощным бульоном) — 750 г;</li>
            <li>соль, специи — по вкусу;</li>
            <li>морковь — 150 г;</li>
            <li>брокколи — 150 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Морковь, кабачок, тыкву и брокколи разрежьте на части. Залейте бульоном.</li>
            <li>Варите после закипания 15 минут.</li>
            <li>Снимите с огня. Оставьте на 10 минут, чтобы жидкость немного остыла.</li>
            <li>Взбейте овощи. Добавьте карри, посолите и поперчите.</li>
            <li>Налейте в тарелки и посыпьте смесью сортов микрозелени.</li>
          </ul>
          <p>
            Чтобы сделать вкус супа-пюре более насыщенным и оригинальным, можете добавить острый
            перец чили и вяленые помидоры.
          </p>
          <h2>Томатный суп с микрозеленью</h2>
          <Picture webp={ImgW28_4} png={Img28_4} alt="Томатный суп с микрозеленью" />
          <p>
            Полезный витаминный суп-пюре без варки. Подается в холодном виде. Для заправки можете
            использовать сметану или греческий йогурт.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>чеснок — зубчик;</li>
            <li>томаты — 600 г;</li>
            <li>соль;</li>
            <li>перец черный молотый;</li>
            <li>рукола — 50 г;</li>
            <li>сельдерей — стебель;</li>
            <li>микрозелень — 150 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Томаты нарежьте. Поместите в чашу блендера и взбейте.</li>
            <li>
              Добавьте руколу, нарезанный стебель сельдерея и чеснок. Посолите и поперчите. Взбейте
              блендером. Масса должна получиться однородной, без мелких кусочков.
            </li>
            <li>Подавайте, посыпав сверху микрозеленью.</li>
          </ul>
          <p>Если хотите получить новые вкусовые оттенки, добавьте в состав половинку авокадо.</p>
          <h2>Гороховый крем-суп с микрозеленью</h2>
          <p>
            Гороховый суп-пюре с микрозеленью полезно включать в рацион взрослых и детей. Блюдо
            содержит большое количество минеральных веществ, витаминов и растительного белка,
            который требуется для мышечной ткани. Кроме того, суп помогает улучшить перистальтику
            кишечника и служит профилактикой заболеваний ЖКТ.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>колотый горох — 350 г;</li>
            <li>черный перец — 1 г;</li>
            <li>лук — 150 г;</li>
            <li>вода — 800 мл;</li>
            <li>соль — 1 ч. л.;</li>
            <li>морковь — 150 г;</li>
            <li>микрозелень — 15 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Горох промойте 4 раза. Поместите в кастрюлю и залейте водой.</li>
            <li>Морковь нарежьте брусочками, а лук — полукольцами. Отправьте к гороху.</li>
            <li>
              Включите максимальный режим конфорки. Дождитесь кипения. Переключите огонь на минимум
              и накройте крышкой. Периодически перемешивая, варите 50 минут.
            </li>
            <li>Посолите, поперчите, затем взбейте суп погружным блендером.</li>
            <li>Посыпьте микрозеленью.</li>
          </ul>
          <p>
            Чтобы сделать суп более сытным, а вкус — насыщенным, можете нарезать 100 г шпика тонкими
            полосками, обжарить и добавить шкварки в каждую тарелку при подаче.
          </p>
          <h2>Морковно-лаймовый суп-пюре с микрозеленью</h2>
          <p>
            Суп-пюре получается мягким и нежным. Сок лайма наполняет вкус приятной кислинкой, а
            розмарин — особым ароматом, который делает блюдо немного экзотичным.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>морковь — 150 г;</li>
            <li>розмарин — 5 г;</li>
            <li>картофель — 300 г;</li>
            <li>перец, соль — по вкусу;</li>
            <li>репчатый лук — 100 г;</li>
            <li>чеснок — 1 зубчик;</li>
            <li>мясной бульон — 600 мл;</li>
            <li>твердый сыр — 15 г;</li>
            <li>лимон — 0,5 шт.;</li>
            <li>микрозелень — 30 г;</li>
            <li>растительное масло — 50 мл.</li>
          </ul>
          <p>
            <b>Пошаговый процесс:</b>
          </p>
          <ul>
            <li>
              Картофель нарежьте кубиками, морковь — тонкими кружочками, лук и чеснок — на мелкие
              части.
            </li>
            <li>
              Подогрейте в сотейнике растительное масло. Добавьте чеснок и лук. Обжарьте до
              золотистого цвета.
            </li>
            <li>Добавьте картофель и морковь. Жарьте, постоянно перемешивая, 10 минут.</li>
            <li>
              Залейте бульон. Посолите, поперчите, посыпьте розмарином. Тушите 7 минут. Проверьте
              овощи на готовность, если мягкие, добавьте сок, выжатый из половинки лимона.
            </li>
            <li>Если жидкости после варки осталось мало, то добавьте еще бульона.</li>
            <li>Взбейте суп блендером до однородности.</li>
            <li>
              Подавайте суп-пюре, посыпав натертым на мелкой терке сыром и мелко порубленным
              микрогрином.
            </li>
          </ul>
          <p>Если хотите сделать суп более полезным, то добавляйте несколько сортов микрозелени.</p>
          <h2>Суп-пюре из шампиньонов и микрозеленью</h2>
          <Picture webp={ImgW28_5} png={Img28_5} alt="Суп-пюре из шампиньонов и микрозеленью" />
          <p>
            Суп-пюре с микрозеленью и шампиньонами вкусно подавать с сырными гренками, заправлять
            сметаной и сливочным маслом.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>шампиньоны — 300 г;</li>
            <li>картофель — 650 г;</li>
            <li>лук — 200 г;</li>
            <li>черный перец молотый — 1 г;</li>
            <li>микрозелень — 50 г;</li>
            <li>растительное масло — 50 мл;</li>
            <li>вода — 1,5 л;</li>
            <li>сливки 20% - 250 мл.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Нарежьте брусками картофель. Залейте водой. Посолите и отварите до готовности.</li>
            <li>Нашинкуйте мелко лук. Грибы нарежьте.</li>
            <li>Подогрейте масло в сковороде. Засыпьте лук и обжарьте до золотистого цвета.</li>
            <li>
              Добавьте шампиньоны. Посолите. Готовьте на среднем огне, пока жидкость не испарится.
            </li>
            <li>Добавьте к картофелю грибы с луком и взбейте блендером.</li>
            <li>
              Залейте сливки в кастрюлю. Поперчите. Тщательно перемешайте и доведите до кипения (не
              кипятите).
            </li>
            <li>Налейте суп в тарелки, а затем посыпьте порубленной микрозеленью.</li>
          </ul>
          <p>В качестве украшения можете использовать несколько кусочков жареных шампиньонов.</p>
          <h2>Куриный суп-пюре с микрозеленью</h2>
          <p>
            Куриный суп-пюре получается нежным, с насыщенным сливочным вкусом. Благодаря особенной
            технологии приготовления у блюда однородная структура без волокнистых частей мяса.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>куриное филе — 350 г;</li>
            <li>соль — 0,5 ч. л.;</li>
            <li>картофель — 300 г;</li>
            <li>сливки 10% - 230 мл;</li>
            <li>лук — 170 г;</li>
            <li>морковь — 130 г;</li>
            <li>растительное масло — 50 мл;</li>
            <li>вода — 900 мл;</li>
            <li>чеснок — 2 зубчика;</li>
            <li>микрозелень — 150 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Промытое куриное филе нарежьте на небольшие кубики. Переложите в чашу блендера.
              Добавьте 50 мл воды. Измельчите. Через минуту добавьте еще 50 мл воды и снова взбейте.
              Через минуту долейте 100 мл воды и снова поработайте блендером. Добавьте еще 100 мл
              воды. Получится однородная масса, которая быстро сварится. Благодаря такому методу суп
              получится нежным и без плавающих волокон.
            </li>
            <li>Нарежьте полукольцами лук, а морковь натрите на крупной терке.</li>
            <li>
              Поставьте кастрюлю на огонь. Налейте растительное масло и прогрейте. Добавьте морковь
              и лук. Жарьте 5 минут, регулярно перемешивая.
            </li>
            <li>Добавьте измельченный на мелкой терке чеснок и жарьте 3 минуты.</li>
            <li>
              Картофель нарежьте на мелкие кубики. Налейте в кастрюлю 600 мл воды. Опустите
              картофель. Посолите и перемешайте. Доведите до кипения, варите на среднем огне 5
              минут.
            </li>
            <li>
              Добавьте, интенсивно перемешивая, куриную массу, чтобы она не успела свернуться и
              превратиться в куриные фрикадельки.
            </li>
            <li>Доведите до кипения. Варите на небольшом огне 5 минут.</li>
            <li>
              Взбейте суп погружным блендером. Добавьте сливки. Доведите до кипения и уберите с
              огня. Накройте крышкой и оставьте на 5 минут.
            </li>
            <li>Посыпьте суп, разлитый по тарелкам, порубленной микрозеленью.</li>
          </ul>
          <p>Вместо куриного филе можете использовать мясо утки или индейки.</p>
          <h2>Суп-пюре с колбасой и микрозеленью</h2>
          <Picture webp={ImgW28_6} png={Img28_6} alt="Суп-пюре с колбасой и микрозеленью" />
          <p>
            Суп-пюре с легким копченым ароматом понравится всей семье. Вместо свежей петрушки в
            зимнее время можете использовать сушеную зелень.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>горох — 260 г;</li>
            <li>черный перец — 1 г;</li>
            <li>микрозелень — 50 г;</li>
            <li>полукопченая колбаса — 180 г;</li>
            <li>вода — 500 мл;</li>
            <li>свежая петрушка — 15 г;</li>
            <li>пшеничная мука — 25 г;</li>
            <li>лук — 120 г;</li>
            <li>свиной жир — 90 г;</li>
            <li>соль.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Залейте горох водой и оставьте на 6 часов (можно на ночь). Промойте. Залейте холодной
              водой и отварите до мягкости.
            </li>
            <li>
              Отвар слейте в отдельную емкость. Горох протрите через сито. Соедините с отваром.
            </li>
            <li>Добавьте колбасу, нарезанную тонкими брусочками. Доведите до кипения.</li>
            <li>Нашинкованный лук поджарьте до золотистого цвета. Соедините с мукой.</li>
            <li>
              Отправьте луковую смесь в суп. Интенсивно перемешайте, чтобы не появились комочки.
              Посолите.
            </li>
            <li>
              Варите 10 минут. Заправьте черным молотым перцем и измельченной свежей петрушкой.
            </li>
            <li>Подавайте суп, украсив микрозеленью.</li>
          </ul>
          <p>
            Благодаря микрогрину, ваш обед будет не только вкусным, но и полезным. Не бойтесь
            экспериментировать. Любой рецепт можете изменять по своим вкусовым предпочтениям:
            добавлять любимые продукты, уменьшать или увеличивать густоту супа-пюре. Где купить
            свежую микрозелень, мы рассказывали{' '}
            <a href="https://vitamincus.me/blog/rasskazyvaem-gde-i-kak-kupit-mikrozelen-gotovuju-k-upotrebleniju-v-pishhu/">
              здесь
            </a>
            .
          </p>
        </>
      ),
      date: '29.09.2023',
    },
    {
      id: 'ispolzovanie-mikrozeleni-dlja-prigotovlenija-vtoryh-bljud',
      name: 'Вторые блюда с микрозеленью: рецепты и секреты приготовления',
      title: 'Использование микрозелени для приготовления вторых блюд',
      descr:
        'Вторые блюда с микрозеленью: карри с курицей и микрозеленью; закуска из лосося с микрозеленью; французский стейк тартар и другие рецепты с микрозеленью.',
      bg: [Img27_1, ImgW27_1],
      content: (
        <>
          <p>
            <b>Микрозелень</b> — это молодые побеги различных растений. Их высота составляет 5-10
            см. Раньше в ресторанах микрогрином украшали различные блюда, но, когда была доказана
            польза продукта, его стали использовать в домашних условиях в качестве основного и
            дополнительного ингредиента.
          </p>
          <p>
            <b>Вторые блюда с микрозеленью</b> — неотъемлемая часть рациона людей, которые выбрали
            ЗОЖ. Предлагаем лучшие рецепты, по которым вы можете готовить вкусные, полезные блюда
            для всей семьи.
          </p>
          <Picture
            webp={ImgW27_2}
            png={Img27_2}
            alt="Вторые блюда с микрозеленью: рецепты и секреты приготовления"
          />
          <h2>Вкус разных сортов микрогрина</h2>
          <p>
            <b>Первоначально</b> разнообразие микрогрина ограничивалось несколькими культурами.
            Сегодня для приготовления вторых блюд используют более 20 сортов.
          </p>
          <p>
            <b>На их основе</b> вы можете готовить здоровую пищу, которая своевременно восполнит
            запасы организма необходимыми витаминами, минералами и микроэлементами.
          </p>
          <p>
            <b>
              Чтобы приготовленное второе блюдо пришлось вам по вкусу, заранее изучите вкусовые
              оттенки каждого вида молодых ростков. У микрогрина:
            </b>
          </p>
          <ul>
            <li>
              Кресс-салата и горчицы насыщенный пурпурный цвет, нежный, пряный и слегка островатый
              вкус.
            </li>
            <li>Листовой горчицы ростки светло-селеного цвета, перечно-пряного вкуса.</li>
            <li>
              Дайкона зеленые листочки, приятный пряный аромат и стебли розового цвета. Блюда с
              этими ростками получаются питательными.
            </li>
            <li>
              Настурции островатый привкус. Ее лучше использовать совместно с другими сортами
              микрозелени, чтобы добиться более нежных вкусовых оттенков.
            </li>
            <li>
              Свеклы мягкий аромат. Цвет стеблей может быть различным: белым, розовым,
              ярко-оранжевым, фиолетовым.
            </li>
            <li>
              Лука характерный луковый привкус, который не будет ощущаться в блюдах при сочетании с
              другими сортами микрозелени.
            </li>
            <li>
              Кориандра более чистый, тонкий аромат кинзы. Ростки добавляют свежесть любому блюду.
            </li>
            <li>
              Моркови, кукурузы, кольраби пикантный, немного сладковатый вкус. Ростки отличаются
              сочностью.
            </li>
            <li>
              Шпината, листьев салата, брокколи нет определенного аромата и вкуса. Но отказываться
              от употребления этих ростков не стоит, так как это самые полезные для организма
              растения.
            </li>
            <li>
              Капусты чок-пай выраженный островатый вкус, который придает пикантность любому блюду.
            </li>
            <li>
              Брюквы привкус капусты и репы. В отличие от взрослых растений, микрогрин не содержит
              остроты и придает блюдам немного сладости.
            </li>
            <li>Горошка нежная структура и сладковатый привкус.</li>
            <li>Щавеля приятная легкая кислинка.</li>
            <li>
              Подсолнечника, руколы, льна, рыжика приятный, легкий ореховый аромат, который
              сочетается с любыми блюдами.
            </li>
            <li>Люцерны легкий травянистый вкус. Структура у ростков хрустящая и сочная.</li>
            <li>Сельдерея пряный, соленый вкус, но менее насыщенный, чем у взрослого растения.</li>
          </ul>
          <p>
            <b>Микрогрин можете подавать</b> как самостоятельный гарнир и использовать как пикантное
            дополнение ко всем вторым блюдам. Например, с говядиной и рыбными стейками отлично
            сочетаются корианндр и базилик, с морепродуктами — дайкон и горчица, с картофельным пюре
            — кресс-салат.
          </p>
          <p>
            <b>Перед тем как добавить молодые ростки в блюдо</b>, попробуйте разные сорта, так как
            взрослое растение часто по аромату и вкусу сильно отличается от микрогрина. Бывает, что
            вкус у молодых ростков более насыщенный, нейтральный или сильно перечный и острый.
          </p>
          <h2> В чем польза микрогрина для здоровья</h2>
          <Picture webp={ImgW27_3} png={Img27_3} alt="В чем польза микрогрина для здоровья" />
          <p>
            <b>Многие врачи и диетологи рекомендуют</b> включать микрогрин в ежедневный рацион, так
            как молодые ростки содержат в 50 раз больше витаминов, минералов и микроэлементов, чем
            взрослое растение.
          </p>
          <p>
            <b>Микрогрин:</b>
          </p>
          <ul>
            <li>приводит в норму обменные процессы в организме;</li>
            <li>выводит токсины;</li>
            <li>оказывает антибактериальное действие;</li>
            <li>укрепляет иммунитет;</li>
            <li>повышает жизненный тонус;</li>
            <li>очищает печень;</li>
            <li>снимает отечность;</li>
            <li>придает сияющий блеск волосам;</li>
            <li>укрепляет ногти;</li>
            <li>выводит холестерин;</li>
            <li>повышает уровень гемоглобина;</li>
            <li>благотворно влияет на кровеносный процесс и пищеварение;</li>
            <li>стимулирует аппетит;</li>
            <li>способствует похудению;</li>
            <li>очищает стенки сосудов от бляшек;</li>
            <li>служит профилактикой ОРВИ, гриппа.</li>
          </ul>
          <p>
            <b>Молодые ростки</b> помогают замедлять процессы старения, а клеткам быстрее
            восстанавливаться. Кроме того, микрогрин насыщает кровь кислородом.
          </p>
          <p>
            <b>Чтобы сохранить все полезные вещества</b>, нельзя подвергать микрозелень термической
            обработке. Храните ее в холодильнике, избегая повышенной влажности. Ранее мы уже писали{' '}
            <a href="https://vitamincus.me/blog/sostav-mikrozeleni-vitaminy-himicheskij-sostav-i-poleznye-veshhestva/">
              какие витамины и микроэлементы входят в состав микрозелени.
            </a>
          </p>
          <h2>Карри с курицей и микрозеленью</h2>
          <p>
            Вкусное и полезное второе блюдо с курицей придется по вкусу всей семье. Если хотите
            сделать его более сытным, то в состав добавьте цветную капусту.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>кокосовое молоко — 500 мл;</li>
            <li>семена кунжута;</li>
            <li>куриное бедро — 350 г;</li>
            <li>перец чили — 0,5 стручка;</li>
            <li>растительное масло — 70 мл;</li>
            <li>красная паста карри — 25 г;</li>
            <li>морковь — 200 г;</li>
            <li>корень имбиря — 20 г;</li>
            <li>лук — 200 г;</li>
            <li>чеснок — 2 зубка;</li>
            <li>соль;</li>
            <li>болгарский перец -150 г;</li>
            <li>консервированная кукуруза — 130 г;</li>
            <li>зеленая фасоль — 120 г;</li>
            <li>микрозелень — 50 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Нарежьте средними кубиками морковь, болгарский перец, корень имбиря, перец чили и
              куриное мясо. Измельчите чесночные зубчики. Лук нарежьте полукольцами.
            </li>
            <li>
              Прогрейте в скороде растительное масло. Засыпьте подготовленные ингредиенты. Добавьте
              кукурузу и фасоль. Посолите.
            </li>
            <li>
              Налейте в сковороду кокосовое молоко. Перемешайте. Готовьте на среднем режиме конфорки
              полчаса.
            </li>
            <li>Добавьте пасту карри. Тщательно размешайте до полного растворения.</li>
            <li>Готовое блюдо переложите в тарелки. Посыпьте кунжутом и микрозеленью.</li>
          </ul>
          <p>Если не любите острые блюда, то перец чили можете не добавлять.</p>
          <h2>Закуска из лосося с микрозеленью</h2>
          <Picture webp={ImgW27_4} png={Img27_4} alt="Закуска из лосося с микрозеленью" />
          <p>
            Этот рецепт придется по вкусу любителям сырой рыбы. Блюдо готовится быстро, а выглядит
            привлекательно и декоративно благодаря насыщенному цвету лосося.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>приправы (смесь перцев, соль) — по вкусу;</li>
            <li>чеснок — 1 зубчик;</li>
            <li>лук — 100 г;</li>
            <li>цедра лимона — 1 ч. ложка;</li>
            <li>микс микрозелени — 100 г;</li>
            <li>майонез.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Нарежьте мелкими кубиками рыбу. Посолите и тщательное перемешайте. Поместите в
              небольшое сервировочное кольцо. Слегка утрамбуйте. Если у вас нет сервировочного
              кольца, то сделайте круглую форму руками.
            </li>
            <li>Мелко порубите чеснок, лук.</li>
            <li>Цедру лимона натрите на мелкой терке.</li>
            <li>
              Выложите утрамбованную рыбу в центр красивого блюда. Вокруг распределите чеснок,
              майонез, цедру лимона и лук.
            </li>
            <li>Украсьте микрозеленью.</li>
          </ul>
          <p>Майонез в рецепте можете заменить соевым соусом.</p>
          <h2>Французский стейк тартар</h2>
          <p>
            Стейк тартар готовят только из говядины. Кроме того, его не жарят и не запекают. Это
            сырое мясо, которое подают с различными компонентами. Говяжью вырезку покупайте свежую,
            которая не подвергалась заморозке. Обращайте внимание, чтобы у нее не было жилок, жира и
            перепонок.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>говяжья вырезка — 250 г;</li>
            <li>кайенский перец — 1 г;</li>
            <li>лук — 80 г;</li>
            <li>свежая петрушка — 30 г;</li>
            <li>соль — 5 г;</li>
            <li>чеснок — 2 зубчика;</li>
            <li>куриный желток — 1 шт.;</li>
            <li>микрозелень — 70 г.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Мясо тщательно промойте. Удалите все жилки и перепонки.</li>
            <li>
              Говядину измельчите в мясорубке. Если техники нет под рукой, то разбейте мясо в кашу,
              используя деревянный молоток. Для переработки 250 г мяса понадобится около 10 минут.
              Готовую кашицу соскребайте с доски ложкой или ножом, оставшиеся куски дальше
              отбивайте. Фарш посолите.
            </li>
            <li>Репчатый лук порубите на мелкие кубики.</li>
            <li>Чеснок продавите через чесночный пресс.</li>
            <li>Измельчите петрушку и микрозелень.</li>
            <li>
              Для сервировки используйте специальную тарелку с несколькими отделениями. В самую
              большую часть выложите горкой мясо, посыпьте микрозеленью. В центр фарша поместите
              желток. В остальные ячейки вокруг говядины распределите подготовленные продукты и
              специи.
            </li>
          </ul>
          <p>
            Если блюдо подаете для группы людей, то выставляйте приправы на стол в общих маленьких
            мисочках. Говядину в этом случае подавайте в круглой тарелке с желтком в центре, посыпав
            микрозеленью. Ешьте говядину, приправляя по вкусу.
          </p>
          <h2>Рулетики из ветчины</h2>
          <p>
            Ветчина подходит для рецепта любая: свиная, из индейки и куриная. Сорт сыра можете
            менять, в этом случае каждый раз у блюда будет новый вкус.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>ветчина — 250 г;</li>
            <li>микрозелень — 150 г;</li>
            <li>сыр Дор Блю — 80 г;</li>
            <li>сушеный инжир — 7 шт.;</li>
            <li>листья салата — 8 шт.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>Нарежьте тонкими, круглыми пластинами ветчину.</li>
            <li>
              Вяленый инжир измельчите. Должны получиться небольшие кусочки. Форма может быть
              произвольной.
            </li>
            <li>Сыр Дор Блю порубите. Измельчите микрозелень.</li>
            <li>На ветчину поместите салатный лист. По центральной части расположите инжир.</li>
            <li>Посыпьте микрозеленью и укройте сыром Дор Блю.</li>
            <li>
              Заверните заготовку. Рулетики закрепите при помощи деревянных зубочисток, которые
              сверху украсьте кусочками инжира.
            </li>
          </ul>
          <p>Подавайте закуску к столу, украсив микрогрином.</p>
          <h2>Плов с микрогрином</h2>
          <Picture webp={ImgW27_5} png={Img27_5} alt="Плов с микрогрином" />
          <p>
            Это быстрый рецепт приготовления на сковородке, который по достоинству оценят занятые
            хозяйки. Блюдо получается ароматным и рассыпчатым.
          </p>
          <p>
            <b>Ингредиенты:</b>
          </p>
          <ul>
            <li>мякоть говядины — 350 г;</li>
            <li>морковь — 120 г;</li>
            <li>растительное масло — 50 мл;</li>
            <li>репчатый лук — 90 г;</li>
            <li>чеснок — 1 зубчик;</li>
            <li>приправа для плова — 0,5 ч. ложки;</li>
            <li>соль — 5 г;</li>
            <li>кипяток;</li>
            <li>микрозелень — 150 г;</li>
            <li>рис круглозернистый — 1 стакан.</li>
          </ul>
          <p>
            <b>Процесс приготовления:</b>
          </p>
          <ul>
            <li>
              Рис промойте. Залейте теплой водой (она не должна быть горячей), таким образом, чтобы
              зерна полностью были покрыты жидкостью. Отставьте в сторону.
            </li>
            <li>Лук нарежьте полукольцами, а морковь — брусочками.</li>
            <li>
              Растительное масло прогрейте в сковороде. Засыпьте лук. На минимальном режиме конфорки
              готовьте несколько минут, пока не выпарится жидкость, а овощ не станет прозрачным. Не
              зажаривайте.
            </li>
            <li>Добавьте морковь. Перемешайте и потомите 8 минут, периодически перемешивая.</li>
            <li>
              Говядину нарежьте небольшими кубиками. Отправьте к овощам. Быстро перемешайте. Жарьте
              до румяности.
            </li>
            <li>Посыпьте приправой для плова. Тщательно перемешайте.</li>
            <li>
              Налейте кипяток, таким образом, чтобы жидкость на 1 см покрыла говядину. Включите
              максимальный огонь. После того как закипит, убавьте режим конфорки и тушите полчаса
              под закрытой крышкой, чтобы вода быстро не испарялась. Посолите.
            </li>
            <li>
              Слейте с риса воду. Пересыпьте в сковороду. Залейте кипятком, чтобы она на 1 см была
              выше риса. В центр поместите очищенную головку чеснока.
            </li>
            <li>Тушите, не накрывая крышкой, до готовности риса.</li>
            <li>Отключите огонь. Накройте плов крышкой и оставьте на четверть часа.</li>
          </ul>
          <p>
            Готовый плов переложите на большое блюдо горкой. Сверху обильно посыпьте микрозеленью.
            Ешьте ложкой или руками. Рецепты салатов с микрогрином, найдете{' '}
            <a href="https://vitamincus.me/blog/reczepty-kak-upotreblyat-mikrozelen-salaty-blyuda-i-smuzi/">
              здесь
            </a>
            .
          </p>
        </>
      ),
      date: '28.09.2023',
    },
    {
      id: 'vkljuchenie-mikrozeleni-v-racion-pitanija',
      name: 'Вся правда о микрозелени: как часто включать в рацион, кому противопоказана и какую пользу приносит организму',
      title: 'Включение микрозелени в рацион питания',
      descr:
        'Рассказываем всю правду о микрозелени: как часто включать в рацион, кому противопоказана и какую пользу она приносит организму',
      bg: [Img26_1, ImgW26_1],
      content: (
        <>
          <p>
            <b>Микрозелень</b> — проростки семян в возрасте от 5 до 14 дней. Многие ученые
            утверждают, что микрогрин — самый полный витоминно-минеральный комплекс в мире по многим
            показателям.
          </p>
          <p>
            <b>Предлагаем разобраться</b>, как часто можно употреблять молодые ростки, какую пользу
            они приносят организму.
          </p>
          <Picture
            webp={ImgW26_2}
            png={Img26_2}
            alt="Вся правда о микрозелени: как часто включать в рацион, кому противопоказана и какую пользу приносит организму"
          />
          <h2>Почему важно включать микрогрин в ежедневный рацион</h2>
          <p>
            <b>Микрозелень</b> — кладезь витаминов. Поэтому, для хорошего самочувствия ее надо
            включать в ежедневный рацион взрослых и детей. Все полезные вещества, которые содержатся
            в проростках, организм быстро и полностью усваивает.
          </p>
          <p>
            <b>К самому ценному свойству микрогрина</b> относят высокую концентрацию минералов и
            витаминов, которая в 30-50 раз больше, по сравнению с взрослыми растениями.
          </p>
          <p>
            <b>Полезная доза микрогрина в день</b> — около 50 грамм. Этого веса достаточно для
            получения необходимой порции полезных веществ.
          </p>
          <p>
            Микрогрин содержит{' '}
            <a href="https://vitamincus.me/blog/myagkaya-kletchatka-v-mikrozeleni-i-poleznye-svojstva-kletchatki">
              мягкую клетчатку.
            </a>{' '}
            В активной биодоступной форме молодые ростки содержат:
          </p>
          <ul>
            <li>ферменты;</li>
            <li>минералы;</li>
            <li>фитонутриенты.</li>
          </ul>
          <p>
            <b>Активные ферменты</b> отвечают за быстрое усваивание пищи, а клетчатка благоприятно
            влияет на микрофлору. Микрогрин быстро восполняет дефицит полезных для организма
            веществ, что особенно важно в межсезонье и на диете. Также необходимо включать в
            ежедневный рацион микрогрин вегетарианцам.
          </p>
          <p>
            <b>Можете ежедневно употреблять</b> молодые ростки специй, овощей и бобовых. Выбирать
            один сорт или микс. Микрогрин содержит около 70% активного хлорофилла, который по
            составу напоминает гемоглобин (самый важный и дефицитный элемент в организме). Поэтому
            важно давать ежедневно проростки людям, страдающим онкологическими заболеваниями.
          </p>
          <p>
            <b>
              Нежные ростки помогают быстрее вырабатываться интерферону, который подавляет вирус:
            </b>
          </p>
          <ul>
            <li>ОРЗ;</li>
            <li>гриппа;</li>
            <li>герпеса.</li>
          </ul>
          <p>
            <b>Микрогрин способствует</b> быстрому восстановлению организма после болезни, укрепляет
            суставы и мышцы, улучшает состояние кожных покровов, волос и зрения. При ежедневном
            употреблении молодых ростков утром, вы получите мощный заряд энергии на целый день.
            Более подробно о пользе микрозелени читайте{' '}
            <a href="https://vitamincus.me/blog/mikrozelen-dlja-ukreplenija-zdorovja/">здесь</a>.
          </p>
          <h2>Какую микрозелень нельзя включать в рацион питания</h2>
          <Picture
            webp={ImgW26_3}
            png={Img26_3}
            alt="Какую микрозелень нельзя включать в рацион питания"
          />
          <p>
            <b>Нельзя употреблять микрогрин семейства Пасленовых, к которому относят:</b>
          </p>
          <ul>
            <li>помидоры;</li>
            <li>баклажаны;</li>
            <li>перец;</li>
            <li>картофель.</li>
          </ul>
          <p>
            Проростки этих растений <b>содержат соланин</b>. Это ядовитое вещество, которое приводит
            к возникновению проблем с пищеварением. Также нельзя включать в рацион ростки тыквенных
            культур, так как микрогрин из них получается очень горьким.
          </p>
          <p>
            <b>Кроме того</b>, в состав ростков фасоли входят токсичные вещества, которые в большом
            количестве приводят к отравлению.
          </p>
          <h2>Влияние микрозелени на прыщи и морщины</h2>
          <p>
            <b>Регулярное употребление микрогрина</b> помогает приостанавливать признаки старения.
            Молодые ростки содержат полифенолы — мощные антиоксиданты, способствующие выработке
            эластина и коллагена. Это основные белки, которые помогают поддерживать упругость и
            гладкость кожи.
          </p>
          <p>
            <b>Причина появления акне</b> — воспалительные процессы. Микрогрин оказывает
            противовоспалительное действие. Благодаря чему уменьшается воспаление, снижается частота
            и тяжесть высыпаний. В результате лицо становится чистым и приобретает здоровый цвет.
          </p>
          <p>
            <b>Молодые ростки</b> стимулируют приток крови к кожным покровам, благодаря чему кожа
            получает больше питательных веществ и кислорода. В результате она более быстро
            производит новые клетки и восстанавливает разные повреждения.
          </p>
          <h2>Кому не следует употреблять микрозелень</h2>
          <p>
            Микрозелень — <b>не лекарство</b>, а продукт с высокой биологической ценностью.
            Осторожно употреблять микрогрин надо людям, у которых есть:
          </p>
          <ul>
            <li>
              проблемы со свертываемостью крови, так как в состав входит большое количество витамина
              К;
            </li>
            <li>острые заболевания ЖКТ;</li>
            <li>язвы желудка и двенадцатиперстной кишки.</li>
          </ul>
          <p>
            Не следует включать в рацион микрозелень при индивидуальной непереносимости, так как
            отдельные побеги могут вызывать аллергическую реакцию, которая чаще всего проявляется в
            виде кожной сыпи. Редко могут возникать:
          </p>
          <ul>
            <li>отек гортани;</li>
            <li>диарея;</li>
            <li>тошнота;</li>
            <li>рвота.</li>
          </ul>
          <p>
            Аллергические реакции на микрозелень <b>встречаются крайне редко</b>. Но, если у вас
            есть аллергия, на какие-либо овощи, бобовые, злаковые, то от употребления их ростков вам
            надо отказаться.
          </p>
          <p>
            Перед сном микрозелень лучше не есть, так как она активизирует деятельность центральной
            нервной системы.
          </p>
          <h2>Вкус микрозелени</h2>
          <Picture webp={ImgW26_4} png={Img26_4} alt="Вкус микрозелени" />
          <p>
            <b>
              Микрогрин обладает нежной текстурой, ярким вкусом и приятным ароматом исходного
              растения:
            </b>
          </p>
          <ul>
            <li>рукколы — орехово-горчичный привкус;</li>
            <li>гороха — нежный, сладковатый;</li>
            <li>капусты — слегка сладковатый;</li>
            <li>щавеля — лимонный, кислый;</li>
            <li>кресс-салата — жгучий, остро-горький;</li>
            <li>кориандра — цитрусовый;</li>
            <li>редиса — островато-свежий;</li>
            <li>подсолнечника — орехово-сладковатый;</li>
            <li>горчицы — пряный, жгучий;</li>
            <li>клевера — нейтральный;</li>
            <li>кукурузы — сладковатый, сочный;</li>
            <li>редиса — остро-жгучий;</li>
            <li>горчицы — сладковатый, острый;</li>
            <li>кольраби — сладкий, мягкий;</li>
            <li>чечевица — сладкий (напоминает вкус молодого горошка);</li>
            <li>лука — приятный, пикантный.</li>
          </ul>
          <p>
            <b>Выбирая сорт микрогрина</b>, учитывайте индивидуальные предпочтения. Маленьким детям
            понравятся по вкусу сочные и сладкие ростки красной капусты, гороха и подсолнечника.
            Входящие в их состав витамины С, В, ретинол, цитокинины помогут правильному развитию
            организма и здоровому росту.
          </p>
          <p>
            <b>Женщины по достоинству оценят:</b>
          </p>
          <ul>
            <li>ореховую мягкость руколы, амаранта;</li>
            <li>летнюю свежесть настурции, бораго;</li>
            <li>пикантную горечь кресс-салата, цикория.</li>
          </ul>
          <p>
            <b>Входящие в состав ростков</b> активные антиоксиданты наполнят организм энергией.
            Минералы и витамины сделают более крепкими волосы, ногти и улучшат состояние кожных
            покровов. При ежедневном употреблении вы сможете снизить лишний вес и нормализовать
            метаболизм.
          </p>
          <p>
            <b>Мужчинам понравится</b> терпкий, брутальный, яркий вкус лука, базилика, кориандра.
            Эти растения богаты витамином Е, эфирными маслами и цинком, которые помогают усиливать
            мужскую силу и повышать иммунитет.
          </p>
          <p>
            <b>При выборе сорта микрозелени</b> учитывайте свои вкусовые предпочтения. Например,
            если вам нравится вкус чечевицы, то микрогрин этого растения тоже придется по вкусу. Но
            из этого правила бывают исключения. Например, ростки щавеля и кольраби отличаются
            значительно от вкуса взрослого растения. Молодые побеги содержат ценные биологические
            вещества и лишены резкого специфического привкуса.
          </p>
          <p>
            <a href="https://vitamincus.me/blog/10-interesnyh-faktov-o-mikrozeleni">
              В этой статье
            </a>{' '}
            вы можете прочитать о самых интересных фактах, связанных с молодыми ростками.
          </p>
          <h2>Правила хранения микрогрина</h2>
          <p>
            <b>Микрогрин, как любая зелень и овощи</b>, долго будет оставаться свежей, при
            соблюдении определенных условий. Чтобы сохранить хруст и свежесть ростков надо
            поддерживать правильный уровень влажности.
          </p>
          <p>
            <b>Самый простой способ:</b>
          </p>
          <ul>
            <li>
              Возьмите два влажных полотенца. Поместите между ними микрогрин, но не придавливайте.
            </li>
            <li>Положите в пластиковый контейнер и закройте крышкой.</li>
            <li>Поставьте в холодильник на среднюю полку.</li>
          </ul>
          <p>
            <b>В таких условиях ростки</b> останутся свежими в течение недели. Доставайте микрогрин
            из контейнера, непосредственно перед подачей на стол. В этом случае ростки не завянут и
            не потеряют сочность.
          </p>
          <p>
            Помните, что микрогрин любит холод. Идеальный температурный режим +2…+4°С. Самое
            холодное место в холодильнике — верхняя полка у стенки. На нижней полке температура
            обычно составляет около +8°С. Поэтому храните микрогрин на средней полке холодильного
            отделения.
          </p>
          <p>
            Во время роста микрозелень любит солнечный свет, но после срезки солнце изменяет вкус и
            внешний вид ростков. Поэтому держите их в темноте.
          </p>
          <p>
            <b>Перед употреблением</b> мойте микрозелень, но помните, что она быстро мнется. Поэтому
            аккуратно сполосните ростки холодной водой. Положите их на дуршлаг и дайте время каплям
            стечь. Мойте только перед подачей на стол, а не перед тем как поместить в холодильник.
            Иначе растение, став мокрым, быстро испортится.
          </p>
          <h2>Правила употребления микрозелени</h2>
          <Picture webp={ImgW26_5} png={Img26_5} alt="Правила употребления микрозелени" />
          <p>
            Добавлять микрогрин можно в любые блюда. Чтобы извлечь максимум пользы из каждого
            ростка, <b>соблюдайте простые правила:</b>
          </p>
          <ul>
            <li>Употребляйте только свежий микрогрин.</li>
            <li>Не держите молодые ростки в холодильнике более 7 дней.</li>
            <li>Не маринуйте, не замораживайте, не консервируйте.</li>
          </ul>
          <p>
            <b>Термическая обработка</b> убивает большую часть полезных компонентов. Поэтому
            добавляйте микрогрин в остывшие блюда.
          </p>
          <p>
            <b>Молодые ростки</b> — универсальная приправа, которая может выступать самостоятельным
            гарниром. Повара выделили несколько отдельных, удачных сочетаний:
          </p>
          <ul>
            <li>с мясом сочетается лук-батун, кориандр, горчица.</li>
            <li>с морепродуктами — редис, брокколи.</li>
            <li>с овощным рагу, супами — щавель, настурция, перилла.</li>
            <li>с омлетом — кольраби, кресс-салат.</li>
            <li>с птицей — рукола, базилик.</li>
            <li>с мягкими сырами — пажидник, цикорий, амарант.</li>
            <li>с десертами и ягодными смузи — горох, люцерна, подсолнечник.</li>
            <li>
              с овощами — ростки маш (вводите эту микрозелень в рацион постепенно, чтобы избежать
              проблем с пищеварением).
            </li>
          </ul>
          <p>
            <b>Микрогрин добавляют</b> в салаты, супы, бутерброды, смузи, соусы и коктейли. В
            ресторанах маленькие ростки служат украшением блюд. Ранее мы писали{' '}
            <a href="https://vitamincus.me/blog/recepty-i-sovety-po-prigotovleniju-koktejlej-i-smuzi-iz-mikrozeleni">
              о лучших рецептах коктейлей и смузи на основе микрозелени.
            </a>
          </p>
          <p>
            <b>Кроме того</b>, молодые ростки используют в косметических целях. На их основе делают
            отвары и домашние маски для лица. Например, микрогрин протирают в блендере, затем
            переливают в ячейки для льда и замораживают. Получившимися кубиками протирают кожу.
            Процедура помогает тонизировать кожные покровы, насыщать ее полезными элементами.
          </p>
          <p>
            Разноцветные, сочные листья и стебли микрогрина помогают сделать утонченным и изысканным
            любое, даже самое простое блюдо. Для первого знакомства с микрозеленью выбирайте готовые
            миксы. Можете приобрести несколько сортов и создать собственную уникальную комбинацию,
            смешивая крестоцветные травы с пряными. У каждого сорта существуют свои вкусовые
            особенности. Попробуйте поэкспериментировать и выбрать лучший для вас вариант.
          </p>
        </>
      ),
      date: '28.09.2023',
    },
    {
      id: 'myagkaya-kletchatka-v-mikrozeleni-i-poleznye-svojstva-kletchatki',
      name: 'Микрозелень как дополнительный источник мягкой клетчатки',
      title: 'Мягкая клетчатка в микрозелени и полезные свойства клетчатки',
      descr:
        'Польза для организма от мягкой клетчатки, которая содержится в микрозелени. Мягкая клетчатка – это разновидность растворимой клетчатки.',
      bg: [Img24_1, ImgW24_1],
      content: (
        <>
          <p>
            <b>У микрозелени много полезных свойств.</b> В этой статье мы рассмотрим микрозелень как
            дополнительный источник клетчатки.
          </p>
          <p>
            <b>Микрозелень</b> – это нежные ростки растений, которые выращиваются в условиях высокой
            влажности и являются <b>дополнительным источником клетчатки</b>. Более того, микрозелень
            является <b>источником «мягкой клетчатки»</b>
          </p>
          <Picture
            webp={ImgW24_2}
            png={Img24_2}
            alt="Микрозелень как дополнительный источник мягкой клетчатки"
          />
          <h2>Мягкая клетчатка в микрозелени</h2>
          <p>
            Видов микрозелени много, поэтому мы <b>обобщим и усредним информацию</b>, приведя её к
            «общему знаменателю» Важен тот факт, что микрозелень содержит так называемую{' '}
            <b>«мягкую клетчатку»</b>.
          </p>
          <p>
            <b>Мягкая клетчатка</b> – это разновидность растворимой клетчатки, которая содержится в
            некоторых продуктах растительного происхождения (например, в микрозелени). Она играет
            важную роль в пищеварении и здоровье человека.
          </p>
          <p>
            Одним из главных преимуществ мягкой клетчатки является ее способность улучшать работу
            кишечника. Она помогает{' '}
            <b>ускорить процесс переваривания пищи и улучшить кишечную микрофлору</b>, что снижает
            риск различных заболеваний желудочно-кишечного тракта, таких как запоры, дивертикулез и
            рак толстой кишки.
          </p>
          <p>
            Кроме того, мягкая клетчатка способствует <b>снижению уровня холестерина в крови</b>.
            Она связывает жирные кислоты и холестерин, что помогает им выйти из организма через
            кишечник. Это снижает риск сердечно-сосудистых заболеваний и инсультов.
          </p>
          <p>
            Мягкая клетчатка также может помочь <b>контролировать уровень сахара в крови</b>. Она
            замедляет процесс усвоения углеводов, что позволяет уменьшить колебания уровня глюкозы в
            крови. Это особенно важно для людей, страдающих сахарным диабетом.
          </p>
          <p>
            Наконец, мягкая клетчатка <b>может помочь в контроле веса</b>. Она создает ощущение
            сытости и уменьшает аппетит, что позволяет снизить количество потребляемых калорий.
          </p>
          <p>
            Чтобы получить достаточное количество мягкой клетчатки в рационе питания,{' '}
            <b>рекомендуется употреблять 25-30 граммов клетчатки в день.</b>
          </p>
          <h2>
            В целом, мягкая клетчатка микрозелени является важным элементом здорового рациона
            питания
          </h2>
          <Picture
            webp={ImgW24_3}
            png={Img24_3}
            alt="В целом, мягкая клетчатка микрозелени является важным элементом здорового рациона питания"
          />
          <p>
            <b>Она помогает</b> поддерживать здоровье кишечника, снижает уровень холестерина и
            сахара в крови, помогает контролировать вес и предотвращает различные заболевания.
            Поэтому, следует обязательно включать мягкую клетчатку в свой рацион питания.
          </p>
          <p>
            <b>Микрозелень можно выращивать дома</b>, на подоконнике или в специальных контейнерах.
            Для выращивания микрозелени необходимо выбрать качественные семена и посадочный
            материал. В качестве посадочного материала можно использовать специальный грунт для
            микрозелени.
          </p>
          <p>
            <b>Выращивание микрозелени</b> – это простой и доступный способ получить дополнительный
            источник клетчатки и других питательных веществ. Микрозелень можно добавлять в салаты,
            супы, бутерброды и другие блюда, чтобы придать им свежий вкус и улучшить их питательную
            ценность.
          </p>
          <p>
            <b>
              Можно не выращивать микрозелень, а{' '}
              <Link to="/">покупать уже готовые миксы и проростки!</Link>
            </b>{' '}
            В нашем ассортименте более 10 вариантов на любой вкус!
          </p>
        </>
      ),
      date: '11.07.2023',
    },
    {
      id: 'polza-i-vkus-prorostkov-masha-bobov-mung',
      name: 'Польза и вкус проростков маша (бобов мунг)',
      title: 'Всё про проростки бобов мунг (проростки маша)',
      descr:
        'Вкус и польза ростков бобов мунг, использование в кулинарии, пищевая ценность ростков бобов мунг и польза от их употребления',
      bg: [Img25_1, ImgW25_1],
      content: (
        <>
          <p>
            <b>Проростки бобов мунг (маша)</b> — это невероятно полезный и вкусный продукт, который
            можно добавлять в различные блюда или употреблять в качестве отдельной закуски. Эти
            проростки получают из зеленых бобов мунг, которые содержат большое количество
            питательных веществ.
          </p>
          <h2>Преимущества употребления ростков бобов мунг</h2>
          <p>
            <b>Проростки бобов мунг богаты</b> белками, витаминами и минералами, включая железо,
            кальций, магний, фосфор, калий, витамины А, С и Е. Они также содержат антиоксиданты,
            которые помогают защитить организм от свободных радикалов и предотвращают развитие
            многих заболеваний.
          </p>
          <p>
            Этот продукт обладает <b>множеством преимуществ для здоровья</b>. Он помогает улучшить
            пищеварение, способствует снижению уровня холестерина в крови, укрепляет иммунную
            систему, стимулирует рост и развитие клеток, а также улучшает состояние кожи, волос и
            ногтей.
          </p>
          <h2>Вкус ростков бобов мунг и использование в кулинарии</h2>
          <Picture
            png={Img25_2}
            webp={ImgW25_2}
            alt="Вкус ростков бобов мунг и использование в кулинарии"
          />
          <p>
            Проростки бобов мунг могут использоваться <b>в качестве ингредиента</b> для
            приготовления салатов, супов, каш и других блюд. Они также могут быть добавлены в смузи
            или употребляться в качестве отдельной закуски.
          </p>
          <p>
            Кроме того, проростки бобов мунг <b>имеют приятный вкус и аромат</b>, что делает их
            любимым продуктом многих людей. Они являются отличной альтернативой мясу для
            вегетарианцев и веганов, так как содержат большое количество белка.
          </p>
          <p>
            <b>Вкус проростков бобов мунг</b> можно описать как свежий и легкий, с легким ореховым
            оттенком. Они имеют нежную текстуру и приятный аромат. Вкус может быть немного
            сладковатым или горьковатым в зависимости от степени зрелости бобов и способа их
            приготовления. В целом, проростки бобов мунг имеют деликатный вкус, который хорошо
            сочетается с другими ингредиентами в блюдах.
          </p>
          <h2>Пищевая ценность ростков бобов мунг и польза от их употребления</h2>
          <p>
            <b>Примерные значения КБЖУ на 100 грамм. </b>
          </p>
          <p>
            <b>Калорийность: 29,81 ККал</b>
          </p>
          <ul>
            <li>
              <b>Белки: 3,08 г.</b>
            </li>
            <li>
              <b>Жиры: 0,19 г.</b>
            </li>
            <li>
              <b>Углеводы: 5,96 г.</b>
            </li>
          </ul>
          <p>
            Проростки бобов мунг являются ценным источником питательных веществ. Они содержат
            витамины А, В, С, Е и К, а также минералы, такие как калий, кальций, железо и магний.
            Проростки бобов мунг также богаты белком, клетчаткой и антиоксидантами. Они помогают
            улучшить пищеварение, снизить уровень холестерина в крови и укрепить иммунную систему.
          </p>
          <Picture webp={ImgW25_3} png={Img25_3} alt="Польза и вкус проростков маша (бобов мунг)" />
          <p>
            В целом, проростки бобов мунг — это <b>замечательный продукт</b> для тех, кто следит за
            своим здоровьем и питанием. Они не только вкусные и питательные, но и способствуют
            улучшению состояния организма в целом. Рекомендуется добавлять этот продукт в свой
            рацион, чтобы получить максимальную пользу для здоровья.
          </p>
        </>
      ),
      date: '10.07.2023',
    },
    {
      id: 'dzhakuzi-dlya-mikrozeleni',
      name: i18n.__('blog-23-name'),
      title: i18n.__('blog-23-title'),
      descr: i18n.__('blog-23-descr'),
      bg: [ImgW23_1],
      content: (
        <>
          <Picture webp={ImgW23_1} alt="ТОП-5 идей чем можно перекусить полезно и вкусно" />
          <p>{i18n.__('blog-23-descr-1')}</p>
          <p>{i18n.__('blog-23-descr-2')}</p>
          <p>{i18n.__('blog-23-descr-3')}</p>
          <p>{i18n.__('blog-23-descr-4')}</p>
          <p>{i18n.__('blog-23-descr-5')}</p>
          <p>{i18n.__('blog-23-descr-6')}</p>

          <p>{i18n.__('blog-23-descr-7')}</p>
        </>
      ),
      date: '20.06.2023',
    },
    {
      id: 'pjat-produktov-v-racione-beremennyh',
      name: i18n.__('blog-20-name'),
      title: i18n.__('blog-20-title'),
      descr: i18n.__('blog-20-descr'),
      bg: [Img20_1, ImgW20_1],
      content: (
        <>
          <p>{i18n.__('blog-20-descr-1')}</p>
          <p>{i18n.__('blog-20-descr-2')}</p>
          <p>{i18n.__('blog-20-descr-3')}</p>
          <Picture
            webp={ImgW20_1}
            png={Img20_1}
            alt="5 продуктов, которые должны быть в рационе беременных"
          />
          <h2>{i18n.__('blog-20-descr-4')}</h2>
          <p>{i18n.__('blog-20-descr-5')}</p>
          <p>{i18n.__('blog-20-descr-6')}</p>
          <p>
            <Link to="/">{i18n.__('blog-20-link-1')}</Link>
            {i18n.__('blog-20-descr-7')}
          </p>
          <p>{i18n.__('blog-20-descr-8')}</p>
          <p>{i18n.__('blog-20-descr-9')}</p>
          <p>{i18n.__('blog-20-descr-10')}</p>
          <p>{i18n.__('blog-20-descr-11')}</p>
          <p>{i18n.__('blog-20-descr-12')}</p>
          <p>{i18n.__('blog-20-descr-13')}</p>
          <Picture
            webp={ImgW20_2}
            png={Img20_2}
            alt="ТОП-5 обязательных продуктов при беременности"
          />
        </>
      ),
      date: '20.02.2023',
    },
    {
      id: 'mikrozelen-iz-losino-petrovskogo-na-polkakh-dubajskikh-supermarketov',
      name: i18n.__('blog-1-name'),
      title: i18n.__('blog-1-title'),
      descr: i18n.__('blog-1-descr'),
      bg: [Img1_1, ImgW1_1],
      content: (
        <>
          <h2>{i18n.__('blog-1-title-1')}</h2>
          <p>{i18n.__('blog-1-descr-1')}</p>
          <p>{i18n.__('blog-1-descr-2')}</p>
          <p>{i18n.__('blog-1-descr-3')}</p>
          <p>{i18n.__('blog-1-descr-4')}</p>
          <p>{i18n.__('blog-1-descr-5')}</p>
          <video style={{ width: '100%', marginBottom: '3rem' }} controls={true}>
            <source src={mp4} type="video/mp4" />
          </video>
          <Picture webp={ImgW1_1} png={Img1_1} alt="Изготовление ростковых салатов" />
          <Picture webp={ImgW1_2} png={Img1_2} alt="Работа по производству микрозелени" />
          <Picture webp={ImgW1_3} png={Img1_3} alt="Производственный процесс микрозелени" />
        </>
      ),
      date: '10.02.2023',
    },
    {
      id: 'mikrozelen-v-racione-rebenka',
      name: i18n.__('blog-2-name'),
      title: i18n.__('blog-2-title'),
      descr: i18n.__('blog-2-descr'),
      bg: [Img2_1, ImgW2_1],
      content: (
        <>
          <p>{i18n.__('blog-2-descr-1-1')}</p>
          <p>
            {i18n.__('blog-2-descr-1-2')}
            <Link to="/">{i18n.__('blog-2-link-1')}</Link>
            {i18n.__('blog-2-descr-1-3')}
          </p>
          <h2>{i18n.__('blog-2-descr-1-4')}</h2>
          <p>{i18n.__('blog-2-descr-1-5')}</p>
          <p>{i18n.__('blog-2-descr-1-6')}</p>
          <Picture webp={ImgW2_1} png={Img2_1} alt="Включение микрозелени в детский рацион" />
          <p>{i18n.__('blog-2-descr-1')}</p>
          <p>{i18n.__('blog-2-descr-2')}</p>
          <h2>{i18n.__('blog-2-title-2')}</h2>
          <h3>{i18n.__('blog-2-title-3')}</h3>
          <p>{i18n.__('blog-2-descr-3')}</p>
          <h3>{i18n.__('blog-2-title-4')}</h3>
          <p>{i18n.__('blog-2-descr-4')}</p>
          <h3>{i18n.__('blog-2-title-5')}</h3>
          <p>{i18n.__('blog-2-descr-5')}</p>
          <h3>{i18n.__('blog-2-title-6')}</h3>
          <p>{i18n.__('blog-2-descr-6')}</p>
          <h3>{i18n.__('blog-2-title-7')}</h3>
          <p>{i18n.__('blog-2-descr-7')}</p>
          <h2>{i18n.__('blog-2-title-8')}</h2>
          <Picture webp={ImgW2_2} png={Img2_2} alt="Микрозелень в рационе ребенка" />
          <p>{i18n.__('blog-2-descr-8')}</p>
          <p>{i18n.__('blog-2-descr-9')}</p>
          <p>{i18n.__('blog-2-descr-10')}</p>
          <p>{i18n.__('blog-2-descr-11')}</p>
        </>
      ),
      date: '02.02.2022',
    },
    {
      id: 'ukreplenie-immuniteta-mikrozelenju',
      name: i18n.__('blog-3-name'),
      title: i18n.__('blog-3-title'),
      descr: i18n.__('blog-3-descr'),
      bg: [Img3_1, ImgW3_1],
      content: (
        <>
          <p>{i18n.__('blog-3-descr-1')}</p>
          <p>{i18n.__('blog-3-descr-2')}</p>
          <p>{i18n.__('blog-3-descr-3')}</p>
          <h2>{i18n.__('blog-3-title-2')}</h2>
          <Picture webp={ImgW3_1} png={Img3_1} alt={i18n.__('blog-3-title-2')} />
          <p>{i18n.__('blog-3-descr-4')}</p>
          <p>{i18n.__('blog-3-descr-5')}</p>
          <h2>{i18n.__('blog-3-title-3')}</h2>
          <Picture webp={ImgW3_2} png={Img3_2} alt={i18n.__('blog-3-title-3')} />
          <p>{i18n.__('blog-3-descr-6')}</p>
          <p>{i18n.__('blog-3-descr-7')}</p>
          <h2>{i18n.__('blog-3-title-4')}</h2>
          <Picture webp={ImgW3_3} png={Img3_3} alt={i18n.__('blog-3-title-4')} />
          <p>{i18n.__('blog-3-descr-8')}</p>
          <p>{i18n.__('blog-3-descr-9')}</p>
          <h2>{i18n.__('blog-3-title-5')}</h2>
          <Picture webp={ImgW3_4} png={Img3_4} alt={i18n.__('blog-3-title-5')} />
          <p>{i18n.__('blog-3-descr-10')}</p>
          <p>{i18n.__('blog-3-descr-11')}</p>
          <h2>{i18n.__('blog-3-title-6')}</h2>
          <Picture webp={ImgW3_5} png={Img3_5} alt={i18n.__('blog-3-title-6')} />
          <p>{i18n.__('blog-3-descr-12')}</p>
          <p>{i18n.__('blog-3-descr-13')}</p>
          <h2>{i18n.__('blog-3-title-7')}</h2>
          <p>{i18n.__('blog-3-descr-14')}</p>
          <p>
            {i18n.__('blog-3-descr-15')}
            <Link to="/">{i18n.__('blog-3-link-1')}</Link>
          </p>
        </>
      ),
      date: '09.11.2022',
    },
    {
      id: 'polza-mikrozeleni-goroha',
      name: i18n.__('blog-4-name'),
      title: i18n.__('blog-4-title'),
      descr: i18n.__('blog-4-descr'),
      bg: [Img4_1, ImgW4_1],
      content: (
        <>
          <p>{i18n.__('blog-4-descr-1')}</p>
          <Picture webp={ImgW4_1} png={Img4_1} alt="Польза микрозелени гороха" />
          <p>{i18n.__('blog-4-descr-2')}</p>
          <p>{i18n.__('blog-4-descr-3')}</p>
          <h2>{i18n.__('blog-4-title-2')}</h2>
          <Picture webp={ImgW4_2} png={Img4_2} alt={i18n.__('blog-4-title-2')} />
          <p>{i18n.__('blog-4-descr-4')}</p>
          <h3>{i18n.__('blog-4-title-3')}</h3>
          <p>{i18n.__('blog-4-descr-5')}</p>
          <p>{i18n.__('blog-4-descr-6')}</p>
          <h3>{i18n.__('blog-4-title-4')}</h3>
          <p>
            {i18n.__('blog-4-descr-7-1')}
            <Link to="/">{i18n.__('blog-4-link-1')}</Link>
            {i18n.__('blog-4-descr-7')}
          </p>
          <h2>{i18n.__('blog-4-title-5')}</h2>
          <Picture webp={ImgW4_3} png={Img4_3} alt={i18n.__('blog-4-title-5')} />
          <p>{i18n.__('blog-4-descr-8')}</p>
          <p>{i18n.__('blog-4-descr-9')}</p>
          <h3>{i18n.__('blog-4-title-6')}</h3>
          <Picture webp={ImgW4_4} png={Img4_4} alt={i18n.__('blog-4-title-6')} />
          <p>{i18n.__('blog-4-descr-10')}</p>
          <p>{i18n.__('blog-4-descr-11')}</p>
          <h3>{i18n.__('blog-4-title-7')}</h3>
          <Picture webp={ImgW4_5} png={Img4_5} alt={i18n.__('blog-4-title-7')} />
          <p>{i18n.__('blog-4-descr-12')}</p>
          <p>{i18n.__('blog-4-descr-13')}</p>
          <h2>{i18n.__('blog-4-title-8')}</h2>
          <Picture webp={ImgW4_6} png={Img4_6} alt={i18n.__('blog-4-title-8')} />
          <p>{i18n.__('blog-4-descr-14')}</p>
          <p>{i18n.__('blog-4-descr-15')}</p>
        </>
      ),
      date: '02.11.2022',
    },
    {
      id: 'recepty-i-sovety-po-prigotovleniju-koktejlej-i-smuzi-iz-mikrozeleni',
      name: i18n.__('blog-5-name'),
      title: i18n.__('blog-5-title'),
      descr: i18n.__('blog-5-descr'),
      bg: [Img5_1, ImgW5_1],
      content: (
        <>
          <Picture
            webp={ImgW5_1}
            png={Img5_1}
            alt="Рецепты и советы по приготовлению коктейлей и смузи из микрозелени"
          />
          <p>{i18n.__('blog-5-descr-1')}</p>
          <p>{i18n.__('blog-5-descr-2')}</p>
          <h2>{i18n.__('blog-5-title-2')}</h2>
          <Picture webp={ImgW5_2} png={Img5_2} alt={i18n.__('blog-5-title-2')} />
          <p>{i18n.__('blog-5-descr-3')}</p>
          <h3>{i18n.__('blog-5-title-3')}</h3>
          <p>{i18n.__('blog-5-descr-4')}</p>
          <Picture webp={ImgW5_3} png={Img5_3} alt={i18n.__('blog-5-title-2')} />
          <p>{i18n.__('blog-5-descr-5')}</p>
          <p>{i18n.__('blog-5-descr-6')}</p>
          <h2>{i18n.__('blog-5-title-4')}</h2>
          <p>{i18n.__('blog-5-descr-7')}</p>
          <ul>
            <li>{i18n.__('blog-5-descr-8')}</li>
            <li>{i18n.__('blog-5-descr-9')}</li>
            <li>{i18n.__('blog-5-descr-10')}</li>
            <li>{i18n.__('blog-5-descr-11')}</li>
            <li>{i18n.__('blog-5-descr-12')}</li>
            <li>{i18n.__('blog-5-descr-13')}</li>
          </ul>
          <h2>{i18n.__('blog-5-title-5')}</h2>
          <p>{i18n.__('blog-5-descr-14')}</p>
          <h3>{i18n.__('blog-5-title-6')}</h3>
          <ul>
            <li>{i18n.__('blog-5-descr-15')}</li>
            <li>{i18n.__('blog-5-descr-16')}</li>
            <li>{i18n.__('blog-5-descr-17')}</li>
            <li>{i18n.__('blog-5-descr-18')}</li>
            <li>{i18n.__('blog-5-descr-19')}</li>
          </ul>
          <h3>{i18n.__('blog-5-title-7')}</h3>
          <ul>
            <li>{i18n.__('blog-5-descr-20')}</li>
            <li>{i18n.__('blog-5-descr-21')}</li>
            <li>{i18n.__('blog-5-descr-22')}</li>
            <li>{i18n.__('blog-5-descr-23')}</li>
            <li>{i18n.__('blog-5-descr-24')}</li>
          </ul>
          <h3>{i18n.__('blog-5-title-8')}</h3>
          <ul>
            <li>{i18n.__('blog-5-descr-25')}</li>
            <li>{i18n.__('blog-5-descr-26')}</li>
            <li>{i18n.__('blog-5-descr-27')}</li>
            <li>{i18n.__('blog-5-descr-28')}</li>
            <li>{i18n.__('blog-5-descr-29')}</li>
          </ul>
          <h3>{i18n.__('blog-5-title-9')}</h3>
          <ul>
            <li>{i18n.__('blog-5-descr-30')}</li>
            <li>{i18n.__('blog-5-descr-31')}</li>
            <li>{i18n.__('blog-5-descr-32')}</li>
            <li>{i18n.__('blog-5-descr-33')}</li>
            <li>{i18n.__('blog-5-descr-34')}</li>
          </ul>
        </>
      ),
      date: '17.06.2022',
    },
    {
      id: 'mikrozelen-dlja-ukreplenija-zdorovja',
      name: i18n.__('blog-6-name'),
      title: i18n.__('blog-6-title'),
      descr: i18n.__('blog-6-descr'),
      bg: [Img6_1, ImgW6_1],
      content: (
        <>
          <p>{i18n.__('blog-6-descr-1')}</p>
          <p>{i18n.__('blog-6-descr-2')}</p>
          <p>{i18n.__('blog-6-descr-3')}</p>
          <Picture webp={ImgW6_1} png={Img6_1} alt="Микрозелень для укрепления здоровья" />
          <h2>{i18n.__('blog-6-title-2')}</h2>
          <p>{i18n.__('blog-6-descr-4')}</p>
          <b>{i18n.__('blog-6-descr-5')}</b>
          <ul>
            <li>{i18n.__('blog-6-descr-6')}</li>
            <li>{i18n.__('blog-6-descr-7')}</li>
            <li>{i18n.__('blog-6-descr-8')}</li>
            <li>{i18n.__('blog-6-descr-9')}</li>
            <li>{i18n.__('blog-6-descr-10')}</li>
          </ul>
          <p>{i18n.__('blog-6-descr-11')}</p>
          <ul>
            <li>{i18n.__('blog-6-descr-12')}</li>
            <li>{i18n.__('blog-6-descr-13')}</li>
            <li>{i18n.__('blog-6-descr-14')}</li>
            <li>{i18n.__('blog-6-descr-15')}</li>
          </ul>
          <h3>{i18n.__('blog-6-title-3')}</h3>
          <p>{i18n.__('blog-6-descr-16')}</p>
          <p>{i18n.__('blog-6-descr-17')}</p>
          <b>{i18n.__('blog-6-descr-18')}</b>
          <ul>
            <li>{i18n.__('blog-6-descr-19')}</li>
            <li>{i18n.__('blog-6-descr-20')}</li>
          </ul>
          <p>{i18n.__('blog-6-descr-21')}</p>
          <h3>{i18n.__('blog-6-title-4')}</h3>
          <p>{i18n.__('blog-6-descr-22')}</p>
          <Picture webp={ImgW6_2} png={Img6_2} alt={i18n.__('blog-6-title-4')} />
          <p>{i18n.__('blog-6-descr-23')}</p>
          <p>{i18n.__('blog-6-descr-24')}</p>
          <h2>{i18n.__('blog-6-title-5')}</h2>
          <p>{i18n.__('blog-6-descr-25')}</p>
          <h3>{i18n.__('blog-6-title-6')}</h3>
          <p>{i18n.__('blog-6-descr-26')}</p>
          <h3>{i18n.__('blog-6-title-7')}</h3>
          <p>{i18n.__('blog-6-descr-27')}</p>
          <h3>{i18n.__('blog-6-title-8')}</h3>
          <p>{i18n.__('blog-6-descr-28')}</p>
          <h3>{i18n.__('blog-6-title-9')}</h3>
          <p>{i18n.__('blog-6-descr-29')}</p>
          <h3>{i18n.__('blog-6-title-10')}</h3>
          <p>{i18n.__('blog-6-descr-30')}</p>
          <h3>{i18n.__('blog-6-title-11')}</h3>
          <p>
            {i18n.__('blog-6-descr-31')}
            <Link to="/blog/pokupat-mikrozelen-ili-vyrashhivat-samostojatelno-razbiraemsja-v-voprose">
              {i18n.__('blog-6-link-1')}
            </Link>
          </p>
          <p>
            {i18n.__('blog-6-descr-32')}
            <Link to="/">{i18n.__('blog-6-link-2')}</Link>
          </p>
          <p>{i18n.__('blog-6-descr-33')}</p>
          <p>{i18n.__('blog-6-descr-34')}</p>
          <Picture webp={ImgW6_3} png={Img6_3} alt="Полезная для здоровья микрозелень" />
          <b>{i18n.__('blog-6-descr-35')}</b>
        </>
      ),
      date: '07.06.2022',
    },
    {
      id: 'ispolzovanie-mikrozeleni-v-restoranah',
      name: i18n.__('blog-7-name'),
      title: i18n.__('blog-7-title'),
      descr: i18n.__('blog-7-descr'),
      bg: [Img7_1, ImgW7_1],
      content: (
        <>
          <p>{i18n.__('blog-7-descr-1')}</p>
          <p>{i18n.__('blog-7-descr-2')}</p>
          <Picture webp={ImgW7_1} png={Img7_1} alt="Использование микрозелени в ресторанах" />
          <h2>{i18n.__('blog-7-title-2')}</h2>
          <p>{i18n.__('blog-7-descr-4')}</p>
          <p>{i18n.__('blog-7-descr-5')}</p>
          <h2>{i18n.__('blog-7-title-3')}</h2>
          <p>{i18n.__('blog-7-descr-6')}</p>
          <ul>
            <li>{i18n.__('blog-7-descr-7')}</li>
            <li>{i18n.__('blog-7-descr-8')}</li>
            <li>{i18n.__('blog-7-descr-9')}</li>
            <li>{i18n.__('blog-7-descr-10')}</li>
            <li>{i18n.__('blog-7-descr-11')}</li>
          </ul>
          <h3>{i18n.__('blog-7-title-4')}</h3>
          <Picture webp={ImgW7_2} png={Img7_2} alt="Правила хранения микрозелени в ресторане" />
          <p>{i18n.__('blog-7-descr-12')}</p>
          <h2>{i18n.__('blog-7-title-5')}</h2>
          <ul>
            <li>{i18n.__('blog-7-descr-13')}</li>
            <li>{i18n.__('blog-7-descr-14')}</li>
            <li>{i18n.__('blog-7-descr-15')}</li>
            <li>{i18n.__('blog-7-descr-16')}</li>
            <li>{i18n.__('blog-7-descr-17')}</li>
          </ul>
          <h2>{i18n.__('blog-7-title-6')}</h2>
          <Picture
            webp={ImgW7_3}
            png={Img7_3}
            alt="По каким правилам рестораны украшают блюда микрозеленью"
          />
          <p>{i18n.__('blog-7-descr-18')}</p>
          <ul>
            <li>{i18n.__('blog-7-descr-19')}</li>
            <li>{i18n.__('blog-7-descr-20')}</li>
            <li>{i18n.__('blog-7-descr-21')}</li>
            <li>{i18n.__('blog-7-descr-22')}</li>
            <li>{i18n.__('blog-7-descr-23')}</li>
          </ul>
          <b>{i18n.__('blog-7-title-7')}</b>
          <ul>
            <li>{i18n.__('blog-7-descr-24')}</li>
            <li>{i18n.__('blog-7-descr-25')}</li>
            <li>{i18n.__('blog-7-descr-26')}</li>
            <li>{i18n.__('blog-7-descr-27')}</li>
          </ul>
        </>
      ),
      date: '09.04.2022',
    },
    {
      id: '10-interesnyh-faktov-o-mikrozeleni',
      name: i18n.__('blog-8-name'),
      title: i18n.__('blog-8-title'),
      descr: i18n.__('blog-8-descr'),
      bg: [Img8_1, ImgW8_1],
      content: (
        <>
          <p>{i18n.__('blog-8-descr-1')}</p>
          <p>{i18n.__('blog-8-descr-2')}</p>
          <p>{i18n.__('blog-8-descr-3')}</p>
          <h2>{i18n.__('blog-8-title-2')}</h2>
          <Picture webp={ImgW8_1} png={Img8_1} alt="Факт №1 История происхождения микрозелени" />
          <p>{i18n.__('blog-8-descr-4')}</p>
          <ul>
            <li>{i18n.__('blog-8-descr-5')}</li>
            <li>{i18n.__('blog-8-descr-6')}</li>
            <li>{i18n.__('blog-8-descr-7')}</li>
            <li>{i18n.__('blog-8-descr-8')}</li>
            <li>{i18n.__('blog-8-descr-9')}</li>
            <li>{i18n.__('blog-8-descr-10')}</li>
          </ul>
          <p>{i18n.__('blog-8-descr-11')}</p>
          <h3>{i18n.__('blog-8-title-3')}</h3>
          <p>{i18n.__('blog-8-descr-12')}</p>
          <p>{i18n.__('blog-8-descr-13')}</p>
          <h3>{i18n.__('blog-8-title-4')}</h3>
          <p>{i18n.__('blog-8-descr-14')}</p>
          <p>{i18n.__('blog-8-descr-15')}</p>
          <h3>{i18n.__('blog-8-title-5')}</h3>
          <p>{i18n.__('blog-8-descr-16')}</p>
          <h3>{i18n.__('blog-8-title-6')}</h3>
          <p>{i18n.__('blog-8-descr-17')}</p>
          <p>{i18n.__('blog-8-descr-18')}</p>
          <h2>{i18n.__('blog-8-title-7')}</h2>
          <Picture webp={ImgW8_2} png={Img8_2} alt={i18n.__('blog-8-title-7')} />
          <p>{i18n.__('blog-8-descr-19')}</p>
          <ul>
            <li>{i18n.__('blog-8-descr-20')}</li>
            <li>{i18n.__('blog-8-descr-21')}</li>
            <li>{i18n.__('blog-8-descr-22')}</li>
            <li>{i18n.__('blog-8-descr-23')}</li>
            <li>{i18n.__('blog-8-descr-24')}</li>
            <li>{i18n.__('blog-8-descr-25')}</li>
            <li>{i18n.__('blog-8-descr-26')}</li>
          </ul>
          <p>{i18n.__('blog-8-descr-27')}</p>
          <h2>{i18n.__('blog-8-title-8')}</h2>
          <p>{i18n.__('blog-8-descr-28')}</p>
          <p>{i18n.__('blog-8-descr-29')}</p>
          <h2>{i18n.__('blog-8-title-9')}</h2>
          <p>{i18n.__('blog-8-descr-30')}</p>
          <h2>{i18n.__('blog-8-title-10')}</h2>
          <p>{i18n.__('blog-8-descr-31')}</p>
          <h2>{i18n.__('blog-8-title-11')}</h2>
          <p>{i18n.__('blog-8-descr-32')}</p>
        </>
      ),
      date: '03.03.2022',
    },
    {
      id: 'rasskazyvaem-gde-i-kak-kupit-mikrozelen-gotovuju-k-upotrebleniju-v-pishhu',
      name: i18n.__('blog-9-name'),
      title: i18n.__('blog-9-title'),
      descr: i18n.__('blog-9-descr'),
      bg: [Img9_1, ImgW9_1],
      content: (
        <>
          <p>{i18n.__('blog-9-descr-1')}</p>
          <Picture webp={ImgW9_1} png={Img9_1} alt="Купить микрозелень в сетевых магазинах" />
          <h2>{i18n.__('blog-9-title-2')}</h2>
          <p>{i18n.__('blog-9-descr-2')}</p>
          <p>{i18n.__('blog-9-descr-3')}</p>
          <p>{i18n.__('blog-9-descr-4')}</p>
          <h2>{i18n.__('blog-9-title-3')}</h2>
          <p>{i18n.__('blog-9-descr-5')}</p>
          <p>{i18n.__('blog-9-descr-6')}</p>
          <b>{i18n.__('blog-9-descr-7')}</b>
          <ul>
            <li>{i18n.__('blog-9-descr-8')}</li>
            <li>{i18n.__('blog-9-descr-9')}</li>
            <li>{i18n.__('blog-9-descr-10')}</li>
            <li>{i18n.__('blog-9-descr-11')}</li>
            <li>{i18n.__('blog-9-descr-12')}</li>
          </ul>
          <h3>{i18n.__('blog-9-title-4')}</h3>
          <p>{i18n.__('blog-9-descr-13')}</p>
          <h3>{i18n.__('blog-9-title-5')}</h3>
          <p>{i18n.__('blog-9-descr-14')}</p>
        </>
      ),
      date: '24.02.2022',
    },
    {
      id: 'pokupat-mikrozelen-ili-vyrashhivat-samostojatelno-razbiraemsja-v-voprose',
      name: i18n.__('blog-10-name'),
      title: i18n.__('blog-10-title'),
      descr: i18n.__('blog-10-descr'),
      bg: [Img10_1, ImgW10_1],
      content: (
        <>
          <p>{i18n.__('blog-10-descr-1')}</p>
          <p>{i18n.__('blog-10-descr-2')}</p>
          <p>{i18n.__('blog-10-descr-3')}</p>
          <Picture
            webp={ImgW10_1}
            png={Img10_1}
            alt="Стоит ли выращивать микрозелень самостоятельно"
          />
          <h2>{i18n.__('blog-10-title-2')}</h2>
          <b>{i18n.__('blog-10-descr-4')}</b>
          <p>{i18n.__('blog-10-descr-5')}</p>
          <h3>{i18n.__('blog-10-title-3')}</h3>
          <b>{i18n.__('blog-10-descr-6')}</b>
          <ul>
            <li>{i18n.__('blog-10-descr-7')}</li>
            <li>{i18n.__('blog-10-descr-8')}</li>
            <li>{i18n.__('blog-10-descr-9')}</li>
          </ul>
          <p>{i18n.__('blog-10-descr-10')}</p>
          <b>{i18n.__('blog-10-descr-11')}</b>
          <ul>
            <li>{i18n.__('blog-10-descr-12')}</li>
            <li>{i18n.__('blog-10-descr-13')}</li>
            <li>{i18n.__('blog-10-descr-14')}</li>
          </ul>
          <h2>{i18n.__('blog-10-title-4')}</h2>
          <p>{i18n.__('blog-10-descr-15')}</p>
          <p>{i18n.__('blog-10-descr-16')}</p>
          <p>{i18n.__('blog-10-descr-17')}</p>
          <h3>{i18n.__('blog-10-title-5')}</h3>
          <p>{i18n.__('blog-10-descr-18')}</p>
          <p>{i18n.__('blog-10-descr-19')}</p>
          <p>{i18n.__('blog-10-descr-20')}</p>
          <ul>
            <li>{i18n.__('blog-10-descr-21')}</li>
            <li>{i18n.__('blog-10-descr-22')}</li>
            <li>{i18n.__('blog-10-descr-23')}</li>
          </ul>
          <p>{i18n.__('blog-10-descr-24')}</p>
          <Picture webp={ImgW10_2} png={Img10_2} alt="Заказать доставку микрозелени" />
          <h3>{i18n.__('blog-10-title-6')}</h3>
          <p>{i18n.__('blog-10-descr-25')}</p>
        </>
      ),
      date: '17.02.2022',
    },
    {
      id: 'reczepty-kak-upotreblyat-mikrozelen-salaty-blyuda-i-smuzi',
      name: i18n.__('blog-11-name'),
      title: i18n.__('blog-11-title'),
      descr: i18n.__('blog-11-descr'),
      bg: [Img11_1, ImgW11_1],
      content: (
        <>
          <p>{i18n.__('blog-11-descr-1')}</p>
          <p>{i18n.__('blog-11-descr-2')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-3')}</li>
            <li>{i18n.__('blog-11-descr-4')}</li>
            <li>{i18n.__('blog-11-descr-5')}</li>
            <li>{i18n.__('blog-11-descr-6')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-7')}</p>
          <p>{i18n.__('blog-11-descr-8')}</p>
          <Picture webp={ImgW11_1} png={Img11_1} alt="Рецепты как употреблять микрозелень" />
          <h2>{i18n.__('blog-11-title-1')}</h2>
          <p>{i18n.__('blog-11-descr-9')}</p>
          <h3>{i18n.__('blog-11-title-2')}</h3>
          <p>{i18n.__('blog-11-descr-10')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-11')}</li>
            <li>{i18n.__('blog-11-descr-12')}</li>
            <li>{i18n.__('blog-11-descr-13')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-14')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-15')}</li>
            <li>{i18n.__('blog-11-descr-16')}</li>
            <li>{i18n.__('blog-11-descr-17')}</li>
            <li>{i18n.__('blog-11-descr-18')}</li>
            <li>{i18n.__('blog-11-descr-19')}</li>
            <li>{i18n.__('blog-11-descr-20')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-21')}</p>
          <p>{i18n.__('blog-11-descr-22')}</p>
          <p>{i18n.__('blog-11-descr-23')}</p>
          <h2>{i18n.__('blog-11-title-3')}</h2>
          <p>{i18n.__('blog-11-descr-24')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-25')}</li>
            <li>{i18n.__('blog-11-descr-26')}</li>
            <li>{i18n.__('blog-11-descr-27')}</li>
            <li>{i18n.__('blog-11-descr-28')}</li>
            <li>{i18n.__('blog-11-descr-29')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-30')}</p>
          <p>{i18n.__('blog-11-descr-31')}</p>
          <p>{i18n.__('blog-11-descr-32')}</p>
          <p>{i18n.__('blog-11-descr-33')}</p>
          <h2>{i18n.__('blog-11-title-4')}</h2>
          <p>{i18n.__('blog-11-descr-34')}</p>
          <p>{i18n.__('blog-11-descr-35')}</p>
          <p>{i18n.__('blog-11-descr-36')}</p>
          <p>{i18n.__('blog-11-descr-37')}</p>
          <h3>{i18n.__('blog-11-title-5')}</h3>
          <p>{i18n.__('blog-11-descr-38')}</p>
          <h3>{i18n.__('blog-11-title-6')}</h3>
          <p>{i18n.__('blog-11-descr-39')}</p>
          <p>{i18n.__('blog-11-descr-40')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-41')}</li>
            <li>{i18n.__('blog-11-descr-42')}</li>
            <li>{i18n.__('blog-11-descr-43')}</li>
            <li>{i18n.__('blog-11-descr-44')}</li>
            <li>{i18n.__('blog-11-descr-45')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-46')}</p>
          <p>{i18n.__('blog-11-descr-47')}</p>
          <h3>{i18n.__('blog-11-title-7')}</h3>
          <p>{i18n.__('blog-11-descr-48')}</p>
          <p>{i18n.__('blog-11-descr-49')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-50')}</li>
            <li>{i18n.__('blog-11-descr-51')}</li>
            <li>{i18n.__('blog-11-descr-52')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-53')}</p>
          <p>{i18n.__('blog-11-descr-54')}</p>
          <h3>{i18n.__('blog-11-title-8')}</h3>
          <p>{i18n.__('blog-11-descr-55')}</p>
          <p>{i18n.__('blog-11-descr-56')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-57')}</li>
            <li>{i18n.__('blog-11-descr-58')}</li>
            <li>{i18n.__('blog-11-descr-59')}</li>
            <li>{i18n.__('blog-11-descr-60')}</li>
            <li>{i18n.__('blog-11-descr-61')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-62')}</p>
          <p>{i18n.__('blog-11-descr-63')}</p>
          <p>{i18n.__('blog-11-descr-64')}</p>
          <h3>{i18n.__('blog-11-title-9')}</h3>
          <p>{i18n.__('blog-11-descr-65')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-66')}</li>
            <li>{i18n.__('blog-11-descr-67')}</li>
            <li>{i18n.__('blog-11-descr-68')}</li>
            <li>{i18n.__('blog-11-descr-69')}</li>
            <li>{i18n.__('blog-11-descr-70')}</li>
            <li>{i18n.__('blog-11-descr-71')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-72')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-73')}</li>
            <li>{i18n.__('blog-11-descr-74')}</li>
            <li>{i18n.__('blog-11-descr-75')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-76')}</p>
          <p>{i18n.__('blog-11-descr-77')}</p>
          <h3>{i18n.__('blog-11-title-10')}</h3>
          <p>{i18n.__('blog-11-descr-78')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-79')}</li>
            <li>{i18n.__('blog-11-descr-80')}</li>
            <li>{i18n.__('blog-11-descr-81')}</li>
            <li>{i18n.__('blog-11-descr-82')}</li>
            <li>{i18n.__('blog-11-descr-83')}</li>
            <li>{i18n.__('blog-11-descr-84')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-85')}</p>
          <p>{i18n.__('blog-11-descr-86')}</p>
          <h3>{i18n.__('blog-11-title-11')}</h3>
          <p>{i18n.__('blog-11-descr-87')}</p>
          <ul>
            <li>{i18n.__('blog-11-descr-88')}</li>
            <li>{i18n.__('blog-11-descr-89')}</li>
            <li>{i18n.__('blog-11-descr-90')}</li>
            <li>{i18n.__('blog-11-descr-91')}</li>
            <li>{i18n.__('blog-11-descr-92')}</li>
            <li>{i18n.__('blog-11-descr-93')}</li>
            <li>{i18n.__('blog-11-descr-94')}</li>
          </ul>
          <p>{i18n.__('blog-11-descr-95')}</p>
          <p>{i18n.__('blog-11-descr-96')}</p>
          <p>{i18n.__('blog-11-descr-97')}</p>
          <p>{i18n.__('blog-11-descr-98')}</p>
        </>
      ),
      date: '14.02.2022',
    },
    {
      id: 'top-5-produktov-dajushhih-vam-bodrost-i-jenergiju',
      name: i18n.__('blog-12-name'),
      title: i18n.__('blog-12-title'),
      descr: i18n.__('blog-12-descr'),
      bg: [Img12_1, ImgW12_1],
      content: (
        <>
          <p>{i18n.__('blog-12-descr-1')}</p>
          <p>
            <strong>{i18n.__('blog-12-descr-2')}</strong>
          </p>
          <h2>{i18n.__('blog-12-title-1')}</h2>
          <Picture webp={ImgW12_1} png={Img12_1} alt={i18n.__('blog-12-title-1')} />
          <p>{i18n.__('blog-12-descr-3')}</p>
          <p>{i18n.__('blog-12-descr-4')}</p>
          <ul>
            <li>{i18n.__('blog-12-descr-5')}</li>
            <li>{i18n.__('blog-12-descr-6')}</li>
            <li>{i18n.__('blog-12-descr-7')}</li>
          </ul>
          <p>{i18n.__('blog-12-descr-8')}</p>
          <h2>{i18n.__('blog-12-title-2')}</h2>
          <p>{i18n.__('blog-12-descr-9')}</p>
          <p>{i18n.__('blog-12-descr-10')}</p>
          <h2>{i18n.__('blog-12-title-3')}</h2>
          <p>{i18n.__('blog-12-descr-11')}</p>
          <p>{i18n.__('blog-12-descr-12')}</p>
          <h2>{i18n.__('blog-12-title-4')}</h2>
          <p>{i18n.__('blog-12-descr-13')}</p>
          <p>{i18n.__('blog-12-descr-14')}</p>
          <h2>{i18n.__('blog-12-title-5')}</h2>
          <Picture webp={ImgW12_2} png={Img12_2} alt={i18n.__('blog-12-title-5')} />
          <p>{i18n.__('blog-12-descr-15')}</p>
          <p>{i18n.__('blog-12-descr-16')}</p>
          <p>{i18n.__('blog-12-descr-17')}</p>
          <p>{i18n.__('blog-12-descr-18')}</p>
          <p>
            <strong>{i18n.__('blog-12-descr-19')}</strong>
          </p>
          <ul>
            <li>{i18n.__('blog-12-descr-20')}</li>
            <li>{i18n.__('blog-12-descr-21')}</li>
            <li>{i18n.__('blog-12-descr-22')}</li>
          </ul>
          <p>
            <strong>{i18n.__('blog-12-descr-23')}</strong>
          </p>
          <ul>
            <li>{i18n.__('blog-12-descr-24')}</li>
            <li>{i18n.__('blog-12-descr-25')}</li>
          </ul>
          <p>{i18n.__('blog-12-descr-26')}</p>
        </>
      ),
      date: '10.02.2022',
    },
    {
      id: 'lajfhaki-i-sovety-po-vyboru-mikrozeleni',
      name: i18n.__('blog-13-name'),
      title: i18n.__('blog-13-title'),
      descr: i18n.__('blog-13-descr'),
      bg: [Img13_1, ImgW13_1],
      content: (
        <>
          <p>{i18n.__('blog-13-descr-1')}</p>
          <Picture
            webp={ImgW13_1}
            png={Img13_1}
            alt="Проверенный магазин и проверенный производитель микрозелени"
          />
          <h2>{i18n.__('blog-13-descr-2')}</h2>
          <p>{i18n.__('blog-13-descr-3')}</p>
          <h3>{i18n.__('blog-13-descr-4')}</h3>
          <p>{i18n.__('blog-13-descr-5')}</p>
          <h3>{i18n.__('blog-13-descr-6')}</h3>
          <p>{i18n.__('blog-13-descr-7')}</p>
          <ul>
            <li>{i18n.__('blog-13-descr-8')}</li>
            <li>{i18n.__('blog-13-descr-9')}</li>
          </ul>
          <p>{i18n.__('blog-13-descr-10')}</p>
          <h3>{i18n.__('blog-13-descr-11')}</h3>
          <p>{i18n.__('blog-13-descr-12')}</p>
          <p>{i18n.__('blog-13-descr-13')}</p>
          <p>{i18n.__('blog-13-descr-14')}</p>
          <h3>{i18n.__('blog-13-descr-15')}</h3>
          <p>{i18n.__('blog-13-descr-16')}</p>
          <h2>{i18n.__('blog-13-descr-17')}</h2>
          <p>{i18n.__('blog-13-descr-18')}</p>
          <p>
            <strong>{i18n.__('blog-13-descr-19')}</strong>
          </p>
          <ul>
            <li>{i18n.__('blog-13-descr-20')}</li>
            <li>{i18n.__('blog-13-descr-21')}</li>
            <li>{i18n.__('blog-13-descr-22')}</li>
            <li>{i18n.__('blog-13-descr-23')}</li>
            <li>
              {i18n.__('blog-13-descr-24')}
              <Link to="/">{i18n.__('blog-13-link-1')}</Link>
            </li>
          </ul>
        </>
      ),
      date: '03.02.2022',
    },
    {
      id: 'mikrozelen-v-racione-pitanija-polza-dlja-zdorovja-fizicheskih-kondicij-i-lichnostnoj-produktivnosti',
      name: i18n.__('blog-14-name'),
      title: i18n.__('blog-14-title'),
      descr: i18n.__('blog-14-descr'),
      bg: [Img14_1, ImgW14_1],
      content: (
        <>
          <Picture
            webp={ImgW14_1}
            png={Img14_1}
            alt="Микрозелень в рационе питания: польза для здоровья, физических кондиций и личностной продуктивности"
          />
          <p>{i18n.__('blog-14-descr-1')}</p>
          <h2>{i18n.__('blog-14-descr-2')}</h2>
          <p>{i18n.__('blog-14-descr-3')}</p>
          <p>{i18n.__('blog-14-descr-4')}</p>
          <p>{i18n.__('blog-14-descr-5')}</p>
          <h3>{i18n.__('blog-14-descr-6')}</h3>
          <p>{i18n.__('blog-14-descr-7')}</p>
          <p>{i18n.__('blog-14-descr-8')}</p>
          <p>{i18n.__('blog-14-descr-9')}</p>
          <p>{i18n.__('blog-14-descr-10')}</p>
          <h4>{i18n.__('blog-14-descr-11')}</h4>
          <ul>
            <li>{i18n.__('blog-14-descr-12')}</li>
            <li>{i18n.__('blog-14-descr-13')}</li>
            <li>{i18n.__('blog-14-descr-14')}</li>
            <li>{i18n.__('blog-14-descr-15')}</li>
          </ul>
          <h3>{i18n.__('blog-14-descr-16')}</h3>
          <p>{i18n.__('blog-14-descr-17')}</p>
          <p>{i18n.__('blog-14-descr-18')}</p>
          <p>{i18n.__('blog-14-descr-19')}</p>
          <p>{i18n.__('blog-14-descr-20')}</p>
          <h2>{i18n.__('blog-14-descr-21')}</h2>
          <ul>
            <li>{i18n.__('blog-14-descr-22')}</li>
            <li>{i18n.__('blog-14-descr-23')}</li>
            <li>{i18n.__('blog-14-descr-24')}</li>
            <li>{i18n.__('blog-14-descr-25')}</li>
            <li>{i18n.__('blog-14-descr-26')}</li>
          </ul>
          <p>{i18n.__('blog-14-descr-27')}</p>
          <h3>{i18n.__('blog-14-descr-28')}</h3>
          <Picture
            webp={ImgW14_2}
            png={Img14_2}
            alt="Микрозелень в рационе питания: польза для здоровья, физических кондиций и личностной продуктивности"
          />
          <ul>
            <li>{i18n.__('blog-14-descr-29')}</li>
            <li>{i18n.__('blog-14-descr-30')}</li>
            <li>{i18n.__('blog-14-descr-31')}</li>
          </ul>
          <h2>{i18n.__('blog-14-descr-32')}</h2>
          <p>{i18n.__('blog-14-descr-33')}</p>
        </>
      ),
      date: '24.01.2022',
    },
    {
      id: 'produkty-uluchshajushhie-rabotu-mozga-prokachivaem-intellekt-i-pamjat-pri-pomoshhi-pravilnogo-pitanija',
      name: i18n.__('blog-15-name'),
      title: i18n.__('blog-15-title'),
      descr: i18n.__('blog-15-descr'),
      bg: [Img15_1, ImgW15_1],
      content: (
        <>
          <Picture
            webp={ImgW15_1}
            png={Img15_1}
            alt="Продукты, улучшающие работу мозга. Прокачиваем интеллект и память при помощи правильного питания"
          />
          <p>{i18n.__('blog-15-descr-1')}</p>
          <h2>{i18n.__('blog-15-descr-2')}</h2>
          <p>{i18n.__('blog-15-descr-3')}</p>
          <p>{i18n.__('blog-15-descr-4')}</p>
          <h2>{i18n.__('blog-15-descr-5')}</h2>
          <p>{i18n.__('blog-15-descr-6')}</p>
          <p>{i18n.__('blog-15-descr-7')}</p>
          <p>{i18n.__('blog-15-descr-8')}</p>
          <h2>{i18n.__('blog-15-descr-9')}</h2>
          <p>{i18n.__('blog-15-descr-10')}</p>
          <h2>{i18n.__('blog-15-descr-11')}</h2>
          <p>{i18n.__('blog-15-descr-12')}</p>
          <p>{i18n.__('blog-15-descr-13')}</p>
          <h2>{i18n.__('blog-15-descr-14')}</h2>
          <p>{i18n.__('blog-15-descr-15')}</p>
          <p>{i18n.__('blog-15-descr-16')}</p>
          <h2>{i18n.__('blog-15-descr-17')}</h2>
          <Picture
            webp={ImgW15_2}
            png={Img15_2}
            alt="Продукты, улучшающие работу мозга. Прокачиваем интеллект и память при помощи правильного питания"
          />
          <p>{i18n.__('blog-15-descr-18')}</p>
          <b>{i18n.__('blog-15-descr-19')}</b>
          <ul>
            <li>{i18n.__('blog-15-descr-20')}</li>
            <li>{i18n.__('blog-15-descr-21')}</li>
            <li>{i18n.__('blog-15-descr-22')}</li>
            <li>{i18n.__('blog-15-descr-23')}</li>
            <li>{i18n.__('blog-15-descr-24')}</li>
          </ul>
          <p>{i18n.__('blog-15-descr-25')}</p>
          <h2>{i18n.__('blog-15-descr-26')}</h2>
          <p>{i18n.__('blog-15-descr-27')}</p>
          <p>{i18n.__('blog-15-descr-28')}</p>
        </>
      ),
      date: '20.01.2022',
    },
    {
      id: 'o-znachimosti-sporta-v-zhizni-sovremennogo-cheloveka',
      name: i18n.__('blog-16-name'),
      title: i18n.__('blog-16-title'),
      descr: i18n.__('blog-16-descr'),
      bg: [Img16_1, ImgW16_1],
      content: (
        <>
          <Picture
            webp={ImgW16_1}
            png={Img16_1}
            alt="О значимости спорта в жизни современного человека"
          />
          <p>{i18n.__('blog-16-descr-1')}</p>
          <h2>{i18n.__('blog-16-descr-2')}</h2>
          <p>{i18n.__('blog-16-descr-3')}</p>
          <h3>{i18n.__('blog-16-descr-4')}</h3>
          <p>{i18n.__('blog-16-descr-5')}</p>
          <h3>{i18n.__('blog-16-descr-6')}</h3>
          <p>{i18n.__('blog-16-descr-7')}</p>
          <h3>{i18n.__('blog-16-descr-8')}</h3>
          <p>{i18n.__('blog-16-descr-9')}</p>
          <p>{i18n.__('blog-16-descr-10')}</p>
          <h3>{i18n.__('blog-16-descr-11')}</h3>
          <p>{i18n.__('blog-16-descr-12')}</p>
          <h3>{i18n.__('blog-16-descr-13')}</h3>
          <p>{i18n.__('blog-16-descr-14')}</p>
          <h3>{i18n.__('blog-16-descr-15')}</h3>
          <p>{i18n.__('blog-16-descr-16')}</p>
          <p>{i18n.__('blog-16-descr-17')}</p>
          <h3>{i18n.__('blog-16-descr-18')}</h3>
          <Picture
            webp={ImgW16_2}
            png={Img16_2}
            alt="О значимости спорта в жизни современного человека"
          />
          <p>{i18n.__('blog-16-descr-19')}</p>
        </>
      ),
      date: '17.01.2022',
    },
    {
      id: 'kak-vsjo-uspevat-na-rabote-formiruem-poleznye-privychki',
      name: i18n.__('blog-17-name'),
      title: i18n.__('blog-17-title'),
      descr: i18n.__('blog-17-descr'),
      bg: [Img17_1, ImgW17_1],
      content: (
        <>
          <Picture
            webp={ImgW17_1}
            png={Img17_1}
            alt="Как всё успевать на работе? Формируем полезные привычки"
          />
          <p>{i18n.__('blog-17-descr-1')}</p>
          <h2>{i18n.__('blog-17-descr-2')}</h2>
          <p>{i18n.__('blog-17-descr-3')}</p>
          <h3>{i18n.__('blog-17-descr-4')}</h3>
          <ul>
            <li>{i18n.__('blog-17-descr-5')}</li>
            <li>{i18n.__('blog-17-descr-6')}</li>
            <li>{i18n.__('blog-17-descr-7')}</li>
            <li>{i18n.__('blog-17-descr-8')}</li>
            <li>{i18n.__('blog-17-descr-9')}</li>
          </ul>
          <h2>{i18n.__('blog-17-descr-10')}</h2>
          <p>{i18n.__('blog-17-descr-11')}</p>
          <p>{i18n.__('blog-17-descr-12')}</p>
          <h2>{i18n.__('blog-17-descr-13')}</h2>
          <p>{i18n.__('blog-17-descr-14')}</p>
          <p>{i18n.__('blog-17-descr-15')}</p>
          <p>{i18n.__('blog-17-descr-16')}</p>
          <p>{i18n.__('blog-17-descr-17')}</p>
          <h2>{i18n.__('blog-17-descr-18')}</h2>
          <p>{i18n.__('blog-17-descr-19')}</p>
          <h2>{i18n.__('blog-17-descr-20')}</h2>
          <Picture
            webp={ImgW17_2}
            png={Img17_2}
            alt="Как всё успевать на работе? Формируем полезные привычки"
          />
          <p>{i18n.__('blog-17-descr-21')}</p>
          <p>{i18n.__('blog-17-descr-22')}</p>
          <ul>
            <li>{i18n.__('blog-17-descr-23')}</li>
            <li>{i18n.__('blog-17-descr-24')}</li>
            <li>{i18n.__('blog-17-descr-25')}</li>
          </ul>
          <p>{i18n.__('blog-17-descr-26')}</p>
          <h2>{i18n.__('blog-17-descr-27')}</h2>
          <p>{i18n.__('blog-17-descr-28')}</p>
          <p>{i18n.__('blog-17-descr-29')}</p>
          <h2>{i18n.__('blog-17-descr-30')}</h2>
          <p>{i18n.__('blog-17-descr-31')}</p>
        </>
      ),
      date: '10.02.2023',
    },
    {
      id: 'o-vkusah-ne-sporjat-no-kakaja-zhe-mikrozelen-samaja-vkusnaja-i-poleznaja',
      name: i18n.__('blog-18-name'),
      title: i18n.__('blog-18-title'),
      descr: i18n.__('blog-18-descr'),
      bg: [Img18_1, ImgW18_1],
      content: (
        <>
          <Picture
            webp={ImgW18_1}
            png={Img18_1}
            alt="О вкусах не спорят, но какая же микрозелень самая вкусная и полезная?"
          />
          <p>{i18n.__('blog-18-descr-1')}</p>
          <p>{i18n.__('blog-18-descr-2')}</p>
          <p>{i18n.__('blog-18-descr-3')}</p>
          <h2>{i18n.__('blog-18-descr-4')}</h2>
          <h3>{i18n.__('blog-18-title-1')}</h3>
          <p>{i18n.__('blog-18-descr-5')}</p>
          <p>{i18n.__('blog-18-descr-6')}</p>
          <h3>{i18n.__('blog-18-descr-7')}</h3>
          <p>{i18n.__('blog-18-descr-8')}</p>
          <p>{i18n.__('blog-18-descr-9')}</p>
          <p>{i18n.__('blog-18-descr-10')}</p>
          <p>{i18n.__('blog-18-descr-11')}</p>
          <h3>{i18n.__('blog-18-descr-12')}</h3>
          <p>{i18n.__('blog-18-descr-13')}</p>
          <p>{i18n.__('blog-18-descr-14')}</p>
          <h3>{i18n.__('blog-18-descr-15')}</h3>
          <p>{i18n.__('blog-18-descr-16')}</p>
          <p>{i18n.__('blog-18-descr-17')}</p>
          <h2>{i18n.__('blog-18-descr-18')}</h2>
          <Picture
            webp={ImgW18_2}
            png={Img18_2}
            alt="О вкусах не спорят, но какая же микрозелень самая вкусная и полезная?"
          />
          <p>{i18n.__('blog-18-descr-19')}</p>
          <b>{i18n.__('blog-18-descr-20')}</b>
          <ul>
            <li>{i18n.__('blog-18-descr-24')}</li>
            <li>{i18n.__('blog-18-descr-25')}</li>
            <li>{i18n.__('blog-18-descr-26')}</li>
            <li>{i18n.__('blog-18-descr-27')}</li>
            <li>{i18n.__('blog-18-descr-28')}</li>
            <li>{i18n.__('blog-18-descr-29')}</li>
            <li>{i18n.__('blog-18-descr-30')}</li>
            <li>{i18n.__('blog-18-descr-31')}</li>
          </ul>
          <b>{i18n.__('blog-18-descr-32')}</b>
          <ul>
            <li>{i18n.__('blog-18-descr-33')}</li>
            <li>{i18n.__('blog-18-descr-34')}</li>
            <li>{i18n.__('blog-18-descr-35')}</li>
            <li>{i18n.__('blog-18-descr-36')}</li>
            <li>{i18n.__('blog-18-descr-37')}</li>
            <li>{i18n.__('blog-18-descr-38')}</li>
            <li>{i18n.__('blog-18-descr-39')}</li>
            <li>{i18n.__('blog-18-descr-40')}</li>
          </ul>
          <b>{i18n.__('blog-18-descr-41')}</b>
          <ul>
            <li>{i18n.__('blog-18-descr-42')}</li>
            <li>{i18n.__('blog-18-descr-43')}</li>
            <li>{i18n.__('blog-18-descr-44')}</li>
            <li>{i18n.__('blog-18-descr-45')}</li>
            <li>{i18n.__('blog-18-descr-46')}</li>
            <li>{i18n.__('blog-18-descr-47')}</li>
            <li>{i18n.__('blog-18-descr-48')}</li>
            <li>{i18n.__('blog-18-descr-49')}</li>
            <li>{i18n.__('blog-18-descr-50')}</li>
            <li>{i18n.__('blog-18-descr-51')}</li>
            <li>{i18n.__('blog-18-descr-52')}</li>
            <li>{i18n.__('blog-18-descr-53')}</li>
            <li>{i18n.__('blog-18-descr-54')}</li>
            <li>{i18n.__('blog-18-descr-55')}</li>
            <li>{i18n.__('blog-18-descr-56')}</li>
          </ul>
          <p>{i18n.__('blog-18-descr-57')}</p>
        </>
      ),
      date: '04.01.2022',
    },
    {
      id: 'sostav-mikrozeleni-vitaminy-himicheskij-sostav-i-poleznye-veshhestva',
      name: i18n.__('blog-19-name'),
      title: i18n.__('blog-19-title'),
      descr: i18n.__('blog-19-descr'),
      bg: [Img19_1, ImgW19_1],
      content: (
        <>
          <Picture
            webp={ImgW19_1}
            png={Img19_1}
            alt="Состав микрозелени: витамины, химический состав и полезные вещества"
          />
          <p>{i18n.__('blog-19-descr-1')}</p>
          <p>{i18n.__('blog-19-descr-2')}</p>
          <h2>{i18n.__('blog-19-descr-3')}</h2>
          <Picture
            webp={ImgW19_2}
            png={Img19_2}
            alt="Состав микрозелени: витамины, химический состав и полезные вещества"
          />
          <p>{i18n.__('blog-19-descr-4')}</p>
          <p>{i18n.__('blog-19-descr-5')}</p>
          <p>{i18n.__('blog-19-descr-6')}</p>
          <p>{i18n.__('blog-19-descr-7')}</p>
          <h2>{i18n.__('blog-19-descr-8')}</h2>
          <p>{i18n.__('blog-19-descr-9')}</p>
          <ul>
            <li>{i18n.__('blog-19-descr-10')}</li>
            <li>{i18n.__('blog-19-descr-11')}</li>
            <li>{i18n.__('blog-19-descr-12')}</li>
            <li>{i18n.__('blog-19-descr-13')}</li>
          </ul>
          <p>{i18n.__('blog-19-descr-14')}</p>
          <h3>{i18n.__('blog-19-descr-15')}</h3>
          <p>{i18n.__('blog-19-descr-16')}</p>
          <ul>
            <li>{i18n.__('blog-19-descr-17')}</li>
            <li>{i18n.__('blog-19-descr-18')}</li>
            <li>{i18n.__('blog-19-descr-19')}</li>
            <li>{i18n.__('blog-19-descr-20')}</li>
            <li>{i18n.__('blog-19-descr-21')}</li>
          </ul>
          <p>{i18n.__('blog-19-title-1')}</p>
          <h2>{i18n.__('blog-19-descr-22')}</h2>
          <p>{i18n.__('blog-19-descr-23')}</p>
          <h3>{i18n.__('blog-19-descr-24')}</h3>
          <ul>
            <li>{i18n.__('blog-19-descr-25')}</li>
            <li>{i18n.__('blog-19-descr-26')}</li>
            <li>{i18n.__('blog-19-descr-27')}</li>
            <li>{i18n.__('blog-19-descr-28')}</li>
            <li>{i18n.__('blog-19-descr-29')}</li>
            <li>{i18n.__('blog-19-descr-30')}</li>
            <li>{i18n.__('blog-19-descr-31')}</li>
            <li>{i18n.__('blog-19-descr-32')}</li>
            <li>{i18n.__('blog-19-descr-33')}</li>
            <li>{i18n.__('blog-19-descr-34')}</li>
            <li>{i18n.__('blog-19-descr-35')}</li>
            <li>{i18n.__('blog-19-descr-36')}</li>
            <li>{i18n.__('blog-19-descr-37')}</li>
            <li>{i18n.__('blog-19-descr-38')}</li>
          </ul>

          <h2>{i18n.__('blog-19-descr-39')}</h2>
          <p>{i18n.__('blog-19-descr-40')}</p>
          <p>{i18n.__('blog-19-descr-41')}</p>
          <p>{i18n.__('blog-19-descr-42')}</p>
          <p>{i18n.__('blog-19-descr-43')}</p>
        </>
      ),
      date: '29.12.2021',
    },
    {
      id: 'top-10-produktov-dlja-pravilnogo-pitanija',
      name: i18n.__('blog-21-name'),
      title: i18n.__('blog-21-title'),
      descr: i18n.__('blog-21-descr'),
      bg: [Img21_1, ImgW21_1],
      content: (
        <>
          <Picture webp={ImgW21_1} png={Img21_1} alt="ТОП-10 продуктов для правильного питания" />
          <p>{i18n.__('blog-21-descr-1')}</p>
          <p>{i18n.__('blog-21-descr-2')}</p>
          <p>{i18n.__('blog-21-descr-3')}</p>
          <h2>{i18n.__('blog-21-descr-4')}</h2>
          <p>{i18n.__('blog-21-descr-5')}</p>
          <p>{i18n.__('blog-21-descr-6')}</p>
          <p>{i18n.__('blog-21-descr-7')}</p>
          <h2>{i18n.__('blog-21-descr-8')}</h2>
          <p>{i18n.__('blog-21-descr-9')}</p>
          <p>{i18n.__('blog-21-descr-10')}</p>
          <h2>{i18n.__('blog-21-descr-11')}</h2>
          <p>{i18n.__('blog-21-descr-12')}</p>
          <h2>{i18n.__('blog-21-descr-13')}</h2>
          <Picture webp={ImgW21_2} png={Img21_2} alt="ТОП-10 продуктов для правильного питания" />
          <p>{i18n.__('blog-21-descr-14')}</p>
          <p>{i18n.__('blog-21-descr-15')}</p>
          <h2>{i18n.__('blog-21-descr-16')}</h2>
          <p>{i18n.__('blog-21-descr-17')}</p>
          <p>{i18n.__('blog-21-descr-18')}</p>
          <h2>{i18n.__('blog-21-descr-19')}</h2>
          <p>{i18n.__('blog-21-descr-20')}</p>
          <p>{i18n.__('blog-21-descr-21')}</p>
          <h2>{i18n.__('blog-21-descr-22')}</h2>
          <p>{i18n.__('blog-21-descr-23')}</p>
          <p>{i18n.__('blog-21-descr-24')}</p>
          <p>{i18n.__('blog-21-descr-25')}</p>
          <h2>{i18n.__('blog-21-descr-26')}</h2>
          <p>{i18n.__('blog-21-descr-27')}</p>
          <b>{i18n.__('blog-21-descr-28')}</b>
          <ul>
            <li>{i18n.__('blog-21-descr-29')}</li>
            <li>{i18n.__('blog-21-descr-30')}</li>
            <li>{i18n.__('blog-21-descr-31')}</li>
            <li>{i18n.__('blog-21-descr-32')}</li>
            <li>{i18n.__('blog-21-descr-33')}</li>
          </ul>
          <h2>{i18n.__('blog-21-descr-34')}</h2>
          <p>{i18n.__('blog-21-descr-35')}</p>
          <p>{i18n.__('blog-21-descr-36')}</p>
          <h2>{i18n.__('blog-21-descr-37')}</h2>
          <p>{i18n.__('blog-21-descr-38')}</p>
          <p>{i18n.__('blog-21-descr-39')}</p>
          <h2>{i18n.__('blog-21-descr-40')}</h2>
          <p>{i18n.__('blog-21-descr-41')}</p>
        </>
      ),
      date: '21.12.2021',
    },
    {
      id: 'top-5-idej-chem-mozhno-perekusit-polezno-i-vkusno',
      name: i18n.__('blog-22-name'),
      title: i18n.__('blog-22-title'),
      descr: i18n.__('blog-22-descr'),
      bg: [Img22_1, ImgW22_1],
      content: (
        <>
          <Picture
            webp={ImgW22_1}
            png={Img22_1}
            alt="ТОП-5 идей чем можно перекусить полезно и вкусно"
          />
          <p>{i18n.__('blog-22-descr-1')}</p>
          <p>{i18n.__('blog-22-descr-2')}</p>
          <p>{i18n.__('blog-22-descr-3')}</p>
          <h2>{i18n.__('blog-22-descr-4')}</h2>
          <p>{i18n.__('blog-22-descr-5')}</p>
          <p>{i18n.__('blog-22-descr-6')}</p>

          <h2>{i18n.__('blog-22-descr-7')}</h2>
          <p>{i18n.__('blog-22-descr-8')}</p>
          <p>{i18n.__('blog-22-descr-9')}</p>
          <p>{i18n.__('blog-22-descr-10')}</p>

          <h2>{i18n.__('blog-22-descr-11')}</h2>
          <p>{i18n.__('blog-22-descr-12')}</p>

          <h2>{i18n.__('blog-22-descr-13')}</h2>
          <p>{i18n.__('blog-22-descr-14')}</p>
          <p>{i18n.__('blog-22-descr-15')}</p>
          <p>{i18n.__('blog-22-descr-16')}</p>

          <h2>{i18n.__('blog-22-descr-17')}</h2>
          <Picture webp={ImgW22_2} png={Img22_2} alt={i18n.__('blog-22-descr-17')} />
          <p>{i18n.__('blog-22-descr-18')}</p>
          <p>{i18n.__('blog-22-descr-19')}</p>
          <p>{i18n.__('blog-22-descr-20')}</p>

          <h3>{i18n.__('blog-22-descr-21')}</h3>
          <ul>
            <li>{i18n.__('blog-22-descr-22')}</li>
            <li>{i18n.__('blog-22-descr-23')}</li>
            <li>{i18n.__('blog-22-descr-24')}</li>
            <li>{i18n.__('blog-22-descr-25')}</li>
            <li>{i18n.__('blog-22-descr-26')}</li>
          </ul>
          <p>{i18n.__('blog-22-descr-27')}</p>
          <p>{i18n.__('blog-22-descr-28')}</p>
        </>
      ),
      date: '21.12.2021',
    },
  ];

  return (
    <BrowserRouter>
      <Context.Provider value={{ isModalCTX, setModalCTX, i18n }}>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<MainPage articles={configArticles} />} />
            <Route path="/blog" element={<BlogPage articles={configArticles} />} />
            <Route path="/blog/:articleID" element={<ArticlePage articles={configArticles} />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Context.Provider>
    </BrowserRouter>
  );
};

export default Application;
